/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import Modal from "react-modal";
import { withFirebase } from "library/Firebase";
import Suboptions from "./Suboptions";
import * as styles from "./styles";

const ReportIssue = ({
  workerData,
  gigID,
  gigData,
  reportOpen,
  setReportModalOpen,
}) => {
  const [option, setOption] = useState(0);
  const [hideOptions, setHideOptions] = useState(true);
  const [certOption, setCertOption] = useState(false);

  // switch to sub-options screen & pass option prop to sub-options
  const handleClick = (selectedOption) => {
    if (selectedOption === 2) {
      setCertOption(!certOption);
    }

    setOption(selectedOption);
    setHideOptions(!hideOptions);
  };

  return (
    <Modal
      isOpen={reportOpen}
      onRequestClose={() => {
        setOption(0);
        setHideOptions(true);
        setReportModalOpen(false);
      }}
      css={styles.modalContainer}
    >
      <button
        type="button"
        onClick={() => setReportModalOpen(false)}
        className="closeBtn"
      >
        X
      </button>
      {hideOptions ? (
        <div css={styles.reportBody}>
          <div className="header">
            <h2 className="title">Report an Issue</h2>
            <p>
              You are reporting
              <span className="boldFont">
                {workerData.firstName} {workerData.lastName}
              </span>
            </p>
          </div>
          <p>Please select from the following options:</p>
          <div>
            <button
              type="button"
              className="optionBtn"
              onClick={() => handleClick(1)}
            >
              The worker is not here for the job and I have attempted to contact
              the worker via the number provided
            </button>

            {gigData.certs.length == 0 ? null : (
              <button
                type="button"
                className="optionBtn"
                onClick={() => handleClick(2)}
              >
                The worker does not possess the relevant certificate/license
                stated on their profile that I have set as a requirement to
                perform this job
              </button>
            )}
            <button
              type="button"
              className="optionBtn"
              onClick={() => handleClick(3)}
            >
              The worker arrived without the mentioned equipment or attire
              specified in the gig description.
            </button>
            <button
              type="button"
              className="optionBtn"
              onClick={() => handleClick(4)}
            >
              The worker is not following instructions provided and is causing a
              disruption that is detrimental to my business.
            </button>
          </div>
        </div>
      ) : (
        <div>
          <Suboptions
            workerData={workerData}
            gigID={gigID}
            gigData={gigData}
            option={option}
            setOption={setOption}
            hideOptions={hideOptions}
            setHideOptions={setHideOptions}
            certOption={certOption}
            setCertOption={setCertOption}
            setReportModalOpen={setReportModalOpen}
          />
        </div>
      )}
    </Modal>
  );
};

export default withFirebase(ReportIssue);
