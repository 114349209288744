/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { formInput, button, centerBox, addButton } from 'theme/styleBlocks';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { bp, mq } from 'constants/mediaQueries';

const DateTimeForm = ({
  addDateTime,
  checkDateFields,
  checkOverlap,
  sameWorker,
  dateFieldsChecked,
  noDates,
}) => {
  const [date, setDate] = useState('');
  const [nextDay, setNextDay] = useState(false);
  const [highlightButton, setHighlightButton] = useState(false);
  const [paidBreak, setPaidBreak] = useState(true);
  const {
    setError,
    errors,
    clearErrors,
    handleSubmit,
    control,
    reset,
    formState,
    setValue,
    register,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      day: '',
      startTime: '',
      endTime: '',
      breakTime: 0,
    },
  });
  const watchDay = watch('day');
  const watchTime = watch(['startTime', 'endTime']);
  // useEffect(() => {
  //   if (formState.isSubmitted && !Object.keys(errors).length) {
  //     return reset();
  //   }
  // }, [formState, reset, errors]);
  useEffect(() => {
    if (checkDateFields) {
      if (noDates) {
        setHighlightButton(true);
      }
      if (!noDates) {
        setHighlightButton(false);
      }
      if (!watchDay) {
        setError('day', {
          type: 'required',
        });
      }
      if (watchDay) {
        clearErrors('day');
      }
      if (!watchTime.startTime) {
        setError('startTime', {
          type: 'required',
        });
      }
      if (watchTime.startTime) {
        clearErrors('startTime');
      }
      if (!watchTime.endTime) {
        setError('endTime', {
          type: 'required',
        });
      }
      if (watchTime.endTime) {
        clearErrors('endTime');
      }
      dateFieldsChecked();
    }
  }, [checkDateFields]);
  useEffect(() => {
    if (watchTime.startTime && watchTime.endTime) {
      const start = moment(watchTime.startTime).milliseconds(0).seconds(0);
      const end = moment(watchTime.endTime).milliseconds(0).seconds(0);
      const diff = end.diff(start);
      if (diff < 0) {
        return setNextDay(true);
      }
      return setNextDay(false);
    }
    setNextDay(false);
  }, [watchTime]);
  useEffect(() => {
    if (watchDay) {
      const day = watchDay.getDate();
      const month = watchDay.getMonth();
      const year = watchDay.getFullYear();
      const start = moment(watchTime.startTime).set({
        year,
        month,
        date: day,
      });
      console.log(start);
      if (start.isBefore(moment())) {
        setValue('startTime', '');
      }
    }
  }, [watchDay]);

  const onSubmit = (data) => {
    setHighlightButton(false);
    if (sameWorker) {
      if (checkOverlap(data)) return;
    }
    const start = moment(data.startTime).milliseconds(0).seconds(0);
    const end = moment(data.endTime).milliseconds(0).seconds(0);
    const day = moment(data.day).format('MMM D, YYYY');
    let totalHours = end.diff(start);
    if (nextDay) {
      totalHours += 86400000;
    }

    addDateTime({
      start: start.format('h:mm A'),
      end: end.format('h:mm A'),
      nextDay,
      day,
      hours: totalHours / 3600000,
      paidBreak,
      breakTime: parseInt(data.breakTime, 10),
    });
    reset();
  };

  const setMinTime = () => {
    const dateSelected = moment(watchDay);

    if (dateSelected.isAfter(moment())) {
      return null;
    }

    if (dateSelected.isSame(moment(), 'day')) {
      return new Date();
    }
  };
  const setMaxTime = () => {
    const dateSelected = moment(watchDay);
    const today = moment();
    if (dateSelected.isAfter(moment())) {
      return null;
    }

    if (dateSelected.isSame(moment(), 'day')) {
      return moment().hours(23).minutes(59).toDate();
    }
  };

  const checkBreak = () => {};

  return (
    <div
      css={{
        gridColumn: '1/4',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: 40,
        marginBottom: 20,
      }}
    >
      <p css={{ gridColumn: '1/4' }}>
        Please <strong>add</strong> each date and time that you require workers
        for this gig in addition to the hourly rate of pay.
      </p>
      <div
        css={[
          formInput,
          {
            gridColumn: '1/4',
            [mq(bp.large)]: {
              gridColumn: '1/2',
            },
            '.react-datepicker-wrapper': {
              width: '100%',
            },
            input: {
              borderColor: errors.day && 'red',
            },
          },
        ]}
      >
        <label>Date</label>
        <Controller
          control={control}
          name="day"
          rules={{ required: true }}
          render={({ value, onChange }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              minDate={new Date()}
              placeholderText="Work Date"
            />
          )}
        />
      </div>
      <div
        css={[
          formInput,
          {
            gridColumn: '1/4',
            [mq(bp.large)]: {
              gridColumn: '2/3',
            },
            '.react-datepicker-wrapper': {
              width: '100%',
            },
            input: {
              borderColor: errors.breakTime && 'red',
            },
          },
        ]}
      >
        <label>Break</label>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Controller
            control={control}
            name="breakTime"
            rules={{
              required: true,
              validate: {
                lessThanTotalHours: (value) => {
                  const start = moment(watchTime.startTime)
                    .milliseconds(0)
                    .seconds(0);
                  const end = moment(watchTime.endTime)
                    .milliseconds(0)
                    .seconds(0);
                  let totalHours = end.diff(start);
                  if (nextDay) {
                    totalHours += 86400000;
                  }
                  const breakMilli = value * 60000;
                  return totalHours > breakMilli;
                },
              },
            }}
            render={({ value, onChange }) => (
              <input type="number" onChange={onChange} value={value} />
            )}
          />
          <span css={{ marginLeft: 8 }}>minutes</span>
        </div>
        <div
          css={{
            '& input[type="checkbox"]': {
              width: 'unset',
              display: 'inline-block',
              marginRight: 8,
            },
          }}
        >
          <input
            type="checkbox"
            name="paidBreak"
            onChange={(e) => setPaidBreak(e.target.checked)}
            checked={paidBreak}
          />
          <span>Paid Break</span>
        </div>
      </div>
      <div
        css={[
          formInput,
          {
            gridColumn: '1/4',
            [mq(bp.large)]: {
              gridColumn: '1/2',
            },
            '.react-datepicker-wrapper': { width: '100%' },
            '.react-datepicker__header--time': {
              display: 'none',
            },
            '.react-datepicker__time-container': { width: 150 },
            '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box': {
              width: 150,
            },
            '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item': {
              color: '#272727',
              fontSize: 16,
            },
            '.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled': {
              background: 'lightgray !important',
            },
            input: {
              borderColor: (errors.startTime || errors.breakTime) && 'red',
            },
          },
        ]}
      >
        <label>Start Time</label>
        <Controller
          control={control}
          name="startTime"
          rules={{ required: true }}
          render={({ value, onChange }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              minTime={setMinTime()}
              maxTime={setMaxTime()}
              timeCaption="Time"
              dateFormat="h:mm aa"
              placeholderText="Start Time"
            />
          )}
        />
      </div>
      <div
        css={[
          formInput,
          {
            gridColumn: '1/4',
            marginBottom: 0,
            [mq(bp.large)]: {
              gridColumn: '2/3',
            },
            '.react-datepicker-wrapper': { width: '100%' },
            '.react-datepicker__header--time': {
              display: 'none',
            },
            '.react-datepicker__time-container': { width: 150 },
            '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box': {
              width: 150,
            },
            '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item': {
              color: '#272727',
              fontSize: 16,
            },
            input: {
              borderColor: (errors.endTime || errors.breakTime) && 'red',
            },
          },
        ]}
      >
        <label>End Time</label>
        <Controller
          control={control}
          name="endTime"
          rules={{ required: true }}
          render={({ value, onChange }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              placeholderText="End Time"
            />
          )}
        />
        {nextDay ? <span css={{ color: 'red' }}>Next Day</span> : null}
      </div>
      {errors.day || errors.endTime || errors.startTime || highlightButton ? (
        <div css={{ gridColumn: '1/4' }}>
          <p css={{ color: 'red', margin: 0 }}>Please Add a work date</p>
        </div>
      ) : null}
      {errors.breakTime ? (
        <div css={{ gridColumn: '1/4' }}>
          <p css={{ color: 'red', margin: 0 }}>
            Break cannot be greater than Total Hours
          </p>
        </div>
      ) : null}
      <div
        css={{
          gridColumn: '1/4',
          marginTop: 15,
          [mq(bp.large)]: {
            gridColumn: '1/4',
            marginTop: 0,
          },
        }}
      >
        <button
          type="button"
          onClick={handleSubmit(onSubmit)}
          css={[
            addButton,
            {
              borderColor:
                errors.day ||
                errors.endTime ||
                errors.startTime ||
                errors.breakTime ||
                highlightButton
                  ? 'red !important'
                  : '#002E6D',
            },
          ]}
        >
          + Add this work date
        </button>
      </div>
    </div>
  );
};

export default DateTimeForm;
