/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import FaqItem from './FaqItem';
import {} from 'theme/styleBlocks';

const Faq = () => {
  const [faqs, setFaqs] = useState([
    {
      question: 'How do I find workers for a job/gig?',
      answer: (
        <div>
          <p>There are two ways you can be booked for a gig.</p>
          <ul>
            <li>
              By requesting recommended workers shown to you after creating a
              gig.
            </li>
            <li>
              Workers can also be booked by ‘claiming the gig’ when it appears
              on their list of available gigs.
            </li>
            <li>
              Please note that gigs are claimed and therefore booked by workers
              on a first come, first served basis till all vacancies you have
              made for a particular gig are filled.
            </li>
          </ul>
        </div>
      ),
    },
    {
      question: 'When does my card get charged?',
      answer: (
        <div>
          <ul>
            <li>
            You will only be charged for gigs completed by worker(s) at the end of the gig upon confirming gig completion for each worker.
            </li>
          </ul>
        </div>
      ),
    },
    {
      question: 'When I create a gig, is it made public immediately?',
      answer: (
        <div>
          <ul>
            <li>
              If you choose to invite a recommended worker, your gig will only
              be made public 2 hours later. This is to allow time for the
              recommended worker(s) you’ve sent a request to, to claim your gig
              before opening it up to a larger pool of workers.{' '}
            </li>
            <li>
              If however, you do not invite any recommended workers, your gig
              will be made public and workers will be able to claim your gig on
              a first come, first served basis till all vacancies are filled.
            </li>
          </ul>
        </div>
      ),
    },
    {
      question:
        'I’ve booked a worker for a gig! Is there anything I need to do or prepare?',
      answer: (
        <div>
          <p>Things to prepare once you’ve booked a worker:</p>
          <ul>
            <li>
              A brief introduction of your business and explanation of what
              needs to be done as well as expectations so that you can brief the
              worker upon arrival. (We recommend creating a short
              script/document that can be reused).
            </li>
            <li>
              Any attire/equipment that you might require the worker to wear/use
              for the job (eg. apron, mask, gloves, goggles, etc).
            </li>
          </ul>
        </div>
      ),
    },
    {
      question: 'What to do when a worker arrives for a gig?',
      answer: (
        <div>
          <ul>
            <li>
              When a worker arrives for a gig they will be prompted to
              “check-in” for it with the “point of contact” that you have
              identified in the gig creation process. You will then be prompted
              to confirm their “check-in” on your device.
            </li>
            <li>
              After the “check-in” process is completed please brief the worker
              and give them instructions so that they can carry out their job.
            </li>
            <li>
              Once the job is completed / time is up, both you and the worker
              will receive prompts to confirm that the gig has been completed
              before the worker leaves.
            </li>
            <li>
              After this check-out process is completed please review the worker
              by answering three simple yes or no questions.
            </li>
          </ul>
        </div>
      ),
    },
    {
      question:
        'What happens if I need to cancel a gig where I have already booked a worker?',
      answer: (
        <div>
          <p>
            In the event that a cancellation is made less than 24 hours before
            the performance of the service is to begin, you will be required to
            pay half of the cost of the Service Engagement. The cost includes
            both the payment to the WorkBriefly Worker and the fee paid to
            WorkBriefly.
          </p>
        </div>
      ),
    },
    {
      question: 'Where is WorkBriefly available?',
      answer: (
        <div>
          <p>
            WorkBriefly is quickly expanding across the Canada and currently
            available in:{' '}
          </p>
          <ul>
            <li>British Columbia</li>
          </ul>
        </div>
      ),
    },
    {
      question: 'What jobs does WorkBriefly help Businesses staff?',
      answer: (
        <div>
          <p>WorkBriefly helps find workers for a variety of jobs, such as: </p>
          <ul>
            <li>Warehouse operations</li>
            <li>General labor</li>
            <li>Food & Beverage staff</li>
            <li>Food production</li>
            <li>Event staffing</li>
            <li>Washing & cleaning</li>
            <li>Administrative</li>
            <li>Merchandising</li>
          </ul>
        </div>
      ),
    },
    {
      question: 'How does WorkBriefly work?',
      answer: (
        <div>
          <p>
            We designed WorkBriefly to make finding and offering work to
            frontline workers a simple and affordable process:{' '}
          </p>
          <ul>
            <li>
              Create and post a job/gig so that workers can decide if it’s a
              good fit for them.
            </li>
            <li>
              Choose to invite recommended workers who we think would be most
              suited for the job or accept applicants.
            </li>
            <li>
              The job gets accepted by verified worker(s), reducing the time and
              cost of recruiting compared to traditional staffing solutions.
            </li>
            <li>
              Rate worker(s) after a job is completed and their payment is
              processed through WorkBriefly.
            </li>
          </ul>
        </div>
      ),
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div>
      {faqs.map((faq, i) => (
        <FaqItem faq={faq} index={i} toggleFAQ={toggleFAQ} />
      ))}
    </div>
  );
};

export default Faq;
