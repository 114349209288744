/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { button } from "theme/styleBlocks";

import { ReactComponent as GreenCheck } from "assets/icons/successChip.svg";
import { bp, mq } from "constants/mediaQueries";
import {
  useHistory,
  Route,
  Switch,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { withFirebase } from "library/Firebase";
import { ReactComponent as Verified } from "assets/icons/verified.svg";
import { ReactComponent as NotVerified } from "assets/icons/notVerified.svg";
import { ReactComponent as BackArrow } from "assets/icons/arrowBack.svg";
import ExpBlock from "components/ExpBlock/ExpBlock";

const Recommended = ({
  recommendedWorkers,
  onWorkerProfileClick,
  onWorkerRequestClick,
  onLoadMoreRecommendedWorkers,
  showLoadMore,
  recommendedWorkerLoading,
}) => {
  const showRecommended = () => {
    if (recommendedWorkers.length > 0) {
      return (
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridColumnGap: 40,
            gridRowGap: 40,
            marginBottom: 40,
          }}
        >
          {recommendedWorkers.map((worker, i) => {
            return (
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                  padding: 20,
                }}
                key={i}
              >
                <img
                  src={worker.img}
                  alt=""
                  css={{ borderRadius: "50%", width: 100 }}
                />
                <p
                  css={{
                    fontSize: 16,
                    color: "#13327C",
                    fontWeight: "bold",
                  }}
                >
                  {worker.name}
                </p>
                <button
                  type="button"
                  css={(theme) => [
                    button(
                      theme.buttons.type_light,
                      theme.buttons.bg1,
                      theme.buttons.hover1
                    ),
                    {
                      marginBottom: 8,
                      minWidth: "unset !important",
                      width: "100% !important",
                    },
                  ]}
                  onClick={() => onWorkerProfileClick(worker)}
                >
                  View Profile
                </button>
                <button
                  type="button"
                  css={(theme) => [
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    ),
                    {
                      minWidth: "unset !important",
                      width: "100% !important",
                      paddingLeft: 0,
                      paddingRight: 0,
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    },
                  ]}
                  onClick={() => onWorkerRequestClick(worker.workerID)}
                  disabled={worker.requestSent}
                >
                  {worker.requestSent ? (
                    <React.Fragment>
                      <span>
                        <GreenCheck
                          css={{ width: 24, height: 24, marginRight: 8 }}
                        />
                      </span>{" "}
                      Request Sent
                    </React.Fragment>
                  ) : (
                    "Send Request"
                  )}
                </button>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <div
        css={{
          textAlign: "center",
          padding: 20,
          border: "1px dashed #979797",
          borderRadius: 3,
          marginBottom: 40,
        }}
      >
        <h2>No Recommended Workers</h2>
        <p>
          Unfortunately there are no recommended workers for the gig you have
          created. However, your gig is now available for workers to claim on a
          first come, first served basis until all vacancies are filled.
        </p>
      </div>
    );
  };
  return (
    <div
      css={(theme) => ({
        padding: "20px 15px",
        margin: "0px auto 65px",
        [mq(bp.small)]: {
          width: "90vw",
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: "60px 20px 60px 60px",
          margin: 0,
          width: "100%",
        },
      })}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          marginBottom: 80,
        }}
      >
        <GreenCheck css={{ width: 50, height: 50 }} />
        <h1
          css={{
            fontSize: 24,
            [mq(bp.large)]: { fontSize: 32, marginLeft: 10, marginBottom: 0 },
          }}
        >
          Gig Successfully Created
        </h1>
      </div>
      <div
        css={{
          border: "1px solid",
          background: "#fff",
          padding: 20,
          maxWidth: 700,
        }}
      >
        <h2>Recommended Workers</h2>
        <p>
          Here are some workers that we think you may be looking for. Invite
          them to claim your gig by sending them a request. If a request is
          sent, your gig will only be made public after two hours in order to
          allow requestees a chance to claim your gig.
        </p>
        <p>
          Note: If you do not send any requests to Recommended Workers, workers
          will be able to claim your gig immediately on a first come, first
          served basis until all vacancies are filled.
        </p>

        {recommendedWorkerLoading ? (
          <div css={{ marginBottom: 40 }}>
            <h2>Loading...</h2>
          </div>
        ) : (
          showRecommended()
        )}
        {showLoadMore ? (
          <div css={{ textAlign: "center", marginBottom: 40 }}>
            <button
              onClick={onLoadMoreRecommendedWorkers}
              type="button"
              css={{
                border: "none",
                fontSize: 14,
                color: "#13327C",
                fontWeight: "bold",
              }}
            >
              View More Workers
            </button>
          </div>
        ) : null}
        <div css={{ textAlign: "right" }}>
          <a
            href="/gigs/active"
            // onClick={() => history.push('/gigs/active')}
            css={(theme) => [
              button(
                theme.buttons.type_dark,
                theme.buttons.bg2,
                theme.buttons.hover2
              ),
              { textAlign: "center" },
            ]}
          >
            Continue
          </a>
        </div>
      </div>
    </div>
  );
};

const WorkerProfile = ({
  workerData,
  firebase,
  onWorkerRequestClick,
  selectedWorker,
}) => {
  const [loading, setLoading] = useState(true);
  const [completedGigs, setCompletedGigs] = useState([]);

  useEffect(() => {
    if (workerData) {
      setLoading(false);
    }
  }, [workerData]);
  useEffect(() => {
    if (!loading && workerData.completedGigs) {
      firebase.getGigs(workerData.completedGigs, "completed").then((gigs) => {
        setCompletedGigs(gigs);
      });
    }
  }, [workerData, loading]);
  const badgeDisplay = () => {
    if (
      workerData.setup.bgCheckSent &&
      workerData.setup.bgCheckPayed &&
      workerData.isVerifiedClean
    ) {
      return (
        <Verified
          css={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 30,
            height: "auto",
          }}
        />
      );
    }
    if (!workerData.isVerifiedClean) {
      return (
        <NotVerified
          css={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 30,
            height: "auto",
          }}
        />
      );
    }
  };
  const formatedExpList = () => {
    const data = workerData.exp.reduce((acc, item) => {
      const array = [...acc];
      const monthsDecimal = item.months / 12;
      const years = Math.floor((item.years + monthsDecimal) * 100) / 100;
      const index = array.findIndex(
        (exp) => exp.industry === item.industry.label
      );
      if (index < 0) {
        array.push({
          industry: item.industry.label,
          years,
          exp: [item],
        });
      }
      if (index >= 0) {
        const obj = {
          industry: item.industry.label,
          years: array[index].years + years,
          exp: [...array[index].exp, item],
        };
        array[index] = obj;
      }

      return array;
      // array.push();
      // console.log(years);
    }, []);
    return data;
  };
  return (
    <div css={{ height: "100%" }}>
      {loading ? (
        "loading..."
      ) : (
        <div
          css={{
            flex: 1,
            display: "flex",
            background: "#FAFAFA",
            height: "100%",
          }}
        >
          <div
            css={{
              width: "100%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              marginBottom: 65,
              background: "rgba(217, 224, 233, 0.5)",
              [mq(bp.large)]: {
                flexDirection: "row",
                margin: "0",
              },
            }}
          >
            <div
              css={(theme) => ({
                background: theme.colors.light,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "20px",
                [mq(bp.large)]: {
                  padding: "20px 40px",
                  justifyContent: "flex-start",
                  width: "30%",
                  borderBottomRightRadius: 47,
                  borderTopRightRadius: 47,
                },
              })}
            >
              <div>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <BackArrow css={{ marginRight: 8 }} />
                  <Link to="/create/complete" css={{ color: "#13327C" }}>
                    Back to Recommended Workers
                  </Link>
                </div>
                <h1
                  css={{
                    textAlign: "center",
                    display: "none",
                    [mq(bp.large)]: { display: "block" },
                  }}
                >
                  View Profile
                </h1>
                <div
                  css={{
                    textAlign: "center",
                    [mq(bp.large)]: {
                      marginTop: 75,
                    },
                  }}
                >
                  <div
                    css={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <div
                      css={{
                        width: 150,
                        height: 150,
                        borderRadius: "50%",
                        overflow: "hidden",
                        border: "1px solid lightgrey",
                        background: "#ECF0F4",
                      }}
                    >
                      <img
                        src={workerData.profileImage || ""}
                        alt=""
                        css={{
                          objectFit: "contain",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                    {badgeDisplay()}
                  </div>
                </div>
                <h2
                  css={(theme) => ({
                    textAlign: "center",
                    color: theme.colors.primary,
                    fontWeight: "bold",
                    fontSize: 24,
                  })}
                >
                  {workerData.firstName} {workerData.lastName}
                </h2>
                {/* <div
                  css={{
                    textAlign: 'center',
                    backgroundColor: '#FFF0C6',
                    padding: '8px 20px',
                    marginBottom: 20,
                    p: { margin: '0 0 8px 0' },
                  }}
                >
                  <div css={{ marginBottom: 24 }}>
                    <p>98% On Time</p>
                    <p>100% Dressed Appropriately</p>
                    <p>95% Would Hire Again</p>
                  </div>
                  <p css={{ color: '#002E6D', fontWeight: 500 }}>
                    (Based on 42 reviews)
                  </p>
                </div> */}
                {/* <Location
                  location={authUser.location}
                  preferences={authUser.preferences}
                /> */}
                {/* <Industry
                  industryPreference={authUser.preferences.industries}
                /> */}
              </div>
            </div>
            <div
              css={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: 20,
                [mq(bp.large)]: {
                  padding: 40,
                },
              }}
            >
              <div>
                <h3>Recently Completed Gigs</h3>
                <div>
                  {completedGigs.length > 0 ? (
                    completedGigs.map((gig, i) => (
                      <div
                        key={i}
                        css={{
                          display: "flex",
                          alignItems: "center",
                          padding: 20,
                          borderRadius: 4,
                          background: "#fff",
                          marginBottom: 20,
                        }}
                      >
                        <div
                          css={{
                            border: "1px solid red",
                            height: 120,
                            width: 120,
                          }}
                        >
                          <img
                            src={gig.profileImage}
                            alt=""
                            css={{
                              objectFit: "cover",
                              objectPosition: "center",
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        </div>
                        <div css={{ flex: 1, margin: "0 20px" }}>
                          <h2 css={{ margin: 0 }}>{gig.businessName}</h2>
                          <p css={{ margin: 0 }}>{gig.position}</p>
                        </div>
                        <div>
                          <p>{gig.day}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Gigs Completed</p>
                  )}
                </div>
                <div
                  css={{
                    marginTop: 40,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <button
                    type="button"
                    css={(theme) => [
                      button(
                        theme.buttons.type_dark,
                        theme.buttons.bg2,
                        theme.buttons.hover2
                      ),
                      {
                        marginBottom: 20,
                        paddingLeft: 16,
                        paddingRight: 16,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      },
                    ]}
                    onClick={() => onWorkerRequestClick(workerData.uid)}
                    disabled={selectedWorker.requestSent}
                  >
                    {selectedWorker.requestSent ? (
                      <React.Fragment>
                        <span>
                          <GreenCheck
                            css={{ width: 24, height: 24, marginRight: 8 }}
                          />
                        </span>{" "}
                        Request Sent
                      </React.Fragment>
                    ) : (
                      `Request ${workerData.firstName} for Gig`
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div
              css={(theme) => ({
                background: theme.colors.light,
                height: "100%",
                padding: "20px 40px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                [mq(bp.large)]: {
                  height: "auto",
                  width: "30%",
                  borderBottomLeftRadius: 47,
                  borderTopLeftRadius: 47,
                },
              })}
            >
              <div>
                <div>
                  <h2>Experience</h2>
                  {workerData.exp.length ? (
                    <div>
                      {formatedExpList().map((item, i) => (
                        <ExpBlock item={item} key={i} />
                      ))}
                    </div>
                  ) : (
                    <p
                      css={{
                        color: "#002E6D",
                        fontWeight: 500,
                      }}
                    >
                      None
                    </p>
                  )}
                </div>
                <hr />
                <div>
                  <h2>Certificates</h2>
                  {workerData.certList.length > 0 ? (
                    workerData.certList.map((cert, i) => (
                      <div css={{ display: "flex", marginBottom: 12 }} key={i}>
                        <p css={{ flex: 1, margin: 0, fontWeight: "bold" }}>
                          {cert.label}
                        </p>
                        <p css={{ margin: 0 }}>
                          (exp:{" "}
                          {cert.month && cert.year
                            ? `${cert.month}/${cert.year}`
                            : "N/A"}
                          )
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No Certificates or Licenses</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Complete = (props) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [recommendedWorkers, setRecommendedWorkers] = useState([]);
  const [recommendedWorkerLoading, setRecommendedWorkerLoading] = useState(
    true
  );
  const [selectedWorker, setSelectedWorker] = useState("");
  const [workerData, setWorkerData] = useState(null);
  const [workersToShow, setWorkersToShow] = useState(3);
  const [workers, setWorkers] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [skip, setSkip] = useState(true);

  useEffect(() => {
    if (props.gigIDs.length === 0) {
      history.push("/create");
    }
  }, []);
  useEffect(() => {
    if (props.gigIDs.length === 0) return;
    props.setStep(8);
    // if (props.step !== 8) history.push('/create');
    props.firebase.getRecommendedWorkers(props.gigIDs[0]).then((data) => {
      if (data.length === 0) {
        setWorkers([]);
        setRecommendedWorkers([]);
        setRecommendedWorkerLoading(false);
        setSkip(true);
        return;
      }
      if (data.length > 0) {
        const workersList = data.slice();
        let currentIndex = workersList.length;
        let temporaryValue;
        let randomIndex;

        while (currentIndex !== 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = workersList[currentIndex];
          workersList[currentIndex] = workersList[randomIndex];
          workersList[randomIndex] = temporaryValue;
        }

        if (workersList.length <= workersToShow) setShowLoadMore(false);
        if (workersList.length > workersToShow) setShowLoadMore(true);
        setWorkers(workersList);
        setRecommendedWorkers(workersList.slice(0, workersToShow));
        setRecommendedWorkerLoading(false);
        setSkip(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (workers.length <= workersToShow) setShowLoadMore(false);
    if (workers.length > workersToShow) setShowLoadMore(true);
    setRecommendedWorkers(workers.slice(0, workersToShow));
  }, [workersToShow]);

  const onLoadMoreRecommendedWorkers = () => {
    setWorkersToShow(workersToShow + 3);
  };
  const onWorkerProfileClick = (worker) => {
    setSelectedWorker(worker);
    props.firebase.getWorkerData(worker.workerID).then((data) => {
      setWorkerData(data);
    });
    history.push(`${path}/worker-profile`);
  };
  const onWorkerRequestClick = (workerID) => {
    let gigs = [];
    if (props.gigIDs.length > 1 && props.sameWorker) {
      gigs = [props.gigIDs[0]];
    }
    if (props.gigIDs.length > 1 && !props.sameWorker) {
      gigs = props.gigIDs;
    }
    if (props.gigIDs.length === 1) {
      gigs = [props.gigIDs[0]];
    }
    props.firebase.requestWorker(workerID, gigs).then(() => {
      const recWorkers = [...recommendedWorkers];
      const workerIndex = recommendedWorkers.findIndex(
        (worker) => worker.workerID === workerID
      );
      recWorkers[workerIndex].requestSent = true;
      setRecommendedWorkers(recWorkers);
    });
  };
  return (
    <Switch>
      <Route exact path={path}>
        <Recommended
          recommendedWorkers={recommendedWorkers}
          onWorkerProfileClick={onWorkerProfileClick}
          onWorkerRequestClick={onWorkerRequestClick}
          onLoadMoreRecommendedWorkers={onLoadMoreRecommendedWorkers}
          showLoadMore={showLoadMore}
          recommendedWorkerLoading={recommendedWorkerLoading}
          // temp
          skip={skip}
        />
      </Route>
      <Route path={`${path}/worker-profile`}>
        <WorkerProfile
          workerData={workerData}
          firebase={props.firebase}
          onWorkerRequestClick={onWorkerRequestClick}
          selectedWorker={selectedWorker}
        />
      </Route>
    </Switch>
  );
};

export default withFirebase(Complete);
