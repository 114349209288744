/** @jsx jsx */
import { jsx } from '@emotion/core';
import { button } from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';
import { useRef, useState, useEffect } from 'react';
import Cropperjs from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import ExifReader from 'exifreader';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Rotate } from 'assets/icons/rotate.svg';
import { Ring } from 'react-spinners-css';

const ImageUploadModal = (props) => {
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [range, setRange] = useState(0.5);
  const [saving, setSaving] = useState(false);
  const imageRef = useRef(null);
  const cropperRef = useRef(null);
  const [fileField, setFileField] = useState('');

  useEffect(() => {
    cropperRef.current = new Cropperjs(imageRef.current, {
      aspectRatio: 1 / 1,
      viewMode: 0,
      background: false,
      dragMode: 'move',
      rotatable: true,
      scalable: true,
      cropBoxResizable: true,
      cropBoxMovable: true,
      guides: false,
      checkOrientation: true,
      ready() {
        const container = cropperRef.current.getContainerData();
        cropperRef.current.setCropBoxData({
          width: 150,
          height: 150,
          left: container.width / 2 - 75,
          top: container.height / 2 - 75,
        });
      },
    });
    return () => {
      // if there is an image ref, destroy cropper
      if (cropperRef.current) {
        cropperRef.current.destroy();
      }
    };
  }, []);
  useEffect(() => {
    cropperRef.current.reset().clear().replace(image);
  }, [image]);
  const onImageUpload = (event) => {
    event.persist();
    setFileField(event.target.value);
    setLoading(true);
    if (event.target.files.length) {
      if (event.target.files[0].type.match('image.*')) {
        const { files } = event.target;
        const reader = new FileReader();
        let orientation;

        reader.onload = (readerEvent) => {
          const tags = ExifReader.load(readerEvent.target.result);
          if (tags.Orientation) {
            orientation = tags.Orientation.value;
          }
          if (!tags.Orientation) {
            orientation = 1;
          }
          const { type } = event.target.files[0];
          const imageEl = new Image();

          imageEl.src = URL.createObjectURL(event.target.files[0]);
          imageEl.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const max_size = 350;
            let { width } = imageEl;
            let { height } = imageEl;

            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }

            canvas.width = width;
            canvas.height = height;
            // switch (orientation) {
            //   case 2:
            //     // horizontal flip
            //     ctx.translate(canvas.width, 0);
            //     ctx.scale(-1, 1);
            //     break;
            //   case 3:
            //     // 180Â° rotate left
            //     ctx.translate(canvas.width, canvas.height);
            //     ctx.rotate((Math.PI / 180) * -180);
            //     break;
            //   case 4:
            //     // vertical flip
            //     ctx.translate(0, canvas.height);
            //     ctx.scale(1, -1);
            //     break;
            //   case 5:
            //     // vertical flip + 90 rotate right
            //     canvas.width = height;
            //     canvas.height = width;
            //     ctx.rotate((Math.PI / 180) * 90);
            //     ctx.scale(1, -1);
            //     break;
            //   case 6:
            //     // 90Â° rotate right
            //     canvas.width = height;
            //     canvas.height = width;
            //     ctx.rotate((Math.PI / 180) * 90);
            //     ctx.translate(0, -canvas.width);
            //     break;
            //   case 7:
            //     // horizontal flip + 90 rotate right
            //     canvas.width = height;
            //     canvas.height = width;
            //     ctx.rotate((Math.PI / 180) * 90);
            //     ctx.scale(-1, 1);
            //     ctx.translate(-canvas.height, -canvas.width);
            //     break;
            //   case 8:
            //     // 90Â° rotate left
            //     canvas.width = height;
            //     canvas.height = width;
            //     ctx.rotate((Math.PI / 180) * -90);
            //     ctx.translate(-canvas.height, 0);
            //     break;
            //   default:
            // }

            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(imageEl, 0, 0, width, height);

            canvas.toBlob((data) => {
              setImage(URL.createObjectURL(data));
              setLoading(false);
              setModal(true);
            }, type);
          };
        };
        reader.readAsArrayBuffer(files[0]);
      } else {
        cropperRef.current.clear();
        setImage(null);
        setLoading(false);
      }
    }
    if (!event.target.files.length) {
      cropperRef.current.clear();
      setImage(null);
      setLoading(false);
    }
  };
  const onSlide = (e) => {
    cropperRef.current.zoomTo(1 * e.target.value);
    setRange(e.target.value);
  };
  const onRotate = () => {
    cropperRef.current.rotate(90);
  };
  const onSave = () => {
    setSaving(true);
    const croppedCanvas = cropperRef.current.getCroppedCanvas({
      width: 180,
      height: 180,
      imageSmoothingQuality: 'high',
      fillColor: '#fff',
    });
    setImageURL(croppedCanvas.toDataURL());
    croppedCanvas.toBlob((croppedBlob) => {
      props.imageBlob(croppedBlob);
    }, 'image/jpeg');
    setModal(false);
    setRange(2);
    setFileField('');
    setImage(null);
    setSaving(false);
  };
  const clearFileField = () => {
    setFileField('');
  };
  const closeModal = () => {
    setModal(false);
    setRange(2);
    setFileField('');
    setImage(null);
  };

  return (
    <div css={{ marginBottom: 20, gridColumn: '1 / 3' }}>
      <div
        css={{
          display: modal ? 'flex' : 'none',
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: 'rgba(0,0,0,.5)',
          top: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99,
        }}
      >
        <div
          css={{
            padding: '8px 8px 20px',
            background: '#fff',
            borderRadius: 3,
            width: '88vw',
            [mq(bp.small)]: {
              width: 'auto',
            },
          }}
        >
          <div css={{ textAlign: 'right' }}>
            <button
              type="button"
              onClick={closeModal}
              css={{
                border: 'none',
                background: 'none',
                fontWeight: 'bold',
                fontSize: 18,
                padding: 0,
                path: {
                  fill: '#002E6D',
                },
              }}
            >
              <Close css={{ height: 16 }} />
            </button>
          </div>
          <div css={{ padding: '0 20px' }}>
            <p css={{ margin: ' 0 0 16px 0', fontWeight: 500 }}>Upload Image</p>

            <div
              css={{
                maxWidth: 200,
                width: '100%',
                height: 200,
                margin: '0 auto 8px',
                textAlign: 'center',
                [mq(bp.large)]: {
                  width: 400,
                  maxWidth: 400,
                  height: 400,
                },
                '.cropper-view-box, .cropper-face': {
                  outline: 'none',
                },
                '.cropper-container': {
                  display: 'block',
                },
                '.cropper-modal': {
                  borderRadius: 5,
                },
              }}
            >
              <img
                id="image"
                src={image || null}
                alt=""
                ref={imageRef}
                css={{
                  maxWidth: '100%',
                  height: 150,
                  width: 150,
                  backgroundColor: 'white',
                  objectPosition: 'center',
                  objectFit: 'cover',
                  border: '2px solid lightgray',
                }}
              />
            </div>
            <div
              css={{
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 25px',
              }}
            >
              <div css={{ width: '60%' }}>
                <label htmlFor="radius">Zoom</label>
                <div css={{ width: '100%' }}>
                  <input
                    type="range"
                    min={0.5}
                    max={4}
                    value={range}
                    onChange={onSlide}
                    step={0.05}
                    css={{
                      WebkitAppearance: 'none',
                      width: '100%',
                      height: 8,
                      background: '#d3d3d3',
                      outline: 'none',
                      margin: '16px 0',
                      borderRadius: '10px',
                      '&::-webkit-slider-thumb': {
                        appearance: 'none',
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        background: '#F9D753',
                        cursor: 'pointer',
                      },
                      '&::-moz-range-thumb': {
                        width: 20,
                        height: 20,
                        background: '#F9D753',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        border: 'none',
                      },
                    }}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="">Rotate</label>
                <button
                  type="button"
                  onClick={onRotate}
                  css={{
                    border: 'none',
                    background: 'none',
                    fontWeight: 'bold',
                    fontSize: 18,
                    padding: 0,
                    display: 'flex',
                    margin: '7px auto 0',
                  }}
                >
                  <Rotate
                    css={{
                      height: 20,
                      path: { fill: '#002E6D' },
                    }}
                  />
                </button>
              </div>
            </div>
            <div
              css={{
                position: 'relative',
                overflow: 'hidden',
                display: 'inline-block',
                width: '100%',
                'input:hover ~ button': {
                  backgroundColor: '#1A4887',
                },
              }}
            >
              <input
                type="file"
                name="image"
                id=""
                onClick={clearFileField}
                onChange={(e) => onImageUpload(e)}
                accept="image/*"
                disabled={saving}
                css={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  opacity: 0,
                }}
              />
              <button
                type="button"
                css={(theme) => [
                  button(
                    theme.buttons.type_light,
                    theme.buttons.bg1,
                    theme.buttons.hover1
                  ),
                  {
                    width: '100%!important',
                    marginBottom: 8,
                    display: 'block',
                  },
                ]}
                disabled={saving}
              >
                Change Photo
              </button>
            </div>
            <div>
              <button
                type="button"
                css={(theme) => [
                  button(
                    theme.buttons.type_dark,
                    theme.buttons.bg2,
                    theme.buttons.hover2
                  ),
                  { width: '100%!important', display: 'block' },
                ]}
                onClick={onSave}
                disabled={saving}
              >
                {saving ? (
                  <Ring
                    color="#002E6D"
                    size="22"
                    css={{
                      width: 22,
                      height: 22,
                      display: 'flex',
                      margin: 'auto',
                    }}
                  />
                ) : (
                    'Save Photo'
                  )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          [mq(bp.small)]: {
            flexDirection: 'row',
            alignItems: 'flex-end',
          },
        }}
      >
        <div
          css={{
            width: 150,
            height: 150,
            overflow: 'hidden',
            backgroundColor: '#EBF7F9',
            marginBottom: 15,
          }}
        >
          <img src={imageURL} alt="" css={{ width: '100%', height: '100%' }} />
        </div>
        <div
          css={{
            position: 'relative',
            overflow: 'hidden',
            display: 'inline-block',
            marginBottom: 10,
            [mq(bp.large)]: {
              marginLeft: 10,
            },
            'input:hover ~ button': {
              backgroundColor: '#1A4887',
            },
          }}
        >
          <input
            type="file"
            name="image"
            id=""
            onClick={clearFileField}
            onChange={(e) => onImageUpload(e)}
            accept="image/*"
            css={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              opacity: 0,
            }}
          />
          <button
            type="button"
            css={(theme) => [
              button(
                theme.buttons.type_light,
                theme.buttons.bg1,
                theme.buttons.hover1
              ),
              {
                zIndex: 99,
                marginBottom: 20,
                [mq(bp.large)]: {
                  marginLeft: 10,
                },
              },
            ]}
          >
            {loading ? (
              <Ring
                color="#002E6D"
                size="22"
                css={{
                  width: 22,
                  height: 22,
                  display: 'flex',
                  margin: 'auto',
                }}
              />
            ) : imageURL ? (
              'Change Photo'
            ) : (
                  'Upload Company Logo'
                )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadModal;
