/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useContext, useEffect } from 'react';
import {
  useHistory,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { withFirebase } from 'library/Firebase';
import { AuthUserContext } from 'library/Session';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { pageTitle } from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';
import * as ROUTES from 'constants/routes';
import IdentityVerification from './IdentityVerification';
import ServiceAgreement from './ServiceAgreement';
import BusinessDetails from './BusinessDetails';
import PersonalDetails from './PersonalDetails';
import SignupComplete from './SignupComplete';

const SignUpContainer = (props) => {
  const [complete, setComplete] = useState(false);
  const [signupError, setSignupError] = useState({});
  const [businessLogo, setBusinessLogo] = useState(null);
  const [saving, setSaving] = useState(false);
  const { authUser } = useContext(AuthUserContext);
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!authUser) {
      return;
    }
    history.push(ROUTES.DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (complete) {
      history.push(`${path}/complete`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);
  const initialState = {
    // service agreement state
    confirmAgreement: false,
    // verification state
    phoneNumber: '',
    sending: false,
    phoneCodeSent: false,
    phoneCode: '',
    phoneVerified: false,
    // profile state
    firstName: '',
    lastName: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    businessLogo: '',
    businessName: '',
    address1: '',
    address2: '',
    city: '',
    province: { label: '', value: '' },
    country: 'Canada',
    postalCode: '',
    businessTel: '',
    industry: '',
    message: null,
  };

  const [step, setStep] = useState(1);
  const [verificationStep, setVerificationStep] = useState(1);
  const [detailsStep, setDetailsStep] = useState(1);
  const [state, setState] = useState(initialState);

  const onSubmit = (data) => {
    const { email, firstName, lastName, passwordOne } = data;
    setSaving(true);
    return props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((userData) => {
        return props.firebase
          .createStripeCustomer({
            businessName: state.businessName,
            email,
            wbUID: userData.user.uid,
            phoneNumber: state.phoneNumber,
            businessTel: state.businessTel,
          })
          .then(async (stripeCustomerId) => {
            return props.firebase.user(userData.user.uid).set({
              signupDate: new Date(),
              businessName: state.businessName,
              address1: state.address1,
              address2: state.address2,
              city: state.city,
              province: state.province,
              country: state.country,
              postalCode: state.postalCode,
              businessTel: state.businessTel,
              industry: state.industry,
              firstName,
              lastName,
              email,
              phoneNumber: state.phoneNumber,
              notificationsRead: false,
              setupComplete: false,
              setup: {
                locations: false,
                paymentCard: false,
              },
              spendHistory: [],
              locations: [],
              stripeCustomerId,
              type: 'business',
              activeGigs: [],
              completedGigs: [],
              invoicingOption: false,
              uid: userData.user.uid,
              checkoutMethod: 'wage',
              checkoutRating: true,
              businessNumber: await props.firebase.getBusinessNumber(),
              gigCount: 0,
            });
          })
          .then(() => {
            return props.firebase.sendWelcomeNotif(
              userData.user.uid,
              state.businessName
            );
          });
      })
      .then(() => {
        const logo = businessLogo || '';
        props.firebase.saveBusinessLogo(logo);
        return true;
      })
      .then(() => {
        // setState({ ...initialState });
        // history.push(ROUTES.SETUP);
        setSaving(false);
        return setComplete(true);
      })
      .catch((error) => {
        setSaving(false);
        return setSignupError(error);
        // setState((prev) => ({ ...prev, error }));
      });
  };
  const onChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const onCheckboxChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const nextStep = (num, step) => {
    setState((prev) => ({ ...prev, message: null }));
    setStep(num);
    history.push(`${path}/${step}`);
  };
  const twilioCreate = (method, phoneNum) => {
    setState((prev) => ({ ...prev, sending: true }));
    return props.firebase
      .twilioCreateVerification(method, `+${phoneNum}`)
      .then(() => {
        setState((prev) => ({
          ...prev,
          phoneCodeSent: true,
          sending: false,
          message: null,
        }));
        setVerificationStep(2);
      })
      .then(() => {
        setState((prev) => ({ ...prev, phoneNumber: phoneNum }));
        return true;
      })
      .catch((error) => {
        setState((prev) => ({
          ...prev,
          phoneCodeSent: false,
          sending: false,
          message: `Error ${error.code}: ${error.message}`,
        }));
      });
  };
  const twilioCheck = (code) => {
    setState((prev) => ({ ...prev, message: null }));
    return props.firebase
      .twilioCheckVerification(`+${state.phoneNumber}`, code)
      .then((result) => {
        setState((prev) => ({ ...prev, phoneVerified: result.phoneVerified }));
        if (result.phoneVerified) {
          setVerificationStep(3);
          return { succes: true };
        }
        if (!result.phoneVerified) {
          return { success: false, message: result.message };
        }
      });
  };
  const onPhoneChange = (value) => {
    const phoneNumber = `+${value.replace(/[^0-9]+/g, '')}`;
    setState((prev) => ({ ...prev, phoneNumber, message: null }));
  };

  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.neutral_light2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        [mq(bp.large)]: {
          minHeight: 'auto',
        },
      })}
    >
      <div css={{ flex: 1 }}>
        <div
          css={(theme) => ({
            maxWidth: '100%',
            padding: '10px 10px',
            flex: 1,
            margin: '0 auto',
            [mq(bp.small)]: {
              padding: 0,
              width: '90vw',
            },
          })}
        >
          <div
            css={(theme) => ({
              maxWidth: theme.layout.max_width,
              margin: '0 auto',
              padding: '20px 0',
              flex: 1,
            })}
          >
            <div css={pageTitle}>
              <h1>Start filling shifts with WorkBriefly</h1>
            </div>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                padding: '0 40px',
                maxWidth: 600,
                margin: '0 auto',
              }}
            >
              <div
                css={{
                  width: '100%',
                  display: 'none',
                  justifyContent: 'space-between',
                  position: 'relative',
                  marginBottom: 80,
                  [mq(bp.large)]: {
                    display: 'flex',
                  },
                }}
              >
                <div
                  css={{
                    position: 'absolute',
                    height: 3,
                    background: '#FFCC41',
                    width: '100%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
                <div css={{ position: 'relative' }}>
                  <span
                    css={{
                      border: step > 1 ? 'none' : '1px solid #002E6D',
                      background: step === 1 ? '#002E6D' : '#FAFAFA',
                      height: 40,
                      width: 40,
                      borderRadius: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: step === 1 ? '#FFF' : '#808080',
                    }}
                  >
                    {step > 1 ? (
                      <GreenCheck css={{ width: 40, height: 40 }} />
                    ) : (
                      '1'
                    )}
                  </span>
                  <span
                    css={{
                      color: step === 1 ? '#002E6D' : '#808080',
                      position: 'absolute',
                      left: '50%',
                      top: 'calc(100% + 8px)',
                      transform: 'translate(-50%, 0)',
                      textAlign: 'center',
                    }}
                  >
                    Service Agreement
                  </span>
                </div>
                <div css={{ position: 'relative' }}>
                  <span
                    css={{
                      border: step >= 2 ? 'none' : '1px solid #808080',
                      background: step === 2 ? '#002E6D' : '#FAFAFA',
                      height: 40,
                      width: 40,
                      borderRadius: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: step === 2 ? '#FFF' : '#808080',
                    }}
                  >
                    {step > 2 ? (
                      <GreenCheck css={{ width: 40, height: 40 }} />
                    ) : (
                      '2'
                    )}
                  </span>
                  <span
                    css={{
                      color: step === 2 ? '#002E6D' : '#808080',
                      position: 'absolute',
                      left: '50%',
                      top: 'calc(100% + 8px)',
                      transform: 'translate(-50%, 0)',
                      textAlign: 'center',
                    }}
                  >
                    Phone Verification
                  </span>
                </div>
                <div css={{ position: 'relative' }}>
                  <span
                    css={{
                      border: step >= 4 ? 'none' : '1px solid #808080',
                      background: step === 4 ? '#002E6D' : '#FAFAFA',
                      height: 40,
                      width: 40,
                      borderRadius: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: step === 4 ? '#FFF' : '#808080',
                    }}
                  >
                    {step > 4 ? (
                      <GreenCheck css={{ width: 40, height: 40 }} />
                    ) : (
                      '3'
                    )}
                  </span>
                  <span
                    css={{
                      color: step === 4 ? '#002E6D' : '#808080',
                      position: 'absolute',
                      left: '50%',
                      top: 'calc(100% + 8px)',
                      transform: 'translate(-50%, 0)',
                      textAlign: 'center',
                    }}
                  >
                    Account Details
                  </span>
                </div>
              </div>
            </div>
            <Switch>
              <Route exact path={`${path}`}>
                <Redirect to={`${path}/agreement`} />
              </Route>
              <Route path={`${path}/agreement`}>
                <ServiceAgreement
                  {...state}
                  onCheckboxChange={onCheckboxChange}
                  nextStep={nextStep}
                  setStep={setStep}
                />
              </Route>
              <Route path={`${path}/verification`}>
                <IdentityVerification
                  {...state}
                  verificationStep={verificationStep}
                  setVerificationStep={setVerificationStep}
                  onPhoneChange={onPhoneChange}
                  nextStep={nextStep}
                  onChange={onChange}
                  twilioCreate={twilioCreate}
                  twilioCheck={twilioCheck}
                  step={step}
                  setState={setState}
                />
              </Route>
              <Route path={`${path}/business-details`}>
                <BusinessDetails
                  {...state}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  setDetailsStep={setDetailsStep}
                  detailsStep={detailsStep}
                  nextStep={nextStep}
                  step={step}
                  setState={setState}
                  setVerificationStep={setVerificationStep}
                  setStep={setStep}
                  setBusinessLogo={setBusinessLogo}
                />
              </Route>
              <Route path={`${path}/personal-details`}>
                <PersonalDetails
                  {...state}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  onCheckboxChange={onCheckboxChange}
                  setDetailsStep={setDetailsStep}
                  detailsStep={detailsStep}
                  nextStep={nextStep}
                  step={step}
                  setState={setState}
                  signupError={signupError}
                  setStep={setStep}
                  saving={saving}
                />
              </Route>
              <Route path={`${path}/complete`}>
                <SignupComplete {...state} complete={complete} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

const SignUp = withFirebase(SignUpContainer);

export default SignUp;
