/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withFirebase } from 'library/Firebase';
import { navButton } from 'theme/styleBlocks';

const LogoutButton = ({ firebase }) => (
  <button
    type="button"
    onClick={firebase.doLogout}
    css={(theme) => navButton(theme.buttons.type_light, theme.buttons.bg1, theme.buttons.hover1)}
  >
    Logout
  </button>
);
export default withFirebase(LogoutButton);
