/** @jsx jsx */
import { jsx } from '@emotion/core';

const FormFieldErrorMessage = ({ error }) => {
  if (error) {
    return <span css={{ color: 'red' }}>{error.message}</span>;
  }
  return null;
};

export default FormFieldErrorMessage;
