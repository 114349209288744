/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useContext } from 'react';
import { AuthUserContext } from 'library/Session';
import { useHistory } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import {
  leftBox,
  button,
  completeText,
  completedIcon,
} from 'theme/styleBlocks';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { bp, mq } from 'constants/mediaQueries';

const SignupComplete = ({ complete, businessName }) => {
  const { authUser } = useContext(AuthUserContext);
  const history = useHistory();
  useEffect(() => {
    if (complete) {
      return;
    }
    if (!authUser || authUser === null) {
      history.push(ROUTES.LOGIN);
    }
    if (authUser !== null) {
      if (authUser.setupComplete) {
        history.push(ROUTES.DASHBOARD);
      }
      if (!authUser.setupComplete) {
        history.push(ROUTES.SETUP);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  return (
    <div>
      <h2 css={{ textAlign: 'left', [mq(bp.small)]: { textAlign: 'center' } }}>
        Account Created
      </h2>
      <div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: 550,
            margin: '0 auto',
          }}
        >
          <div css={[leftBox, { textAlign: 'center' }]}>
            <GreenCheck css={completedIcon} />
            <p css={completeText}>
              Congratulations {businessName}! Your account has been created.
            </p>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '96%',
                margin: 'auto',
                flexDirection: 'column-reverse',
                [mq(bp.large)]: {
                  flexDirection: 'row',
                },
                a: {
                  marginBottom: 15,
                  width: '100%',
                  [mq(bp.large)]: {
                    width: 'auto',
                  },
                },
              }}
            >
              <div>
                <a
                  css={(theme) =>
                    button(
                      theme.buttons.type_light,
                      theme.buttons.bg1,
                      theme.buttons.hover1
                    )
                  }
                  href={ROUTES.DASHBOARD}
                >
                  Go To Dashboard
                </a>
              </div>
              {/* <div>
                <a
                  css={(theme) =>
                    button(theme.buttons.type_dark, theme.buttons.bg2, theme.buttons.hover2)
                  }
                  href={ROUTES.SETUP}
                >
                  Continue to onboarding
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupComplete;
