/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useState, useEffect } from 'react';
import { AuthUserContext } from 'library/Session';
import { withFirebase } from 'library/Firebase';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Ring } from 'react-spinners-css';
import { bp, mq } from 'constants/mediaQueries';

const Dashboard = (props) => {
  const { authUser } = useContext(AuthUserContext);
  const [gigs, setGigs] = useState({});
  const [spend, setSpend] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const businessSnapshot = props.firebase.businessSnapshot(authUser.uid);
    businessSnapshot.get().then((snapshot) => {
      props.firebase
        .getGigs(snapshot.data().activeGigs, 'active')
        .then(async (gigs) => {
          const sortedGigs = await gigs.sort((a, b) => {
            return (
              moment(`${b.start} ${b.day}`, 'h:mm A MMM D, YYYY').toDate() -
              moment(`${a.start} ${a.day}`, 'h:mm A MMM D, YYYY').toDate()
            );
          });

          return sortedGigs;
        })
        .then((data) => {
          setGigs(data);
          setLoading(false);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.activeGigs]);
  // useEffect(() => {
  //   props.firebase.getSpend(authUser.stripeCustomerId).then((data) => {
  //     console.log(data);
  //   });
  // }, []);

  useEffect(() => {
    if (authUser.spendHistory && authUser.spendHistory.length > 0) {
      let sum = 0;
      authUser.spendHistory.map((data) => {
        sum += data.spend;
      });
      setSpend(sum / 100);
    }
  }, []);

  const vacancyColor = (booked, vacant) => {
    if (booked === 0) return '#D72F2F';
    if (booked < vacant) return '#FFCC41';
    if (booked === vacant) return '#74B711';
  };

  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '60px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <div css={{ display: 'flex', alignItems: 'center', marginBottom: 40 }}>
        <div css={{ height: 100, width: 100, marginRight: 16 }}>
          <img
            src={authUser.profileImage}
            alt=""
            css={{
              objectFit: 'contain',
              objectPosition: 'center',
              height: '100%',
              width: '100%',
            }}
          />
        </div>
        <h1 css={{ margin: 0 }}>Welcome back, {authUser.businessName}!</h1>
      </div>
      {/* <div css={{ marginBottom: 20 }}>
        <h2 css={{ marginBottom: 8 }}>Spend</h2>
        <div
          css={{
            width: '100%',
            border: '1px solid #979797',
            borderRadius: '4px',
            backgroundColor: '#fff',
            display: 'inline-block',
            padding: 15,
            [mq(bp.large)]: {
              padding: 20,
              width: 'auto',
            },
          }}
        >
          <p
            css={{
              margin: 0,
              fontSize: 30,
              fontWeight: 600,
              color: '#272727',
              lineHeight: 1,
              br: {
                [mq(bp.large)]: {
                  display: 'none',
                },
              },
            }}
          >
            ${spend}
            {'  '}
            <br />
            <span css={{ fontSize: 16, fontWeight: 400 }}>Total Spend</span>
          </p>
        </div>
      </div> */}

      {loading ? (
        <div css={{ textAlign: 'center' }}>
          <Ring color="#002E6D" size="50" css={{ width: 50 }} />
        </div>
      ) : (
        <div css={{ marginBottom: 20 }}>
          <h2 css={{ marginBottom: 8 }}>Upcoming Gigs</h2>
          <div
            css={{
              [mq(bp.large)]: {
                border: '1px solid #979797',
                borderRadius: '4px',
                padding: 20,
                backgroundColor: '#fff',
                '&>div:not(:first-of-type):not(:last-of-type)': {
                  borderBottom: '1px solid #EFEFEF',
                  paddingBottom: 16,
                },
                '&>div:last-of-type': {
                  marginBottom: 0,
                },
              },
            }}
          >
            <div
              css={{
                borderBottom: '1px solid #979797',
                display: 'none',
                // gridTemplateColumns: '1fr 1.25fr 2fr 2fr 2fr 2.25fr 2.5fr',
                gridTemplateColumns: '3fr 1.5fr 2fr 2fr 2.5fr',
                gridColumnGap: 20,
                marginBottom: 20,
                p: {
                  margin: '0 0 10px 0',
                  fontSize: 14,
                  fontWeight: 600,
                },
                [mq(bp.large)]: {
                  display: 'grid',
                  '&>p:not(:first-of-type)': {
                    textAlign: 'right',
                  },
                },
              }}
            >
              <p>When</p>
              <p>Vacancies</p>
              {/* <p>Requests</p> */}
              <p>Position</p>
              <p>Total Cost</p>
              <p>Actions</p>
            </div>
            {gigs.map((gig) => (
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7, 1fr)',
                  marginBottom: 10,
                  backgroundColor: '#fff',
                  border: '1px solid #ECECEC',
                  position: 'relative',
                  borderRadius: '4px',
                  padding: 15,
                  gridAutoFlow: 'column',
                  [mq(bp.large)]: {
                    marginBottom: 20,
                    gridColumnGap: 20,
                    border: 'none',
                    padding: 0,
                    // gridTemplateColumns: '1fr 1.25fr 2fr 2fr 2fr 2.25fr 2.5fr',
                    gridTemplateColumns: '3fr 1.5fr 2fr 2fr 2.5fr',
                    '&>div:not(:first-of-type)': {
                      textAlign: 'right',
                    },
                  },
                }}
              >
                <div
                  css={{
                    order: 1,
                    gridColumn: '1/7',
                    fontWeight: 400,
                    marginBottom: 10,
                    [mq(bp.large)]: {
                      marginBottom: 0,
                      gridColumn: 'unset',
                    },
                  }}
                >
                  <p css={{ margin: 0 }}>
                    {gig.day}{' '}
                    <span css={{ marginLeft: 5 }}>
                      {gig.start} - {gig.end}
                    </span>
                  </p>
                </div>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    order: 3,
                    gridColumn: '1/3',
                    [mq(bp.large)]: {
                      gridColumn: 'unset',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      order: 2,
                    },
                  }}
                >
                  <span
                    css={{
                      height: 16,
                      width: 16,
                      borderRadius: '50%',
                      background: vacancyColor(
                        gig.bookedWorkers.length,
                        gig.vacancies
                      ),
                      display: 'block',
                      marginRight: 8,
                    }}
                  />
                  <p css={{ margin: 0 }}>
                    {gig.bookedWorkers.length}/{gig.vacancies}
                  </p>
                </div>
                {/* <div>
                  <p css={{ margin: 0 }}>N/A</p>
                </div> */}
                <div
                  css={{
                    order: 2,
                    gridColumn: '1/4',
                    marginBottom: 10,
                    [mq(bp.large)]: {
                      marginBottom: 0,
                      gridColumn: 'unset',
                      order: 3,
                    },
                  }}
                >
                  <p
                    css={{
                      margin: 0,
                      fontSize: 20,
                      fontWeight: 800,
                      width: '55vw',
                      color: '#272727',
                      [mq(bp.large)]: {
                        color: 'unset',
                        fontSize: 16,
                        fontWeight: 400,
                        width: 140,
                        marginLeft: 'auto',
                      },
                    }}
                  >
                    {gig.position}
                  </p>
                </div>
                <div
                  css={{
                    order: 4,
                    gridColumn: '3/5',
                    display: 'flex',
                    alignItems: 'center',
                    [mq(bp.large)]: {
                      gridColumn: 'unset',
                      display: 'block',
                    },
                  }}
                >
                  <p css={{ margin: '0 5px 0 0', fontWeight: 600 }}>
                    ${gig.totalForThisDay.toFixed(2)}{' '}
                  </p>
                  <p
                    css={{
                      margin: 0,
                      fontSize: 12,
                      [mq(bp.large)]: {
                        fontSize: 14,
                      },
                    }}
                  >
                    (${gig.payPerHour}/hr)
                  </p>
                </div>
                <div
                  css={{
                    order: 5,
                    textAlign: 'center',
                    position: 'absolute',
                    top: 35,
                    right: 15,
                    [mq(bp.large)]: {
                      top: 0,
                      right: 0,
                      position: 'relative',
                      marginTop: 0,
                      gridColumn: 'unset',
                      gridRow: 'unset',
                    },
                  }}
                >
                  <Link
                    to={`/gigs/active?gig=${gig.gigID}`}
                    css={{
                      margin: 0,
                      color: '#002E6D',
                      fontWeight: 600,
                      br: { [mq(bp.large)]: { display: 'none' } },
                    }}
                  >
                    View <br />
                    Booked Workers
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default withFirebase(Dashboard);
