/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactComponent as BackArrow } from 'assets/icons/backArrow.svg';
import { ReactComponent as CreateGig } from 'assets/icons/createGig.svg';
import * as ROUTES from 'constants/routes';
import AccountNav from 'components/AccountNav';
import { useHistory } from 'react-router-dom';
import { bp, mq } from 'constants/mediaQueries';
import Notifications from 'components/Notifications';

const Topbar = (props) => {
  const history = useHistory();
  return (
    <div
      css={(theme) => ({
        padding: '13px 20px',
        backgroundColor: '#002E6D',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 99,
        [mq(bp.large)]: {
          padding: '10px 20px',
          backgroundColor: props.bgColor,
          position: 'unset',
        },
      })}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          [mq(bp.large)]: {
            display: 'none',
          },
        }}
      >
        <BackArrow css={{ marginRight: 20 }} onClick={() => history.goBack()} />
        <h2
          css={{
            display: 'inline',
            color: '#fff',
            margin: 0,
          }}
        >
          {props.pageTitle}
        </h2>
      </div>
      <div
        css={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          [mq(bp.large)]: {
            width: '100%',
          },
        })}
      >
        <div
          css={{
            marginRight: 16,
            display: 'none',
            [mq(bp.large)]: {
              display: 'block',
            },
          }}
        >
          <Notifications />
        </div>

        <a
          href={ROUTES.CREATE}
          css={{
            display: props.pageTitle === 'Gigs' ? 'flex' : 'none',
            marginRight: 10,
            [mq(bp.large)]: {
              display: 'none',
            },
          }}
        >
          <CreateGig />
        </a>
        <AccountNav />
      </div>
    </div>
  );
};

export default Topbar;
