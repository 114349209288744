/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from 'library/Firebase';

const Profile = ({ workerID, setProfileOpen, handleAccept, handleDecline }) => {
  const firebase = useContext(FirebaseContext);
  const [workerData, setWorkerData] = useState({});
  const [completedGigs, setCompletedGigs] = useState([]);
  useEffect(() => {
    firebase.getWorkerData(workerID).then((data) => {
      setWorkerData(data);

      firebase
        .getGigs(data.completedGigs, 'completed')
        .then((gigs) => setCompletedGigs(gigs));
    });
  }, []);
  return (
    <div
      css={{
        position: 'fixed',
        backgroundColor: 'rgba(0,0,0,0.5)',
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0,
        zIndex: 99,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 24,
      }}
    >
      <div
        css={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          position: 'relative',
          overflowY: 'scroll',
          zIndex: 999,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          css={{
            '&>div': {
              marginBottom: 40,
            },
            padding: '80px 40px',
            flex: 1,
          }}
        >
          <div>
            <img
              src={workerData.profileImage}
              alt=""
              css={{
                height: 100,
                width: 100,
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: '50%',
              }}
            />
            <p css={{ fontSize: 24, fontWeight: 700, marginBottom: 0 }}>
              {workerData.firstName} {workerData.lastName}
            </p>
            <a
              href={`tel:${workerData.phoneNumber}`}
              css={{ color: '#002E6D' }}
            >
              +{workerData.phoneNumber}
            </a>
          </div>
          {workerData.ratings && (
            <div
              css={{
                p: { margin: 0 },
                backgroundColor: 'rgba(255,204,65,0.3)',
                padding: 20,
                textAlign: 'center',
                display: 'inline-block',
              }}
            >
              <p>
                <span css={{ fontWeight: 700 }}>
                  {workerData.ratings.rating1}%
                </span>{' '}
                On Time
              </p>
              <p>
                <span css={{ fontWeight: 700 }}>
                  {workerData.ratings.rating2}%
                </span>{' '}
                Dressed Appropriately
              </p>
              <p>
                <span css={{ fontWeight: 700 }}>
                  {workerData.ratings.rating3}%
                </span>{' '}
                Would Hire Again
              </p>
            </div>
          )}
          <div>
            <h2>Experience</h2>
            {workerData.exp
              ? workerData.exp.map((exp) => (
                  <div>
                    <p>
                      <strong>{exp.position} </strong>
                      at {exp.company} for {exp.years}yrs {exp.months}mo
                    </p>
                  </div>
                ))
              : 'N/A'}
          </div>
          <div>
            <h2>Certifications</h2>
            {workerData.certList
              ? workerData.certList.map((cert) => (
                  <div>
                    <p>
                      <strong>{cert.label} </strong>exp:
                      {cert.year ? ` ${cert.month}/${cert.year}` : 'N/A'}
                    </p>
                  </div>
                ))
              : 'N/A'}
          </div>
          <div>
            <h2>Past Completed Gigs</h2>
            {completedGigs > 0 ? (
              completedGigs.map((gig) => (
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 40,
                  }}
                >
                  <img
                    src={gig.profileImage}
                    alt=""
                    css={{ height: 80, width: 80, background: 'grey' }}
                  />
                  <div css={{ margin: '0 40px' }}>
                    <h2 css={{ margin: 0 }}>{gig.businessName}</h2>
                    <p>{gig.position}</p>
                  </div>
                  <p>{gig.formattedDate}</p>
                </div>
              ))
            ) : (
              <p>N/A</p>
            )}
          </div>
        </div>
        <div
          css={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            borderTop: '1px solid black',
            background: 'white',
            padding: '20px 40px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <button
            onClick={() => setProfileOpen(false)}
            css={{ border: 0, fontSize: 16, color: '#002E6D' }}
          >
            Back
          </button>
          <div>
            <button
              onClick={handleAccept}
              css={{
                border: 0,
                background: '#FFCC41',
                margin: 0,
                padding: '8px 16px',
                color: '#002E6D',
                borderRadius: 5,
                marginRight: 20,
              }}
            >
              Accept
            </button>
            <button
              onClick={handleDecline}
              css={{
                border: 0,
                background: 'red',
                margin: 0,
                padding: '8px 16px',
                color: '#002E6D',
                borderRadius: 5,
              }}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
