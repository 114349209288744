/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext } from 'react';
import { AuthUserContext } from 'library/Session';
// import LogoutButton from 'components/Logout';
import Topbar from 'components/Topbar';
import Bottombar from 'components/Bottombar';
import { useHistory } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { withFirebase } from 'library/Firebase';
import { ReactComponent as ChevronRight } from 'assets/icons/chevronRight.svg';

const AccountNav = ({ firebase }) => {
  const { authUser } = useContext(AuthUserContext);
  const history = useHistory();
  const handleLogout = () => {
    firebase.doLogout().then(() => {
      history.push(ROUTES.LOGIN);
    });
  };

  return (
    <React.Fragment>
      <div css={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {authUser ? (
          <div>
            <Topbar bgColor="#F3F3F3" pageTitle="Account" />
            <Bottombar />
          </div>
        ) : null}
        <div
          css={{
            backgroundColor: '#FAFAFA',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 65,
            padding: 20,
            height: '100vh',
          }}
        >
          <div css={{ display: 'flex', borderBottom: '1px solid #EFEFEF' }}>
            <div
              css={{
                width: 66,
                height: 66,
                border: '1px solid lightgrey',
                borderRadius: '50%',
                overflow: 'hidden',
                background: '#ECF0F4',
                marginBottom: 10,
              }}
            >
              <img
                src={authUser.profileImage}
                alt=""
                css={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </div>
            <h2 css={{ marginLeft: 10 }}>{authUser.firstName}</h2>
          </div>
          <div
            css={{
              width: '100%',
              '&>div:not(:last-child)': {
                borderBottom: '1px solid #EFEFEF',
              },
              a: {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px 0',
              },
              p: {
                fontSize: 16,
                color: '#002E6D',
                fontWeight: 500,
                margin: 0,
              },
              svg: {
                width: 10,
              },
            }}
          >
            <div>
              <a href={ROUTES.SETTINGS}>
                <p>Settings </p>
                <ChevronRight />
              </a>
            </div>
            <div>
              <a href={ROUTES.HELP}>
                <p>Help & Support</p>
                <ChevronRight />
              </a>
            </div>
            {/* <div>
              <a href="/">
                <p>Give Feedback</p>
                <ChevronRight />
              </a>
            </div> */}
            <div>
              <button
                type="button"
                onClick={handleLogout}
                css={{
                  background: 'none',
                  border: 'none',
                  fontSize: 16,
                  margin: '20px 0',
                  width: '100%',
                  padding: 0,
                  cursor: 'pointer',
                  color: '#002E6D',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withFirebase(AccountNav);
