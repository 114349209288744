/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { AuthUserContext } from 'library/Session';
import { ReactComponent as Arrow } from 'assets/icons/topbarArrow.svg';
import { useHistory } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { withFirebase } from 'library/Firebase';
import { bp, mq } from 'constants/mediaQueries';
import KebabMenu from './KebabMenu';

const AccountNav = ({ firebase }) => {
  const { authUser } = useContext(AuthUserContext);
  const [expanded, setExpanded] = useState(false);
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const history = useHistory();
  const handleLogout = () => {
    firebase.doLogout().then(() => {
      history.push(ROUTES.LOGIN);
    });
  };

  return (
    <React.Fragment>
      <div
        css={{
          zIndex: 50,
          [mq(bp.large)]: {
            position: 'relative',
          },
        }}
        ref={wrapperRef}
      >
        {/* Desktop nav */}
        <button
          css={{
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'rgba(58, 177, 202, .3)',
            padding: '8px 30px',
            borderRadius: 3,
            width: '100%',
            border: 'none',
            fontSize: 16,
            cursor: 'pointer',
            display: 'none',
            '&:focus': { outline: 0 },
            [mq(bp.large)]: {
              display: 'flex',
            },
          }}
          type="button"
          onClick={() => setExpanded(!expanded)}
        >
          <div
            css={{
              width: 32,
              height: 32,
              border: '1px solid lightgrey',
              overflow: 'hidden',
              background: '#ECF0F4',
            }}
          >
            <img
              src={authUser.profileImage}
              alt=""
              css={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </div>
          <div
            css={{
              minWidth: 50,
              margin: '0 10px',
              [mq(bp.small)]: {
                margin: '0 40px',
              },
              p: { margin: 0, color: '#002E6D', fontWeight: 500 },
            }}
          >
            <p>{authUser.firstName}</p>
          </div>
          <div>
            <Arrow
              css={{
                width: 20,
                transform: expanded ? 'scale(1, -1)' : null,
                transition: '.25s ease',
              }}
            />
          </div>
        </button>
        {/* Desktop nav Content */}
        <div
          css={{
            position: 'absolute',
            width: '100%',
            backgroundColor: '#C3E7EF',
            borderRadius: 3,
            textAlign: 'center',
            padding: '8px 40px 16px',
            right: 0,
            top: 50,
            zIndex: 99,
            '&>div:not(:last-child)': {
              borderBottom: '1px solid #fff',
            },
            [mq(bp.large)]: {
              top: 'unset',
            },
            a: {
              marginTop: 8,
              marginBottom: 8,
              display: 'block',
              fontSize: 16,
              color: '#002E6D',
              fontWeight: 500,
            },
            display: expanded ? 'block' : 'none',
          }}
        >
          <div>
            <a href={ROUTES.SETTINGS}>Settings</a>
          </div>
          <div>
            <a href={ROUTES.HELP}>Help & Support</a>
          </div>
          {/* <div>
            <a href="/">Give Feedback</a>
          </div> */}
          <div>
            <button
              type="button"
              onClick={handleLogout}
              css={{
                background: 'none',
                border: 'none',
                fontSize: 16,
                margin: '8px 0',
                width: '100%',
                padding: 0,
                cursor: 'pointer',
                color: '#002E6D',
                fontWeight: 500,
              }}
            >
              Logout
            </button>
          </div>
        </div>
        <KebabMenu />
      </div>
    </React.Fragment>
  );
};

export default withFirebase(AccountNav);
