/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import SignUpPage from 'pages/SignUp';
import LoginPage from 'pages/Login';
import PasswordRecoverPage from 'pages/PasswordRecover';
import { AuthUserContext } from 'library/Session';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import { bp, mq } from 'constants/mediaQueries';

const NonAuthNavRoutes = () => {
  const { authUser } = useContext(AuthUserContext);
  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.neutral_light2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      })}
    >
      <div css={{ backgroundColor: '#FFFFFF' }}>
        <Navigation authUser={authUser} />
      </div>
      <div css={{ flex: 1 }}>
        <div
          css={(theme) => ({
            width: '100%',
            flex: 1,
            margin: '0 auto',
            padding: '10px 10px',
            [mq(bp.small)]: {
              width: '90vw',
            },
            [mq(bp.large)]: {
              maxWidth: theme.layout.max_width,
              padding: '40px 64px',
            },
          })}
        >
          <Switch>
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.LOGIN} component={LoginPage} />
            <Route path={ROUTES.RECOVER} component={PasswordRecoverPage} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NonAuthNavRoutes;
