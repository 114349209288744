export const redirectConditions = (authUser, currentPath) => {
  if (!authUser) return { redirect: true, redirectTo: '/login' };
  if (authUser && authUser.setupComplete && currentPath === '/setup')
    return { redirect: true, redirectTo: '/dashboard' };

  if (authUser && !authUser.setupComplete && currentPath === '/setup')
    return { redirect: false };
  if (authUser && authUser.setupComplete) return { redirect: false };

  if (authUser && !authUser.setupComplete && currentPath !== '/setup')
    return { redirect: true, redirectTo: '/setup' };
};
