/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { ReactComponent as ParkingIcon } from 'assets/icons/parkingIcon.svg';

const ParkingIndicator = ({ num }) => {
  const [text, setText] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    switch (num) {
      case 1:
        setText('Free Parking');
        setColor('#74B711');
        break;
      case 2:
        setText('Paid parking nearby');
        setColor('#FFC628');
        break;
      case 3:
        setText('No parking available nearby');
        setColor('#D72F2F');
        break;
      default:
        break;
    }
  }, [num]);
  return (
    <div css={{ display: 'flex', margin: '10px 0' }}>
      <ParkingIcon css={{ marginRight: 10, path: { fill: color } }} />
      <p css={{ margin: 0 }}>{text}</p>
    </div>
  );
};

export default ParkingIndicator;
