/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext, useState } from 'react';
import { AuthUserContext } from 'library/Session';
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthNavigation from 'components/AuthNavigation';
import Topbar from 'components/Topbar';
import Bottombar from 'components/Bottombar';
import LogoutButton from 'components/Logout';
import { button, sideNav, tabs, topBarContainer } from 'theme/styleBlocks';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import StartGuide from './StartGuide';
import Faq from './Faq/Faq';
import CertRequest from './CertRequest';
import 'react-tabs/style/react-tabs.css';
import { bp, mq } from '../../constants/mediaQueries';

const Help = () => {
  const [menuOpen, setMenuOpen] = useState();
  const { authUser } = useContext(AuthUserContext);
  const { path } = useRouteMatch();
  const handleMenu = () => {
    setMenuOpen(false);
  };
  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.light,
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
      })}
    >
      <React.Fragment>
        {authUser ? (
          <div css={sideNav}>
            <div css={{ padding: '20px 0' }}>
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo-white.svg?alt=media&token=8d014c04-599a-40e7-95b1-23090cab3430"
                  alt=""
                  css={{ height: 50 }}
                />
              </Link>
            </div>
            <AuthNavigation />
            <a
              href="/create"
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
                { textAlign: 'center' },
              ]}
            >
              Create a Gig
            </a>
            <LogoutButton />
          </div>
        ) : null}
        <div
          css={{
            [mq(bp.large)]: {
              width: '100%',
            },
          }}
        >
          {authUser ? (
            <div css={topBarContainer}>
              <Topbar bgColor="#F3F3F3" pageTitle="Help & Support" />
              <Bottombar />
            </div>
          ) : null}
          <Switch>
            <Route exact path={path}>
              <div
                css={{
                  width: '100%',
                  flex: 1,
                  marginBottom: 65,
                  [mq(bp.large)]: {
                    margin: 0,
                  },
                }}
              >
                <div
                  css={{
                    backgroundColor: '#C3E7EF',
                    textAlign: 'center',
                    padding: '50px 0',
                  }}
                >
                  <div
                    css={(theme) => ({
                      margin: 'auto',
                      padding: '0 20px',
                      [mq(bp.large)]: {
                        maxWidth: theme.layout.max_width - 460,
                      },
                      padding: 0,
                    })}
                  >
                    <h1>Help & Support</h1>
                    {/* <input type="text" css={{ width: '100%' }} /> */}
                    <p
                      css={{
                        fontWeight: 600,
                        fontSize: 18,
                        a: { textDecoration: 'underline', fontSize: 18 },
                      }}
                    >
                      Here are the most frequently asked questions and answers.
                      If you still have a question not answered below, please{' '}
                      <a
                        href="https://www.workbriefly.com/contact-us/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        contact us
                      </a>{' '}
                      and we’ll get back to you as soon as possible.
                    </p>
                  </div>
                </div>
                <Tabs css={tabs} defaultIndex={1}>
                  <TabList>
                    <Tab>
                      <p>FAQ</p>
                    </Tab>
                    <Tab>
                      <p>Getting Started</p>
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <Faq />
                  </TabPanel>
                  <TabPanel>
                    <StartGuide />
                  </TabPanel>
                </Tabs>
              </div>
            </Route>
            <Route path={`${path}/request-cert`}>
              <CertRequest />
            </Route>
          </Switch>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Help;
