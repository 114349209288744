/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext } from 'react';
import { Route, Switch, Link, useLocation } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import Dashboard from 'pages/Dashboard';
import CreateGig from 'pages/CreateGig';
import Gigs from 'pages/Gigs';
import { AuthUserContext } from 'library/Session';
import { button, sideNav, topBarContainer } from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';
import AuthNavigation from 'components/AuthNavigation';
import Topbar from 'components/Topbar';
import Bottombar from 'components/Bottombar';

const AuthNavRoutes = () => {
  const { authUser } = useContext(AuthUserContext);
  const location = useLocation();
  const pageTitle = () => {
    if (location.pathname.includes('/gigs')) {
      return 'Gigs';
    }
    if (location.pathname.includes('/create')) {
      return 'Create a gig';
    }
    if (location.pathname === ROUTES.DASHBOARD) {
      return 'Home';
    }
  };
  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.light,
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        width: '100%',
        margin: 'auto',
        [mq(bp.large)]: {
          width: 'auto',
          padding: 0,
        },
      })}
    >
      <React.Fragment>
        {authUser ? (
          <div css={sideNav}>
            <div css={{ padding: '20px 0' }}>
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo-white.svg?alt=media&token=8d014c04-599a-40e7-95b1-23090cab3430"
                  alt=""
                  css={{ height: 50 }}
                />
              </Link>
            </div>
            <AuthNavigation />
            <a
              href="/create"
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
                { textAlign: 'center' },
              ]}
            >
              Create a Gig
            </a>
          </div>
        ) : null}
        <div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FAFAFA',
          }}
        >
          {authUser ? (
            <div css={topBarContainer}>
              <Topbar bgColor="#F3F3F3" pageTitle={pageTitle()} />
              <Bottombar />
            </div>
          ) : null}
          <div
            css={(theme) => ({
              width: '100%',
              flex: 1,
            })}
          >
            <Switch>
              <Route path={ROUTES.CREATE} component={CreateGig} />
              {/* <Route path={ROUTES.DASHBOARD} component={Dashboard} /> */}
              {/* <Route path={ROUTES.ACCOUNT} component={AccountPage} /> */}
              <Route path={ROUTES.GIGS} component={Gigs} />
              <Route path={ROUTES.DASHBOARD} component={Dashboard} />
              {/* <Route path={ROUTES.PROFILE} component={Profile} /> */}
            </Switch>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default AuthNavRoutes;
