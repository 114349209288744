/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { withAuthentication, AuthUserContext } from "library/Session";
import version from "constants/ver";
import * as ROUTES from "constants/routes";
import { theme } from "theme/globalTheme";

import SignUpPage from "pages/SignUp";
import NoMatch from "pages/NoMatch";
import ProfilePage from "pages/Profile";
import SettingsPage from "pages/Settings";
import AccountMenuPage from "pages/AccountMenu";
import Help from "pages/Help";
import SetupPage from "pages/Setup";
import NotificationsPage from "pages/NotificationsPage";

import ReactGA from "react-ga";
import AuthNavRoutes from "./AuthNavRoutes";
import NonAuthNavRoutes from "./NonAuthNavRoutes";

let stripePromise;
if (
  (process.env.NODE_ENV === "development" &&
    window.location.hostname === "localhost") ||
  (process.env.NODE_ENV === "production" &&
    window.location.hostname === "biz-wb-staging.web.app")
) {
  stripePromise = loadStripe("pk_test_Xw2ORVezTOeyWtNzhjndUqS900DmuUtU9y");
}
if (
  process.env.NODE_ENV === "production" &&
  window.location.hostname !== "biz-wb-staging.web.app" &&
  window.location.hostname === "business.workbriefly.com"
) {
  stripePromise = loadStripe("pk_live_efnD3LSEXZT9vstiVIkoo5nm00dnUOTA3C");
  ReactGA.initialize("UA-177263831-1");
}
const Routes = () => {
  const { authUser } = useContext(AuthUserContext);
  const history = useHistory();

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "biz-wb-staging.web.app" &&
      window.location.hostname === "business.workbriefly.com"
    ) {
      const unlisten = history.listen((location) => {
        ReactGA.pageview(location.pathname + location.search);
      });
      return () => {
        unlisten();
      };
    }
  }, []);
  return (
    <React.Fragment>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Redirect to={ROUTES.LOGIN} />
        </Route>
        <Route path={[ROUTES.SIGN_UP, ROUTES.LOGIN, ROUTES.RECOVER]}>
          <NonAuthNavRoutes />
        </Route>
        {/* <Route
              path={ROUTES.SIGN_UP}
              render={({ location }) =>
                authUser ? (
                  <SignUpPage />
                ) : (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: location },
                    }}
                  />
                )
              }
            /> */}
        <Route
          path={ROUTES.SETUP}
          render={({ location }) =>
            authUser ? (
              <SetupPage />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
        <Route
          path={ROUTES.PROFILE}
          render={({ location }) =>
            authUser ? (
              <ProfilePage />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
        <Route
          path={ROUTES.NOTIFICATIONS}
          render={({ location }) =>
            authUser ? (
              <NotificationsPage />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
        <Route
          path={ROUTES.SETTINGS}
          render={({ location }) =>
            authUser ? (
              <SettingsPage />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
        <Route
          path={ROUTES.ACCOUNT_MENU}
          render={({ location }) =>
            authUser ? (
              <AccountMenuPage />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
        <Route
          path={[ROUTES.CREATE, ROUTES.GIGS, ROUTES.DASHBOARD]}
          render={({ location }) =>
            authUser ? (
              <AuthNavRoutes />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
        <Route
          path={[ROUTES.HELP]}
          render={({ location }) =>
            authUser ? (
              <Help />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
        <Route
          path={[ROUTES.ACCOUNT, ROUTES.DASHBOARD]}
          // render={({ location }) =>
          //   authUser ? (
          //     <AuthNavRoutes />
          //   ) : (
          //     <Redirect
          //       to={{
          //         pathname: '/login',
          //         state: { from: location },
          //       }}
          //     />
          //   )
          // }
        >
          <Redirect to={ROUTES.PROFILE} />
        </Route>
        <Route component={NoMatch} />
      </Switch>
    </React.Fragment>
  );
};
const App = () => {
  // useEffect(() => {
  //   console.log(window.location.pathname + window.location.search);
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   console.log(1);
  // }, [window.location.pathname, window.location.search]);
  useEffect(() => {
    document.title = `${version} - WB Business Platform`;
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <Router>
          <Routes />
          {/* <div
            css={(theme) => ({
              backgroundColor:
                authUser && authUser.setupComplete
                  ? theme.colors.light
                  : theme.colors.neutral_light2,
              display: 'flex',
              flexDirection:
                authUser && authUser.setupComplete ? 'row' : 'column',
              minHeight: '100vh',
            })}
          >
            {authUser && authUser.setupComplete ? (
              <div
                css={(theme) => ({
                  backgroundColor: theme.colors.primary,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  minWidth: 250,
                })}
              >
                <div css={{ padding: '20px 0' }}>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/workbriefly-alpha.appspot.com/o/assets%2Fimages%2Fwb-logo%20wordless%20white.svg?alt=media&token=4be0db28-c8e5-47d3-bf7a-ffa333b924d7"
                    alt=""
                    css={{ height: 50 }}
                  />
                </div>
                <a
                  href="/create"
                  css={(theme) =>
                    linkButton(theme.buttons.type_dark, theme.buttons.bg2, theme.buttons.hover2)
                  }
                >
                  Create Gigs
                </a>
                <AuthNavigation setupComplete={authUser.setupComplete} />
                <LogoutButton />
              </div>
            ) : (
              <div css={{ backgroundColor: '#FFFFFF' }}>
                <Navigation authUser={authUser} />
              </div>
            )}
            <div css={{ flex: 1 }}>
              {authUser && authUser.setupComplete ? <Topbar /> : null}
              <div
                css={(theme) => [
                  authUser && authUser.setupComplete
                    ? {
                        maxWidth: theme.layout.max_width - 250,
                        width: '100%',
                        padding: 20,
                      }
                    : {
                        maxWidth: theme.layout.max_width,
                        padding: '40px 64px',
                      },
                  { flex: 1, margin: '0 auto' },
                ]}
              >
                <Switch>
                  <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                  <Route path={ROUTES.LOGIN} component={LoginPage} />
                  <Route
                    path={ROUTES.RECOVER}
                    component={PasswordRecoverPage}
                  />
                  <PrivateRoutes />
                  <Route component={NoMatch} />
                </Switch>
              </div>
            </div>
            {authUser && authUser.setupComplete ? null : (
              <div>
                <Footer />
              </div>
            )}
          </div> */}
        </Router>
      </Elements>
      <div>{version}</div>
    </ThemeProvider>
  );
};
export default withAuthentication(App);
