import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'components/App';
import Firebase, { FirebaseContext } from 'library/Firebase';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
