import React, { useState, useEffect } from 'react';
import { withFirebase } from 'library/Firebase';
import AuthUserContext from './context';

const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    const [update, setUpdate] = useState(false);
    const [authUser, setAuthUser] = useState(
      JSON.parse(localStorage.getItem('authUser'))
    );
    useEffect(() => {
      const listener = props.firebase.onAuthUserListener(
        (authUser) => {
          if (authUser) {
            localStorage.setItem('authUser', JSON.stringify(authUser));
            setAuthUser(authUser);
            setUpdate(false);
          }
        },
        () => {
          localStorage.removeItem('authUser');
          setAuthUser(null);
          setUpdate(false);
        }
      );
      return () => {
        listener();
      };
    }, [update, props.firebase]);
    return (
      <AuthUserContext.Provider
        value={{ authUser, refetch: setUpdate, update }}
      >
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };

  return withFirebase(WithAuthentication);
};
export default withAuthentication;
