/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import { withFirebase } from "library/Firebase";
import { button } from "theme/styleBlocks";
import { ReactComponent as GreenTick } from "assets/icons/greenTick.svg";
import { Ring } from "react-spinners-css";
import * as styles from "./styles";

const Confirmation = ({
  setOption,
  option,
  workerData,
  gigID,
  certCodes,
  hideSuboptions,
  setHideOptions,
  setReportModalOpen,
  firebase,
}) => {
  const [confirmResults, setConfirmResults] = useState(true);
  const [confirming, setConfirming] = useState(false);

  const optionFunc = () => {
    switch (option) {
      case 1:
        return [
          {
            message: `Find me a replacement ASAP. (Note: This does not guarantee that we will be able to find a replacement. 
            It may take up to an hour for a replacement worker to be found.)`,
          },
          {
            result: `${workerData.firstName} ${workerData.lastName} will be cancelled from the gig and asked to leave your work site.
              Your gig will be available for workers to claim.
              Workers will be notified about your gig vacancy and the need to report to the worksite ASAP.`,
          },
        ];
      case 2:
        return [
          {
            message: `I don’t need a replacement, I’d like ${workerData.firstName} ${workerData.lastName} to leave and to be cancelled from the gig. (Note: You will not be charged for ${workerData.firstName} ${workerData.lastName}.)`,
          },
          {
            result: `${workerData.firstName} ${workerData.lastName} will be cancelled from the gig and asked to leave your work site.`,
          },
        ];
      case 3:
        return [
          {
            message: `I will accept ${workerData.firstName} ${workerData.lastName} and assign him/her another task that
            doesn't require said certificate/licence. (Note: This has to be a
            mutual agreement with the worker.)`,
          },
          {
            result: `You will have to come to a mutual agreement with ${workerData.firstName} ${workerData.lastName} on the task that he/she will perform. Upon confirming this, the gig may continue as per normal.
            The certificat/lisence in question will be removed from the workers profile and the worker will recieve a warning.`,
          },
        ];
      case 4:
        return [
          {
            message: `I will allow ${workerData.firstName} ${workerData.lastName} to quickly pick up said attire/equipment and
            report back for the gig. (Note: This has to be a mutual agreement
            with the worker.)`,
          },
          {
            result: `You will have to come to a mutual agreement with ${workerData.firstName} ${workerData.lastName} for him/her to leave your work site momentarily to pick up said attrire/equpment and return ASAP.
            Upon returning with said equipment/attire, the gig may continue as per normal.`,
          },
        ];
      case 5:
        return [
          {
            message: `I will accept ${workerData.firstName} ${workerData.lastName} and assign him/her another task that
            doesn’t require said equipment/attire. (Note: This has to be a
            mutual agreement with the worker.)`,
          },
          {
            result: `You will have to come to a mutual agreement with ${workerData.firstName} ${workerData.lastName} on the task that he/she will perform. Upon confirming this, the gig may continue as per normal.
            The worker will recieve a warning.`,
          },
        ];

      default:
        break;
    }
  };

  const handleConfirm = () => {
    setConfirming(true);
    firebase
      .reportIssue(workerData.id, gigID, option, certCodes)
      .then((result) => {
        if (result.success) {
          setConfirmResults(!confirmResults);
        }
        if (result.error) {
          console.log(result.error);
          return <span css={{ color: "red" }}>{result.error}</span>;
        }
      });
  };

  return (
    <div>
      {confirmResults ? (
        <div css={styles.reportBody}>
          {option === 0 ? null : (
            <div>
              <div className="header">
                <h2 className="title">Report an Issue</h2>
                <p>
                  You are reporting
                  <span className="boldFont">
                    {workerData.firstName} {workerData.lastName}
                  </span>
                </p>
              </div>

              <p className="smallFont">YOU SELECTED</p>
            </div>
          )}
          {option === 0
            ? null
            : optionFunc().map((data, index) => (
                <p key={index} className="firstOption">
                  {data.message}
                </p>
              ))}
          <hr />

          <p>
            What will happen if you
            <span className="boldFont">Confirm</span>
          </p>
          <div>
            {option === 0
              ? null
              : optionFunc().map((data, index) => (
                  <p key={index}>{data.result}</p>
                ))}
          </div>
          <div className="btnContainer">
            <button
              type="button"
              className="backBtn"
              onClick={() => {
                setHideOptions(!hideSuboptions);
              }}
            >
              Back
            </button>
            <button
              type="button"
              className="confirmBtn"
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
              ]}
              onClick={handleConfirm}
            >
              {confirming ? (
                <Ring
                  color="#fff"
                  size="22"
                  css={{
                    width: 22,
                    height: 22,
                    display: "flex",
                    margin: "auto",
                  }}
                />
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </div>
      ) : (
        <div css={styles.reportBody}>
          <div className="continueContainer">
            {option === 1 || option === 2 ? (
              <h1>
                <GreenTick className="greenTick" />
                Successfully cancelled
              </h1>
            ) : null}
            {option === 1 ? (
              <p className="message">
                {workerData.firstName} {workerData.lastName} has been cancelled
                for the gig and we are working on finding you a replacement.
              </p>
            ) : option === 2 ? (
              <p className="message">
                {workerData.firstName} {workerData.lastName} has been cancelled
                for the gig. <br /> <br />
                Sorry for the inconvenience and thank you for your
                understanding.
              </p>
            ) : option === 3 ? (
              <p className="message">
                Thank you for accepting {workerData.firstName}{" "}
                {workerData.lastName} and assigning him/her another task that
                doesn't require said certificate/licence. <br />
                <br />
                Sorry for the inconvenience and thank you for your
                understanding.
                <br />
                <br />
                You may continue with the check-in process if you haven't done
                so already.
              </p>
            ) : option === 4 ? (
              <p className="message">
                Please allow {workerData.firstName} {workerData.lastName} to
                pick up said equipment/attire and return ASAP.
                <br /> <br />
                Sorry for the inconvenience and thank you for your
                understanding.
              </p>
            ) : option === 5 ? (
              <p className="message">
                Thank you for accepting {workerData.firstName}{" "}
                {workerData.lastName} and assigning him/her another task that
                doesn't require said equipment/attire.
                <br /> <br />
                Sorry for the inconvenience and thank you for your
                understanding.
                <br /> <br />
                You may continue with the check-in process if you haven't done
                so already.
              </p>
            ) : null}
            <button
              type="button"
              className="continueBtn"
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
              ]}
              onClick={() => {
                setOption(0);
                setHideOptions(true);
                setReportModalOpen(false);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withFirebase(Confirmation);
