/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, useContext } from 'react';
import { withFirebase } from 'library/Firebase';
import * as ROUTES from 'constants/routes';
import { AuthUserContext } from 'library/Session';
import { button, centerBox, formInput, pageTitle } from 'theme/styleBlocks';

const PasswordRecoverPage = () => (
  <div>
    <div>
      <div css={pageTitle}>
        <h1>Password Recovery</h1>
      </div>
      <div css={[centerBox, { margin: '0 auto' }]}>
        <p>Don’t worry, it happens to the best of us.</p>
        <PasswordRecoverForm />
      </div>
    </div>
  </div>
);

const PasswordRecover = (props) => {
  const { authUser } = useContext(AuthUserContext);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    props.history.push(ROUTES.DASHBOARD);
  }, [authUser, props.history]);

  const initialState = {
    email: '',
    message: '',
    error: null,
  };
  const [state, setState] = useState(initialState);
  const onSubmit = (event) => {
    const { email } = state;
    props.firebase
      .doPasswordReset(email)
      .then(() => {
        setState({
          ...initialState,
          message:
            'Password reset email has been sent. Please check your inbox.',
        });
      })
      .catch((error) => {
        setState((prev) => ({ ...prev, error }));
      });
    event.preventDefault();
  };
  const onChange = (event) => {
    event.persist();
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const { email, message, error } = state;
  const isInvalid = email === '';
  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div css={formInput}>
        <label htmlFor="email">Username/E-mail</label>
        <input
          name="email"
          value={state.email}
          onChange={(e) => onChange(e)}
          type="text"
          placeholder="Email Address"
          id="email"
        />
      </div>
      <button
        disabled={isInvalid}
        type="submit"
        css={(theme) =>
          button(
            theme.buttons.type_dark,
            theme.buttons.bg2,
            theme.buttons.hover2
          )
        }
      >
        Reset My Password
      </button>
      {message && <p>{message}</p>}
      {error && <p>{error.message}</p>}
    </form>
  );
};

export default PasswordRecoverPage;
const PasswordRecoverForm = withFirebase(PasswordRecover);
export { PasswordRecoverForm };
