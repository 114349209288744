import { bp, mq } from "constants/mediaQueries";

export const formContainer = {
  display: "grid",
  gridColumn: "1/3",
  maxWidth: "100%",
};
export const labelContainer = {
  display: "flex",
  flexDirection: "column",
  ".inputs": {
    display: "flex",
    flexirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 45,
  },
  ".radioBtn": {
    marginTop: 3,
  },
};
export const nextBtn = {
  marginTop: 15,
  maxWidth: 120,
};
