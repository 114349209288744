/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link, useLocation } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { ReactComponent as Home } from 'assets/icons/homeBlue.svg';
import { ReactComponent as Gigs } from 'assets/icons/gigsBlue.svg';
// import { ReactComponent as Schedule } from 'assets/icons/schedule.svg';
import { useState } from 'react';
import { bp, mq } from 'constants/mediaQueries';

const AuthNavigation = () => {
  const [showGigs, setShowGigs] = useState(false);
  const location = useLocation();
  return (
    <div css={{ width: '100%', marginBottom: 40 }}>
      <ul
        css={(theme) => ({
          padding: '20px 0',
          margin: 0,
          listStyle: 'none',
          color: '#fff',
          width: '100%',
          '&>li': {
            padding: '20px 50px',
            margin: '30px 0',
            transition: '0.3s',
          },
          '&>li:hover': {
            backgroundColor: theme.colors.util4,
          },
          'li div': {
            gridColumn: '2/3',
          },
          svg: {
            width: 26,
            height: 26,
          },
          'svg path': {
            fill: '#fff',
            stroke: '#fff',
          },
        })}
      >
        <li>
          <div>
            <Link
              to={ROUTES.DASHBOARD}
              css={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 14,
                fontWeight: 500,
                [mq(bp.small)]: {
                  fontSize: 20,
                },
                '&:hover': {
                  opacity: '1!important',
                },
              }}
            >
              <Home css={{ marginRight: 20 }} />
              Home
            </Link>
          </div>
        </li>
        <li
          css={{
            marginBottom: '0!important',
            padding: '0!important',
            '&:hover': {
              backgroundColor: 'none',
            },
          }}
          onClick={() => setShowGigs(!showGigs)}
        >
          <div
            css={(theme) => ({
              padding: '20px 50px',
              backgroundColor: showGigs ? theme.colors.util4 : 'none',
              cursor: 'pointer',
            })}
          >
            <p
              css={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 14,
                fontWeight: 500,
                color: '#fff',
                margin: 0,
                [mq(bp.small)]: {
                  fontSize: 20,
                },
              }}
            >
              <Gigs css={{ marginRight: 20 }} />
              Gigs
            </p>
          </div>
          <ul
            css={(theme) => ({
              listStyle: 'none',
              color: theme.colors.dark,
              width: '100%',
              backgroundColor: theme.colors.light,
              display: showGigs ? 'block' : 'none',
              padding: 0,
              '&>li': {
                width: '100%',
                cursor: 'pointer',
                transition: '0',
              },
              '&>li:hover': {
                backgroundColor: theme.colors.util4,
              },
              '&>li:hover a': {
                color: '#fff',
                opacity: '1!important',
              },
              'li a': {
                padding: '12px 50px',
                display: 'block',
                opacity: '1!important',
                transition: '0!important',
              },
            })}
          >
            <li>
              <Link
                to="/gigs/active"
                css={{
                  fontWeight:
                    location.pathname === '/gigs/active' ? '700' : '500',
                  color:
                    location.pathname === '/gigs/active' ? '#393939' : '#8B8B8B',
                }}
              >
                Active
              </Link>
            </li>
            <li>
              <Link
                to="/gigs/completed"
                css={{
                  fontWeight:
                    location.pathname === '/gigs/completed' ? '700' : '500',
                    color:
                    location.pathname === '/gigs/completed' ? '#393939' : '#8B8B8B',
                }}
              >
                Archived
              </Link>
            </li>
          </ul>
        </li>
        {/* hide schedule for now */}
        {/* <li>
        <div>
          <a
            href="/"
            css={{
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
              fontSize: 14,
              fontWeight: 500,
              [mq(bp.small)]: {
                fontSize: 20,
              },
            }}
          >
            <Schedule css={{ marginRight: 20 }} />
            Schedule
          </a>
        </div>
      </li> */}
      </ul>
    </div>
  );
};
export default AuthNavigation;
