/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState, useEffect, useContext } from "react";
import { AuthUserContext } from "library/Session";
import { withFirebase } from "library/Firebase";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import useScript from "hooks/useScript";
import { ReactComponent as GreenCheck } from "assets/icons/successChip.svg";
import { bp, mq } from "constants/mediaQueries";
import { goolgeApi } from "constants/config";
import { button } from "theme/styleBlocks";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Preview from "./Preview";
// import Breakdown from './Breakdown';
import Payment from "./Payment";
import Complete from "./Complete";
import Recommended from "./Recommended";

const initialState = {
  hasStarted: false,
  mode: "claim",
  position: "",
  vacancies: 1,
  description: "",
  additional: "",
  verifiedReq: false,
  // needCerts: false,
  certs: [],
  location: {
    address1: "",
    address2: "",
    lat: "",
    lng: "",
    locationID: "",
    locality: "",
    formattedAddress: "",
  },
  payPerHour: "",
  dates: [],
  attire: "",
  bring: "",
  parking: [false, false, false],
  transit: "1",
  firstName: "",
  lastName: "",
  title: "",
  contactNumber: "",
  sameWorker: false,
  totalCost: 0,
};

const CreateGig = ({ firebase }) => {
  const { refetch, authUser } = useContext(AuthUserContext);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${goolgeApi()}&libraries=places`
  );
  useEffect(() => {
    setScriptLoaded(loaded);
  }, [loaded]);

  useEffect(() => {
    firebase
      .getTaxPercentage(authUser.province.value)
      .then((data) => setTaxPercentage(data));
  }, []);

  const [state, setState] = useState(initialState);
  const [hasDraft, setHasDraft] = useState(false);
  const [startDraft, setStartDraft] = useState(false);
  const [draftPosition, setDraftPosition] = useState("");
  const [step, setStep] = useState(1);
  const [gigIDs, setGigIDs] = useState([]);
  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (state.hasStarted && step < 7) {
      console.log("set");
      localStorage.setItem("createGig", JSON.stringify({ ...state, step }));
      setHasDraft(false);
    }
  }, [state]);

  useEffect(() => {
    const draft = JSON.parse(localStorage.getItem("createGig"));
    if (draft) {
      setDraftPosition(draft.position);
      setHasDraft(true);
    }
  }, []);

  const onChange = (e) => {
    e.persist();
    let { name, value } = e.target;
    if (name === "payPerHour") {
      value = Math.trunc(value * 100) / 100;
    }
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const nextStep = (step) => {
    setStep(step);
    setState((prev) => ({ ...prev, hasStarted: true }));
    if (step === 6) {
      return history.push(`${path}/preview`);
    }
    if (step === 7) {
      return history.push(`${path}/payment`);
    }
    if (step === 8) {
      return history.push(`${path}/complete`);
    }
    if (step === 9) {
      return history.push(`${path}/recommended`);
    }
    history.push(`/create/step${step}`);
  };
  const prevStep = (step) => {
    setStep(step);
    if (step === 1) {
      return history.push(`${path}`);
    }
    if (step === 6) {
      return history.push(`${path}/preview`);
    }
    if (step === 7) {
      return history.push(`${path}/payment`);
    }

    history.push(`${path}/step${step}`);
  };

  const onGigCreateClose = () => {
    refetch(true);
    setState(initialState);
    history.push("/gigs/active");
  };
  const setInitialState = () => {
    setState(initialState);
  };

  const startNew = () => {
    setHasDraft(false);
    localStorage.removeItem("createGig");
  };
  const continueDraft = () => {
    setHasDraft(false);
    const draft = JSON.parse(localStorage.getItem("createGig"));
    setState(draft);
    setStep(draft.step);
    setStartDraft(true);
  };

  return (
    <div css={{ margin: 0, height: "100%" }}>
      {hasDraft && (
        <div
          css={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.8)",
            zIndex: 99,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            css={{
              background: "#fff",
              padding: 40,
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              maxWidth: 400,
            }}
          >
            <p css={{ fontSize: 24 }}>
              Your draft for <strong>{draftPosition} </strong>
              is unfinished!
            </p>
            <button
              type="button"
              onClick={continueDraft}
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
                { marginBottom: 20 },
              ]}
            >
              Continue editing
            </button>
            <button
              type="button"
              onClick={startNew}
              css={{
                border: "none",
                fontSize: 16,
                padding: 0,
                cursor: "pointer",
              }}
            >
              Discard & Start New
            </button>
          </div>
        </div>
      )}
      {step < 6 && (
        <React.Fragment>
          <div
            css={{
              marginTop: 60,
              marginBottom: 40,
              padding: 20,
              display: "none",
              [mq(bp.large)]: {
                paddingLeft: 60,
                marginTop: 0,
                display: "block",
              },
            }}
          >
            <h1
              css={{
                [mq(bp.small)]: {
                  fontSize: 40,
                },
              }}
            >
              Create a Gig
            </h1>
            <p>
              In order to start hiring workers, you will need to fill out
              information about your gig.
            </p>
          </div>
          <div
            css={{
              display: "none",
              justifyContent: "center",
              padding: "0 45px",
              maxWidth: 1000,
              marginTop: 40,
              marginBottom: 40,
              [mq(bp.large)]: {
                marginLeft: 15,
                display: "flex",
              },
            }}
          >
            <div
              css={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
                marginBottom: 60,
              }}
            >
              <div
                css={{
                  position: "absolute",
                  height: 3,
                  background: "#FFCC41",
                  width: "100%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
              <div css={{ position: "relative" }}>
                <button
                  css={{
                    border: step > 1 ? "none" : "1px solid #808080",
                    background: step === 1 ? "#002E6D" : "#FAFAFA",
                    height: 33,
                    width: 33,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: step === 1 ? "#FFF" : "#808080",
                    padding: 0,
                    cursor: step > 1 && "pointer",
                    "&:hover": {
                      opacity: "0.9!important",
                    },
                  }}
                  type="button"
                  disabled={step <= 1}
                  onClick={() => prevStep(1)}
                >
                  {step > 1 ? (
                    <GreenCheck css={{ width: 33, height: 33 }} />
                  ) : (
                    "1"
                  )}
                </button>
                <span
                  css={{
                    position: "absolute",
                    left: "50%",
                    top: "calc(100% + 8px)",
                    transform: "translate(-50%, 0)",
                    textAlign: "center",
                    width: 120,
                    color: step === 1 ? "#002E6D" : "#808080",
                  }}
                >
                  Overview
                </span>
              </div>
              <div css={{ position: "relative" }}>
                <button
                  css={{
                    border: step > 2 ? "none" : "1px solid #808080",
                    background: step === 2 ? "#002E6D" : "#FAFAFA",
                    height: 33,
                    width: 33,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: step === 2 ? "#FFF" : "#808080",
                    padding: 0,
                    cursor: step > 2 && "pointer",
                  }}
                  type="button"
                  disabled={step <= 2}
                  onClick={() => prevStep(2)}
                >
                  {step > 2 ? (
                    <GreenCheck css={{ width: 33, height: 33 }} />
                  ) : (
                    "2"
                  )}
                </button>
                <span
                  css={{
                    position: "absolute",
                    left: "50%",
                    top: "calc(100% + 8px)",
                    transform: "translate(-50%, 0)",
                    textAlign: "center",
                    width: 120,
                    color: step === 2 ? "#002E6D" : "#808080",
                  }}
                >
                  Experience
                </span>
              </div>
              <div css={{ position: "relative" }}>
                <button
                  css={{
                    border: step > 3 ? "none" : "1px solid #808080",
                    background: step === 3 ? "#002E6D" : "#FAFAFA",
                    height: 33,
                    width: 33,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: step === 3 ? "#FFF" : "#808080",
                    padding: 0,
                    cursor: step > 3 && "pointer",
                  }}
                  type="button"
                  disabled={step <= 3}
                  onClick={() => prevStep(3)}
                >
                  {step > 3 ? (
                    <GreenCheck css={{ width: 33, height: 33 }} />
                  ) : (
                    "3"
                  )}
                </button>
                <span
                  css={{
                    position: "absolute",
                    left: "50%",
                    top: "calc(100% + 8px)",
                    transform: "translate(-50%, 0)",
                    textAlign: "center",
                    width: 130,
                    color: step === 3 ? "#002E6D" : "#808080",
                  }}
                >
                  Work Dates & Pay
                </span>
              </div>
              <div css={{ position: "relative" }}>
                <button
                  css={{
                    border: step > 4 ? "none" : "1px solid #808080",
                    background: step === 4 ? "#002E6D" : "#FAFAFA",
                    height: 33,
                    width: 33,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: step === 4 ? "#FFF" : "#808080",
                    padding: 0,
                    cursor: step > 4 && "pointer",
                  }}
                  type="button"
                  disabled={step <= 4}
                  onClick={() => prevStep(4)}
                >
                  {step > 4 ? (
                    <GreenCheck css={{ width: 33, height: 33 }} />
                  ) : (
                    "4"
                  )}
                </button>
                <span
                  css={{
                    position: "absolute",
                    left: "50%",
                    top: "calc(100% + 8px)",
                    transform: "translate(-50%, 0)",
                    textAlign: "center",
                    width: 120,
                    color: step === 4 ? "#002E6D" : "#808080",
                  }}
                >
                  Location
                </span>
              </div>
              <div css={{ position: "relative" }}>
                <button
                  css={{
                    border: step > 5 ? "none" : "1px solid #808080",
                    background: step === 5 ? "#002E6D" : "#FAFAFA",
                    height: 33,
                    width: 33,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: step === 5 ? "#FFF" : "#808080",
                    padding: 0,
                  }}
                  type="button"
                  disabled={step <= 5}
                  onClick={() => prevStep(5)}
                >
                  {step > 5 ? (
                    <GreenCheck css={{ width: 33, height: 33 }} />
                  ) : (
                    "5"
                  )}
                </button>
                <span
                  css={{
                    position: "absolute",
                    left: "50%",
                    top: "calc(100% + 8px)",
                    transform: "translate(-50%, 0)",
                    textAlign: "center",
                    width: 120,
                    color: step === 5 ? "#002E6D" : "#808080",
                  }}
                >
                  Point of Contact
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {step >= 6 && step < 8 && (
        <React.Fragment>
          <div
            css={{
              marginTop: 60,
              marginBottom: 40,
              padding: 20,
              display: "none",
              [mq(bp.large)]: {
                paddingLeft: 60,
                marginTop: 0,
                display: "block",
              },
            }}
          >
            <h1
              css={{
                [mq(bp.small)]: {
                  fontSize: 40,
                },
              }}
            >
              Gig Summary
            </h1>
            <p>
              In order to start hiring workers, you will need to fill out
              information about your gig.
            </p>
          </div>
          <div
            css={{
              display: "none",
              justifyContent: "center",
              padding: "0 45px",
              maxWidth: 1000,
              marginTop: 40,
              marginBottom: 40,
              [mq(bp.large)]: {
                marginLeft: 15,
                display: "flex",
                justifyContent: "left",
              },
            }}
          >
            <div
              css={{
                width: "25%",
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
                marginBottom: 0,
              }}
            >
              <div
                css={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  marginBottom: 60,
                }}
              >
                <div
                  css={{
                    position: "absolute",
                    height: 3,
                    background: "#FFCC41",
                    width: "100%",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
                <div css={{ position: "relative" }}>
                  <button
                    css={{
                      border: step > 6 ? "none" : "1px solid #808080",
                      background: step === 6 ? "#002E6D" : "#FAFAFA",
                      height: 33,
                      width: 33,
                      borderRadius: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: step === 6 ? "#FFF" : "#808080",
                      padding: 0,
                      cursor: step > 6 && "pointer",
                      "&:hover": {
                        opacity: "0.9!important",
                      },
                    }}
                    type="button"
                    disabled={step <= 6}
                    onClick={() => prevStep(6)}
                  >
                    {step > 6 ? (
                      <GreenCheck css={{ width: 33, height: 33 }} />
                    ) : (
                      "1"
                    )}
                  </button>
                  <span
                    css={{
                      position: "absolute",
                      left: "50%",
                      top: "calc(100% + 8px)",
                      transform: "translate(-50%, 0)",
                      textAlign: "center",
                      width: 120,
                      color: step === 6 ? "#002E6D" : "#808080",
                    }}
                  >
                    Post Preview
                  </span>
                </div>

                <div css={{ position: "relative" }}>
                  <button
                    css={{
                      border: step > 7 ? "none" : "1px solid #808080",
                      background: step === 7 ? "#002E6D" : "#FAFAFA",
                      height: 33,
                      width: 33,
                      borderRadius: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: step === 7 ? "#FFF" : "#808080",
                      padding: 0,
                      cursor: step > 7 && "pointer",
                      "&:hover": {
                        opacity: "0.9!important",
                      },
                    }}
                    type="button"
                    disabled={step <= 7}
                    onClick={() => prevStep(7)}
                  >
                    {step > 7 ? (
                      <GreenCheck css={{ width: 33, height: 33 }} />
                    ) : (
                      "2"
                    )}
                  </button>
                  <span
                    css={{
                      position: "absolute",
                      left: "50%",
                      top: "calc(100% + 8px)",
                      transform: "translate(-50%, 0)",
                      textAlign: "center",
                      width: 120,
                      color: step === 6 ? "#002E6D" : "#808080",
                    }}
                  >
                    Cost Summary
                  </span>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      <Switch>
        <Route exact path={path}>
          <Step1
            nextStep={nextStep}
            {...state}
            setStep={setStep}
            setState={setState}
            startDraft={startDraft}
          />
        </Route>
        <Route path={`${path}/step2`}>
          <Step2
            nextStep={nextStep}
            prevStep={prevStep}
            setState={setState}
            {...state}
            setStep={setStep}
            step={step}
          />
        </Route>
        <Route path={`${path}/step3`}>
          <Step3
            {...state}
            nextStep={nextStep}
            prevStep={prevStep}
            scriptLoaded={scriptLoaded}
            setStep={setStep}
            setState={setState}
            step={step}
            taxPercentage={taxPercentage}
          />
        </Route>
        <Route path={`${path}/step4`}>
          <Step4
            {...state}
            nextStep={nextStep}
            prevStep={prevStep}
            scriptLoaded={scriptLoaded}
            setStep={setStep}
            setState={setState}
            step={step}
          />
        </Route>
        <Route path={`${path}/step5`}>
          <Step5
            {...state}
            nextStep={nextStep}
            prevStep={prevStep}
            setStep={setStep}
            setState={setState}
            step={step}
          />
        </Route>
        <Route path={`${path}/preview`}>
          <Preview
            {...state}
            nextStep={nextStep}
            prevStep={prevStep}
            onChange={onChange}
            scriptLoaded={scriptLoaded}
            setStep={setStep}
            step={step}
          />
        </Route>
        <Route path={`${path}/payment`}>
          <Payment
            {...state}
            state={state}
            nextStep={nextStep}
            prevStep={prevStep}
            onChange={onChange}
            scriptLoaded={scriptLoaded}
            setStep={setStep}
            setGigIDs={setGigIDs}
            step={step}
            setInitialState={setInitialState}
            taxPercentage={taxPercentage}
          />
        </Route>
        <Route path={`${path}/complete`}>
          <Complete
            setStep={setStep}
            nextStep={nextStep}
            gigIDs={gigIDs}
            sameWorker={state.sameWorker}
          />
        </Route>
        <Route path={`${path}/recommended`}>
          <Recommended
            setStep={setStep}
            {...state}
            gigIDs={gigIDs}
            onGigCreateClose={onGigCreateClose}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default withFirebase(CreateGig);
