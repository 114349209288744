/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, useContext } from 'react';
import {
  formInput,
  button,
  gigBox,
  buttonContainer,
  goBackButton,
  customCheck,
  blueBox,
  stepTitle,
} from 'theme/styleBlocks';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { bp, mq } from 'constants/mediaQueries';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { useHistory } from 'react-router-dom';
import { AuthUserContext } from 'library/Session';
import DateTimeForm from './DateTimeForm';

const moment = extendMoment(Moment);
const Step3 = (props) => {
  const { authUser } = useContext(AuthUserContext);
  const [dateList, setDateList] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  const [wage, setWage] = useState('');
  const [totalCost, setTotalCost] = useState(0);
  const [checkDateFields, setCheckDateFields] = useState(false);
  const [dateOverlap, setDateOverlap] = useState(false);
  const [noDates, setNoDates] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [fees, setFees] = useState(0);
  const [total, setTotal] = useState(0);
  const [creating, setCreating] = useState(false);
  const {
    errors,
    handleSubmit,
    formState,
    setValue,
    register,
    watch,
    control,
  } = useForm();
  const history = useHistory();

  const watchSameWorker = watch('sameWorker');

  useEffect(() => {
    if (props.step !== 3) history.push('/create');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (dateList.length > 0) setNoDates(false);
    if (dateList.length <= 0) setNoDates(true);
  }, [dateList]);
  useEffect(() => {
    setDateList(props.dates);
    setWage(props.payPerHour);
  }, []);
  useEffect(() => {
    setValue('payPerHour', props.payPerHour);
    setValue('sameWorker', props.sameWorker);
  }, []);
  useEffect(() => {
    if (dateList.length) {
      const total = dateList.reduce((acc, { hours, paidBreak, breakTime }) => {
        if (!paidBreak) {
          const breakHours = moment.duration(breakTime * 60000).asHours();
          return Math.round((acc + hours - breakHours) * 100) / 100;
        }
        return acc + hours;
      }, 0);
      return setTotalHours(total);
    }
    setTotalHours(0);
  }, [dateList]);
  useEffect(() => {
    if (!wage) return setTotal(0);
    if (totalHours && wage) {
      const calcSubtotal = totalHours * wage * props.vacancies;
      const calcFees = Math.round(calcSubtotal * 0.2 * 100) / 100;
      const calcTax =
        Math.round(
          (calcSubtotal + calcFees) * (props.taxPercentage / 100) * 100
        ) / 100;
      setSubTotal(Math.round(calcSubtotal * 100) / 100);
      setTax(calcTax);
      setFees(calcFees);
      setTotal(Math.round((calcSubtotal + calcTax + calcFees) * 100) / 100);
      // setTotalCost(totalHours * wage * props.vacancies);
    }
  }, [totalHours, wage]);
  useEffect(() => {
    if (!dateList.length && formState.isSubmitted) {
      setCheckDateFields(true);
    }
  }, [formState.isSubmitted, formState.submitCount]);

  const addDateTime = (data) => {
    setCheckDateFields(false);
    setDateList((prev) => [...prev, data]);
  };
  const removeDateTime = (index) => {
    const removedList = [...dateList];
    removedList.splice(index, 1);
    setDateList(removedList);
  };
  const changeWage = (e) => {
    const event = e;
    if (!e.target.value) {
      setWage(0);
      event.target.value = '';
      return event;
    }
    if (e.target.value === '0') {
      setWage(0);
      event.target.value = '';
      return event;
    }
    if (e.target.value) {
      setWage(Math.trunc(e.target.value * 100) / 100);
      event.target.value = Math.trunc(e.target.value * 100) / 100;
      return event;
    }
  };
  const onSubmit = (data) => {
    if (!dateList.length) {
      return setCheckDateFields(true);
    }

    props.setState((prev) => ({
      ...prev,
      ...data,
      payPerHour: parseFloat(data.payPerHour),
      totalCost: parseFloat(total),
      dates: dateList,
    }));
    props.nextStep(4);
  };
  const checkOverlap = () => {
    setDateOverlap(false);
    const rangeList = [];
    dateList.map((date) => {
      // const newList = dateList.filter((date, index) => index !== i);
      const start = moment(`${date.start} ${date.day}`);
      let end = moment(`${date.end} ${date.day}`);
      if (date.nextDay) {
        end = end.add(1, 'day');
      }
      const range = moment.range(start, end);
      return rangeList.push(range);
    });
    let { length } = rangeList;
    let overlap = false;
    while (length--) {
      const lastItem = rangeList[length];
      rangeList.splice(length, 1);

      rangeList.map((range) => {
        console.log(range.overlaps(lastItem));
        if (range.overlaps(lastItem)) {
          overlap = true;
        }
      });
      // rangeList.reduce((acc, range, index2) => {
      //   if (range.overlaps(lastItem)) {
      //     console.log(length, lastItem);
      //     console.log(index2, range);
      //   }
      // });
    }
    if (overlap) {
      setValue('sameWorker', false);
      setDateOverlap(overlap);
      return overlap;
    }
    if (!overlap) {
      setDateOverlap(overlap);
      return overlap;
    }
  };
  const checkOverlap2 = (data) => {
    setDateOverlap(false);
    const startTime = moment(data.startTime)
      .milliseconds(0)
      .seconds(0)
      .format('h:mm A');
    const endTime = moment(data.endTime)
      .milliseconds(0)
      .seconds(0)
      .format('h:mm A');
    const dataDate = moment(data.day).format('MMM D, YYYY');

    const dataStart = moment(`${startTime} ${dataDate}`, 'h:mm A MMM D, YYYY');
    let dataEnd = moment(`${endTime} ${dataDate}`, 'h:mm A MMM D, YYYY');
    if (data.nextDay) {
      dataEnd = dataEnd.add(1, 'day');
    }
    const newRange = moment.range(dataStart, dataEnd);
    let overlap = false;
    dateList.map((date) => {
      const start = moment(`${date.start} ${date.day}`, 'h:mm A MMM D, YYYY');
      let end = moment(`${date.end} ${date.day}`, 'h:mm A MMM D, YYYY');
      if (date.nextDay) {
        end = end.add(1, 'day');
      }
      const range = moment.range(start, end);
      if (range.overlaps(newRange)) {
        overlap = true;
      }
    });
    if (overlap) {
      setDateOverlap(overlap);
      return overlap;
    }
    if (!overlap) {
      setDateOverlap(overlap);
      return overlap;
    }
  };
  const checkNumber = (value) => {
    const regex = RegExp('^[0-9]+(.[0-9]{1,2})?$');
    const test = regex.test(value);
    if (!value) return false;
    if (!test) return false;
    if (test && value) return true;
  };
  const convertNumToTime = (number) => {
    let sign = number >= 0 ? 1 : -1;
    number *= sign;
    const hour = Math.floor(number);
    let decpart = number - hour;
    const min = 1 / 60;
    decpart = min * Math.round(decpart / min);

    let minute = `${Math.floor(decpart * 60)}`;
    if (minute.length < 2) {
      minute = `0${minute}`;
    }
    sign = sign === 1 ? '' : '-';
    const time = `${sign + hour} hr ${minute} min`;
    return time;
  };
  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '60px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <p
        css={{
          fontWeight: 500,
          fontSize: 16,
          color: '#8A8A8A',
          marginBottom: 0,
          [mq(bp.large)]: {
            display: 'none',
          },
        }}
      >
        STEP 3 OF 5
      </p>
      <h2 css={stepTitle}>Work Dates & Pay</h2>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          [mq(bp.large)]: {
            flexDirection: 'row',
          },
        }}
      >
        <div
          css={[
            gigBox,
            {
              width: '100%',
              marginBottom: 30,
              [mq(bp.large)]: {
                width: '66%',
              },
            },
          ]}
        >
          <DateTimeForm
            addDateTime={addDateTime}
            checkDateFields={checkDateFields}
            checkOverlap={checkOverlap2}
            sameWorker={watchSameWorker}
            dateFieldsChecked={() => setCheckDateFields(false)}
            noDates={noDates}
          />
          {dateList.length ? (
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                marginBottom: 20,
              }}
            >
              {dateList.map((date, i) => (
                <div
                  css={[
                    blueBox(),
                    {
                      gridColumn: '1/4',
                      [mq(bp.large)]: { gridColumn: '1/4' },
                    },
                  ]}
                  key={i}
                >
                  <div
                    css={{
                      margin: '0',
                      fontSize: 16,
                      [mq(bp.small)]: {
                        display: 'flex',
                        alignItems: 'flex-end',
                      },
                      p: {
                        color: '#FFFFFF',
                        margin: '0 5px 0 0',
                        fontSize: 16,
                        fontWeight: '500',
                      },
                    }}
                  >
                    <p>{date.day}</p>
                    <span css={{ marginRight: 5 }}>
                      {date.start} - {date.end}
                    </span>
                    <span
                      css={{ fontSize: 12, lineHeight: 1.6, marginRight: 16 }}
                    >
                      ({convertNumToTime(date.hours)})
                    </span>
                    <span css={{ fontSize: 12 }}>
                      with {date.breakTime} min{' '}
                      <strong>{date.paidBreak ? 'paid' : 'unpaid'}</strong>{' '}
                      break
                    </span>
                  </div>
                  <div>
                    <button
                      type='button'
                      onClick={() => removeDateTime(i)}
                      css={{
                        border: 'none',
                        background: 'none',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        fontSize: 18,
                        padding: 0,
                        display: 'flex',
                      }}
                    >
                      <Close css={{ height: 16 }} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          <FormFieldErrorMessage
            error={
              dateOverlap
                ? {
                    message:
                      'Dates cannot overlap when requiring the same worker.',
                  }
                : null
            }
          />
          {}
          {dateList.length > 1 &&
          authUser.checkoutMethod === 'wage' &&
          !authUser.invoicingOption ? (
            <div css={{ marginBottom: 24 }}>
              <label htmlFor='sameWorker' css={customCheck}>
                <input
                  type='checkbox'
                  name='sameWorker'
                  id='sameWorker'
                  onChange={checkOverlap}
                  ref={register}
                />
                <span />I require the same worker(s) for all dates
              </label>
            </div>
          ) : null}
        </div>
        <div
          css={[
            gigBox,
            {
              width: '100%',
              [mq(bp.large)]: {
                width: '32%',
              },
            },
          ]}
        >
          <h2>Cost Breakdown</h2>
          <div>
            <div>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  gridColumnGap: 80,
                  marginBottom: 20,
                }}
              >
                <div>
                  <p># of Workers</p>
                </div>
                <div>
                  <p>{props.vacancies}</p>
                </div>
                <div>
                  <p>Hourly Pay</p>
                </div>
                <div css={[formInput]}>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 3,
                      border: '1px solid transparent',
                      borderColor: errors.payPerHour && 'red !important',
                      '&:focus': {
                        border: '1px solid #002E6D',
                      },
                    }}
                  >
                    $
                    <Controller
                      name='payPerHour'
                      control={control}
                      rules={{ required: true }}
                      defaultValue=''
                      render={({ onChange, value }) => (
                        <input
                          type='number'
                          id='payPerHour'
                          onChange={(e) => onChange(changeWage(e))}
                          // placeholder="Enter hourly pay"
                          value={value}
                          min={0}
                          css={{
                            marginLeft: 5,
                            '&:focus': {
                              border: 'none!important',
                              padding: '14px',
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <p>Total Paid Hours per worker</p>
                </div>
                <div css={{ width: '100%', borderBottom: '1px solid #000' }}>
                  <p>{totalHours < 0 ? 0 : totalHours} hours</p>
                </div>
                <div>
                  <p>Subtotal</p>
                </div>
                <div>
                  <p>${subTotal.toFixed(2)}</p>
                </div>
                <hr css={{ gridColumn: '1/3', width: '100%' }} />
                <div>
                  <p>Fee</p>
                </div>
                <div>
                  <p>${fees.toFixed(2)}</p>
                </div>
                <div>
                  <p>Tax (GST/HST)</p>
                </div>
                <div css={{ width: '100%', borderBottom: '1px solid #000' }}>
                  <p>${tax.toFixed(2)}</p>
                </div>
                <div>
                  <p>Estimated Total</p>
                </div>
                <div>
                  <p>${total.toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
          <div css={buttonContainer}>
            <button
              type='button'
              onClick={() => props.prevStep(2)}
              css={goBackButton}
            >
              Back
            </button>
            <button
              type='submit'
              onClick={handleSubmit(onSubmit)}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
