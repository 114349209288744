/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext } from 'react';
import { AuthUserContext } from 'library/Session';
import {
  Link,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import AuthNavigation from 'components/AuthNavigation';
import Topbar from 'components/Topbar';
import Bottombar from 'components/Bottombar';
import * as ROUTES from 'constants/routes';
import { button, sideNav, topBarContainer } from 'theme/styleBlocks';
import { bp, mq } from '../../constants/mediaQueries';
import Banking from './SettingsBanking';
import Business from './SettingsBusiness';
import Personal from './SettingsPersonal';

const Settings = () => {
  const { authUser } = useContext(AuthUserContext);
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.light,
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
      })}
    >
      <React.Fragment>
        {authUser ? (
          <div css={sideNav}>
            <div css={{ padding: '20px 0' }}>
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo-white.svg?alt=media&token=8d014c04-599a-40e7-95b1-23090cab3430"
                  alt=""
                  css={{ height: 50 }}
                />
              </Link>
            </div>
            <AuthNavigation />
            <a
              href="/create"
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
                { textAlign: 'center' },
              ]}
            >
              Create a Gig
            </a>
          </div>
        ) : null}
        <div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {authUser ? (
            <div css={topBarContainer}>
              <Topbar bgColor="#F3F3F3" pageTitle="Account Settings" />
              <Bottombar />
            </div>
          ) : null}
          <div
            css={{
              flex: 1,
              display: 'flex',
              background: '#FAFAFA',
            }}
          >
            <div
              css={(theme) => ({
                width: '100%',
                flex: 1,
                marginBottom: 65,
                [mq(bp.large)]: {
                  padding: '0 60px 0 20px',
                  margin: 0,
                  maxWidth: theme.layout.max_width - 80,
                },
              })}
            >
              <div
                css={{
                  padding: 20,
                  [mq(bp.small)]: {
                    width: '90vw',
                    margin: 'auto',
                  },
                  [mq(bp.large)]: { padding: '60px 0', width: 'auto' },
                }}
              >
                <h1>General Account Settings</h1>
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    [mq(bp.large)]: {
                      flexDirection: 'row',
                    },
                  }}
                >
                  <div
                    css={{
                      display: 'block',
                      [mq(bp.large)]: { marginRight: 60 },
                    }}
                  >
                    <div
                      css={{
                        padding: 16,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        [mq(bp.large)]: {
                          border: '1px solid #969696',
                          borderRadius: '4px',
                          flexDirection: 'column',
                          background: '#fff',
                        },
                      }}
                    >
                      <div css={{ marginBottom: 20 }}>
                        <Link
                          to={`${ROUTES.SETTINGS}/business`}
                          css={{
                            color: '#002E6D',
                            fontWeight:
                              pathname === `${ROUTES.SETTINGS}/business` &&
                              'bold',
                            fontSize: 16,
                          }}
                        >
                          Business Information
                        </Link>
                      </div>
                      <div css={{ marginBottom: 20 }}>
                        <Link
                          to={`${ROUTES.SETTINGS}/personal`}
                          css={{
                            color: '#002E6D',
                            fontWeight:
                              pathname === `${ROUTES.SETTINGS}/personal` &&
                              'bold',
                            fontSize: 16,
                          }}
                        >
                          Personal Information
                        </Link>
                      </div>
                      <div>
                        <Link
                          // to={`${ROUTES.SETTINGS}/banking`}
                          to={`${ROUTES.SETTINGS}/banking`}
                          css={{
                            color: '#002E6D',
                            fontWeight:
                              pathname === `${ROUTES.SETTINGS}/banking` &&
                              'bold',
                            fontSize: 16,
                          }}
                        >
                          Banking Information
                        </Link>
                      </div>
                    </div>
                  </div>
                  <Switch>
                    <Route exact path={path}>
                      <Redirect to={`${path}/business`} />
                    </Route>
                    <Route exact path={`${path}/business`}>
                      <Business authUser={authUser} />
                    </Route>
                    <Route exact path={`${path}/personal`}>
                      <Personal authUser={authUser} />
                    </Route>
                    <Route exact path={`${path}/banking`}>
                      <Banking authUser={authUser} />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Settings;
