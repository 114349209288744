/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import { AuthUserContext } from 'library/Session';
import { settingContent, settingTitle } from 'theme/styleBlocks';
import { bp, mq } from '../../constants/mediaQueries';
import Password from './Password';
import PhoneNumber from './PhoneNumber';
import Email from './Email';
import ProfileImage from './ProfileImage';

const SettingsBusiness = () => {
  const { authUser } = useContext(AuthUserContext);
  return (
    <div css={{ flex: 1 }}>
      <div
        css={{
          border: '1px solid #969696',
          borderRadius: '4px',
          background: '#fff',
        }}
      >
        <ProfileImage
          profileImage={authUser.profileImage}
          original={authUser.originalProfileImage}
        />
        <div
          css={{
            padding: 30,
            alignItems: 'center',
            [mq(bp.large)]: {
              display: 'grid',
              gridTemplateColumns: '1fr 2fr',
            },
          }}
        >
          <div css={{ justifySelf: 'start' }}>
            <h2 css={settingTitle}>Business Name</h2>
          </div>
          <div>
            <div css={settingContent}>
              <p>{authUser.businessName}</p>
            </div>
          </div>
        </div>
        <Email email={authUser.email} />
        <PhoneNumber phoneNumber={authUser.phoneNumber} />
        <Password />
      </div>
    </div>
  );
};

export default SettingsBusiness;
