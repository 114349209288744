/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

const NotAvailableModal = ({ setNotAvaiableOpen }) => {
  return (
    <div
      css={{
        position: 'fixed',
        backgroundColor: 'rgba(0,0,0,0.5)',
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0,
        zIndex: 99,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 24,
      }}
    >
      <div
        css={{
          backgroundColor: 'white',
          position: 'relative',
          zIndex: 999,
          display: 'flex',
          flexDirection: 'column',
          padding: 100,
        }}
      >
        <p>The Worker you have selected is no longer available</p>
        <button
          type='button'
          onClick={() => setNotAvaiableOpen(false)}
          css={{ border: 0, fontSize: 16, color: '#002E6D', cursor: 'pointer' }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default NotAvailableModal;
