/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { bp, mq } from "constants/mediaQueries";
import { ReactComponent as Bell } from "assets/icons/bell.svg";
import { ReactComponent as NotifIcon } from "assets/icons/notifIcon.svg";
import { withFirebase } from "library/Firebase";
import { AuthUserContext } from "library/Session";
import * as ROUTES from "constants/routes";

const Notifications = ({ firebase }) => {
  const { authUser } = useContext(AuthUserContext);
  const [open, setOpen] = useState(false);
  const [notifs, setNotifs] = useState([]);
  const [notifIndicator, setNotifIndicator] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const notifRef = useRef(null);

  useEffect(() => {
    const notifSnapshot = firebase.notifSnapshot(authUser.uid);
    const unsub = notifSnapshot.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data());
      if (data.length > 10) {
        const firstTen = data.slice(0, 10);
        setNotifs(firstTen);
      } else {
        setNotifs(data);
      }
    });
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (!authUser.notificationsRead) {
      const notifIndicatorNum = notifs.filter(
        (notification) => notification.new === true
      ).length;
      setNotifIndicator(notifIndicatorNum);
    }
    if (authUser.notificationsRead) {
      setNotifIndicator(0);
    }
  }, [authUser.notificationsRead, notifs]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notifRef.current && !notifRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notifRef]);

  const markAllAsRead = () => {
    firebase.markAllAsRead();
  };
  const openNotifs = () => {
    if (!authUser.notificationsRead) {
      firebase.notifsRead();
    }
    setOpen(!open);
  };
  const markRead = (notifID) => {
    firebase.markRead(notifID);
  };
  const notifClick = (url, uid, read) => {
    if (!read) {
      firebase.markRead(uid);
      console.log(url);
      history.push(url);
    }
    if (read) {
      history.push(url);
    }
  };
  return (
    <div
      css={{
        position: "relative",
        display: "none",
        [mq(bp.large)]: {
          display: "block",
        },
      }}
      ref={notifRef}
    >
      <Bell css={{ width: 20, marginRight: 16 }} onClick={() => openNotifs()} />
      {notifIndicator > 0 ? (
        <span
          css={{
            position: "absolute",
            borderRadius: "50%",
            background: "red",
            color: "#fff",
            height: 16,
            width: 16,
            fontSize: 10,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: -5,
            right: 8,
          }}
        >
          {notifIndicator}
        </span>
      ) : null}
      {open ? (
        <div
          css={{
            position: "absolute",
            width: "100vw",
            maxWidth: 350,
            right: -10,
            zIndex: 999,
          }}
        >
          <div
            css={{
              position: "relative",
              border: "1px solid #c4c4c4",
              marginTop: 12,
              background: "#fff",
              borderRadius: 4,
              "&:after, &:before": {
                bottom: "100%",
                left: "calc(100% - 35px );",
                border: "solid transparent",
                content: '" "',
                height: 0,
                width: 0,
                position: "absolute",
                pointerEvents: "none",
              },
              "&:after": {
                borderColor: "rgba(213, 213, 213, 0)",
                borderBottomColor: "#fff",
                borderWidth: 9,
                marginLeft: -9,
              },
              "&:before": {
                borderColor: "rgba(0, 0, 0, 0)",
                borderBottomColor: "#a0a0a0",
                borderWidth: 10,
                marginLeft: -10,
              },
              "&>div:not(:last-of-type)": {
                borderBottom: "1px solid #efefef",
              },
            }}
          >
            <div
              css={{
                padding: 12,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p css={{ margin: 0, fontWeight: "bold", fontSize: 14 }}>
                Notifications
              </p>
              <button
                type="button"
                css={{
                  border: "none",
                  fontSize: 12,
                  color: "#002E6D",
                  cursor: "pointer",
                }}
                onClick={markAllAsRead}
              >
                Mark all as read
              </button>
            </div>
            {notifs.length > 0 ? (
              notifs.map((notif, i) => (
                <div
                  key={i}
                  css={{
                    backgroundColor: notif.read ? null : "#D9E0E9",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <button
                    type="button"
                    css={{
                      margin: 0,
                      fontSize: 14,
                      width: "100%",
                      textAlign: "left",
                      border: "none",
                      padding: "12px 0 12px 12px",
                      cursor: "pointer",
                      "&:hover": {
                        opacity: 0.7,
                      },
                    }}
                    onClick={() => notifClick(notif.url, notif.uid, notif.read)}
                  >
                    {notif.message}
                  </button>
                  <button
                    type="button"
                    css={{
                      padding: 12,
                      display: "block",
                      border: "none",
                      margin: 0,
                      cursor: "pointer",
                    }}
                    disabled={notif.read}
                    onClick={() => markRead(notif.uid)}
                  >
                    <NotifIcon
                      css={{
                        display: "block",
                        visibility: notif.read ? "hidden" : "visible",
                      }}
                    />
                  </button>
                </div>
              ))
            ) : (
              <div css={{ padding: 12 }}>
                <p css={{ margin: 0 }}>No Notifications</p>
              </div>
            )}
            <div>
              {notifs.length >= 10 ? (
                <div
                  css={{
                    color: "#002E6D",
                    width: "100%",
                    textAlign: "center",
                    padding: "9px",
                    borderTop: "1px solid #efefef",
                    fontSize: "12px",
                  }}
                >
                  <Link
                    to={ROUTES.NOTIFICATIONS}
                    css={{
                      fontSize: "12px",
                    }}
                  >
                    See all
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withFirebase(Notifications);
