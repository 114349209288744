/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useContext } from "react";
import { withFirebase } from "library/Firebase";
import { AuthUserContext } from "library/Session";
import { button } from "theme/styleBlocks";
import * as styles from "./styles";

const CheckoutSurvey = ({
  workerData,
  gigData,
  preConfirm,
  setPreConfirm,
  preAnswers,
  setPreAnswers,
  setSurveyResults,
}) => {
  const [q1, setQ1] = useState();
  const [q2, setQ2] = useState();
  const [q3, setQ3] = useState();
  const { gigID } = gigData;
  const { authUser } = useContext(AuthUserContext);
  const bizID = authUser.uid;

  const handleSubmit = (e) => {
    e.preventDefault();

    const surveyAnswers = {
      q1,
      q2,
      q3,
      gigID,
      bizID,
    };
    // display payment component
    setPreConfirm(true);
    // hide survey component
    setPreAnswers(false);
    setSurveyResults(surveyAnswers);
  };

  return (
    <form css={styles.formContainer} onSubmit={handleSubmit}>
      <h3>
        Please answer the following questions in order to mark the gig as
        complete by {workerData.firstName}:
      </h3>
      <h4>Did {workerData.firstName} arrive on time?</h4>
      <div css={styles.labelContainer}>
        <label className="inputs">
          Yes
          <input
            className="radioBtn"
            type="radio"
            value
            name="q1"
            required
            onChange={(e) => setQ1(e.target.value)}
          />
        </label>
        <label className="inputs">
          No
          <input
            className="radioBtn"
            type="radio"
            value={false}
            name="q1"
            required
            onChange={(e) => setQ1(e.target.value)}
          />
        </label>
      </div>
      <h4>
        Was {workerData.firstName} dressed appropriately according to the
        instructions?
      </h4>
      <div css={styles.labelContainer}>
        <label className="inputs">
          Yes
          <input
            className="radioBtn"
            type="radio"
            value
            name="q2"
            required
            onChange={(e) => setQ2(e.target.value)}
          />
        </label>
        <label className="inputs">
          No
          <input
            className="radioBtn"
            type="radio"
            value={false}
            name="q2"
            required
            onChange={(e) => setQ2(e.target.value)}
          />
        </label>
      </div>
      <h4>Would you hire {workerData.firstName} again?</h4>
      <div css={styles.labelContainer}>
        <label className="inputs">
          Yes
          <input
            className="radioBtn"
            type="radio"
            value
            name="q3"
            required
            onChange={(e) => setQ3(e.target.value)}
          />
        </label>
        <label className="inputs">
          No
          <input
            className="radioBtn"
            type="radio"
            value={false}
            name="q3"
            required
            onChange={(e) => setQ3(e.target.value)}
          />
        </label>
      </div>
      {/* <h4>
        Would you like to add {workerData.firstName} to your Favourite Worker's
        list?
      </h4>
      <div css={styles.labelContainer}>
        <label className="inputs">
          Yes
          <input
            className="radioBtn"
            type="radio"
            value
            name="q4"
            required
            onChange={(e) => setQ4(e.target.value)}
          />
        </label>
        <label className="inputs">
          No
          <input
            className="radioBtn"
            type="radio"
            value={false}
            name="q4"
            required
            onChange={(e) => setQ4(e.target.value)}
          />
        </label>
      </div> */}
      <button
        type="submit"
        css={(theme) => [
          button(
            theme.buttons.type_light,
            theme.buttons.bg1,
            theme.buttons.hover1
          ),
          styles.nextBtn,
        ]}
      >
        Next
      </button>
    </form>
  );
};

export default withFirebase(CheckoutSurvey);
