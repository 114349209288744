/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useContext } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from 'library/Firebase';
import * as ROUTES from 'constants/routes';
import { AuthUserContext } from 'library/Session';
import { formInput, pageTitle, button } from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';

const LoginPage = (props) => {
  const { authUser } = useContext(AuthUserContext);
  return (
    <div>
      {authUser ? (
        <Redirect to={ROUTES.DASHBOARD} />
      ) : (
        <div>
          <div css={pageTitle}>
            <h1>Sign in to WorkBriefly</h1>
          </div>
          <div
            css={(theme) => ({
              padding: '32px 40px',
              border: `1px solid ${theme.colors.neutral_light1}`,
              backgroundColor: theme.colors.light,
              margin: '0 auto',
              borderRadius: '4px',
              width: '100%',
              [mq(bp.small)]: {
                width: 565,
                padding: '32px 40px',
              },
            })}
          >
            <LoginForm />
            <p css={{ marginBottom: 40 }}>
              <Link to={ROUTES.RECOVER} css={{ textDecoration: 'underline' }}>
                Forgot Password?
              </Link>
            </p>
            <p>
              Don&apos;t have an account?{' '}
              <Link to={ROUTES.SIGN_UP} css={{ textDecoration: 'underline' }}>
                Sign Up Free
              </Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const LoginFormBase = (props) => {
  const initialState = {
    email: '',
    password: '',
    error: null,
  };

  const [state, setState] = useState(initialState);

  const onSubmit = (event) => {
    const { email, password } = state;
    props.firebase
      .doLoginWithEmailAndPassword(email, password)
      .then((data) => {
        setState((prev) => ({ ...prev, success: data.success }));
        if (!data.success) {
          return setState((prev) => ({ ...prev, error: data }));
        }
        if (data.success) {
          props.history.push(ROUTES.DASHBOARD);
        }
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-email') {
          error.message = 'Invalid Email';
        }
        setState((prev) => ({ ...prev, error }));
      });
    event.preventDefault();
  };
  const onChange = (event) => {
    event.persist();
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const { email, password, error } = state;
  const isInvalid = password === '' || email === '';
  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div css={formInput}>
        <label htmlFor="email">Username/E-mail</label>
        <input
          name="email"
          value={email}
          onChange={(e) => onChange(e)}
          type="text"
          placeholder="Enter your username or e-mail"
          id="email"
        />
      </div>
      <div css={formInput}>
        <label htmlFor="password">Password</label>
        <input
          name="password"
          value={password}
          onChange={(e) => onChange(e)}
          type="password"
          placeholder="Enter your password"
        />
      </div>
      <button
        disabled={isInvalid}
        type="submit"
        css={(theme) =>
          button(
            theme.buttons.type_dark,
            theme.buttons.bg2,
            theme.buttons.hover2
          )
        }
      >
        Sign In
      </button>
      {error && <p>{error.message}</p>}
    </form>
  );
};

const LoginForm = compose(withRouter, withFirebase)(LoginFormBase);

export default LoginPage;
export { LoginForm };
