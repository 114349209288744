export const loadConfig = () => {
  if (
    (process.env.NODE_ENV === 'development' &&
      window.location.hostname === 'localhost') ||
    (process.env.NODE_ENV === 'production' &&
      window.location.hostname === 'biz-wb-staging.web.app')
  ) {
    console.log('dev');
    return {
      apiKey: process.env.REACT_APP_API_KEY_DEV,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
      databaseURL: process.env.REACT_APP_DATABASE_URL_DEV,
      projectId: process.env.REACT_APP_PROJECT_ID_DEV,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
      appId: process.env.REACT_APP_APP_ID_DEV,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID_DEV,
    };
  }
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname !== 'biz-wb-staging.web.app' &&
    window.location.hostname === 'business.workbriefly.com'
  ) {
    console.log('prod');
    return {
      apiKey: process.env.REACT_APP_API_KEY_PROD,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
      databaseURL: process.env.REACT_APP_DATABASE_URL_PROD,
      projectId: process.env.REACT_APP_PROJECT_ID_PROD,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
      appId: process.env.REACT_APP_APP_ID_PROD,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID_PROD,
    };
  }
};

export const goolgeApi = () => {
  if (
    (process.env.NODE_ENV === 'development' &&
      window.location.hostname === 'localhost') ||
    (process.env.NODE_ENV === 'production' &&
      window.location.hostname === 'biz-wb-staging.web.app')
  ) {
    return 'AIzaSyA6EbDGs2JhxwEDiUTAG6rQC_Hg2HOirGY';
  }
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname !== 'biz-wb-staging.web.app' &&
    window.location.hostname === 'business.workbriefly.com'
  ) {
    return 'AIzaSyDRaSfS9ZSCRkXcUTBH9nOMEigUDGCFRIc';
  }
};
