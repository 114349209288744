/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { bp, mq } from 'constants/mediaQueries';

const KebabMenu = () => {
  const [modal, setModal] = useState(false);

  return (
    <div>
      {/* Mobile kebab menu */}
      <button
        type="button"
        css={{
          display: 'block',
          border: 'none',
          cursor: 'pointer',
          [mq(bp.large)]: {
            display: 'none',
          },
          '&:focus': { outline: 0 },
          figure: {
            width: '6px',
            height: '6px',
            borderRadius: '5px',
            background: '#fff',
            margin: '3px 0',
          },
        }}
        onClick={() => setModal(!modal)}
      >
        <figure />
        <figure />
        <figure />
      </button>
      {/* Mobile nav Content */}
      <div>
        <div
          css={{
            display: modal ? 'flex' : 'none',
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0,0,0,.5)',
            top: 60,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 99,
            [mq(bp.large)]: {
              display: 'none',
            },
          }}
        >
          <div
            css={{
              position: 'fixed',
              width: '90vw',
              textAlign: 'center',
              bottom: 80,
            }}
          >
            <div
              css={{
                background: '#fff',
                borderRadius: 4,
                div: {
                  padding: '15px 0',
                  '&:nth-of-type(1)': {
                    borderBottom: '2px solid #EFEFEF',
                  },
                },
                a: {
                  fontSize: 20,
                  fontWeight: 'bold',
                  margin: 0,
                },
              }}
            >
              <div css={{ a: { color: '#002E6D' } }}>
                <a
                  href="https://www.workbriefly.com/contact-us/"
                  target="_blank"
                >
                  Give Feedback
                </a>
              </div>
              <div css={{ a: { color: '#D72F2F' } }}>
                <a
                  href="https://www.workbriefly.com/contact-us/"
                  target="_blank"
                >
                  Report an issue
                </a>
              </div>
            </div>
            <div
              css={{
                padding: '15px 0',
                background: '#fff',
                marginTop: 3,
                borderRadius: 4,
                div: {
                  padding: 10,
                },
                p: {
                  fontSize: 20,
                  fontWeight: 'bold',
                  margin: 0,
                  color: '#002E6D',
                },
              }}
              onClick={() => setModal(!modal)}
            >
              <p>Cancel</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KebabMenu;
