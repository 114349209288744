/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useContext, useEffect } from 'react';
import {
  Link,
  useRouteMatch,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import { withFirebase } from 'library/Firebase';
import { AuthUserContext, withAuthorization } from 'library/Session';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import * as ROUTES from 'constants/routes';
import { setupButton, button, pageTitle } from 'theme/styleBlocks';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { bp, mq } from 'constants/mediaQueries';
import Payment from './Payment';
import Locations from './Locations';

const SetupContainer = (props) => {
  const { authUser, refetch } = useContext(AuthUserContext);

  const { path, url } = useRouteMatch();

  const history = useHistory();
  const [setup, setSetup] = useState({
    paymentCard: false,
    profileImage: false,
  });
  useEffect(() => {
    setSetup(authUser.setup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.setup]);

  const completeSetup = () => {
    props.firebase.completeSetup().then(() => {
      refetch(true);
      history.push('/dashboard');
    });
  };

  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.neutral_light2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      })}
    >
      <div css={{ backgroundColor: '#FFFFFF' }}>
        <Navigation authUser={authUser} />
      </div>
      <div css={{ flex: 1 }}>
        <div
          css={(theme) => ({
            maxWidth: theme.layout.max_width,
            flex: 1,
            margin: '0 auto',
            padding: '40px 15px',
            [mq(bp.small)]: {
              width: '90vw',
            },
            [mq(bp.large)]: {
              maxWidth: theme.layout.max_width,
              padding: '40px 64px',
            },
          })}
        >
          <div>
            <div css={pageTitle}>
              <h1>Start filling shifts with WorkBriefly</h1>
              <Switch>
                <Route exact path={path}>
                  <p>
                    You’re almost ready to go! Just complete the following setup
                    steps. You can always sign out and sign back in to complete
                    this at a later time.
                  </p>
                </Route>
              </Switch>
            </div>
            <Switch>
              <Route exact path={path}>
                <div css={{ textAlign: 'center', paddingTop: 20 }}>
                  <h2>Complete your setup</h2>
                  <div
                    css={{
                      margin: '0 auto',
                      padding: '35px 15px',
                      border: '1px solid #969696',
                      backgroundColor: '#FFFFFF',
                      width: '100%',
                      borderRadius: '4px',
                      [mq(bp.small)]: {
                        width: 387,
                      },
                      div: {
                        maxWidth: 358,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      },
                    }}
                  >
                    <div>
                      <Link
                        to={`${url}/payment`}
                        css={(theme) =>
                          authUser.setup.paymentCard
                            ? setupButton(
                                theme.buttons.bg4,
                                theme.buttons.type_dark
                              )
                            : setupButton(
                                theme.buttons.bg1,
                                theme.buttons.type_light
                              )
                        }
                      >
                        {authUser.setup.paymentCard ? (
                          <span css={{ marginRight: 12, display: 'flex' }}>
                            <GreenCheck css={{ width: 24, height: 24 }} />
                          </span>
                        ) : null}
                        Add Payment Details
                      </Link>
                    </div>
                    <div>
                      <Link
                        to={`${url}/locations`}
                        css={(theme) =>
                          authUser.setup.locations
                            ? setupButton(
                                theme.buttons.bg4,
                                theme.buttons.type_dark
                              )
                            : setupButton(
                                theme.buttons.bg1,
                                theme.buttons.type_light
                              )
                        }
                      >
                        {authUser.setup.locations ? (
                          <span css={{ marginRight: 12, display: 'flex' }}>
                            <GreenCheck css={{ width: 24, height: 24 }} />
                          </span>
                        ) : null}
                        Add Business Locations
                      </Link>
                    </div>
                    {/* <div css={{ marginBottom: 25 }}>
                      <button
                        type="button"
                        onClick={completeSetup}
                        css={(theme) => [
                          button(
                            theme.buttons.type_dark,
                            theme.buttons.bg2,
                            theme.buttons.hover2
                          ),
                          {
                            width: '100%',
                            padding: '12px 32px',
                          },
                        ]}
                        disabled={!setup.paymentCard || !setup.profileImage}
                      >
                        Complete Setup
                      </button>
                    </div> */}
                    <div>
                      <a
                        href={ROUTES.DASHBOARD}
                        css={(theme) => [
                          setupButton(
                            theme.buttons.bg2,
                            theme.buttons.type_dark,
                            theme.buttons.hover2
                          ),
                          { justifyContent: 'center' },
                        ]}
                      >
                        Go to Dashboard
                      </a>
                    </div>
                  </div>
                </div>
              </Route>
              <Route path={`${path}/payment`}>
                <Payment setSetup={setSetup} />
              </Route>
              <Route path={`${path}/locations`}>
                <Locations setSetup={setSetup} locations={authUser.locations} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withFirebase(withAuthorization(SetupContainer));
