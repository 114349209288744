/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import {
  leftBox,
  signUpRight,
  button,
  goBackButton,
  buttonContainer,
  listRight,
  twoColumn,
  customCheck,
} from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';
import ServiceAgreementCopy from 'constants/ServiceAgreementCopy';
import { useHistory } from 'react-router-dom';

const ServiceAgreement = ({
  confirmAgreement,
  onCheckboxChange,
  nextStep,
  setStep,
}) => {
  const history = useHistory();
  const isDisabled = confirmAgreement === false;

  useEffect(() => {
    setStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (authUser) {
  //     return history.push('/dashboard');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const goBack = () => {
    history.push('/login');
  };

  return (
    <div>
      <h2>Service agreement</h2>
      <div css={twoColumn}>
        <div>
          <div css={[leftBox, { padding: 16 }]}>
            <div
              css={(theme) => ({
                maxHeight: 500,
                overflowY: 'auto',
                border: `1px solid ${theme.colors.neutral_light1}`,
                marginBottom: 20,
                padding: '30px 18px',
                [mq(bp.small)]: {
                  padding: 30,
                },
                h2: {
                  marginTop: 0,
                  fontSize: 18,
                  [mq(bp.large)]: {
                    fontSize: 24,
                  },
                },
              })}
            >
              <ServiceAgreementCopy />
            </div>
            <div css={{ marginBottom: 24 }}>
              <label htmlFor="confirmAgreement" css={customCheck}>
                <input
                  type="checkbox"
                  name="confirmAgreement"
                  id="confirmAgreement"
                  onChange={onCheckboxChange}
                  checked={confirmAgreement}
                />
                <span />I confirm that I have read and agree to these terms.
              </label>
            </div>
            <div css={buttonContainer}>
              <button type="button" onClick={goBack} css={goBackButton}>
                Back
              </button>
              <button
                type="button"
                disabled={isDisabled}
                onClick={() => nextStep(2, 'verification')}
                css={(theme) =>
                  button(
                    theme.buttons.type_dark,
                    theme.buttons.bg2,
                    theme.buttons.hover2
                  )
                }
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        <div css={signUpRight}>
          <div>
            <h3 css={{ marginTop: 0 }}>Customer Agreement (simplified)</h3>
            <p>
              We understand that you probably won’t read the entire Customer
              Agreement in it’s legal form so we have simplified it for you. By
              agreeing to these terms of use, you understand and acknowledge the
              following:
            </p>
            <ul css={listRight}>
              <li>
                <p>
                  WorkBriefly is a platform that allows you to connect with
                  workers who act as independent contractors for a variety of
                  types of tasks.
                </p>
              </li>
              <li>
                <p>
                  You agree to treat workers with respect and to adhere to local
                  labour laws such as human rights at the workplace and minimum
                  wage laws.
                </p>
              </li>
              <li>
                <p>
                  You agree to provide a safe working environment and safe
                  working conditions for workers.
                </p>
              </li>
              <li>
                <p>
                  The Service Fee for engaging a WorkBriefly Worker as an
                  independent contractor totals 20% of the payment amount and is
                  generally for creating, hosting, administering, maintaining
                  and providing the application (this fee is subject to change).
                </p>
              </li>
              <li>
                <p>
                  WorkBriefly Inc. is not liable for any loss of profits or any
                  indirect, consequential, special or punitive damage arising
                  from use of the WorkBriefly platform.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAgreement;
