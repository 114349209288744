/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthUserContext } from 'library/Session';
import * as ROUTES from 'constants/routes';
// import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as HomeBlue } from 'assets/icons/homeBlue.svg';
import { ReactComponent as Gigs } from 'assets/icons/gigs.svg';
import { ReactComponent as GigsBlue } from 'assets/icons/gigsBlue.svg';
import { bp, mq } from 'constants/mediaQueries';

const Bottombar = (props) => {
  const { authUser } = useContext(AuthUserContext);
  const location = useLocation();
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff',
        position: 'fixed',
        width: '100%',
        zIndex: 99,
        bottom: 0,
        padding: '5px 40px 5px',
        borderTop: '1px solid #EFEFEF',
        height: '65px',
        [mq(bp.large)]: {
          display: 'none',
        },
        svg: {
          width: 32,
          height: 32,
        },
        path: {
          width: 32,
        },
        a: {
          textAlign: 'center',
        },
        p: {
          margin: 0,
          fontSize: 12,
        },
      }}
    >
      <Link to={ROUTES.DASHBOARD}>
        <Home
          css={{
            display: location.pathname === ROUTES.DASHBOARD ? 'none' : 'inline',
          }}
        />
        <HomeBlue
          css={{
            display: location.pathname === ROUTES.DASHBOARD ? 'inline' : 'none',
          }}
        />
        <p
          css={{
            color:
              location.pathname === ROUTES.DASHBOARD ? '#002E6D' : '#8A8A8A',
          }}
        >
          Home
        </p>
      </Link>
      <Link to="/gigs/active">
        <Gigs
          css={{
            display: location.pathname.includes('/gigs/') ? 'none' : 'inline',
          }}
        />
        <GigsBlue
          css={{
            display: location.pathname.includes('/gigs/') ? 'inline' : 'none',
          }}
        />
        <p
          css={{
            color: location.pathname.includes('/gigs/') ? '#002E6D' : '#8A8A8A',
          }}
        >
          Gigs
        </p>
      </Link>
      {/* hide notification for now */}
      {/* <a href="/">
        <Bell />
        <p css={{ lineHeight: 0.8 }}>Notifications</p>
      </a> */}
      <Link to={ROUTES.ACCOUNT_MENU}>
        <div
          css={{
            width: 34,
            height: 34,
            border:
              location.pathname === ROUTES.ACCOUNT_MENU
                ? '1px solid #002E6D'
                : '1px solid lightgrey',
            borderRadius: '50%',
            overflow: 'hidden',
            background: '#ECF0F4',
            margin: 'auto',
          }}
        >
          <img
            src={authUser.profileImage}
            alt=""
            css={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </div>
        <p
          css={{
            color:
              location.pathname === ROUTES.ACCOUNT_MENU ? '#002E6D' : '#8A8A8A',
          }}
        >
          Account
        </p>
      </Link>
    </div>
  );
};

export default Bottombar;
