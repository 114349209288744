/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  formInput,
  button,
  completedIcon,
  completeText,
} from 'theme/styleBlocks';
import { withFirebase } from 'library/Firebase';
import { AuthUserContext } from 'library/Session';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';

const CertRequest = (props) => {
  const { authUser } = useContext(AuthUserContext);
  const [sending, setSending] = useState(false);
  const [complete, setComplete] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { certName: '', resource: '' },
  });
  const requestCert = (data) => {
    setSending(true);
    props.firebase
      .requestCert({
        ...data,
        uid: authUser.uid,
        email: authUser.email,
        name: `${authUser.businessName}`,
      })
      .then(() => {
        setComplete(true);
        setSending(false);
        reset();
      });
  };
  return (
    <div css={{ maxWidth: 800, margin: '0 auto', padding: 40 }}>
      <h1 css={{ textAlign: 'center', marginBottom: 0 }}>
        Request to add missing Certificate/License
      </h1>
      <p css={{ textAlign: 'center', margin: '0 0 40px 0' }}>
        Fill out the form below to request an addition of a certification or
        license that is currently missing from our list. You will receive an
        email once it has been added to our list.
      </p>
      {complete ? (
        <div
          css={{
            maxWidth: 500,
            margin: '0 auto',
            padding: 20,
            border: '1px solid #000',
            textAlign: 'center',
          }}
        >
          <GreenCheck css={completedIcon} />
          <p css={completeText}>Your request has been sent!</p>
          <button
            type="button"
            onClick={() => setComplete(false)}
            css={(theme) =>
              button(
                theme.buttons.type_dark,
                theme.buttons.bg2,
                theme.buttons.hover2
              )
            }
          >
            Request Another Certificate/License
          </button>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(requestCert)}
          css={{
            maxWidth: 500,
            margin: '0 auto',
            padding: 20,
            border: '1px solid #000',
          }}
        >
          <div css={formInput}>
            <label htmlFor="certName" css={{ display: 'block' }}>
              Official name of Certificate/License
            </label>
            <input
              type="text"
              ref={register({ required: true })}
              name="certName"
              id="certName"
              css={{ display: 'block', width: '100%' }}
              disabled={sending}
            />
          </div>
          <div css={formInput}>
            <label htmlFor="resource" css={{ display: 'block' }}>
              Certification/License Website
            </label>
            <input
              type="text"
              id="resource"
              ref={register}
              name="resource"
              css={{ display: 'block', width: '100%' }}
              disabled={sending}
            />
          </div>
          <div css={{ textAlign: 'right' }}>
            <button
              type="submit"
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
              disabled={sending}
            >
              {sending ? 'Submitting' : 'Submit'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default withFirebase(CertRequest);
