/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import Confirmation from "./Confirmation";
import { button } from "theme/styleBlocks";
import * as styles from "./styles";

const Suboptions = ({
  option,
  setOption,
  workerData,
  gigID,
  gigData,
  hideOptions,
  setHideOptions,
  certOption,
  setCertOption,
  setReportModalOpen,
}) => {
  const [hideSuboptions, setHideSuboptions] = useState(true);
  const [certCodes, setCertCodes] = useState([]);
  const certs = gigData.certs;
  const [btnEnable, setBtnEnable] = useState(true);

  const handleClick = (subOptionNo) => {
    setOption(subOptionNo);
    setHideSuboptions(!hideSuboptions);
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      certCodes.push([e.target.id, e.target.name, e.target.value]);
    }
    if (!e.target.checked) {
      const certToRemove = certCodes.findIndex(
        (cert) => cert[0] === e.target.id
      );
      certCodes.splice(certToRemove, 1);
    }
    certCodes.length > 0 ? setBtnEnable(false) : setBtnEnable(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCertCodes(certCodes);
    setHideSuboptions(hideSuboptions);
    setCertOption(!certOption);
  };

  const optionFunc = () => {
    switch (option) {
      case 1:
        return [
          {
            firstOption: `The worker is not here for the job and I have attempted to contact
            the worker via the number provided`,
          },
          {
            subOptionNo: 1,
            message: `Find me a replacement ASAP. (Note: This does not guarantee that we
                    will be able to find a replacement. It may take up to an hour for a
                    replacement worker to be found.)`,
          },
          {
            subOptionNo: 2,
            message: `I don’t need a replacement, I’d like the worker to leave and for
                    this worker to be cancelled from the gig. (Note: You will not be
                    charged for this worker.)`,
          },
        ];
      case 2:
        return [
          {
            firstOption: `The worker does not possess the relevant certificate/license
            stated on their profile that I have set as a requirement to
            perform this job`,
          },
          {
            subOptionNo: 1,
            message: `Find me a replacement ASAP. (Note: This does not guarantee that we
              will be able to find a replacement. It may take up to an hour for a
              replacement worker to be found.)`,
          },
          {
            subOptionNo: 3,
            message: `I will accept the worker and assign him/her another task that
            doesn't require said certificate/licence. (Note: This has to be a
            mutual agreement with the worker.)`,
          },
          {
            subOptionNo: 2,
            message: `I don’t need a replacement, I’d like the worker to leave and for
            this worker to be cancelled from the gig. (Note: You will not be
            charged for this worker.)`,
          },
        ];
      case 3:
        return [
          {
            firstOption: `The worker arrived without the mentioned equipment or attire
            specified in the gig description.`,
          },
          {
            subOptionNo: 4,
            message: `I will allow the worker to quickly pick up said attire/equipment and
            report back for the gig. (Note: This has to be a mutual agreement
            with the worker.)`,
          },
          {
            subOptionNo: 5,
            message: `I will accept the worker and assign him/her another task that
            doesn’t require said equipment/attire. (Note: This has to be a
            mutual agreement with the worker.)`,
          },
          {
            subOptionNo: 2,
            message: `I don’t need a replacement, I’d like the worker to leave and for
            this worker to be cancelled from the gig. (Note: You will not be
            charged for this worker.)`,
          },
        ];
      case 4:
        return [
          {
            firstOption: `The worker is not following instructions provided and is causing a
            disruption that is detrimental to my business.`,
          },
          {
            subOptionNo: 1,
            message: `Find me a replacement ASAP. (Note: This does not guarantee that we
              will be able to find a replacement. It may take up to an hour for a
              replacement worker to be found.)`,
          },
          {
            subOptionNo: 2,
            message: `I don’t need a replacement, I’d like the worker to leave and for
            this worker to be cancelled from the gig. (Note: You will not be
            charged for this worker.)`,
          },
        ];
      default:
        break;
    }
  };

  return (
    <div>
      {option === 0 ? null : certOption ? (
        // IF WORKER IS MISSING CERTIFICATE
        <div css={styles.reportBody}>
          <div>
            <div className="header">
              <h2 className="title">Report an Issue</h2>
              <p>
                You are reporting
                <span className="boldFont">
                  {workerData.firstName} {workerData.lastName}
                </span>
              </p>
            </div>
            <p className="smallFont">YOU SELECTED</p>
            {optionFunc().map((data, index) => (
              <p key={index} className="firstOption">
                {data.firstOption}
              </p>
            ))}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="certBox">
              {certs.map((cert) => (
                <label name="cert" className="certOption" key={cert.code}>
                  <input
                    type="checkbox"
                    className="checkBox"
                    id={cert.code}
                    name={cert.label}
                    value={cert.equivalent.map((code) => code)}
                    onChange={handleChange}
                  />
                  {cert.label}
                </label>
              ))}
            </div>
            <div className="btnContainer">
              <button
                type="button"
                className="backBtn"
                onClick={() => {
                  setHideOptions(!hideOptions);
                  setCertOption(!certOption);
                }}
              >
                Back
              </button>
              <button
                type="submit"
                className="confirmBtn"
                css={(theme) => [
                  button(
                    theme.buttons.type_light,
                    theme.buttons.bg1,
                    theme.buttons.hover1
                  ),
                ]}
                disabled={btnEnable}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      ) : // END OF MISSING CERTIFICATE
      hideSuboptions ? (
        <div css={styles.reportBody}>
          {option === 0 ? null : (
            <div>
              <div className="header">
                <h2 className="title">Report an Issue</h2>
                <p>
                  You are reporting
                  <span className="boldFont">
                    {workerData.firstName} {workerData.lastName}
                  </span>
                </p>
              </div>

              <p className="smallFont">YOU SELECTED</p>

              {option === 0
                ? null
                : optionFunc().map((data, index) => (
                    <p key={index} className="firstOption">
                      {data.firstOption}
                    </p>
                  ))}
              <hr />
              <p className="selectOption">
                Please select from the following options:
              </p>
            </div>
          )}
          {option === 0
            ? null
            : optionFunc().map((data, index) => (
                <button
                  key={index}
                  type="button"
                  className="optionBtn"
                  onClick={() => handleClick(data.subOptionNo)}
                >
                  {data.message}
                </button>
              ))}
          <button
            type="button"
            className="backBtn"
            onClick={() => {
              setHideOptions(!hideOptions);
            }}
          >
            Back
          </button>
        </div>
      ) : (
        <Confirmation
          workerData={workerData}
          gigID={gigID}
          certCodes={certCodes}
          option={option}
          setOption={setOption}
          hideSuboptions={hideSuboptions}
          setHideOptions={setHideOptions}
          setReportModalOpen={setReportModalOpen}
        />
      )}
    </div>
  );
};

export default Suboptions;
