/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, useRef } from 'react';
import { leftBox, formInput, button, twoColumn } from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { industries } from 'constants/lists';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import composeRefs from '@seznam/compose-react-refs';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import useScript from 'hooks/useScript';
import ImageUploadModal from 'components/ImageUploadModal';
import { ReactComponent as ArrowTip } from 'assets/icons/arrowTip.svg';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { goolgeApi } from 'constants/config';

const BusinessDetails = ({
  setDetailsStep,
  detailsStep,
  industry,
  nextStep,
  step,
  setState,
  setBusinessLogo,
}) => {
  const {
    errors,
    clearErrors,
    handleSubmit,
    setValue,
    control,
    register,
  } = useForm();
  const history = useHistory();
  // const [errors, setErrors] = useState([]);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${goolgeApi()}&libraries=places`
  );
  const locationRef = useRef(null);
  useEffect(() => {
    // if (authUser) {
    //   return history.push('/dashboard');
    // }
    if (step !== 3) {
      history.push('/signup/agreement');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setScriptLoaded(loaded);
  }, [loaded]);
  const onAddressChange = (addressParts) => {
    let streetAddress;
    if (addressParts.route) {
      streetAddress = addressParts.route;
    }
    if (addressParts.street_number) {
      streetAddress = addressParts.street_number;
    }
    if (addressParts.street_number && addressParts.route) {
      streetAddress = `${addressParts.street_number} ${addressParts.route}`;
    }

    setValue('country', addressParts.country);
    setValue('address1', streetAddress || '');
    setValue('address2', addressParts.premise);
    setValue('city', addressParts.locality || '');
    setValue('postalCode', addressParts.postal_code);
    setValue('province', {
      label: addressParts.administrative_area_level_1 || '',
      value: addressParts.administrative_area_level_1 || '',
    });
  };
  useEffect(() => {
    let listener;
    if (scriptLoaded) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        locationRef.current,
        { types: ['geocode', 'establishment'] }
      );
      autoComplete.setComponentRestrictions({ country: ['ca'] });
      autoComplete.setFields(['address_components', 'geometry']);

      listener = window.google.maps.event.addListener(
        autoComplete,
        'place_changed',
        () => {
          // eslint-disable-next-line camelcase
          const { address_components, geometry } = autoComplete.getPlace();
          if (!geometry) return;
          const addressParts = {};

          address_components.map((part) => {
            return (addressParts[part.types[0]] = part.long_name);
          });
          onAddressChange(addressParts);
        }
      );
      return () => {
        listener.remove();
        autoComplete.unbindAll();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);
  const provinceList = [
    { label: 'Alberta', value: 'Alberta' },
    { label: 'British Columbia', value: 'British Columbia' },
    { label: 'Manitoba', value: 'Manitoba' },
    { label: 'New Brunswick', value: 'New Brunswick' },
    { label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
    { label: 'Nova Scotia', value: 'Nova Scotia' },
    { label: 'Ontario', value: 'Ontario' },
    { label: 'Prince Edward Island', value: 'Prince Edward Island' },
    { label: 'Quebec', value: 'Quebec' },
    { label: 'Saskatchewan', value: 'Saskatchewan' },
  ];

  const nextDetails = (data) => {
    setState((prev) => ({ ...prev, ...data }));
    setDetailsStep(2);
    nextStep(4, 'personal-details');
  };
  const onProvinceChange = (option) => {
    setState((prev) => ({ ...prev, province: option }));
  };
  const onIndustryChange = (option) => {
    setState((prev) => ({ ...prev, industry: option }));
  };

  return (
    <div>
      <h2>Account Details</h2>
      <div css={{ marginBottom: 20 }}>
        <div css={{ display: 'flex' }}>
          <div
            css={{
              backgroundColor: detailsStep > 1 ? '#FAFAFA' : '#002E6D',
              borderTop: '1px solid ',
              borderBottom: '1px solid ',
              borderLeft: '1px solid ',
              borderRadius: '4px 0 0 4px',
              borderColor: detailsStep > 1 ? '#808080' : '#002E6D',
              color: '#fff',
              padding: '8px 2vw 8px 3vw',
              height: 36,
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              zIndex: 1,
              fontSize: 12,
              [mq(bp.small)]: {
                height: 45,
                fontSize: 16,
                padding: '8px 20px',
              },
            }}
          >
            {detailsStep > 1 ? (
              <GreenCheck
                css={{
                  height: 18,
                  width: 18,
                  marginRight: 4,
                  [mq(bp.small)]: {
                    height: 24,
                    width: 24,
                    marginRight: 8,
                  },
                }}
              />
            ) : null}
            <p
              css={{
                margin: 0,
                color: detailsStep > 1 ? '#808080' : '#FFF',
                fontWeight: detailsStep > 1 ? 'normal' : 500,
                fontSize: '3.8vw',
                [mq(bp.small)]: {
                  fontSize: 16,
                },
              }}
            >
              Business Details
            </p>
            <ArrowTip
              css={{
                position: 'absolute',
                height: 'calc(100% + 2px)',
                top: -1,
                right: -22,
                '& path': {
                  stroke: detailsStep > 1 ? '#808080' : '#002E6D',
                  fill: detailsStep > 1 ? '#FAFAFA' : '#002E6D',
                },
                [mq(bp.small)]: {
                  right: -28,
                },
              }}
            />
          </div>
          <div
            css={{
              backgroundColor: detailsStep > 1 ? '#002E6D' : '#FAFAFA',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: detailsStep > 1 ? '#002E6D' : '#808080',
              height: 36,
              color: '#fff',
              padding: '2vw 2vw 2vw 9vw',
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              [mq(bp.small)]: {
                height: 45,
                padding: '8px 8px 8px 48px',
              },
            }}
          >
            <p
              css={{
                margin: 0,
                color: detailsStep > 1 ? '#FFFFFF' : '#808080',
                fontWeight: detailsStep > 1 ? 500 : 'normal',
                fontSize: '3.8vw',
                [mq(bp.small)]: {
                  fontSize: 16,
                },
              }}
            >
              Personal Details
            </p>
            <ArrowTip
              css={{
                position: 'absolute',
                height: 'calc(100% + 2px)',
                top: -1,
                right: -22,
                '& path': {
                  stroke: detailsStep > 1 ? '#002E6D' : '#808080',
                  fill: detailsStep > 1 ? '#002E6D' : '#FAFAFA',
                },
                [mq(bp.small)]: {
                  right: -28,
                },
              }}
            />
          </div>
        </div>
      </div>

      <div css={twoColumn}>
        <div css={leftBox}>
          <div>
            <form
              css={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr ',
                gridColumnGap: 20,
              }}
            >
              <ImageUploadModal imageBlob={(blob) => setBusinessLogo(blob)} />
              <div css={[formInput, { gridColumn: '1/3' }]}>
                <label htmlFor="businessName">Business Name</label>
                <input
                  type="text"
                  name="businessName"
                  // value={businessName}
                  // onChange={(e) => onChange(e)}
                  placeholder="Enter the name of your business"
                  id="businessName"
                  css={{
                    border: '1px solid transparent',
                    borderColor: errors.businessName && 'red !important',
                  }}
                  ref={register({ required: true })}
                />
              </div>
              <div css={[formInput, { gridColumn: '1/3' }]}>
                <label htmlFor="address1">Business Address 1</label>
                <input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Enter your business street address"
                  ref={composeRefs(locationRef, register({ required: true }))}
                  // onChange={(e) => onChange(e)}
                  css={{ borderColor: errors.address1 && 'red !important' }}
                />
                <FormFieldErrorMessage error={errors.address1} />
              </div>
              <div css={[formInput, { gridColumn: '1/3' }]}>
                <label htmlFor="address2">Business Address 2</label>
                <input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Enter your apartment, suite, unit number etc. (optional)"
                  ref={register}
                />
              </div>
              <div
                css={[
                  formInput,
                  {
                    gridColumn: '1/3',
                    [mq(bp.large)]: {
                      gridColumn: 'unset',
                    },
                  },
                ]}
              >
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  // value={city}
                  // onChange={(e) => onChange(e)}
                  placeholder="Enter your City"
                  id="city"
                  css={{ borderColor: errors.city && 'red !important' }}
                  ref={register({ required: true })}
                />
                <FormFieldErrorMessage error={errors.city} />
              </div>
              <div
                css={[
                  formInput,
                  {
                    gridColumn: '1/3',
                    [mq(bp.large)]: {
                      gridColumn: 'unset',
                    },
                  },
                ]}
              >
                <label htmlFor="province">Province</label>
                <Controller
                  as={Select}
                  control={control}
                  name="province"
                  id="province"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      background: 'rgba(58, 177, 202, 0.1)',
                      border: '1px solid transparent',
                      borderColor: errors.province && 'red !important',
                      boxShadow: 'none',
                      cursor: 'pointer',
                      fontSize: 14,
                      '&:hover': {
                        border: '1px solid #002E6D',
                      },
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      cursor: 'pointer',
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    valueContainer: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      padding: '0 8px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      zIndex: 20,
                    }),
                  }}
                  options={provinceList}
                  placeholder="Select your province"
                  // defaultValue=""
                  // onChange={(option) => onProvinceChange(option)}
                  isDisabled={false}
                  // value={province}
                  isSearchable={false}
                  rules={{ required: true }}
                />
                <FormFieldErrorMessage error={errors.province} />
              </div>
              <div
                css={[
                  formInput,
                  {
                    gridColumn: '1/3',
                    [mq(bp.large)]: {
                      gridColumn: 'unset',
                    },
                  },
                ]}
              >
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  name="country"
                  defaultValue="Canada"
                  // value={country}
                  // onChange={(e) => onChange(e)}
                  placeholder="Select your country"
                  id="country"
                  disabled
                />
              </div>
              <div
                css={[
                  formInput,
                  {
                    gridColumn: '1/3',
                    [mq(bp.large)]: {
                      gridColumn: 'unset',
                    },
                  },
                ]}
              >
                <label htmlFor="postalCode">Postal Code</label>
                <input
                  type="text"
                  name="postalCode"
                  // value={postalCode}
                  // onChange={(e) => onChange(e)}
                  placeholder="Enter your postal code"
                  id="postalCode"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[ABCEGHJ-NPRSTVXY][0-9][A-Z][ -]?[0-9][A-Z][0-9]$/,
                      message: 'Invalid Postal Code',
                    },
                  })}
                  css={{
                    border: '1px solid transparent',
                    borderColor: errors.postalCode && 'red !important',
                  }}
                />
                <FormFieldErrorMessage error={errors.postalCode} />
              </div>
              <div css={[formInput, { gridColumn: '1/3' }]}>
                <label htmlFor="businessTel">Contact Number</label>
                <div
                  css={{
                    marginBottom: 20,
                    '.form-control': {
                      width: '100%',
                      backgroundColor: 'rgba(58, 177, 202, 0.1)',
                      padding: '10px 0 10px 50px',
                      borderColor: errors.businessTel && 'red !important',
                      display: 'block',
                      borderRadius: '4px',
                      fontSize: 14,
                      outline: 'none',
                      height: 'auto',
                      border: '1px solid transparent',
                      '&:focus': {
                        border: '1px solid #002E6D',
                      },
                    },
                    '.flag-dropdown': {
                      border: 'none',
                      backgroundColor: 'unset',
                      '&:hover .selected-flag': {
                        background: 'rgba(58, 177, 202, 0.1)',
                      },
                      '&.open .selected-flag': {
                        background: 'rgba(58, 177, 202, 0.1)',
                      },
                    },
                    '.selected-flag': {
                      background: 'none',
                      border: 'none',
                      padding: '0 0 0 11px',
                      width: '46px',
                      '&:focus': {
                        background: 'rgba(58, 177, 202, 0.1)',
                      },
                      '&:hover': {
                        background: 'rgba(58, 177, 202, 0.1)',
                      },
                    },
                  }}
                >
                  <Controller
                    as={PhoneInput}
                    name="businessTel"
                    placeholder="Enter phone number"
                    defaultValue=""
                    inputmode="numeric"
                    onChange={(phone) => {
                      clearErrors('businessTel');
                      return phone;
                    }}
                    country="ca"
                    control={control}
                    inputClass="phoneInput"
                    rules={{
                      required: true,
                      pattern: {
                        value: /1(1[.-])?(\(\d{3}\)[.-]|(\d{3}[.-]?)){2}\d{4}/g,
                        message: 'Invalid Phone Number',
                      },
                    }}
                  />
                  <FormFieldErrorMessage error={errors.businessTel} />
                </div>
              </div>
              <div css={[formInput, { gridColumn: '1/3' }]}>
                <label htmlFor="industry">Industry</label>
                <Select
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      '&:focus': {
                        border: '1px solid transparent',
                        outline: 'none',
                      },
                    }),
                    control: (provided) => ({
                      ...provided,
                      background: 'rgba(58, 177, 202, 0.1)',
                      border: '1px solid transparent',
                      boxShadow: 'none',
                      fontSize: 14,
                      cursor: 'pointer',
                      '&:hover': {
                        border: '1px solid #002E6D',
                      },
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      cursor: 'pointer',
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    valueContainer: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      padding: '0 8px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: 14,
                    }),
                    menu: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      zIndex: 20,
                    }),
                  }}
                  options={industries}
                  placeholder="Industry"
                  onChange={(option) => onIndustryChange(option)}
                  isDisabled={false}
                  value={industry}
                  isSearchable={false}
                  menuPlacement="auto"
                />
              </div>
              <div
                css={{
                  gridColumn: '1/3',
                  textAlign: 'right',
                }}
              >
                <button
                  // disabled={isDisabled}
                  type="button"
                  onClick={handleSubmit(nextDetails)}
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
        <div>
          <div>
            <h3 css={{ marginTop: 0 }}>Upload your company logo</h3>
            <p>
              Uploading your official company logo helps to signal legitimacy
              and attract talent. Don’t worry, you’ll be able to update this
              should your logo change.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
