import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
// import 'firebase/analytics';
import moment from 'moment';
import { loadConfig } from 'constants/config';
import Geohash from 'ngeohash';
import GeohashDistance from 'geohash-distance';
import proximityhash from 'proximityhash';

class Firebase {
  constructor() {
    const config = loadConfig();
    let env;
    let deeplink;
    app.initializeApp(config);
    // app.analytics();

    if (window.location.hostname === 'localhost') {
      app.functions().useFunctionsEmulator('http://localhost:5001');
    }
    if (
      (process.env.NODE_ENV === 'development' &&
        window.location.hostname === 'localhost') ||
      (process.env.NODE_ENV === 'production' &&
        window.location.hostname === 'biz-wb-staging.web.app')
    ) {
      env = 'dev';
      deeplink = 'https://worker-wb-staging.web.app';
    }
    if (
      process.env.NODE_ENV === 'production' &&
      window.location.hostname !== 'biz-wb-staging.web.app' &&
      window.location.hostname === 'business.workbriefly.com'
    ) {
      env = 'prod';
      deeplink = 'https://worker.workbriefly.com';
    }
    this.timestamp = app.firestore.Timestamp;
    this.fieldValue = app.firestore.FieldValue;

    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.storage = app.storage();

    this.env = env;
    this.deeplink = deeplink;
  }

  sendEmail = (emailData) => {
    const sendEmail = this.functions.httpsCallable('sendEmail');
    return sendEmail({ ...emailData, env: this.env });
  };

  sendPushNotification = async (data) => {
    const sendUserPushNotif = this.functions.httpsCallable('sendUserPushNotif');
    sendUserPushNotif(data);
  };

  // *** Auth API ***
  sendWelcomeNotif = (uid, businessName) => {
    this.sendEmail({ businessName, type: 'businessSignup' });
    const userRef = this.user(uid);
    const notifID = userRef.collection('notifications').doc().id;
    return userRef.collection('notifications').doc(notifID).set({
      uid: notifID,
      message: 'Welcome! View the Start Guide to help you get started',
      read: false,
      new: true,
      time: new Date(),
      url: '/help',
    });
  };

  doCreateUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  };

  doLoginWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doLogout = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // doSendEmailVerification = () =>
  //   this.auth.currentUser.sendEmailVerification({
  //     url: "http://localhost:3000/"
  //   });

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid).onSnapshot((snapshot) => {
          return new Promise((resolve, reject) => {
            resolve(snapshot.data());
          }).then((dbData) => {
            if (dbData) {
              const combinedData = {
                uid: authUser.uid,
                email: authUser.email,
                // emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbData,
              };
              next(combinedData);
            }
            if (!dbData) {
              next(null);
            }
          });
        });
      }
      if (!authUser) {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => this.db.doc(`businesses/${uid}`);

  users = () => this.db.collection(`businesses`);

  // *** Message API ***
  message = (uid) => this.db.doc(`messages/${uid}`);

  messages = () => this.db.collection('messages');

  sendNotif = (workerID, url, message) => {
    const workerRef = this.db.collection('workers').doc(workerID);
    const notifRef = workerRef.collection('notifications');
    const notifID = notifRef.doc().id;
    workerRef.update({ notificationsRead: false }).then(() => {
      return notifRef.doc(notifID).set({
        uid: notifID,
        message,
        url,
        read: false,
        new: true,
        time: new Date(),
      });
    });
  };

  // *** Twilio ***
  twilioCreateVerification = (method, phoneNumber) => {
    const twilioCreate = this.functions.httpsCallable('twilioCreate');

    return twilioCreate({ method, phoneNumber }).then((result) => {
      if (result.data.success === true) {
        return;
      }
      if (result.data.success === false) {
        const error = {
          code: result.data.code,
          message: 'Please check your number and try again.',
        };
        throw error;
      }
    });
  };

  twilioCheckVerification = (phoneNumber, code) => {
    const twilioVerify = this.functions.httpsCallable('twilioVerify');
    return twilioVerify({ phoneNumber, code }).then((result) => {
      let data = {};
      if (result.data.status === 'pending') {
        data = {
          message: 'Invalid phone verification code',
          phoneVerified: false,
        };
      }
      if (result.data.status === 'approved') {
        data = {
          message: 'Phone Number Verified',
          phoneVerified: true,
        };
      }
      return data;
    });
  };

  // stripe = (uid, code, state) => {
  //   const stripe = this.functions.httpsCallable("stripe");
  //   return stripe({ uid, code, state }).then(response => {
  //     return response.data;
  //   });
  // };

  // deleteAcc = acc => {
  //   const deleteAcc = this.functions.httpsCallable("deleteAcc");
  //   return deleteAcc(acc);
  // };

  getBusinessNumber = () => {
    const metaRef = this.db.collection('settings').doc('meta');
    return this.db
      .runTransaction((transaction) => {
        return transaction.get(metaRef).then((metaDoc) => {
          const businessCount = metaDoc.data().businessCount + 1;
          transaction.update(metaRef, { businessCount });
          return businessCount;
        });
      })
      .then((count) => {
        if (count < 10) {
          return `00${count}`;
        }
        if (count < 100) {
          return `0${count}`;
        }
        return count;
      });
  };

  createStripeCustomer = (data) => {
    const createStripeCustomer = this.functions.httpsCallable(
      'createStripeCustomer'
    );
    return createStripeCustomer(data).then((customerId) => customerId.data);
  };

  getClientSecret = (customerID) => {
    const getClientSecret = this.functions.httpsCallable('getClientSecret');
    return getClientSecret(customerID).then((data) => data.data.client_secret);
  };

  saveCardComplete = (paymentMethod, customerID) => {
    const { uid } = this.auth.currentUser;
    const userRef = this.user(uid);

    const saveCard = this.functions.httpsCallable('saveCards');
    return saveCard({ paymentMethod, customerID }).then(() => {
      return userRef.update({
        'setup.paymentCard': true,
      });
    });
  };

  removePaymentMethod = (card) => {
    const removeCard = this.functions.httpsCallable('removeCard');
    return removeCard(card).catch((err) => console.log(err));
  };

  saveBusinessLogo = (croppedImage) => {
    const { uid } = this.auth.currentUser;
    const storageRef = this.storage.ref();
    const userRef = this.user(uid);

    const croppedImageRef = storageRef.child(
      `businesses/${uid}/profileImage/profile-image.jpeg`
    );
    if (!croppedImage) {
      return new Promise((resolve, reject) => {
        resolve(userRef.update({ profileImage: '' }));
      });
    }
    if (croppedImage) {
      return new Promise((resolve, reject) => {
        croppedImageRef.put(croppedImage).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            userRef
              .update({
                profileImage: url,
              })
              .then(() => {
                resolve();
              });
          });
        });
      });
    }
  };

  saveProfileImage = (originalImage, croppedImage) => {
    const originalType = originalImage.type.replace('image/', '');
    const { uid } = this.auth.currentUser;
    const storageRef = this.storage.ref();
    const userRef = this.user(uid);

    const croppedImageRef = storageRef.child(
      `businesses/${uid}/profileImage/profile-image.jpeg`
    );
    const originalImageRef = storageRef.child(
      `businesses/${uid}/profileImage/original-image.${originalType}`
    );

    const uploadCropped = new Promise((resolve, reject) => {
      croppedImageRef.put(croppedImage).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          userRef
            .update({
              'setup.profileImage': true,
              profileImage: url,
            })
            .then(() => {
              resolve();
            });
        });
      });
    });
    const uploadOriginal = new Promise((resolve, reject) => {
      originalImageRef.put(originalImage).then(() => {
        resolve();
      });
    });
    return Promise.all([uploadOriginal, uploadCropped]).then(() => {});
  };

  completeSetup = () => {
    const { uid } = this.auth.currentUser;
    const userRef = this.user(uid);
    return userRef.update({
      setupComplete: true,
    });
  };

  getSavedCards = (id) => {
    const getSavedCards = this.functions.httpsCallable('getSavedCards');
    return getSavedCards(id).then((cardsData) => cardsData.data);
  };

  saveCard = (paymentMethod, customerID, setDefault) => {
    const saveCard = this.functions.httpsCallable('saveCards');
    return saveCard({ paymentMethod, customerID });
    // .then((result) => {
    //   if (setDefault) {
    //     const uid = this.auth.currentUser.uid;
    //     const userRef = this.user(uid);
    //     return userRef.update({
    //       defaultPaymentCard: result.data.id,
    //     });
    //   }
    // });
  };

  createGig = async (
    paymentMethodID,
    gigData,
    businessName,
    invoicingOption,
    checkoutMethod
  ) => {
    const { uid } = this.auth.currentUser;
    const userRef = this.db.collection('businesses').doc(uid);
    const userData = await userRef.get().then((snapshot) => snapshot.data());

    const reqCertCodes = gigData.certs.reduce((acc, curr) => {
      if (curr.required) {
        acc[curr.code] = true;
      }
      return acc;
    }, {});
    const certReqNum = gigData.certs.filter((cert) => cert.required === true)
      .length;

    const gigGeohash = Geohash.encode(
      gigData.location.lat,
      gigData.location.lng,
      5
    );

    const getGigNumber = (num) => {
      if (num < 10) {
        return `00${num}`;
      }
      if (num < 100) {
        return `0${num}`;
      }
      return num;
    };

    if (gigData.dates.length === 1) {
      const gigID = this.db.collection('gigs').doc().id;

      const totalForTheDay = () => {
        if (!gigData.dates[0].paidBreak) {
          const breakHours = moment
            .duration(gigData.dates[0].breakTime * 60000)
            .asHours();
          return (
            Math.trunc(
              (gigData.dates[0].hours - breakHours) * gigData.payPerHour * 100
            ) / 100
          );
        }
        return (
          Math.trunc(gigData.dates[0].hours * gigData.payPerHour * 100) / 100
        );
      };

      return (
        this.db
          .collection('gigs')
          .doc(gigID)
          .set({
            gigSummaryGroup: [],
            lastGigOfTheWeek: false,
            lastGigInMDR: false,
            requestedWorkers: [],
            public: true,
            creationDate: new Date(),
            active: true,
            gigID,
            sharedID: null,
            owner: uid,
            stripeCustomerId: userData.stripeCustomerId,
            status: 1,
            reqCertCodes,
            certReqNum,
            gigGeohash,
            paymentMethod: paymentMethodID,
            businessName: userData.businessName,
            businessEmail: userData.email,
            businessPhone: userData.phoneNumber,
            reminderSent: false,
            oneDayReminderSent: false,
            oneHourReminderSent: false,
            past5minReminderSent: false,
            ...gigData,
            day: gigData.dates[0].day,
            start: gigData.dates[0].start,
            end: gigData.dates[0].end,
            nextDay: gigData.dates[0].nextDay,
            hours: gigData.dates[0].hours,
            breakTime: gigData.dates[0].breakTime,
            paidBreak: gigData.dates[0].paidBreak,
            checkoutReminderSent: false,
            allWorkersCheckedOut: false,
            authAttempted: false,
            totalForThisDay: totalForTheDay(),
            formattedDate: moment(gigData.dates[0].day).format('MMMM D, YYYY'),
            siblings: [],
            bookedWorkers: [],
            checkin: [],
            profileImage: userData.profileImage,
            paymentAuthorized: false,
            startTimeStamp: new Date(
              `${gigData.dates[0].day} ${gigData.dates[0].start}`
            ),
            endTimeStamp: new Date(
              `${gigData.dates[0].day} ${gigData.dates[0].end}`
            ),
            hasPayouts: false,
            adminArchived: false,
            invoicingOption,
            checkoutMethod,
            workersThatSaved: [],
            appliedWorkers: [],
            gigReferenceNumber: `${moment(gigData.dates[0].day).format(
              'YYMMDD'
            )}-${userData.businessNumber}-${getGigNumber(
              (userData.gigCount || 0) + 1
            )}`,
          })
          .then(() => {
            return userRef.update({
              activeGigs: this.fieldValue.arrayUnion({
                gigID,
                workers: 0,
                startTimeStamp: new Date(
                  `${gigData.dates[0].day} ${gigData.dates[0].start}`
                ),
                endTimeStamp: new Date(
                  `${gigData.dates[0].day} ${gigData.dates[0].end}`
                ),
              }),
              gigCount: this.fieldValue.increment(1),
            });
          })
          // .then(() => {
          //   if (!invoicingOption) {
          //     const { day, end } = gigData.dates[0];
          //     const date = moment(`${end} ${day}`, 'h:mm A MMM D, YYYY').subtract(
          //       5,
          //       'days'
          //     );
          //     const now = moment();
          //     if (now.isAfter(date)) {
          //       const authPayment = this.functions.httpsCallable('authPayment');
          //       return authPayment(gigID).then(() => {
          //         return true;
          //       });
          //     }
          //   }
          //   console.log('invoicing');
          // })
          .then(() => {
            this.sendEmail({
              businessName: userData.businessName,
              gigID,
              type: 'businessCreateGig',
            });
            return { success: true, gigIDs: [gigID] };
          })
          .catch((error) => {
            return {
              success: false,
              error,
            };
          })
      );
    }
    if (gigData.dates.length > 1) {
      const batch = this.db.batch();
      const gigsRef = this.db.collection('gigs');
      const sharedID = gigsRef.doc().id;
      const siblings = [];
      const gigIDs = [];
      let tempGigSummaryGroup = [];
      let gigNum = userData.gigCount || 0;

      const sortedDates = gigData.dates.sort((a, b) => {
        const first = a.nextDay ? moment(a.day).add(1, 'day') : moment(a.day);
        const second = b.nextDay ? moment(b.day).add(1, 'day') : moment(b.day);
        return first.diff(second);
      });

      const totalForTheDay = (date) => {
        if (!date.paidBreak) {
          const breakHours = moment.duration(date.breakTime * 60000).asHours();
          return (
            Math.trunc((date.hours - breakHours) * gigData.payPerHour * 100) /
            100
          );
        }
        return Math.trunc(date.hours * gigData.payPerHour * 100) / 100;
      };

      sortedDates.map((date) => {
        const gigID = this.db.collection('gigs').doc().id;
        gigIDs.push(gigID);

        return siblings.push({
          gigID,
          day: date.day,
          start: date.start,
          end: date.end,
          nextDay: date.nextDay,
          pay: totalForTheDay(date),
          status: 1,
        });
      });

      sortedDates.map((date, i) => {
        gigNum += 1;
        let lastGigOfTheWeek = false;
        let lastGigInMDR = false;
        let gigSummaryGroup = [];

        if (i + 1 === sortedDates.length) {
          gigSummaryGroup = tempGigSummaryGroup;
          lastGigOfTheWeek = true;
          tempGigSummaryGroup = [];
          lastGigInMDR = true;
        }
        if (gigData.sameWorker && i + 1 < sortedDates.length) {
          const gigDate = date.nextDay
            ? moment(date.day).add(1, 'day')
            : moment(date.day);
          const nextGigDate = sortedDates[i + 1].nextDay
            ? moment(sortedDates[i + 1].day).add(1, 'day')
            : moment(sortedDates[i + 1].day);
          if (
            gigDate.day() !== 5 &&
            nextGigDate.diff(gigDate, 'days') <= 6 &&
            nextGigDate.day() <= 5 &&
            nextGigDate.diff(gigDate, 'days') <=
              (5 - gigDate.day() > 0
                ? 5 - gigDate.day()
                : 5 - gigDate.day() + 7)
          ) {
            lastGigOfTheWeek = false;
            tempGigSummaryGroup.push(siblings[i].gigID);
          }
          if (
            gigDate.day() === 5 || // if friday = pay
            nextGigDate.diff(gigDate, 'days') > 6 || // if gap between 2 dates > 6 = pay
            nextGigDate.day() > 5 || // if next gig day is past friday = pay
            nextGigDate.diff(gigDate, 'days') >
              (5 - gigDate.day() > 0
                ? 5 - gigDate.day()
                : 5 - gigDate.day() + 7)
          ) {
            gigSummaryGroup = tempGigSummaryGroup;
            lastGigOfTheWeek = true;
            tempGigSummaryGroup = [];
          }
        }
        const gig = {
          gigSummaryGroup,
          lastGigOfTheWeek,
          lastGigInMDR,
          requestedWorkers: [],
          public: true,
          creationDate: new Date(),
          active: true,
          gigID: siblings[i].gigID,
          sharedID,
          owner: uid,
          stripeCustomerId: userData.stripeCustomerId,
          status: 1,
          reqCertCodes,
          certReqNum,
          gigGeohash,
          paymentMethod: paymentMethodID,
          businessName: userData.businessName,
          businessEmail: userData.email,
          businessPhone: userData.phoneNumber,
          reminderSent: false,
          oneDayReminderSent: false,
          oneHourReminderSent: false,
          past5minReminderSent: false,
          ...gigData,
          day: date.day,
          start: date.start,
          end: date.end,
          nextDay: date.nextDay,
          hours: date.hours,
          breakTime: date.breakTime,
          paidBreak: date.paidBreak,
          checkoutReminderSent: false,
          allWorkersCheckedOut: false,
          authAttempted: false,
          totalForThisDay: totalForTheDay(date),
          formattedDate: moment(date.day).format('MMMM D, YYYY'),
          siblings: siblings.filter(
            (sibling) => sibling.gigID !== siblings[i].gigID
          ),
          bookedWorkers: [],
          checkin: [],
          profileImage: userData.profileImage,
          paymentAuthorized: false,
          startTimeStamp: new Date(`${date.day} ${date.start}`),
          endTimeStamp: new Date(`${date.day} ${date.end}`),
          hasPayouts: false,
          adminArchived: false,
          invoicingOption,
          checkoutMethod,
          workersThatSaved: [],
          appliedWorkers: [],
          gigReferenceNumber: `${moment(date.day).format('YYMMDD')}-${
            userData.businessNumber
          }-${getGigNumber(gigNum)}`,
        };

        batch.update(userRef, {
          activeGigs: this.fieldValue.arrayUnion({
            gigID: siblings[i].gigID,
            workers: 0,
            startTimeStamp: new Date(`${siblings[i].day} ${siblings[i].start}`),
            endTimeStamp: new Date(`${siblings[i].day} ${siblings[i].end}`),
          }),
          gigCount: this.fieldValue.increment(1),
        });
        return batch.set(gigsRef.doc(siblings[i].gigID), gig);
        // eslint-disable-next-line array-callback-return
      });
      return (
        batch
          .commit()
          // .then(() => {
          //   if (!invoicingOption) {
          //     sortedDates.map((date, i) => {
          //       const { day, end } = date;
          //       const gigDate = moment(
          //         `${end} ${day}`,
          //         'h:mm A MMM D, YYYY'
          //       ).subtract(5, 'days');
          //       const now = moment();
          //       if (now.isAfter(gigDate)) {
          //         const authPayment = this.functions.httpsCallable('authPayment');
          //         return authPayment(siblings[i].gigID).then(() => {
          //           return true;
          //         });
          //       }
          //     });
          //   }
          //   console.log('invoicing');
          // })
          .then(() => {
            this.sendEmail({
              businessName: userData.businessName,
              gigID: gigIDs[0],
              type: 'businessCreateGig',
            });
            return { success: true, gigIDs };
          })
          .catch((error) => {
            return { success: false, error };
          })
      );
    }
  };

  businessSnapshot = (uid) => this.db.collection('businesses').doc(uid);

  gigSnapshot = (gigID) => this.db.collection('gigs').doc(gigID);

  workerSnapshot = (workerID) => this.db.collection('workers').doc(workerID);

  getGigs = (gigs, type) => {
    return new Promise((resolve) => {
      // const uid = this.auth.currentUser.uid;
      const userRef = this.db.collection('gigs');
      const gigsArray = [];
      return Promise.all(
        gigs.map((gig) => {
          return userRef
            .doc(gig.gigID)
            .get()
            .then((snapshot) => {
              const data = snapshot.data();
              if (type === 'active') {
                if (data.active) {
                  return Promise.resolve(gigsArray.push(snapshot.data()));
                }
                if (!data.active) {
                  return Promise.resolve();
                }
              }
              if (type === 'completed') {
                return Promise.resolve(gigsArray.push(snapshot.data()));
              }
            });
        })
      ).then(() => {
        resolve(gigsArray);
      });
    });
  };

  // getRecommendedWorkers = (certs, locality) => {
  //   console.log(certs, locality);
  //   const req = certs.some((cert) => cert.certReq);
  //   const certReq = certs.filter((cert) => cert.certReq);
  //   const certCodes = certReq.reduce((acc, curr) => {
  //     acc.push(curr.code);
  //     return acc;
  //   }, []);
  //   let query = this.db.collection("workers");
  //   const workers = [];
  //   if (req) {
  //     query = query
  //       .where("setupComplete", "==", true)
  //       .where("locality", "==", locality)
  //       .where("certCodeListArray", "array-contains-any", certCodes);
  //     return query.get().then((snapshot) => {
  //       snapshot.docs.map((doc) => {
  //         const worker = doc.data();
  //         if (
  //           certCodes.every((code) => worker.certCodeListArray.includes(code))
  //         ) {
  //           return workers.push(worker);
  //         }
  //         return worker;
  //       });
  //       if (workers.length > 5) {
  //         const randomWorkers = [];
  //         for (let i = 1; i <= 5; i++) {
  //           const chosenWorker =
  //             workers[Math.floor(Math.random() * workers.length)];
  //           randomWorkers.push(chosenWorker);
  //         }
  //         return randomWorkers;
  //       }
  //       if (workers.length <= 5) {
  //         return workers;
  //       }
  //     });
  //   }
  //   if (!req) {
  //     query = query
  //       .where("locality", "==", locality)
  //       .where("setupComplete", "==", true);
  //     return query.get().then((snapshot) => {
  //       snapshot.docs.map((doc) => {
  //         const worker = doc.data();
  //         return workers.push(worker);
  //       });
  //       return workers;
  //     });
  //   }
  // };

  // requestWorker = (workerUID, gigIDs) => {
  //   const userRef = this.db.collection("workers").doc(workerUID);
  //   return Promise.all(
  //     gigIDs.map((gigID) => {
  //       return userRef.update({
  //         requestedGigs: this.fieldValue.arrayUnion(gigID),
  //       });
  //     })
  //   );
  //   // return userRef.update({
  //   //   requestedGigs: this.fieldValue.arrayUnion(gigIDs),
  //   // });
  // };

  confirmCheckin = (workerID, gigID) => {
    const gigRef = this.db.collection('gigs').doc(gigID);
    return this.db
      .runTransaction((transaction) => {
        return transaction.get(gigRef).then((gigDoc) => {
          const bookedWorkers = [...gigDoc.data().bookedWorkers];
          const index = bookedWorkers.findIndex(
            (workers) => workers.uid === workerID
          );
          bookedWorkers[index].businessCheckin = true;
          bookedWorkers[index].businessCheckinTimestamp = this.timestamp.now();
          return transaction.update(gigRef, { bookedWorkers });
        });
      })
      .then(() => {
        this.sendPushNotification({
          workerID,
          link: `${this.deeplink}/gigs?gigType=Confirmed&gigToShow=${gigID}`,
          linkTo: 'Gigs',
          type: 'bizConfirmedCheckin',
        });
      });
  };

  reportIssue = async (workerID, gigID, selectedIssue, certArray) => {
    const gigRef = this.db.collection('gigs').doc(gigID);
    const workerRef = this.db.collection('workers').doc(workerID);

    return Promise.all([
      gigRef.get().then((gigDoc) => {
        const gigData = gigDoc.data();
        const bookedWorkers = [...gigData.bookedWorkers];
        const workerToRemove = bookedWorkers.find(
          (workers) => workers.uid === workerID
        );
        const isAfterNow = moment
          .unix(gigData.startTimeStamp.seconds)
          .isAfter(moment());

        if (selectedIssue < 3) {
          gigRef.update({
            bookedWorkers: this.fieldValue.arrayRemove(workerToRemove),
            status: gigData.status === 3 && isAfterNow ? 1 : 3,
          });
        }
        if (gigData.siblings.length > 0 && gigData.status === 3 && isAfterNow) {
          gigData.siblings.map((sibs) => {
            const sibRef = this.db.collection('gigs').doc(sibs.gigID);
            return sibRef.get().then((doc) => {
              const sibData = doc.data();
              const siblings = [...sibData.siblings];
              const index = siblings.findIndex(
                (sibling) => sibling.gigID === gigData.gigID
              );
              siblings[index].status = 1;
              return sibRef.set({ siblings }, { merge: true });
            });
          });
        }
      }),
      workerRef.get().then((workerDoc) => {
        const gigToRemove = [...workerDoc.data().claimedGigs].find(
          (gig) => gig.gigID === gigID
        );
        const workerCerts = [...workerDoc.data().certList].map((cert) => cert);
        const certToRemove = workerCerts.filter((cert) =>
          certArray.map((cert) => cert[1]).includes(cert.label)
        );

        const equivalentCert = workerCerts.filter(
          (cert) => cert.equivalent.length > 0
        );
        const certNumbers = certArray.map((cert) => Number(cert[0]));

        const equivalentCertToRemove = equivalentCert.filter((cert) =>
          certNumbers.includes(cert.equivalent[0])
        );

        if (certArray.length > 0)
          certToRemove.map((obj) =>
            workerRef.update({
              certList: this.fieldValue.arrayRemove(obj),
            })
          );

        if (equivalentCertToRemove.length > 0)
          equivalentCertToRemove.map((obj) =>
            workerRef.update({
              certList: this.fieldValue.arrayRemove(obj),
            })
          );

        if (selectedIssue < 3)
          workerRef.update({
            claimedGigs: this.fieldValue.arrayRemove(gigToRemove),
          });
      }),
    ])
      .then(async () => {
        const gigRef = this.db.collection('gigs').doc(gigID);
        const workerRef = this.db.collection('workers').doc(workerID);
        const workerData = await workerRef.get().then((doc) => {
          return doc.data();
        });
        const gigData = await gigRef.get().then((doc) => {
          return doc.data();
        });
        const gigName = gigData.position;
        const gigDate = gigData.day;
        const gigTime = `From: ${gigData.start} - To: ${gigData.end}`;
        const bizContactNumber = gigData.contactNumber;
        const bizName = gigData.businessName;
        const workerContactNumber = workerData.phoneNumber;
        const workerName = `${workerData.firstName} ${workerData.lastName}`;
        const certLabel = certArray.map((certLabel) => `\n${certLabel[1]}`);

        const message = {};

        if (selectedIssue === 1 && certArray.length > 0) {
          message.biz = `Hi ${bizName},\n\n${workerName} has been cancelled from your gig listed below.\n\nPosition: ${gigName}\nDate/Time: ${gigDate}\n${gigTime}\n\nWe are currently searching for a replacement worker. Your gig has been made available for workers to claim and a notification has been sent to workers informing them that this is an urgent gig requiring them to be present ASAP.\n\nBest regards,\nThe WorkBriefly Team`;
          message.worker = `Hi ${workerName},\n\nUnfortunately you have been cancelled for the gig listed below.\n\nPosition: ${gigName}\n\nDate/Time: ${gigDate} - ${gigTime}\n\nThe certificate/license listed below has been removed from your profile as it has come to our attention that you do not possess it.\n\nCertificate/License:\n${certLabel}\n\nPlease do not to list any certificates/licenses that you do not actually possess on your profile.\n\nIf you would like to have the above certificate/license reinstate on your profile, please email support with an attached copy of the certificate/license in question.\n\nBest regards,\nThe WorkBriefly Team`;
        } else if (selectedIssue === 1 && certArray.length === 0) {
          message.biz = `Hi ${bizName},\n\n${workerName} has been cancelled from your gig listed below.\n\nPosition: ${gigName}\nDate/Time: ${gigDate}\n${gigTime}\n\nWe are currently searching for a replacement worker. Your gig has been made available for workers to claim and a notification has been sent to workers informing them that this is an urgent gig requiring them to be present ASAP.\n\nBest regards,\nThe WorkBriefly Team`;
          message.worker = `Hi ${workerName},\n\nUnfortunately you have been cancelled for the gig listed below.\n\nPosition: ${gigName}\n\nDate/Time: ${gigDate} - ${gigTime}\n\nBest regards,\nThe WorkBriefly Team`;
        } else if (selectedIssue === 2 && certArray.length > 0) {
          message.biz = `Hi ${bizName},\n\n${workerName} has been cancelled from your gig listed below.\n\nPosition: ${gigName}\nDate/Time: ${gigDate}\n${gigTime}\n\nBest regards,\n\nThe WorkBriefly Team`;
          message.worker = `Hi ${workerName},\n\nUnfortunately you have been cancelled for the gig listed below.\n\nPosition: ${gigName}\n\nDate/Time: ${gigDate} - ${gigTime}\n\nThe certificate/license listed below has been removed from your profile as it has come to our attention that you do not possess it.\n\nCertificate/License:\n${certLabel}\n\nPlease do not to list any certificates/licenses that you do not actually possess on your profile.\n\nIf you would like to have the above certificate/license reinstate on your profile, please email support with an attached copy of the certificate/license in question.\n\nBest regards,\nThe WorkBriefly Team`;
        } else if (selectedIssue === 2 && certArray.length === 0) {
          message.biz = `Hi ${bizName},\n\n${workerName} has been cancelled from your gig listed below.\n\nPosition: ${gigName}\nDate/Time: ${gigDate}\n${gigTime}\n\nBest regards,\n\nThe WorkBriefly Team`;
          message.worker = `Hi ${workerName},\n\nUnfortunately you have been cancelled for the gig listed below.\n\nPosition: ${gigName}\n\nDate/Time: ${gigDate} - ${gigTime}\n\nBest regards,\nThe WorkBriefly Team`;
        } else if (selectedIssue === 3) {
          message.biz = `Thank you for accepting ${workerName} and assigning him/her another task that doesn't require said certificate/licence.\n\nSorry for the inconvenience and thank you for your understanding.\n\nYou may continue with the check-in process if you haven't done so already.`;
          message.worker = `Hi ${workerName},\n\nThe certificate/license listed below has been removed from your profile as it has come to our attention that you do not possess it.\n\nCertificate/License:\n${certLabel}\n\nPlease do not to list any certificates/licenses that you do not actually possess on your profile.\n\nIf you would like to have the above certificate/license reinstate on your profile, please email support with an attached copy of the certificate/license in question.\n\nBest regards,\nThe WorkBriefly Team`;
        } else if (selectedIssue === 4) {
          message.biz = `Please allow ${workerName} to pick up said equipment/attire and return ASAP.\n\nSorry for the inconvenience and thank you for your understanding.`;
          message.worker = `Hi ${workerName},\n\nAs agreed upon with the business, you may pick up the equipment/attire you are missing and report back ASAP.\n\nPlease ensure that you report to future gigs with the mentioned equipment/attire specified in the gig description.\n\nBest regards,\nThe WorkBriefly Team`;
        } else if (selectedIssue === 5) {
          message.biz = `Thank you for accepting ${workerName} and assigning him/her another task that doesn't require said equipment/attire.\n\nSorry for the inconvenience and thank you for your understanding.\n\nYou may continue with the check-in process if you haven't done so already.`;
          message.worker = `Hi ${workerName},\n\nPlease ensure that you report to future gigs with the mentioned equipment/attire specified in the gig description.\n\nBest regards,\nThe WorkBriefly Team`;
        } else return false;

        const sendIssueSMS = this.functions.httpsCallable('sendReportIssueSMS');

        return sendIssueSMS({
          workerID,
          gigID,
          message,
          gigName,
          gigDate,
          gigTime,
          bizContactNumber,
          bizName,
          workerContactNumber,
          workerName,
        });
      })
      .then(() => {
        return { success: true, redirect: true };
      })
      .catch((err) => {
        return { success: false, error: err };
      });
  };

  confirmCheckout = async (
    workerID,
    gigID,
    pay,
    paymentMethod,
    customer,
    surveyResults,
    hours,
    totalToCharge
  ) => {
    const gigRef = this.db.collection('gigs').doc(gigID);
    const workerRef = this.db.collection('workers').doc(workerID);

    // const updatedBookedWorkers = [];
    // const stripeID = '';
    // let payPerWorker;
    // const allWorkersCheckedOut = false;
    // let pi;
    // let found;

    // return Promise.all([
    // gigRef.get().then((gigDoc) => {
    //   const bookedWorkers = [...gigDoc.data().bookedWorkers];
    //   const index = bookedWorkers.findIndex(
    //     (workers) => workers.uid === workerID
    //   );
    //   bookedWorkers[index].status = 4;
    //   gigRef.set(
    //     {
    //       bookedWorkers,
    //     },
    //     { merge: true }
    //   );
    //   return bookedWorkers;
    // }),
    // workerRef.get().then((workerDoc) => {
    //   const claimedGigs = [...workerDoc.data().claimedGigs];
    //   const found = claimedGigs.find((gig) => gig.gigID === gigID);

    //   workerRef.update({
    //     claimedGigs: this.fieldValue.arrayRemove(found),
    //   });

    //   found.status = 4;

    //   workerRef.update({
    //     completedGigs: this.fieldValue.arrayUnion(found),
    //   });
    // }),
    // ])
    // .then(async (data) => {
    // const gigDoc = await gigRef.get();
    // const { payPerHour, hours, pi, bookedWorkers } = gigDoc.data();

    const payForGig = this.functions.httpsCallable('payForGig');
    return payForGig({
      pay: totalToCharge || pay,
      paymentMethod,
      workerID,
      customer,
      gigID,
    }).then((result) => {
      if (result.data.success === false) {
        return { success: false, message: result.data.message };
      }
      return Promise.all([
        gigRef.get().then((gigDoc) => {
          const bookedWorkers = [...gigDoc.data().bookedWorkers];
          const index = bookedWorkers.findIndex(
            (workers) => workers.uid === workerID
          );
          bookedWorkers[index].businessCheckout = true;
          bookedWorkers[index].businessCheckoutTimestamp = this.timestamp.now();
          bookedWorkers[index].amountToBePaid = pay;
          bookedWorkers[index].hoursLogged = parseFloat(hours);
          gigRef.set(
            {
              bookedWorkers,
            },
            { merge: true }
          );
          return bookedWorkers;
        }),
        workerRef
          .get()
          .then(async (workerDoc) => {
            const claimedGigs = [...workerDoc.data().claimedGigs];
            const found = claimedGigs.find((gig) => gig.gigID === gigID);

            // Add survey answers to the firebase ratings collection
            workerDoc.ref.collection('ratings').add(surveyResults);

            // RATING CALCULATION STARTS
            const collectRatings = async () => {
              const ratingRef = await workerDoc.ref.collection('ratings').get();
              return ratingRef.docs.map((doc) => doc.data());
            };

            // gets array with results from Question 1-3
            const q1Results = await collectRatings().then((ratings) =>
              ratings.map((rating) => (rating.q1 === 'true' ? 1 : 0))
            );
            const q2Results = await collectRatings().then((ratings) =>
              ratings.map((rating) => (rating.q2 === 'true' ? 1 : 0))
            );
            const q3Results = await collectRatings().then((ratings) =>
              ratings.map((rating) => (rating.q3 === 'true' ? 1 : 0))
            );

            const arrLength = q1Results.length;

            const calcAverage = (results) =>
              (results.reduce((sum, result) => sum + result, 0) / arrLength) *
              100;

            const average1 = calcAverage(q1Results);
            const average2 = calcAverage(q2Results);
            const average3 = calcAverage(q3Results);

            workerRef.update({
              ratings: {
                rating1: Math.ceil(average1),
                rating2: Math.ceil(average2),
                rating3: Math.ceil(average3),
                numberOfRatings: arrLength,
              },
            });
            // END OF RATINGS

            workerRef.update({
              claimedGigs: this.fieldValue.arrayRemove(found),
              completedGigs: this.fieldValue.arrayUnion(found),
            });
          })
          .then(() => {
            this.sendPushNotification({
              workerID,
              link: `${this.deeplink}/gigs?gigType=Completed&gigToShow=${gigID}`,
              linkTo: 'Gigs',
              type: 'bizConfirmedCheckout',
            });
          }),
      ]).then(async (data) => {
        if (!data[0].some((worker) => worker.businessCheckout === false)) {
          const { uid } = this.auth.currentUser;
          const userRef = this.user(uid);
          const completedGig = await userRef.get().then((userDoc) => {
            const activeGigs = [...userDoc.data().activeGigs];
            const found = activeGigs.find((gig) => gig.gigID === gigID);
            return found;
          });

          return userRef
            .update({
              activeGigs: this.fieldValue.arrayRemove(completedGig),
              completedGigs: this.fieldValue.arrayUnion(completedGig),
            })
            .then(() => {
              return gigRef.update({
                status: 3,
                active: false,
                allWorkersCheckedOut: true,
                hasBusinessPaid: true,
                hasPayouts: true,
                haveWorkersBeenPaid: false,
              });
            })
            .then(() => {
              return { success: true, redirect: true, gigID };
            });
        }
        return { success: true, redirect: false };
      });
    });
    // if (!data[0].some((worker) => worker.status !== 4)) {
    //   const { uid } = this.auth.currentUser;
    //   const userRef = this.user(uid);
    //   const completedGig = await userRef.get().then((userDoc) => {
    //     const activeGigs = [...userDoc.data().activeGigs];
    //     const found = activeGigs.find((gig) => gig.gigID === gigID);
    //     return found;
    //   });
    //   return Promise.all([
    //     userRef.update({
    //       activeGigs: this.fieldValue.arrayRemove(completedGig),
    //       completedGigs: this.fieldValue.arrayUnion(completedGig),
    //     }),
    //     gigRef.update({ status: 3, active: false }),
    //   ]).then(async () => {
    //     const gigDoc = await gigRef.get();
    //     const { payPerHour, hours, pi, bookedWorkers } = gigDoc.data();
    //     const payPerWorker = payPerHour * hours;
    //     const wbFees = payPerWorker * 0.2;
    //     const tax = payPerWorker * 0.05;
    //     const amountToCapture = Math.trunc(
    //       Math.round((wbFees + tax + payPerWorker) * data[0].length * 100)
    //     );

    //     const captureTransferPayment = this.functions.httpsCallable(
    //       'captureTransferPayment'
    //     );
    //     return captureTransferPayment({
    //       pi,
    //       amountToCapture,
    //       bookedWorkers,
    //       payPerWorker,
    //       gigID: gigDoc.id,
    //     }).then(() => {
    //       return userRef
    //         .update({
    //           spendHistory: this.fieldValue.arrayUnion({
    //             spend: amountToCapture,
    //             date: new Date(),
    //           }),
    //         })
    //         .then(() => {
    //           return { complete: true, allCheckedout: true };
    //         });
    //     });
    //   });
    // }
    // return { complete: true, allCheckedout: false };
    // });
  };

  confirmCheckoutInvoice = async (
    workerID,
    gigID,
    pay,
    surveyResults,
    hours
  ) => {
    const gigRef = this.db.collection('gigs').doc(gigID);
    const workerRef = this.db.collection('workers').doc(workerID);

    const gigData = await gigRef.get().then((doc) => {
      return doc.data();
    });
    const bookedWorkers = await [...gigData.bookedWorkers];
    const index = await bookedWorkers.findIndex(
      (workers) => workers.uid === workerID
    );
    bookedWorkers[index].businessCheckout = true;
    bookedWorkers[index].businessCheckoutTimestamp = this.timestamp.now();
    bookedWorkers[index].amountToBePaid = pay;
    bookedWorkers[index].hoursLogged = parseFloat(hours);

    return Promise.all([
      gigRef
        .set(
          {
            bookedWorkers,
          },
          { merge: true }
        )
        .then(() => {
          return bookedWorkers;
        }),
      workerRef.get().then(async (workerDoc) => {
        const claimedGigs = [...workerDoc.data().claimedGigs];
        const found = claimedGigs.find((gig) => gig.gigID === gigID);

        // Add survey answers to the firebase ratings collection
        workerDoc.ref.collection('ratings').add(surveyResults);

        // RATING CALCULATION STARTS
        const collectRatings = async () => {
          const ratingRef = await workerDoc.ref.collection('ratings').get();
          return ratingRef.docs.map((doc) => doc.data());
        };

        // gets array with results from Question 1-3
        const q1Results = await collectRatings().then((ratings) =>
          ratings.map((rating) => (rating.q1 === 'true' ? 1 : 0))
        );
        const q2Results = await collectRatings().then((ratings) =>
          ratings.map((rating) => (rating.q2 === 'true' ? 1 : 0))
        );
        const q3Results = await collectRatings().then((ratings) =>
          ratings.map((rating) => (rating.q3 === 'true' ? 1 : 0))
        );

        const arrLength = q1Results.length;

        const calcAverage = (results) =>
          (results.reduce((sum, result) => sum + result, 0) / arrLength) * 100;

        const average1 = calcAverage(q1Results);
        const average2 = calcAverage(q2Results);
        const average3 = calcAverage(q3Results);

        workerRef.update({
          ratings: {
            rating1: Math.ceil(average1),
            rating2: Math.ceil(average2),
            rating3: Math.ceil(average3),
            numberOfRatings: arrLength,
          },
        });
        // END OF RATINGS

        workerRef.update({
          claimedGigs: this.fieldValue.arrayRemove(found),
          completedGigs: this.fieldValue.arrayUnion(found),
        });
      }),
    ]).then(async (data) => {
      if (!data[0].some((worker) => worker.businessCheckout === false)) {
        const { uid } = this.auth.currentUser;
        const userRef = this.user(uid);
        const completedGig = await userRef.get().then((userDoc) => {
          const activeGigs = [...userDoc.data().activeGigs];
          const found = activeGigs.find((gig) => gig.gigID === gigID);
          return found;
        });

        return userRef
          .update({
            activeGigs: this.fieldValue.arrayRemove(completedGig),
            completedGigs: this.fieldValue.arrayUnion({
              ...completedGig,
              endTimeStamp: gigData.endTimeStamp,
              startTimeStamp: gigData.startTimeStamp,
            }),
          })
          .then(() => {
            return gigRef.update({
              status: 3,
              active: false,
              allWorkersCheckedOut: true,
              hasPayouts: true,
              haveWorkersBeenPaid: false,
              hasBusinessPaid: false,
            });
          })
          .then(() => {
            return { success: true, redirect: true, gigID };
          });
      }
      return { success: true, redirect: false };
    });
  };

  saveLocations = (locationList) => {
    const { uid } = this.auth.currentUser;
    const userRef = this.user(uid);
    return userRef.update({
      locations: locationList,
      'setup.locations': true,
    });
  };

  checkOldPassword = (oldPassword) => {
    const user = this.auth.currentUser;
    const credentials = app.auth.EmailAuthProvider.credential(
      user.email,
      oldPassword
    );

    return user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  updatePassword = (newPassword) => {
    const user = this.auth.currentUser;
    return user.updatePassword(newPassword);
  };

  updateEmail = (newEmail, password) => {
    const user = this.auth.currentUser;
    const userRef = this.user(user.uid);
    if (password) {
      const credentials = app.auth.EmailAuthProvider.credential(
        user.email,
        password
      );

      return user.reauthenticateWithCredential(credentials).then(() => {
        return user
          .getIdToken(true)
          .then((data) => {
            return user.updateEmail(newEmail);
          })
          .then(() => {
            return userRef.update({ email: newEmail });
          })
          .catch((error) => error);
      });
    }
    return user
      .getIdToken(true)
      .then((data) => {
        return user.updateEmail(newEmail);
      })
      .then(() => {
        return userRef.update({ email: newEmail });
      })
      .catch((error) => error);
  };

  updatePhoneNumber = (phoneNumber) => {
    const user = this.auth.currentUser;
    const userRef = this.user(user.uid);
    return userRef.update({ phoneNumber });
  };

  // getSpend = (customer) => {
  //   const getSpend = this.functions.httpsCallable('getSpend');
  //   return getSpend(customer).then((charges) => console.log(charges));
  // };

  notifSnapshot = (uid) => {
    const userRef = this.user(uid);
    return userRef
      .collection('notifications')
      .orderBy('time', 'desc')
      .limit(15);
  };

  notifsRead = () => {
    const user = this.auth.currentUser;
    const userRef = this.user(user.uid);
    userRef.update({ notificationsRead: true });
    return userRef
      .collection('notifications')
      .get()
      .then((snapshot) => {
        return snapshot.forEach((doc) => {
          return doc.ref.update({ new: false });
        });
      });
  };

  markAllAsRead = () => {
    const user = this.auth.currentUser;
    const userRef = this.user(user.uid);
    return userRef
      .collection('notifications')
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          return doc.ref.update({ read: true });
        });
      });
  };

  markRead = (id) => {
    const user = this.auth.currentUser;
    const notifRef = this.user(user.uid).collection('notifications').doc(id);
    notifRef.update({ read: true });
  };

  getRecommendedWorkers = (gigID) => {
    const gigRef = this.db.collection('gigs').doc(gigID);
    return gigRef.get().then((doc) => {
      const gigData = doc.data();

      const { gigGeohash } = gigData;
      const certReqs = Object.keys(gigData.reqCertCodes);
      // const verified = gigData.verifiedReq;
      let query = this.db.collection('workers').where('suspended', '==', false);

      if (certReqs.length > 0) {
        certReqs.forEach((cert) => {
          query = query.where(`certCodeList.${cert}`, '==', true);
          // .where(`preferences.range`, "<=", "2000");
        });
        query = query.where(`location.geohashes`, 'array-contains', gigGeohash);

        return query.get().then((data) => {
          return Promise.all(
            data.docs.map((worker) => {
              const workerData = worker.data();

              const { workerGeohash } = workerData.location;

              const returnData = {
                workerID: workerData.uid,
                ratings: workerData.ratings,
                img: workerData.profileImage,
                name: `${workerData.firstName} ${workerData.lastName}`,
                location: workerData.location,
                range: workerData.preferences.range,
                requestSent: false,
                geohashes: workerData.location.geohashes,
                distanceToGig: Math.round(
                  GeohashDistance.inKm(workerGeohash, gigGeohash) * 1000
                ),
              };
              return returnData;
            })
          );
        });
      }

      query = query.where(`location.geohashes`, 'array-contains', gigGeohash);

      return query.get().then((data) => {
        return Promise.all(
          data.docs.map((worker) => {
            const workerData = worker.data();

            const { workerGeohash } = workerData.location;

            const returnData = {
              workerID: workerData.uid,
              ratings: workerData.ratings,
              img: workerData.profileImage,
              name: `${workerData.firstName} ${workerData.lastName}`,
              location: workerData.location,
              range: workerData.preferences.range,
              requestSent: false,
              geohashes: workerData.location.geohashes,
              distanceToGig: Math.round(
                GeohashDistance.inKm(workerGeohash, gigGeohash) * 1000
              ),
            };
            return returnData;
          })
        );
      });
    });
  };

  getWorkerData = (workerID) => {
    const workerRef = this.db.collection('workers').doc(workerID);
    return workerRef.get().then((doc) => doc.data());
  };

  requestWorker = (workerID, gigIDs) => {
    const workerRef = this.db.collection('workers').doc(workerID);

    return this.db
      .runTransaction((transaction) => {
        return transaction.get(workerRef).then((workerDoc) => {
          const workerData = workerDoc.data();
          const requests = [...workerData.requests];
          transaction.update(workerRef, { requests: requests.concat(gigIDs) });
        });
      })
      .then(() => {
        return Promise.all(
          gigIDs.map((gigID) => {
            const gigRef = this.db.collection('gigs').doc(gigID);
            const timeToPost = moment().add(2, 'hours').toDate();
            return gigRef.update({
              public: false,
              timeToPost,
              requestedWorkers: this.fieldValue.arrayUnion(workerID),
            });
          })
        ).then(() => {
          return this.sendNotif(
            workerID,
            `/gigs/requests?gig=${gigIDs[0]}`,
            'You have a new Gig Request.'
          );
        });
      });

    // return workerRef
    //   .update({
    //     requests: this.fieldValue.arrayUnion(gigID),
    //   })
    //   .then(() => {
    //     const gigRef = this.db.collection('gigs').doc(gigID);
    //     const timeToPost = moment().add(2, 'hours').toDate();
    //     return gigRef.update({ public: false, timeToPost });
    //   });
  };

  requestCert = (info) => {
    const data = { ...info };
    data.env = this.env;
    data.toEmail = 'info@workbriefly.com';
    data.type = 'requestCert';
    data.role = 'Business';
    return this.sendEmail(data).then(() => true);
  };

  checkPm = (pm) => {
    const checkPaymentMethod = this.functions.httpsCallable('checkPm');
    return checkPaymentMethod(pm);
  };

  getGigSetInfo = (gigGroup) => {
    return Promise.all(
      gigGroup.map((gigID) => {
        const gigRef = this.db.collection('gigs').doc(gigID);
        return gigRef.get().then((doc) => {
          const data = doc.data();
          return { bookedWorkers: data.bookedWorkers, day: data.day };
        });
      })
    );
  };

  getTaxPercentage = (province) => {
    const camelProvince = province
      .split(' ')
      .map((word, i) => {
        if (i === 0) {
          return word[0].toLowerCase() + word.substring(1);
        }
        if (i !== 0) {
          return word[0].toUpperCase() + word.substring(1);
        }
      })
      .join('');
    const taxDoc = this.db.collection('settings').doc('tax');
    return taxDoc.get().then((doc) => {
      const data = doc.data();
      return data.canada[camelProvince];
    });
  };

  acceptWorker = async (worker, gigID) => {
    const gigRef = this.db.collection('gigs').doc(gigID);
    const gigData = (await gigRef.get()).data();

    const bookedWorker = gigData.bookedWorkers.find(
      (bookedWorker) => bookedWorker.uid === worker.uid
    );
    if (bookedWorker && !bookedWorker.available) {
      return { success: false };
    }

    const workerRef = this.db.collection('workers').doc(worker.uid);
    const workerData = (await workerRef.get()).data();
    const { uid } = this.auth.currentUser;
    const userRef = this.user(uid);
    let sibs = [];

    if (gigData.sameWorker) {
      sibs = gigData.siblings;
    }

    const bizTask = () => {
      return this.db.runTransaction((transaction) => {
        return transaction.get(userRef).then((userDoc) => {
          const userData = userDoc.data();
          const activeGigs = [...userData.activeGigs];
          const gigs = [gigData, ...sibs];
          gigs.map((gig) => {
            const index = activeGigs.findIndex(
              (activeGig) => activeGig.gigID === gig.gigID
            );
            activeGigs[index].workers += 1;
          });
          transaction.update(userRef, { activeGigs });
        });
      });
    };
    const workerTask = () => {
      const gigs = [gigData, ...sibs];
      gigs.map((gig) => {
        const gigToMove = workerData.appliedGigs.find(
          (appliedGig) => appliedGig.gigID === gig.gigID
        );
        return workerRef.update({
          appliedGigs: this.fieldValue.arrayRemove(gigToMove),
          claimedGigs: this.fieldValue.arrayUnion(gigToMove),
        });
      });
    };
    const checkOverlaps = () => {
      const appliedGigs = workerData.appliedGigs.filter(
        (gig) => gig.gigID !== gigID
      );
      if (appliedGigs && appliedGigs.length > 0) {
        const claimedGigRange = moment.range(
          moment(`${gigData.start} ${gigData.day}`, 'h:mm A MMM D,YYYY'),
          moment(`${gigData.end} ${gigData.day}`, 'h:mm A MMM D,YYYY')
        );
        appliedGigs.forEach((appliedGig) => {
          const appliedGigRange = moment.range(
            moment(new Date(appliedGig.start.seconds * 1000)),
            moment(new Date(appliedGig.end.seconds * 1000))
          );
          if (claimedGigRange.overlaps(appliedGigRange)) {
            const overlapGigRef = this.db
              .collection('gigs')
              .doc(appliedGig.gigID);
            overlapGigRef.get().then((doc) => {
              const overlapGigData = doc.data();
              const appliedWorkers = [...overlapGigData.appliedWorkers];
              const workerIndex = appliedWorkers.findIndex(
                (worker) => worker.uid === workerData.uid
              );
              appliedWorkers[workerIndex].available = false;
              overlapGigRef.set({ appliedWorkers }, { merge: true });
            });
          }
        });
      }
    };
    const gigTask = () => {
      const gigs = [gigData, ...sibs];
      const { phoneNumber, email, stripeConnectId } = workerData;
      gigs.map((gig) => {
        const gRef = this.db.collection('gigs').doc(gig.gigID);
        gRef
          .update({
            appliedWorkers: this.fieldValue.arrayRemove(worker),
            bookedWorkers: this.fieldValue.arrayUnion({
              ...worker,
              phoneNumber,
              email,
              stripeConnectId: stripeConnectId || null,
              status: 0,
              amountToBePaid: 0,
              hasBeenPaid: false,
              hoursLogged: 0,
              workerCheckin: false,
              workerCheckout: false,
              businessCheckin: false,
              businessCheckout: false,
            }),
          })
          .then(() => {
            gRef.get().then((gDoc) => {
              const gData = gDoc.data();

              if (gData.bookedWorkers.length === gData.vacancies) {
                gRef.update({ status: 3 });
              }
            });
          });
      });
    };

    return Promise.all([
      gigTask(),
      bizTask(),
      workerTask(),
      checkOverlaps(),
      // ...sibTasks(),
    ])
      .then(() => {
        this.sendPushNotification({
          workerID: worker.uid,
          link: `${this.deeplink}/gigs?gigType=Confirmed&gigToShow=${gigID}`,
          linkTo: 'Gigs',
          type: 'bizAcceptedApplication',
        });
        this.sendEmail({
          toEmail: workerData.email,
          workerFirstName: workerData.firstName,
          gigPosition: gigData.position,
          gigID,
          type: 'bizAcceptedApplication',
        });
      })
      .then(() => {
        return { success: true };
      });
  };

  declineWorker = (worker, gigData) => {
    const gigRef = this.db.collection('gigs').doc(gigData.gigID);
    const workerRef = this.db.collection('workers').doc(worker.uid);
    let sibs = [];
    if (gigData.sameWorker) {
      sibs = gigData.siblings;
    }

    const gigTask = () => {
      return this.db.runTransaction((transaction) => {
        const gigs = [gigData, ...sibs];
        return Promise.all(
          gigs.map((gig) => {
            const gRef = this.db.collection('gigs').doc(gig.gigID);
            return transaction.get(gRef).then((gigDoc) => {
              const appliedWorkers = [...gigDoc.data().appliedWorkers];
              const index = appliedWorkers.findIndex(
                (appliedWorker) => appliedWorker.uid === worker.uid
              );
              appliedWorkers[index].hasBeenDeclined = true;
              transaction.update(gigRef, { appliedWorkers });
            });
          })
        );
      });
    };
    const workerTask = () => {
      return this.db.runTransaction((transaction) => {
        return transaction.get(workerRef).then((workerDoc) => {
          const appliedGigs = [...workerDoc.data().appliedGigs];
          const gigs = [gigData, ...sibs];
          gigs.map((gig) => {
            const index = appliedGigs.findIndex(
              (appliedGig) => appliedGig.gigID === gig.gigID
            );
            appliedGigs[index].hasBeenDeclined = true;
          });
          transaction.update(workerRef, { appliedGigs });
        });
      });
    };

    return Promise.all([gigTask(), workerTask()]);
  };
}

export default Firebase;
