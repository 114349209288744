/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import { button } from "theme/styleBlocks";

const WorkerCard = ({ workerData, gigIDs, onRequest, requested }) => {
  const [requesting, setRequesting] = useState(false);

  const handleRequest = (workerID) => {
    setRequesting(true);
    onRequest(workerID, gigIDs).then((result) => {
      if (result) {
        setRequesting(false);
      }
    });
  };

  return (
    <div css={{ textAlign: "center", marginBottom: 20 }}>
      <div>
        <img
          src={workerData.img}
          alt=""
          css={{
            borderRadius: "50%",
            border: "1px solid #000",
            height: "100px",
            width: "100px",
          }}
        />
        <div>
          <p>{`${workerData.name}`}</p>
        </div>
      </div>
      {/* <div
        css={{
          textAlign: "center",
          backgroundColor: "#FFF0C6",
          padding: "8px 20px",
          p: { margin: "0 0 8px 0" },
          width: 220,
          marginBottom: 10,
        }}
      >
        {workerData.ratings && workerData.ratings.numberOfRatings > 3 ? (
          <div
            css={{
              marginBottom: 10,
              p: { fontSize: 14, fontWeight: 500, color: "#393939" },
            }}
          >
            <p>{workerData.ratings.rating1}% On Time</p>
            <p>{workerData.ratings.rating2}% Dressed Appropriately</p>
            <p>{workerData.ratings.rating3}% Would Hire Again</p>
            <p css={{ color: "#002E6D", fontWeight: 500 }}>
              (Based on {workerData.ratings.numberOfRatings} reviews)
            </p>
          </div>
        ) : (
          <p>No ratings available</p>
        )}
      </div> */}
      <div>
        <button
          css={(theme) =>
            button(
              theme.buttons.type_light,
              theme.buttons.bg1,
              theme.buttons.hover1
            )
          }
          onClick={() => handleRequest(workerData.workerID)}
          disabled={requesting || requested}
        >
          {requesting ? "Requesting" : requested ? "Requested" : "Send Request"}
        </button>
      </div>
    </div>
  );
};

export default WorkerCard;
