/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { withFirebase } from 'library/Firebase';
import {
  editButton,
  formInput,
  button,
  goBackButton,
  buttonContainer,
  settingTitle,
  settingContent,
  passButton,
  savedContainer,
  completedIcon,
  completeText,
} from 'theme/styleBlocks';
import zxcvbn from 'zxcvbn';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { ReactComponent as EyeShow } from 'assets/icons/eyeShow.svg';
import { ReactComponent as EyeHide } from 'assets/icons/eyeHide.svg';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { bp, mq } from 'constants/mediaQueries';

const Password = ({ firebase }) => {
  const [edit, setEdit] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isNumbers, setIsNumbers] = useState(false);
  const [isSpecialCharacters, setIsSpecialCharacters] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [saved, setSaved] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { isSubmitted },
    reset,
    errors,
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (isSubmitted && !Object.keys(errors).length) reset();
  }, [isSubmitted, reset, errors]);
  const handlePassword = (value) => {
    const pw = value;
    const lowerCaseLetters = /[a-z]/;
    const upperCaseLetters = /[A-Z]/;
    const numbers = /[0-9]/;
    const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
    if (lowerCaseLetters.test(pw)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
    if (upperCaseLetters.test(pw)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
    if (numbers.test(pw)) {
      setIsNumbers(true);
    } else {
      setIsNumbers(false);
    }
    if (specialCharacters.test(pw)) {
      setIsSpecialCharacters(true);
    } else {
      setIsSpecialCharacters(false);
    }
    if (pw && pw.length > 8) {
      setIsMinLength(true);
    } else {
      setIsMinLength(false);
    }
  };
  const onEdit = () => {
    setEdit(true);
    if (edit === true) {
      setEdit(false);
    }
  };
  const onCancel = () => {
    setEdit(false);
  };
  const onSave = (data) => {
    firebase.updatePassword(data.newPassword).then(() => {
      setTimeout(() => [setEdit(false)], 1500);
      setSaved(true);
    });
  };
  const checkOldPassword = (oldPassword) => {
    return firebase.checkOldPassword(oldPassword);
  };
  return (
    <div
      css={{
        backgroundColor: edit && '#F8F8F8',
        padding: 30,
        position: 'relative',
      }}
    >
      <div
        css={{
          gridTemplateColumns: '2fr 2fr',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          },
        }}
      >
        <div css={{ justifySelf: 'start' }}>
          <h2 css={settingTitle}>Password</h2>
        </div>
        <div>
          {edit ? (
            <div>
              {saved ? (
                <div css={savedContainer}>
                  <GreenCheck css={completedIcon} />
                  <p css={completeText}>
                    Awesome! Your password has been updated.
                  </p>
                </div>
              ) : (
                <div css={{ marginTop: 50 }}>
                  <div>
                    <div css={formInput}>
                      <label htmlFor="oldPassword">Old Password</label>
                      <input
                        type="password"
                        name="oldPassword"
                        id="oldPassword"
                        placeholder="Enter your old password"
                        ref={register({
                          required: true,
                          validate: (data) => checkOldPassword(data),
                        })}
                        css={{
                          borderColor: errors.oldPassword && 'red !important',
                        }}
                      />
                      <FormFieldErrorMessage error={errors.oldPassword} />
                    </div>
                    <div css={formInput}>
                      <label htmlFor="newPassword">New Password</label>
                      <input
                        name="newPassword"
                        onChange={(e) => handlePassword(e.target.value)}
                        type={isPasswordShown ? 'text' : 'password'}
                        id="newPassword"
                        placeholder="Enter your new password"
                        ref={register({
                          required: true,
                          minLength: 8,
                          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])/,
                          validate: (value) =>
                            zxcvbn(value).score > 0 ||
                            zxcvbn(value).feedback.warning,
                        })}
                        css={{
                          borderColor: errors.newPassword && 'red !important',
                        }}
                      />
                      {isPasswordShown ? (
                        <button
                          type="button"
                          onClick={() => setIsPasswordShown(false)}
                          css={passButton}
                        >
                          <EyeHide />
                          <span>Hide</span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setIsPasswordShown(true)}
                          css={passButton}
                        >
                          <EyeShow />
                          <span>Show</span>
                        </button>
                      )}
                      <div
                        css={{
                          ul: {
                            display: 'flex',
                            flexDirection: 'column',
                            listStyle: 'none',
                            padding: 0,
                            marginTop: 5,
                            [mq(bp.large)]: {
                              marginTop: 16,
                              flexWrap: 'wrap',
                              height: 100,
                            },
                            li: {
                              display: 'flex',
                              margin: '2px 0',
                              alignItems: 'center',
                              [mq(bp.large)]: {
                                margin: '5px 0',
                              },
                            },
                            p: {
                              margin: '0 0 0 3px',
                              fontSize: 14,
                              [mq(bp.large)]: {
                                margin: '0 0 0 10px',
                              },
                            },
                            svg: {
                              width: 15,
                              height: 'auto',
                              display: 'inline',
                              [mq(bp.large)]: {
                                width: 20,
                              },
                            },
                          },
                        }}
                      >
                        <ul>
                          <li>
                            <GreenCheck
                              css={{
                                path: {
                                  '&:first-of-type': {
                                    fill: isLowerCase ? '#74B711' : '#979797',
                                  },
                                },
                              }}
                            />
                            <p
                              css={{
                                color: isLowerCase ? '#393939' : '#969696',
                              }}
                            >
                              One lowercase character
                            </p>
                          </li>
                          <li>
                            <GreenCheck
                              css={{
                                path: {
                                  '&:first-of-type': {
                                    fill: isUpperCase ? '#74B711' : '#979797',
                                  },
                                },
                              }}
                            />
                            <p
                              css={{
                                color: isUpperCase ? '#393939' : '#969696',
                              }}
                            >
                              One uppercase character
                            </p>
                          </li>
                          <li>
                            <GreenCheck
                              css={{
                                path: {
                                  '&:first-of-type': {
                                    fill: isNumbers ? '#74B711' : '#979797',
                                  },
                                },
                              }}
                            />
                            <p
                              css={{ color: isNumbers ? '#393939' : '#969696' }}
                            >
                              One number
                            </p>
                          </li>
                          <li>
                            <GreenCheck
                              css={{
                                path: {
                                  '&:first-of-type': {
                                    fill: isSpecialCharacters
                                      ? '#74B711'
                                      : '#979797',
                                  },
                                },
                              }}
                            />
                            <p
                              css={{
                                color: isSpecialCharacters
                                  ? '#393939'
                                  : '#969696',
                              }}
                            >
                              One special case character
                            </p>
                          </li>
                          <li>
                            <GreenCheck
                              css={{
                                path: {
                                  '&:first-of-type': {
                                    fill: isMinLength ? '#74B711' : '#979797',
                                  },
                                },
                              }}
                            />
                            <p
                              css={{
                                color: isMinLength ? '#393939' : '#969696',
                              }}
                            >
                              8 characters minimum
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div css={buttonContainer}>
                      <button
                        type="button"
                        onClick={onCancel}
                        css={goBackButton}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={handleSubmit(onSave)}
                        css={(theme) =>
                          button(
                            theme.buttons.type_dark,
                            theme.buttons.bg2,
                            theme.buttons.hover2
                          )
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div css={settingContent}>
              <p>***********</p>
            </div>
          )}
        </div>
        <div css={{ position: 'absolute', top: 30, right: 30 }}>
          <button type="button" css={editButton} onClick={onEdit}>
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(Password);
