/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import {
  leftBox,
  formInput,
  button,
  goBackButton,
  passButton,
  customCheck,
  buttonContainer,
} from 'theme/styleBlocks';
import { Ring } from 'react-spinners-css';
import { bp, mq } from 'constants/mediaQueries';
import { useHistory } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import { useForm } from 'react-hook-form';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { ReactComponent as ArrowTip } from 'assets/icons/arrowTip.svg';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { ReactComponent as EyeShow } from 'assets/icons/eyeShow.svg';
import { ReactComponent as EyeHide } from 'assets/icons/eyeHide.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';

const PersonalDetails = ({
  passwordOne,
  onSubmit,
  setDetailsStep,
  detailsStep,
  step,
  setState,
  setStep,
  saving,
}) => {
  const [strength, setStrength] = useState(zxcvbn(passwordOne).score);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isNumbers, setIsNumbers] = useState(false);
  const [isSpecialCharacters, setIsSpecialCharacters] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [isInfoShown, setIsInfoShown] = useState(false);
  const { getValues, register, errors, handleSubmit, watch } = useForm();
  useEffect(() => {
    setStrength(zxcvbn(passwordOne).score);
  }, [passwordOne]);

  const passStrengthData = () => {
    switch (strength) {
      case 0:
        return { fill: 'darkred', label: 'Very weak' };
      case 1:
        return { fill: 'orangered', label: 'Weak' };
      case 2:
        return { fill: 'orange', label: 'Moderate' };
      case 3:
        return { fill: 'yellowgreen', label: 'Strong' };
      case 4:
        return { fill: 'green', label: 'Very strong' };
      default:
        return { fill: 'red', label: ' ' };
    }
  };
  const handlePassword = (value) => {
    setStrength(zxcvbn(value).score);
    const pw = value;
    const lowerCaseLetters = /[a-z]/;
    const upperCaseLetters = /[A-Z]/;
    const numbers = /[0-9]/;
    const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
    if (lowerCaseLetters.test(pw)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
    if (upperCaseLetters.test(pw)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
    if (numbers.test(pw)) {
      setIsNumbers(true);
    } else {
      setIsNumbers(false);
    }
    if (specialCharacters.test(pw)) {
      setIsSpecialCharacters(true);
    } else {
      setIsSpecialCharacters(false);
    }
    if (pw && pw.length > 8) {
      setIsMinLength(true);
    } else {
      setIsMinLength(false);
    }
  };
  const history = useHistory();

  useEffect(() => {
    // if (authUser) {
    //   return history.push('/dashboard');
    // }
    if (step !== 4) {
      history.push('/signup/agreement');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const goBack = () => {
    setState((prev) => ({ ...prev, message: null }));
    setDetailsStep(1);
    setStep(3);
    history.goBack();
  };

  return (
    <div>
      <h2 css={{ textAlign: 'left', [mq(bp.small)]: { textAlign: 'center' } }}>
        Account Details
      </h2>
      <div css={{ marginBottom: 20 }}>
        <div
          css={{
            display: 'flex',
            [mq(bp.large)]: { width: 565, margin: '30px auto' },
          }}
        >
          <div
            css={{
              backgroundColor: detailsStep > 1 ? '#FAFAFA' : '#002E6D',
              borderTop: '1px solid ',
              borderBottom: '1px solid ',
              borderLeft: '1px solid ',
              borderRadius: '4px 0 0 4px',
              borderColor: detailsStep > 1 ? '#808080' : '#002E6D',
              color: '#fff',
              padding: '8px 2vw 8px 3vw',
              height: 36,
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              zIndex: 1,
              fontSize: 12,
              [mq(bp.small)]: {
                height: 45,
                fontSize: 16,
                padding: '8px 20px',
              },
            }}
          >
            {detailsStep > 1 ? (
              <GreenCheck
                css={{
                  height: 18,
                  width: 18,
                  marginRight: 4,
                  [mq(bp.small)]: {
                    height: 24,
                    width: 24,
                    marginRight: 8,
                  },
                }}
              />
            ) : null}
            <p
              css={{
                margin: 0,
                color: detailsStep > 1 ? '#808080' : '#FFF',
                fontWeight: detailsStep > 1 ? 500 : 'normal',
                fontSize: '3.8vw',
                [mq(bp.small)]: {
                  fontSize: 16,
                },
              }}
            >
              Business Details
            </p>
            <ArrowTip
              css={{
                position: 'absolute',
                height: 'calc(100% + 2px)',
                top: -1,
                right: -22,
                '& path': {
                  stroke: detailsStep > 1 ? '#808080' : '#002E6D',
                  fill: detailsStep > 1 ? '#FAFAFA' : '#002E6D',
                },
                [mq(bp.small)]: {
                  right: -28,
                },
              }}
            />
          </div>
          <div
            css={{
              backgroundColor: detailsStep > 1 ? '#002E6D' : '#FAFAFA',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: detailsStep > 1 ? '#002E6D' : '#808080',
              height: 36,
              color: '#fff',
              padding: '2vw 2vw 2vw 9vw',
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              [mq(bp.small)]: {
                height: 45,
                padding: '8px 8px 8px 48px',
              },
            }}
          >
            <p
              css={{
                margin: 0,
                color: detailsStep > 1 ? '#FFFFFF' : '#808080',
                fontWeight: detailsStep > 1 ? 'normal' : 500,
                fontSize: '3.8vw',
                [mq(bp.small)]: {
                  fontSize: 16,
                },
              }}
            >
              Personal Details
            </p>
            <ArrowTip
              css={{
                position: 'absolute',
                height: 'calc(100% + 2px)',
                top: -1,
                right: -22,
                '& path': {
                  stroke: detailsStep > 1 ? '#002E6D' : '#808080',
                  fill: detailsStep > 1 ? '#002E6D' : '#FAFAFA',
                },
                [mq(bp.small)]: {
                  right: -28,
                },
              }}
            />
          </div>
        </div>
      </div>

      <div
        css={(theme) => ({
          backgroundColor: theme.colors.light,
          margin: '0 auto',
          borderRadius: '4px',
          width: '100%',
          [mq(bp.small)]: {
            width: 565,
          },
        })}
      >
        <div css={leftBox}>
          <div>
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr ',
                gridColumnGap: 20,
              }}
            >
              <div
                css={[
                  formInput,
                  {
                    gridColumn: '1/3',
                    [mq(bp.large)]: { gridColumn: 'inherit' },
                  },
                ]}
              >
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  id="firstName"
                  ref={register({
                    required: true,
                  })}
                  css={{ borderColor: errors.firstName && 'red !important' }}
                />
                <FormFieldErrorMessage error={errors.firstName} />
              </div>
              <div
                css={[
                  formInput,
                  {
                    gridColumn: '1/3',
                    [mq(bp.large)]: { gridColumn: 'inherit' },
                  },
                ]}
              >
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  id="lastName"
                  ref={register({
                    required: true,
                  })}
                  css={{ borderColor: errors.firstName && 'red !important' }}
                />
              </div>
              <FormFieldErrorMessage error={errors.lastName} />
              <div css={[formInput, { gridColumn: '1/3' }]}>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  id="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                      message: 'Invalid Email',
                    },
                  })}
                  css={{ borderColor: errors.email && 'red !important' }}
                />
                <FormFieldErrorMessage error={errors.email} />
              </div>
              <div css={[formInput, { gridColumn: '1/3' }]}>
                <label htmlFor="passwordOne">Password</label>
                <input
                  type={isPasswordShown ? 'text' : 'password'}
                  name="passwordOne"
                  onChange={(e) => handlePassword(e.target.value)}
                  placeholder="Enter your Password"
                  id="passwordOne"
                  ref={register({
                    required: true,
                    minLength: 8,
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])/,
                    validate: (value) =>
                      zxcvbn(value).score > 0 || zxcvbn(value).feedback.warning,
                  })}
                  css={{ borderColor: errors.passwordOne && 'red !important' }}
                />
                {isPasswordShown ? (
                  <button
                    type="button"
                    onClick={() => setIsPasswordShown(false)}
                    css={passButton}
                  >
                    <EyeHide />
                    <span>Hide</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setIsPasswordShown(true)}
                    css={passButton}
                  >
                    <EyeShow />
                    <span>Show</span>
                  </button>
                )}
                <div
                  css={{
                    position: 'relative',
                    height: 8,
                    background: '#ddd',
                    margin: '7px 0',
                    borderRadius: 2,
                    '&::before, &::after': {
                      content: "''",
                      height: 'inherit',
                      background: 'transparent',
                      display: 'block',
                      borderColor: '#fff',
                      borderStyle: 'solid',
                      borderWidth: '0 6px 0',
                      position: 'absolute',
                      width: 'calc(20% - 6px)',
                      zIndex: 10,
                    },
                    '&::before': { left: 'calc(20% - 3px)' },
                    '&::after': {
                      right: 'calc(20% - 3px)',
                    },
                  }}
                >
                  <div
                    css={{
                      width: `calc((${strength} + 1) * 20%)`,
                      background: passStrengthData().fill,
                      height: 'inherit',
                      position: 'absolute',
                      borderRadius: 'inherit',
                      transition: 'width 0.5s ease-in-out, background 0.25s',
                    }}
                  />
                </div>
                <span css={{ fontSize: 14, color: '#5E5E5E' }}>
                  Password Strength: {passStrengthData().label}
                </span>
                <div
                  css={{
                    ul: {
                      display: 'flex',
                      flexDirection: 'column',
                      listStyle: 'none',
                      padding: 0,
                      marginTop: 5,
                      [mq(bp.small)]: {
                        marginTop: 16,
                        flexWrap: 'wrap',
                        height: 100,
                      },
                      li: {
                        display: 'flex',
                        margin: '2px 0',
                        alignItems: 'center',
                        [mq(bp.small)]: {
                          margin: '5px 0',
                        },
                      },
                      p: {
                        margin: '0 0 0 3px',
                        fontSize: 14,
                        [mq(bp.small)]: {
                          margin: '0 0 0 10px',
                        },
                      },
                      svg: {
                        width: 15,
                        height: 'auto',
                        display: 'inline',
                        [mq(bp.small)]: {
                          width: 20,
                        },
                      },
                    },
                  }}
                >
                  <ul>
                    <li>
                      <GreenCheck
                        css={{
                          path: {
                            '&:first-of-type': {
                              fill: isLowerCase ? '#74B711' : '#979797',
                            },
                          },
                        }}
                      />
                      <p css={{ color: isLowerCase ? '#393939' : '#969696' }}>
                        One lowercase character
                      </p>
                    </li>
                    <li>
                      <GreenCheck
                        css={{
                          path: {
                            '&:first-of-type': {
                              fill: isUpperCase ? '#74B711' : '#979797',
                            },
                          },
                        }}
                      />
                      <p css={{ color: isUpperCase ? '#393939' : '#969696' }}>
                        One uppercase character
                      </p>
                    </li>
                    <li>
                      <GreenCheck
                        css={{
                          path: {
                            '&:first-of-type': {
                              fill: isNumbers ? '#74B711' : '#979797',
                            },
                          },
                        }}
                      />
                      <p css={{ color: isNumbers ? '#393939' : '#969696' }}>
                        One number
                      </p>
                    </li>
                    <li>
                      <GreenCheck
                        css={{
                          path: {
                            '&:first-of-type': {
                              fill: isSpecialCharacters ? '#74B711' : '#979797',
                            },
                          },
                        }}
                      />
                      <p
                        css={{
                          color: isSpecialCharacters ? '#393939' : '#969696',
                        }}
                      >
                        One special case character
                      </p>
                    </li>
                    <li>
                      <GreenCheck
                        css={{
                          path: {
                            '&:first-of-type': {
                              fill: isMinLength ? '#74B711' : '#979797',
                            },
                          },
                        }}
                      />
                      <p css={{ color: isMinLength ? '#393939' : '#969696' }}>
                        8 characters minimum
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                css={{
                  marginBottom: 30,
                  gridColumn: '1/3',
                  position: 'relative',
                  display: 'flex',
                }}
              >
                <label
                  htmlFor="authorizedCompany"
                  css={[
                    customCheck(),
                    {
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      width: '100%',
                    },
                  ]}
                >
                  <input
                    type="checkbox"
                    name="authorizedCompany"
                    id="authorizedCompany"
                    ref={register({
                      validate: (value) =>
                        value || 'You must agree to these terms',
                    })}
                  />
                  <span />
                  <p>
                    I confirm that I am an authorized company officer.
                    <div
                      css={{
                        display: 'inline',
                        position: 'relative',
                        marginLeft: 8,
                      }}
                    >
                      <button
                        onMouseEnter={() => setIsInfoShown(true)}
                        onMouseLeave={() => setIsInfoShown(false)}
                        type="button"
                        css={{
                          border: 'none',
                          outline: 'none',
                          display: 'inline',
                          verticalAlign: 'middle',
                        }}
                      >
                        <InfoIcon />
                      </button>
                      <div
                        css={{
                          position: 'absolute',
                          top: '-77px',
                          left: '-108px',
                          [mq(bp.small)]: {
                            top: '-91px',
                          },
                          [mq(bp.large)]: {
                            top: '-32px',
                            left: '40px',
                            transform: 'translate(0, 0)',
                          },
                        }}
                      >
                        <div
                          css={{
                            display: isInfoShown ? 'inline-block' : 'none',
                            width: 230,
                            border: '1px solid #5E5E5E',
                            padding: '10px',
                            textAlign: 'center',
                            color: '#5E5E5E',
                            position: 'relative',
                            fontSize: 10,
                            backgroundColor: '#fff',
                            '&::before': {
                              content: "''",
                              width: 0,
                              height: 0,
                              position: 'absolute',
                              borderLeft: '10px solid transparent',
                              borderTop: '12px solid #5E5E5E',
                              borderRight: '10px solid transparent',
                              borderBottom: '10px solid transparent',
                              right: 97,
                              bottom: '-23px',
                            },
                            '&::after': {
                              content: "''",
                              width: 0,
                              height: 0,
                              position: 'absolute',
                              borderLeft: '10px solid transparent',
                              borderTop: '12px solid #fff',
                              borderRight: '10px solid transparent',
                              borderBottom: '10px solid transparent',
                              right: 97,
                              bottom: '-32px',
                              transform: 'translate(0, -50%)',
                            },
                            [mq(bp.small)]: {
                              fontSize: 12,
                            },
                            [mq(bp.large)]: {
                              minWidth: 250,
                              '&::before': {
                                content: "''",
                                width: 0,
                                height: 0,
                                position: 'absolute',
                                borderLeft: '10px solid transparent',
                                borderRight: '12px solid #5E5E5E',
                                borderTop: '10px solid transparent',
                                borderBottom: '10px solid transparent',
                                left: '-22px',
                                top: '50%',
                                transform: 'translate(0, -50%)',
                              },
                              '&::after': {
                                content: "''",
                                width: 0,
                                height: 0,
                                position: 'absolute',
                                borderLeft: '8px solid transparent',
                                borderRight: '12px solid #fff',
                                borderTop: '10px solid transparent',
                                borderBottom: '10px solid transparent',
                                left: '-18px',
                                top: '50%',
                                transform: 'translate(0, -50%)',
                              },
                            },
                          }}
                        >
                          An authorised company officer is someone within the
                          company permitted to make staffing decisions.
                        </div>
                      </div>
                    </div>
                  </p>
                </label>

                <FormFieldErrorMessage error={errors.authorizedCompany} />
              </div>
              <div css={[buttonContainer(), { gridColumn: '1/3' }]}>
                <button onClick={goBack} type="button" css={goBackButton}>
                  Back
                </button>
                <button
                  type="button"
                  onClick={handleSubmit((data) => onSubmit(data))}
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                >
                  {saving ? (
                    <Ring
                      color="#002E6D"
                      size="22"
                      css={{
                        width: 22,
                        height: 22,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  ) : (
                    'Create My Account'
                  )}
                </button>
              </div>
              {errors.length ? (
                <div css={{ gridColumn: '1/3' }}>
                  {errors.map((error) => (
                    <p css={{ color: '#D72F2F' }} key={error.field}>
                      {error.message}
                    </p>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div>
          <div>
            <h3 css={{ marginTop: 0 }}>
              Why we need you to upload a profile picture?
            </h3>
            <ul
              css={{
                paddingInlineStart: 20,
                li: { margin: '10px 0', lineHeight: '24px' },
              }}
            >
              <li>
                Note: Keep it professional, something like your Linkedin profile
                picture would work well.
              </li>
              <li>Here's are examples of what to do and what not to do:</li>
            </ul>
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-around',
              [mq(bp.large)]: {
                justifyContent: 'flex-start',
              },
            }}
          >
            <div css={{ marginRight: 30 }}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/workbriefly-alpha.appspot.com/o/assets%2Fimages%2Fprofile-image-do.jpg?alt=media&token=001752ec-afda-483a-876e-9805e71cc5fa"
                alt=""
                css={{
                  width: 125,
                }}
              />
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <Tick css={{ marginRight: 8 }} />
                <p>Do this</p>
              </div>
            </div>
            <div>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/workbriefly-alpha.appspot.com/o/assets%2Fimages%2Fprofile-image-dont-do.jpg?alt=media&token=a013d362-9a15-47e5-93df-de803da8f18a"
                alt=""
                css={{
                  width: 125,
                }}
              />
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <Cross css={{ marginRight: 8 }} />
                <p>Don&apos;t do this</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PersonalDetails;
