/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthUserContext } from 'library/Session';
import {
  leftBox,
  button,
  twoColumn,
  buttonContainer,
  goBackButton,
  innerDesc,
} from 'theme/styleBlocks';
import { Ring } from 'react-spinners-css';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
// import "./CardSectionStyles.css";
import { withFirebase } from 'library/Firebase';

const Payment = (props) => {
  const { authUser, refetch } = useContext(AuthUserContext);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState('');
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const cardOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    disabled: saving,
  };

  const handleSubmit = (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setSaving(true);
    setMessage('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    props.firebase
      .getClientSecret(authUser.stripeCustomerId)
      .then((client_secret) => {
        return stripe.confirmCardSetup(client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });
      })
      .then((result) => {
        if (result.error) {
          setSaving(false);
          setMessage(result.error.message);
        } else {
          return props.firebase
            .saveCardComplete(
              result.setupIntent.payment_method,
              authUser.stripeCustomerId
            )
            .then(() => {
              setSaving(false);
              refetch(true);
              // props.setSetup(prev => ({ ...prev, paymentCard: true }));
              // history.push('/setup');
            });
        }
      });
  };

  return (
    <div>
      <h2>Banking Details</h2>
      <div css={twoColumn}>
        <div css={leftBox}>
          <div>
            <p css={innerDesc}>
              In order to ensure all transactions occur in a timely manner, we
              will need to redirect you to our payment provider where you will
              securely enter your banking details.
            </p>
            <form onSubmit={handleSubmit}>
              <div css={{ marginBottom: 40 }}>
                <label>
                  Card details
                  <CardElement options={cardOptions} />
                </label>
              </div>
              <div css={buttonContainer}>
                <button
                  type="button"
                  onClick={() => history.push('/setup')}
                  css={goBackButton}
                >
                  Back
                </button>
                <button
                  type="button"
                  disabled={!stripe || saving}
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                >
                  {saving ? (
                    <Ring
                      color="#002E6D"
                      size="22"
                      css={{
                        width: 22,
                        height: 22,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  ) : (
                    'Save Card'
                  )}
                </button>
              </div>
              {message ? <p>{message}</p> : null}
            </form>
          </div>
        </div>
        <div>
          <div>
            <h3 css={{ marginTop: 0 }}>
              Why do we need your banking information?
            </h3>
            <p>
              Don’t worry you won’t have to pay anything now. You will only be
              charged for completed jobs that you have posted. You can always
              update and change payment information as needed on your profile.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(Payment);
