/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, useContext } from 'react';
import { withFirebase, FirebaseContext } from 'library/Firebase';
import { AuthUserContext } from 'library/Session';
import { gigContainer } from 'theme/styleBlocks';
import GigItem from 'components/GigItem';
import moment from 'moment';
import { bp, mq } from 'constants/mediaQueries';
// import { ReactComponent as Close } from 'assets/icons/close.svg';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

const GigsActive = () => {
  const { authUser, refetch } = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);
  const [gigs, setGigs] = useState({});

  const [refetchGigs, setRefetchGigs] = useState(true);
  const location = useLocation();
  const [openedGig, setOpenedGig] = useState('');
  const parsed = qs.parse(location.search);
  const [scrollRef, setScrollRef] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(0);
  // const [gigsArray, setGigsArray] = useState([]);
  // const [applicantData, setApplicantData] = useState({});
  // const [applicantDisplay, setApplicantDisplay] = useState(false);
  // const [openGigID, setOpenGigID] = useState("");
  useEffect(() => {
    firebase
      .getTaxPercentage(authUser.province.value)
      .then((data) => setTaxPercentage(data));
  }, []);
  useEffect(() => {
    const businessSnapshot = firebase.businessSnapshot(authUser.uid);
    businessSnapshot.get().then((snapshot) => {
      firebase
        .getGigs(snapshot.data().activeGigs, 'active')
        .then((gigs) => {
          // setGigsArray([...gigs]);
          const categorized = gigs.reduce((acc, gig) => {
            const array = (acc[gig.formattedDate] =
              acc[gig.formattedDate] || []);
            array.push(gig);
            array.sort(
              (a, b) =>
                moment(`${a.day} ${a.start}`) - moment(`${b.day} ${b.start}`)
            );

            return acc;
          }, {});
          const sorted = {};
          Object.keys(categorized)
            .sort((a, b) => {
              return (
                moment(a, 'MMMM DD, YYYY').toDate() -
                moment(b, 'MMMM DD, YYYY').toDate()
              );
            })
            .forEach((key) => {
              sorted[key] = categorized[key];
            });
          return sorted;
        })
        .then((data) => {
          setGigs(data);
          setRefetchGigs(false);
        });
    });
    // if (refetchGigs) {
    //   refetch();
    //   firebase
    //     .getGigs(authUser.activeGigs)
    //     .then((gigs) => {
    //       // setGigsArray([...gigs]);
    //       const categorized = gigs.reduce((acc, gig) => {
    //         const array = (acc[gig.formattedDate] =
    //           acc[gig.formattedDate] || []);
    //         array.push(gig);
    //         array.sort((a, b) => a.start - b.start);

    //         return acc;
    //       }, {});
    //       const sorted = {};
    //       Object.keys(categorized)
    //         .sort((a, b) => {
    //           return (
    //             moment(a, 'DD MMMM, YYYY').toDate() -
    //             moment(b, 'DD MMMM, YYYY').toDate()
    //           );
    //         })
    //         .forEach((key) => {
    //           sorted[key] = categorized[key];
    //         });
    //       return sorted;
    //     })
    //     .then((data) => {
    //       setGigs(data);
    //       setRefetchGigs(false);
    //     });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchGigs, authUser.activeGigs]);
  useEffect(() => {
    if (scrollRef) {
      window.scrollTo(0, scrollRef.offsetTop);
    }
  }, [scrollRef]);
  useEffect(() => {
    if (parsed.gig) {
      setOpenedGig(parsed.gig);
    }
  }, [parsed.gig]);
  const handleComplete = () => {
    refetch(true);
  };

  return (
    <div css={gigContainer}>
      <div css={{ [mq(bp.large)]: { marginBottom: 50, width: 750 } }}>
        <h1>Gigs Active</h1>
        <p>These are the active gigs you have created that are upcoming.</p>
      </div>
      <div>
        <div css={{ display: 'flex' }}>
          <span
            css={{
              height: 16,
              width: 16,
              borderRadius: '50%',
              backgroundColor: 'green',
              display: 'block',
              marginRight: 8,
            }}
          />{' '}
          <p css={{ margin: 0 }}>
            indicated that all vacancies for the gig have been filled
          </p>
        </div>
        <div css={{ display: 'flex' }}>
          <span
            css={{
              height: 16,
              width: 16,
              borderRadius: '50%',
              backgroundColor: 'orange',
              display: 'block',
              marginRight: 8,
            }}
          />{' '}
          <p css={{ margin: 0 }}>
            indicates that there are still vacancies to be filled.
          </p>
        </div>
        <div css={{ display: 'flex' }}>
          <span
            css={{
              height: 16,
              width: 16,
              borderRadius: '50%',
              backgroundColor: 'red',
              display: 'block',
              marginRight: 8,
            }}
          />{' '}
          <p css={{ margin: 0 }}>
            indicates that no vacancies have been filled.
          </p>
        </div>
      </div>
      <div>
        {Object.keys(gigs).map((gig, i) => {
          return (
            <div key={i}>
              <h2 css={{ fontSize: 18, color: '#393939' }}>{gig}</h2>
              {gigs[gig].map((gigData, i) => {
                return (
                  <div
                    key={gigData.gigID}
                    css={{ marginBottom: 20 }}
                    ref={(el) =>
                      gigData.gigID === openedGig ? setScrollRef(el) : null
                    }
                  >
                    <GigItem
                      gigData={gigData}
                      index={i}
                      gigsActive
                      setRefetchGigs={setRefetchGigs}
                      handleComplete={handleComplete}
                      opened={gigData.gigID === openedGig}
                      taxPercentage={taxPercentage}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {/**
      
      {applicantDisplay ? (
        <div
          css={{
            position: "fixed",
            left: 250,
            top: 0,
            width: "calc(100vw - 250px)",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.25)",
            display: "flex",
          }}
        >
          <div
            css={{
              background: "#fff",
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              padding: 20,
            }}
          >
            <div>
              <img src={applicantData.profileImage} alt="" />
              <p>
                {applicantData.firstName} {applicantData.lastName}
              </p>
            </div>
          </div>
          <div
            css={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => bookForGig(applicantData.uid, openGigID)}
              css={(theme) =>
                button(theme.buttons.type_dark, theme.buttons.bg2, theme.buttons.hover2)
              }
            >
              Book for Gig
            </button>
          </div>
          <div
            css={{
              background: "#fff",
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              padding: 20,
            }}
          >
            <div css={{ textAlign: "right" }}>
              <button
                onClick={closeApplicantDisplay}
                css={{ borderRadius: "50%", height: 24, width: 24, padding: 0 }}
              >
                X
              </button>
            </div>
            <div>
              <div>
                <h3>Experience</h3>
                {applicantData.exp ? calcExp() : <p>N/A</p>}
              </div>
              <hr />
              <div>
                <h3>Certificates</h3>
                {applicantData.certList ? (
                  <div>
                    {applicantData.certList.map((cert, i) => {
                      return (
                        <div key={i}>
                          <p>
                            {cert.name} ({cert.expiry ? cert.expiry : "N/A"})
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
       */}
    </div>
  );
};

export default withFirebase(GigsActive);
