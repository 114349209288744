/** @jsx jsx */
import { jsx } from "@emotion/core";
import { centerBox, button } from "theme/styleBlocks";
import { useEffect, useState } from "react";
import { withFirebase } from "library/Firebase";
import WorkerCard from "./WorkerCard";

const Recommended = (props) => {
  const [recommendedWorkers, setRecommendedWorkers] = useState([]);
  const [requestedWorkers, setRequestedWorkers] = useState([]);
  // const gigID = "1r5PsGuxKyw19I8r57xg";

  useEffect(() => {
    props.setStep(8);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.firebase.getRecommendedWorkers(props.gigIDs[0]).then((workers) => {
      setRecommendedWorkers(workers);
    });
  }, []);

  const onRequest = (workerID, gigID) => {
    return props.firebase.requestWorker(workerID, gigID).then(() => {
      const requestList = [...requestedWorkers];
      requestList.push(workerID);
      setRequestedWorkers(requestList);
      return true;
    });
  };

  return (
    <div css={centerBox}>
      <h1>Recommended Workers</h1>
      <p>
        Based on the gig you have created, here are our recommended workers.
      </p>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
          gridColumnGap: 20,
        }}
      >
        {recommendedWorkers.map((worker, i) => {
          recommendedWorkers.sort((a, b) => {
            return a.distanceToGig - b.distanceToGig;
          });

          return (
            <WorkerCard
              key={i}
              workerData={worker}
              gigIDs={props.gigIDs}
              onRequest={onRequest}
              requested={requestedWorkers.includes(worker.workerID)}
            />
          );
        })}
      </div>
      <div css={{ textAlign: "center" }}>
        <button
          onClick={props.onGigCreateClose}
          css={(theme) =>
            button(
              theme.buttons.type_dark,
              theme.buttons.bg2,
              theme.buttons.hover2
            )
          }
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default withFirebase(Recommended);
