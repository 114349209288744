/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { bp, mq } from 'constants/mediaQueries';
import GigsActive from './GigsActive';
import GigsCompleted from './GigsCompleted';

const GigsContainer = () => {
  const { path } = useRouteMatch();
  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '60px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <Switch>
        <Route path={`${path}/active`}>
          <GigsActive />
        </Route>
        <Route path={`${path}/completed`}>
          <GigsCompleted />
        </Route>
      </Switch>
    </div>
  );
};

export default GigsContainer;
