/** @jsx jsx */
import { jsx } from '@emotion/core';
import { bp, mq } from 'constants/mediaQueries';
import { ReactComponent as Arrow } from 'assets/icons/topbarArrow.svg';

const FaqItem = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      key={index}
      css={(theme) => ({
        borderBottom: `2px solid ${theme.colors.neutral_light1}`,
        [mq(bp.large)]: {
          padding: '30px 0',
        },
        '&:last-of-type': {
          borderBottom: 'none',
        },
      })}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '&>div:not(:last-child)': {
            [mq(bp.large)]: {
              marginRight: 20,
            },
          },
        }}
        onClick={() => toggleFAQ(index)}
      >
        <h2
          css={{
            width: '75%',
            fontSize: 20,
            [mq(bp.large)]: { width: '95%' },
          }}
        >
          {faq.question}
        </h2>
        <div
          css={{
            width: 24,
          }}
        >
          <Arrow
            css={{
              transform: faq.open ? 'scale(1, -1)' : null,
              transition: '.25s ease',
            }}
          />
        </div>
      </div>
      <div
        css={{
          display: faq.open ? 'block' : 'none',
          [mq(bp.large)]: { paddingTop: 20 },
          li: { margin: '16px 0' },
        }}
      >
        {faq.answer}
      </div>
    </div>
  );
};

export default FaqItem;
