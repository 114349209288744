export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";
export const SETUP = "/setup";
export const LOGIN = "/login";
export const RECOVER = "/recover";
export const DASHBOARD = "/dashboard";
export const CREATE = "/create";
export const GIGS = "/gigs";
export const PROFILE = "/profile";
export const SETTINGS = "/settings";
export const ACCOUNT_MENU = "/account-menu";
export const HELP = "/help";
export const NOTIFICATIONS = "/notifications";
