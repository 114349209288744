/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { formInput, button, customCheck, addButton } from 'theme/styleBlocks';
import { certifications, licences } from 'constants/lists';
import Select from 'react-select';
import { bp, mq } from 'constants/mediaQueries';

const list = certifications.concat(licences);

const CertForm = ({ addCert }) => {
  const { errors, handleSubmit, control, reset, formState, register } = useForm(
    {
      defaultValues: { cert: '', certReq: false },
    }
  );
  useEffect(() => {
    if (formState.isSubmitted && !Object.keys(errors).length) {
      return reset();
    }
  }, [formState, reset, errors]);
  return (
    <div>
      <div css={formInput}>
        <label htmlFor="cert">
          Certificate/License
        </label>
        <Controller
          as={Select}
          name="cert"
          control={control}
          styles={{
            control: (provided) => ({
              ...provided,
              background: 'rgba(58, 177, 202, 0.1)',
              border: '1px solid transparent',
              borderColor: errors.month && 'red !important',
              padding: '6px',
              boxShadow: 'none',
              cursor: 'pointer',
              fontSize: 14,
              '&:hover': {
                border: '1px solid #002E6D',
              },
            }),
            option: (provided) => ({
              ...provided,
              fontSize: 14,
              cursor: 'pointer',
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            valueContainer: (provided) => ({
              ...provided,
              fontSize: 14,
              padding: '0px  8px',
              [mq(bp.small)]: {
                fontSize: 16,
              },
            }),
            input: (provided) => ({
              ...provided,
              paddingBottom: 0,
              paddingTop: 0,
              margin: 0,
            }),
          }}
          options={list}
          menuPlacement="auto"
          rules={{
            required: true,
            validate: (value) => !!value.code,
          }}
        />
      </div>
      {/* wont work because list structure was changed */}
      <div css={[customCheck(), { marginBottom: 20 }]}>
        <label htmlFor="certReq">
          <input type="checkbox" name="certReq" id="certReq" ref={register()} />
          <span />
          <p css={{ margin: 0 }}>
            The worker is <strong>required</strong> to have this
            certificate/license
          </p>
        </label>
      </div>
      <div>
        <button type="button" onClick={handleSubmit(addCert)} css={addButton}>
          + Add this certificate/license
        </button>
      </div>
    </div>
  );
};

export default CertForm;
