/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import {
  formInput,
  button,
  gigBox,
  buttonContainer,
  goBackButton,
  blueBox,
  stepTitle,
} from 'theme/styleBlocks';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ReactComponent as Close } from 'assets/icons/close.svg';
// import { ReactComponent as Verified } from 'assets/icons/verified.svg';
import { bp, mq } from 'constants/mediaQueries';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { useHistory } from 'react-router-dom';
import CertForm from './CertForm';

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'bulletdList', 'numberedList'],
};

const Step2 = (props) => {
  const [certList, setCertList] = useState([]);
  const {
    errors,
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
  } = useForm();
  const history = useHistory();

  useEffect(() => {
    if (props.step !== 2) history.push('/create');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setCertList(props.certs);
    setValue('position', props.position);
    setValue('vacancies', props.vacancies);
    setValue('verifiedReq', props.verifiedReq);
  }, []);

  const addCert = (data) => {
    const certToAdd = {
      ...data.cert,
      required: data.certReq,
    };
    const addCertList = [...certList, certToAdd];
    const cleanCertList = addCertList.filter((item, index, array) => {
      return array.findIndex((item2) => item.code === item2.code) === index;
    });
    setCertList(cleanCertList);
  };
  const removeCert = (index) => {
    const removedList = [...certList];
    removedList.splice(index, 1);
    setCertList(removedList);
  };
  const onSubmit = (data) => {
    props.setState((prev) => ({
      ...prev,
      ...data,
      certs: certList,
      vacancies: parseInt(data.vacancies, 10),
    }));
    props.nextStep(3);
  };
  const onVacancyChange = (value) => {
    const regex = RegExp('^[0-9]*$');
    const test = regex.test(value);
    if (!value) return false;
    if (test) return false;
    if (!test && value) return true;
    return false;
  };
  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '60px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <p
        css={{
          fontWeight: 500,
          fontSize: 16,
          color: '#8A8A8A',
          marginBottom: 0,
          [mq(bp.large)]: {
            display: 'none',
          },
        }}
      >
        STEP 2 OF 5
      </p>
      <h2 css={stepTitle}>
        Experience <span css={{ fontSize: 16 }}>(optional)</span>
      </h2>
      <div css={gigBox}>
        <div>
          <div
            css={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridColumnGap: 40,
            }}
          >
            <div
              css={{
                gridColumn: '1/4',
                marginBottom: 20,
              }}
            >
              <p>
                In order to match you with the best workers for the job, please
                indicate below if you have a preference for workers with certain
                certificates/licenses. <br />
                Please make sure that you{' '}
                <strong>"+ Add this certificate/license"</strong> to save each
                certificate/license.
              </p>
              <p css={{ margin: '0 0 20px 0' }}>
                Please choose a Certification/License from the list provided. If
                it is not listed, please{' '}
                <a
                  href="/help/request-cert"
                  target="_blank"
                  css={{ textDecoration: 'underline' }}
                >
                  submit a request
                </a>{' '}
                to add it.
              </p>
            </div>
            <div
              css={{
                gridColumn: '1/4',
                marginBottom: 40,
                [mq(bp.large)]: { gridColumn: '1/3' },
              }}
            >
              <CertForm addCert={addCert} />
            </div>
            {certList.length ? (
              <div
                css={{
                  gridColumn: '1/4',
                  marginBottom: 20,
                  [mq(bp.large)]: { gridColumn: '1/3' },
                }}
              >
                {certList.map((cert, i) => (
                  <div css={blueBox} key={i}>
                    <p
                      css={{
                        margin: '0',
                        fontSize: 16,
                        [mq(bp.small)]: {
                          fontSize: 18,
                        },
                        strong: {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      <strong>{cert.label} </strong>
                      {cert.required ? '(required)' : '(preferred)'}
                    </p>
                    <div css={{ display: 'flex' }}>
                      <button
                        type="button"
                        onClick={() => removeCert(i)}
                        css={{
                          border: 'none',
                          background: 'none',
                          color: '#FFFFFF',
                          fontWeight: 'bold',
                          fontSize: 18,
                          padding: 0,
                          display: 'flex',
                        }}
                      >
                        <Close css={{ height: 16 }} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div css={buttonContainer}>
            <button
              type="button"
              onClick={() => props.prevStep(1)}
              css={goBackButton}
            >
              Back
            </button>
            <button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
