/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState, useEffect, useContext, useRef } from "react";
import { ReactComponent as NotifIcon } from "assets/icons/notifIcon.svg";
import { useHistory, Link } from "react-router-dom";
import { bp, mq } from "constants/mediaQueries";
import { withFirebase } from "library/Firebase";
import { AuthUserContext } from "library/Session";
import { button, sideNav, topBarContainer } from "theme/styleBlocks";
import Topbar from "components/Topbar";
import Bottombar from "components/Bottombar";
import * as ROUTES from "constants/routes";
import AuthNavigation from "components/AuthNavigation";

const NotificationsPage = ({ firebase }) => {
  const { authUser } = useContext(AuthUserContext);
  const [notifs, setNotifs] = useState([]);
  const [notifIndicator, setNotifIndicator] = useState(0);
  const history = useHistory();
  const notifRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState();

  useEffect(() => {
    const notifSnapshot = firebase.notifSnapshot(authUser.uid);
    const unsub = notifSnapshot.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data());
      setNotifs(data);
    });
    return () => {
      unsub();
    };
  }, []);

  const handleMenu = () => {
    setMenuOpen(false);
  };

  const markRead = (notifID) => {
    firebase.markRead(notifID);
  };
  const notifClick = (url, uid, read) => {
    if (!read) {
      firebase.markRead(uid);
      history.push(url);
    }
    if (read) {
      history.push(url);
    }
  };
  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.light,
        display: "flex",
        flexDirection: "row",
        minHeight: "100vh",
      })}
    >
      <React.Fragment>
        {authUser ? (
          <div css={sideNav}>
            <div css={{ padding: "20px 0" }}>
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo-white.svg?alt=media&token=8d014c04-599a-40e7-95b1-23090cab3430"
                  alt=""
                  css={{ height: 50 }}
                />
              </Link>
            </div>
            <AuthNavigation />
            <Link
              to="/gigs"
              onClick={handleMenu}
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
                { textAlign: "center" },
              ]}
            >
              Browse Gigs
            </Link>
          </div>
        ) : null}
        <div
          css={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {authUser ? (
            <div css={topBarContainer}>
              <Topbar bgColor="#F3F3F3" pageTitle="Account Settings" />
              <Bottombar />
            </div>
          ) : null}
          <div
            css={{
              flex: 1,
              display: "flex",
              background: "#FAFAFA",
            }}
          >
            <div
              css={(theme) => ({
                width: "100%",
                flex: 1,
                marginBottom: 65,
                [mq(bp.large)]: {
                  padding: "0 20px 0 60px",
                  margin: 0,
                  maxWidth: theme.layout.max_width - 80,
                },
              })}
            >
              <div
                css={{
                  padding: 20,
                  [mq(bp.small)]: {
                    width: "90vw",
                    margin: "auto",
                  },
                  [mq(bp.large)]: { padding: "60px 0", width: "auto" },
                }}
              >
                <h1>Your Notifications</h1>
                <p>These are your notifications</p>
                <div
                  css={{
                    marginTop: "60px",
                  }}
                >
                  {notifs.length > 0 ? (
                    notifs.map((notif, i) => (
                      <div
                        key={i}
                        css={{
                          backgroundColor: notif.read ? "white" : "#D9E0E9",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          css={{
                            display: "flex",
                            padding: "0 45px",
                          }}
                        >
                          <p>{notif.message}</p>
                          <button
                            type="button"
                            css={{
                              margin: 0,
                              fontSize: 14,
                              width: "fit-content",
                              textAlign: "left",
                              border: "none",
                              padding: "12px 0 12px 12px",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() =>
                              notifClick(notif.url, notif.uid, notif.read)
                            }
                          >
                            View Details
                          </button>
                        </div>
                        <button
                          type="button"
                          css={{
                            padding: 12,
                            display: "block",
                            border: "none",
                            margin: 0,
                            cursor: "pointer",
                          }}
                          disabled={notif.read}
                          onClick={() => markRead(notif.uid)}
                        >
                          <NotifIcon
                            css={{
                              display: "block",
                              visibility: notif.read ? "hidden" : "visible",
                            }}
                          />
                        </button>
                      </div>
                    ))
                  ) : (
                    <div css={{ padding: 12 }}>
                      <p css={{ margin: 0 }}>No Notifications</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default withFirebase(NotificationsPage);
