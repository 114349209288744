/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { bp, mq } from '../../constants/mediaQueries';

const Footer = () => {
  return (
    <div>
      <div
        css={(theme) => ({
          backgroundColor: theme.colors.light,
          padding: '25px 64px',
          display: 'flex',
          justifyContent: 'center',
          [mq(bp.large)]: {
            padding: '56px 64px',
          },
        })}
      >
        <div
          css={{
            maxWidth: 80,
            [mq(bp.large)]: {
              maxWidth: 150,
            },
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo.svg?alt=media&token=340a89f5-cce5-4778-9d0b-9e59daa354d7"
            alt=""
            css={{ width: '100%' }}
          />
        </div>
      </div>
      <div
        css={(theme) => ({
          backgroundColor: theme.colors.primary,
          padding: '20px 64px',
        })}
      >
        <div
          css={(theme) => ({
            maxWidth: theme.layout.max_width,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            [mq(bp.large)]: {
              flexDirection: 'row',
            },
            '&>p': {
              color: theme.buttons.type_light,
              margin: '10px 0',
              [mq(bp.large)]: {
                flexDirection: 'row',
                margin: '0 20px',
              },
            },
          })}
        >
          <p>© 2020 WorkBriefly Inc.</p>
          <p>
            <a
              href="https://www.workbriefly.com/terms-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
          </p>
          <p>
            <a
              href="https://www.workbriefly.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
