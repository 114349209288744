/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext, useState } from 'react';
import { AuthUserContext } from 'library/Session';
import * as ROUTES from 'constants/routes';
import AuthNavigation from 'components/AuthNavigation';
import { Route, Switch, Link, useLocation } from 'react-router-dom';
import Topbar from 'components/Topbar';
// import Bottombar from 'components/Bottombar';
import { button, sideNav, tabs, topBarContainer } from 'theme/styleBlocks';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { bp, mq } from '../../constants/mediaQueries';

const NoMatch = () => {
    const { authUser } = useContext(AuthUserContext);
    const [menuOpen, setMenuOpen] = useState();
    const location = useLocation();
    const handleMenu = () => {
      setMenuOpen(false);
    };
    const refreshPage = () => {
      if (location.pathname === '/gigs') {
        window.location.reload();
      }};
  return (
    <div>
      {authUser ? (
        <div> 
          <div css={topBarContainer}>
          <Topbar bgColor="#F3F3F3" pageTitle="No Found" />
          </div>        
          <div style={{position: "absolute"}} css={sideNav}>
            <div css={{ padding: '20px 0' }}>
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo-white.svg?alt=media&token=8d014c04-599a-40e7-95b1-23090cab3430"
                  alt=""
                  css={{ height: 50 }}
                />
              </Link>
            </div>
            <AuthNavigation />
            <Link
              to="/create"
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
                { textAlign: 'center' },
              ]}
              onClick={refreshPage}
            >
              Create a Gig
            </Link>
          </div>
          <div css={{
          position: 'absolute',
          top: "15%",
          textAlign: "center",
          left: "10%",
          margin: 'auto',
          width: '80%',
          display: 'Flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          [mq(bp.xsmall)]: {
            marginBottom: 0,
            width: '50%',
            top: '30%',
            left: '33%',
          },
          }}>
          <h2 css={{
              fontFamily:"Roboto",
              margin:"5px",
              fontSize: "38px",
          }}>Oops, that page can't be found!</h2>
          <p css={{
              fontFamily:"Roboto",
              textAlign: "center",
              margin:"5px"

          }}>It looks like you stumbled onto a page that can't be found (Error: 404).
          <br /> Let us help you get back to the action.
            </p>
          
          <p>
            <Link
                    to="/create"
                    onClick={handleMenu}
                    css={(theme) => [
                      button(
                        theme.buttons.type_dark,
                        theme.buttons.bg2,
                        theme.buttons.hover2
                      ),
                      { textAlign: 'center' },
                    ]}
                  >
                    Create a Gig
              </Link>
          </p>

            <p>
            <Link
              to={ROUTES.PROFILE}
              css={{
                marginBottom: '10px',
                fontWeight: 500,
                [mq(bp.small)]: {
                  fontSize: 20,
                },
                '&:hover': {
                  opacity: '1!important',
                },
              }}
            >
              Back
            </Link>
            </p>
        </div>
        </div>
      ) :                     <div css={{
        position: 'absolute',
        top: "25%",
        textAlign: "center",
        left: "10%",
        margin: 'auto',
        width: '80%',
        display: 'Flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        [mq(bp.xsmall)]: {
          marginBottom: 0,
          width: '100%',
          top: '30%',
          left: '0%',
        },
        }}>
            <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo.svg?alt=media&token=340a89f5-cce5-4778-9d0b-9e59daa354d7"
                  alt=""
                  css={{ height: 50 }}
                />
              </Link>
        <h2 css={{
          fontSize: "38px",
            fontFamily:"Roboto",
            margin:"5px"
        }}>Oops, that page can't be found!</h2>
        <p css={{
            fontFamily:"Roboto",
            textAlign: "center",
            margin:"5px"
        }}>It looks like you stumbled onto a page that can't be found (Error: 404).
        <br /> Let us help you get back to the action.
          </p>
          <Link
              to={ROUTES.PROFILE}
              css={{
                marginBottom: '10px',
                fontWeight: 500,
                [mq(bp.small)]: {
                  fontSize: 20,
                },
                '&:hover': {
                  opacity: '1!important',
                },
              }}
            >
              Back
            </Link>
      </div>}
    </div>
    )
};

export default NoMatch;
