/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { withFirebase } from 'library/Firebase';
import { conditions } from 'library/Session';
import * as ROUTES from 'constants/routes';
import AuthUserContext from './context';

const withAuthorization = (Component) => {
  const WithAuthorization = (props) => {
    const { authUser, update } = useContext(AuthUserContext);
    const [loading, setLoading] = useState(true);
    const { path } = useRouteMatch();
    const history = useHistory();
    useEffect(() => {
      const listener = props.firebase.onAuthUserListener(
        (authUser) => {
          const { redirect, redirectTo } = conditions.redirectConditions(
            authUser,
            path
          );
          if (redirect) {
            setLoading(false);
            return history.push(redirectTo);
          }
          if (!redirect) {
            return setLoading(false);
          }
        },
        () => {
          setLoading(false);
          return history.push(ROUTES.LOGIN);
        }
      );
      return () => {
        return listener();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.firebase]);
    return authUser && !loading && !update ? (
      <React.Fragment>
        <Component {...props} />
      </React.Fragment>
    ) : (
      <div css={{ flex: 1 }}>loading...</div>
    );
  };
  return withFirebase(WithAuthorization);
};
export default withAuthorization;
