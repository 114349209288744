/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from 'components/Logout';
import { navButton, burgerMenu } from 'theme/styleBlocks';
import { slide as Menu } from 'react-burger-menu';
import { bp, mq } from 'constants/mediaQueries';

const Navigation = (props) => {
  const [menuOpen, setMenuOpen] = useState();
  const handleMenu = () => {
    setMenuOpen(false);
  };
  return (
    <div
      css={(theme) => ({
        maxWidth: theme.layout.max_width,
        margin: '0 auto',
        padding: '20px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.colors.light,
        [mq(bp.small)]: {
          padding: '20px 64px',
        },
      })}
    >
      <div
        css={{
          maxWidth: 100,
          width: 80,
          [mq(bp.large)]: {
            width: 100,
          },
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo.svg?alt=media&token=340a89f5-cce5-4778-9d0b-9e59daa354d7"
          alt=""
          css={{
            width: '100%',
          }}
        />
      </div>
      {props.authUser && !props.authUser.setupComplete ? (
        <div>
          <div
            css={{
              display: 'none',
              [mq(bp.large)]: {
                display: 'inline',
              },
            }}
          >
            <LogoutButton />
          </div>
          <div css={burgerMenu}>
            <Menu right isOpen={menuOpen}>
              <LogoutButton />
            </Menu>
          </div>
        </div>
      ) : (
        <div>
          <div
            css={{
              display: 'none',
              alignItems: 'center',
              [mq(bp.large)]: {
                display: 'flex',
              },
            }}
          >
            <Link
              to="/login"
              css={(theme) => ({
                color: theme.buttons.type_dark,
                marginRight: 30,
                fontWeight: 500,
              })}
            >
              Login
            </Link>
            <Link
              to="/signup/agreement"
              css={(theme) =>
                navButton(
                  theme.buttons.type_light,
                  theme.buttons.bg1,
                  theme.buttons.hover1
                )
              }
            >
              Sign Up Free
            </Link>
          </div>
          <div css={burgerMenu}>
            <Menu right isOpen={menuOpen}>
              <Link to="/login" onClick={handleMenu}>
                Login
              </Link>
              <Link to="/signup/agreement" onClick={handleMenu}>
                Sign Up Free
              </Link>
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navigation;
