import { bp, mq } from "../constants/mediaQueries";

export const centerLayout = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  padding: "80px 20px",
};

export const formInput = {
  marginBottom: 15,
  position: "relative",
  label: {
    display: "block",
    marginBottom: 4,
    color: "#272727",
    fontWeight: "600",
    fontSize: 14,
    [mq(bp.small)]: {
      fontSize: "16px!important",
    },
  },
  "input,textArea": {
    width: "100%",
    padding: 13,
    display: "block",
    border: "1px solid transparent",
    borderRadius: "5px",
    backgroundColor: "rgba(58, 177, 202, 0.1)",
    fontSize: 14,
    outline: "none",
    "&:focus": {
      border: "1px solid #002E6D",
    },
    [mq(bp.large)]: {
      fontSize: 16,
    },
  },
};
export const formLabel = {
  display: "block",
  marginBottom: 4,
  color: "#272727",
  fontWeight: "600",
  fontSize: 14,
  [mq(bp.small)]: {
    fontSize: 16,
  },
};
export const navButton = (color, backgroundColor, hoverColor) => ({
  minWidth: 180,
  boxSizing: "border-box",
  display: "inline-block",
  textAlign: "center",
  color,
  backgroundColor,
  padding: "16px 24px",
  borderRadius: "4px",
  fontWeight: 700,
  width: "100%",
  fontSize: 14,
  lineHeight: 1,
  border: "none",
  [mq(bp.small)]: {
    fontSize: 16,
  },
  [mq(bp.large)]: {
    width: "auto",
  },
  "&:hover:not([disabled])": { opacity: 1, backgroundColor: hoverColor },
});
export const button = (color, backgroundColor, hoverColor) => ({
  display: "inline-block",
  minWidth: 156,
  width: "100%",
  padding: "14px 20px",
  borderRadius: "5px",
  border: "none",
  fontWeight: 500,
  cursor: "pointer",
  color,
  backgroundColor,
  fontSize: 14,
  [mq(bp.small)]: {
    fontSize: 16,
  },
  [mq(bp.large)]: {
    width: "auto",
  },
  "&:disabled": {
    backgroundColor: "#d6d6d6",
    color: "#a2a2a2",
  },
  "&:hover:not([disabled])": { opacity: 1, backgroundColor: hoverColor },
});
export const uploadButton = (color, backgroundColor, hoverColor) => ({
  display: "inline-block",
  minWidth: 156,
  padding: "14px 20px",
  borderRadius: "5px",
  border: "none",
  fontWeight: 500,
  cursor: "pointer",
  color,
  backgroundColor,
  width: "100%",
  fontSize: 14,
  [mq(bp.small)]: {
    fontSize: 16,
  },
  "&:disabled": {
    backgroundColor: "#d6d6d6",
    color: "#a2a2a2",
  },
  "&:hover:not([disabled])": { opacity: 1, backgroundColor: hoverColor },
});
export const goBackButton = {
  border: "none",
  color: "#002E6D",
  display: "flex",
  alignItems: "center",
  padding: 0,
  fontWeight: 500,
  cursor: "pointer",
  fontSize: 14,
  [mq(bp.small)]: {
    fontSize: 16,
  },
  [mq(bp.large)]: {
    marginRight: 30,
  },
};
export const addButton = {
  border: "1px solid",
  borderColor: "#002E6D",
  borderRadius: "4px",
  padding: "8px 14px",
  color: "#002E6D",
  fontSize: 14,
  fontWeight: 500,
  cursor: "pointer",
  [mq(bp.small)]: {
    fontSize: 16,
  },
  "&:hover:not([disabled])": {
    opacity: 1,
    backgroundColor: "#002E6D",
    color: "#fff",
  },
  "&:focus": {
    outline: "none",
  },
};
export const centerBox = {
  padding: "32px 10px",
  border: "1px solid #969696",
  backgroundColor: "#FFFFFF",
  borderRadius: "4px",
  [mq(bp.small)]: {
    width: 565,
    padding: "32px 40px",
  },
};
export const leftBox = {
  padding: "20px",
  border: "1px solid #969696",
  backgroundColor: "#FFFFFF",
  borderRadius: "4px",
  marginBottom: 35,
  [mq(bp.large)]: {
    padding: "32px 40px",
    marginBottom: 0,
  },
};
export const signUpRight = () => ({});
export const setupButton = (bg, color, hoverColor) => ({
  display: "flex",
  alignItems: "center",
  textAlign: "left",
  background: bg,
  padding: "14px 7vw",
  borderRadius: "5px",
  marginBottom: 8,
  color,
  fontWeight: 500,
  width: "100%",
  boxSizing: "border-box",
  [mq(bp.small)]: {
    padding: "13px 32px",
  },
  "&:hover:not([disabled])": { opacity: 1, backgroundColor: hoverColor },
});
export const routeContainer = () => ({
  padding: 20,
  [mq(bp.large)]: {
    padding: 0,
  },
});
export const pageTitle = () => ({
  textAlign: "left",
  marginBottom: 30,
  marginTop: 0,
  [mq(bp.small)]: {
    textAlign: "center",
  },
  [mq(bp.large)]: {
    marginBottom: 60,
  },
  h1: {
    color: "#272727",
    fontWeight: "bold",
    fontSize: "9vw",
    marginTop: 0,
    [mq(bp.small)]: {
      fontSize: "40px",
    },
  },
  p: {
    textAlign: "center",
    margin: "0 auto",
  },
});
export const buttonContainer = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column-reverse",
  padding: 0,
  [mq(bp.large)]: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  button: {
    marginBottom: 15,
  },
  a: {
    marginBottom: 15,
  },
});
export const listRight = () => ({
  paddingInlineStart: 20,
  li: {
    margin: "10px 0",
    lineHeight: "24px",
  },
});
export const burgerMenu = () => ({
  display: "inline-block",
  [mq(bp.large)]: {
    display: "none",
  },
  ".bm-burger-button": {
    position: "absolute",
    width: 36,
    height: 25,
    top: 34,
    right: 24,
  },
  ".bm-burger-bars": {
    background: "#002E6D",
    height: "18%",
  },
  ".bm-cross-button": {
    height: 24,
    width: 24,
  },
  ".bm-cross": {
    background: "#fff",
  },
  ".bm-menu-wrap": {
    position: "absolute",
    height: "100%",
    top: 0,
  },
  ".bm-menu": {
    background: "#002E6D",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  ".bm-morph-shape": {
    fill: "#002E6D",
  },
  ".bm-item-list": {
    color: "#fff",
    padding: "0.8em",
  },
  ".bm-item": {
    display: "inline-block",
    margin: "20px 0",
    fontSize: 20,
    fontWeight: 500,
    "&:focus": {
      outline: "none",
    },
  },
  ".bm-overlay": {
    background: "rgba(0, 0, 0, 0.3)",
  },
});
export const savedContainer = () => ({
  textAlign: "center",
  padding: "32px 0",
  [mq(bp.large)]: { padding: "32px 16px" },
});
export const popupContainer = () => ({
  background: "#fff",
  padding: 35,
  overflowY: "auto",
  height: "80vh",
  width: "95vw",
  [mq(bp.small)]: {
    width: 548,
  },
});
export const innerDesc = () => ({
  fontWeight: 500,
  marginTop: 0,
  color: "#272727",
});
export const completeText = {
  fontWeight: 500,
  marginTop: 0,
  fontSize: 18,
  color: " #272727",
};
export const passButton = () => ({
  position: "absolute",
  top: 37,
  right: 20,
  display: "flex",
  alignItems: "center",
  border: "none",
  outline: "none",
  padding: 0,
  [mq(bp.small)]: {
    top: 42,
  },
  span: {
    marginLeft: 10,
  },
});
export const completedIcon = () => ({
  height: 80,
  width: 80,
  marginBottom: 10,
  [mq(bp.small)]: {
    width: 96,
    height: 96,
  },
});
export const editButton = () => ({
  border: "none",
  cursor: "pointer",
  color: "#002E6D",
  padding: 0,
  fontSize: 16,
  fontWeight: 500,
  "&:focus": { outline: "none" },
});
export const twoColumn = () => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridColumnGap: 40,
  [mq(bp.large)]: {
    gridTemplateColumns: "1fr 1fr",
    alignItems: "flex-start",
  },
});
export const settingTitle = () => ({
  margin: 0,
  fontSize: 16,
  [mq(bp.large)]: {
    fontSize: 24,
  },
});
export const settingContent = () => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  [mq(bp.small)]: {
    justifyContent: "center",
  },
  [mq(bp.large)]: {
    justifyContent: "flex-start",
  },
  p: {
    margin: "10px 0px 0px 40px",
    color: "#393939",
    fontWeight: 500,
    fontSize: 16,
    [mq(bp.large)]: {
      margin: 0,
    },
  },
});
export const customRadio = () => ({
  userSelect: "none",
  position: "relative",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  input: {
    opacity: 0,
    marginRight: 14,
    cursor: "pointer",
  },
  ":hover ~ span": {
    background: "#002E6D",
  },
  "input:checked ~ span": {
    background: "#002E6D",
  },
  "input:checked ~ span::after": {
    display: "block",
  },
  span: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "20px",
    width: "20px",
    backgroundColor: "#eee",
    borderRadius: "50%",
  },
  "span::after": {
    content: '""',
    position: "absolute",
    display: "none",
    top: 5,
    left: 5,
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: "white",
  },
});
export const customCheck = () => ({
  display: "flex",
  alignItems: "flex-start",
  position: "relative",
  cursor: "pointer",
  fontSize: 14,
  [mq(bp.small)]: {
    fontSize: 16,
  },
  label: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  input: {
    position: "absolute",
    opacity: 0,
    cursor: "pointer",
    height: 0,
    width: 0,
  },
  "input:checked ~ span": {
    background: "#002E6D",
  },
  "input:disabled ~ span::after": {
    display: "block",
    content: "''",
    position: "absolute",
    width: 4,
    height: 9,
    border: "solid #979797",
    borderWidth: "0 2px 2px 0",
    bottom: 4,
    WebkitTransform: "rotate(45deg)",
    MsTransform: "rotate(45deg)",
    transform: "rotate(45deg)",
  },
  "input:checked ~ span::after": {
    display: "block",
    content: "''",
    position: "absolute",
    width: 4,
    height: 9,
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    bottom: 4,
    WebkitTransform: "rotate(45deg)",
    MsTransform: "rotate(45deg)",
    transform: "rotate(45deg)",
  },
  "input:disabled ~ span": {
    background: "#D8D8D8",
  },
  span: {
    height: 20,
    width: 20,
    borderRadius: 2,
    border: "1px solid #979797",
    marginRight: 12,
    minWidth: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
});
export const sideNav = () => ({
  backgroundColor: "#002E6D",
  display: "none",
  flexDirection: "column",
  alignItems: "center",
  width: 250,
  minWidth: 250,
  position: "sticky",
  top: 0,
  zIndex: 99,
  height: "100vh",
  [mq(bp.large)]: {
    display: "flex",
  },
});
export const tabs = () => ({
  ".react-tabs__tab-list": {
    backgroundColor: "#C3E7EF",
    borderBottom: "none",
    textAlign: "center",
  },
  ".react-tabs__tab": {
    border: "none",
    color: "#000",
    padding: "20px 0",
    textAlign: "center",
    width: "48%",
    [mq(bp.large)]: {
      maxWidth: 460,
    },
    p: {
      lineHeight: 1.5,
      display: "inline-block",
      margin: 0,
      fontWeight: 500,
      fontSize: 18,
    },
  },
  ".react-tabs__tab--selected": {
    p: {
      "&:after": {
        backgroundColor: "#000",
        borderRadius: "5px",
        content: '""',
        display: "block",
        height: 2,
        [mq(bp.large)]: {
          height: 3,
        },
      },
    },
  },
  ".react-tabs__tab-panel": {
    margin: "0 auto",
    padding: "0 20px",
    marginTop: 50,
    [mq(bp.large)]: {
      padding: 0,
      marginTop: 90,
      maxWidth: 920,
    },
    h2: {
      fontSize: 20,
    },
  },
});
export const blueBox = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#002E6D",
  borderRadius: "4px",
  padding: "12px 12px",
  color: "#FFFFFF",
  marginBottom: 8,
  p: {
    color: "#fff",
    margin: "0 10px 0 0",
    fontSize: 16,
  },
});
export const gigContainer = () => ({
  marginBottom: 70,
  [mq(bp.small)]: {
    padding: "0 20px",
    margin: "70px auto",
  },
  [mq(bp.large)]: {
    padding: 0,
    width: "auto",
    margin: 0,
  },
});
export const tipBox = () => ({
  width: 230,
  border: "1px solid #5E5E5E",
  padding: "10px",
  color: "#5E5E5E",
  position: "relative",
  fontSize: 10,
  backgroundColor: "#fff",
  minWidth: 250,
  "&::before": {
    content: "''",
    width: 0,
    height: 0,
    position: "absolute",
    borderLeft: "10px solid transparent",
    borderRight: "12px solid #5E5E5E",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    left: "-22px",
    top: "50%",
    transform: "translate(0, -50%)",
  },
  "&::after": {
    content: "''",
    width: 0,
    height: 0,
    position: "absolute",
    borderLeft: "8px solid transparent",
    borderRight: "12px solid #fff",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    left: "-18px",
    top: "50%",
    transform: "translate(0, -50%)",
  },
});
export const topBarContainer = () => ({
  marginBottom: 0,
  position: "sticky",
  top: 0,
  zIndex: 99,
});
export const stepTitle = () => ({
  marginTop: 0,
  marginBottom: 10,
  fontSize: 30,
});
export const gigBox = {
  padding: 20,
  border: "1px solid #969696",
  backgroundColor: "#FFFFFF",
  borderRadius: "4px",
  [mq(bp.large)]: {
    padding: 20,
    maxWidth: 790,
    width: "100%",
  },
};
