import { bp, mq } from "constants/mediaQueries";

export const modalContainer = {
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  [mq(bp.large)]: {
    marginLeft: 50,
  },
  width: 600,
  maxWidth: "100%",
  boxShadow: "1px 2px 9px rgba(0, 0, 0, 0.5)",
  borderRadius: 3,
  backgroundColor: "white",
  outline: "none",
  padding: 20,
  ".closeBtn": {
    border: "none",
    fontSize: 18,
    textAlign: "right",
    marginBottom: 10,
  },
  p: {
    fontFamily: "Roboto",
    paddingLeft: 10,
    margin: "10px 0 10px 0",
  },
  h2: {
    margin: 0,
  },
  hr: {
    margin: "26px 10px",
  },
  button: {
    padding: 0,
  },
};

export const reportBody = {
  ".title": {
    padding: "0 10px 0 10px",
  },
  ".header": {
    marginBottom: 40,
  },
  ".boldFont": {
    fontWeight: "bold",
    padding: "0 10px 0 5px",
  },
  ".optionBtn": {
    border: "none",
    textAlign: "left",
    color: "#002E6D",
    fontWeight: 600,
    fontSize: 16,
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      borderTop: "1px solid #4a4f57",
      borderBottom: "1px solid #4a4f57",
      backgroundColor: "#ECECEC",
      opacity: 1,
    },
  },
  ".certOption": {
    display: "flex",
    alignItems: "center",
    border: "none",
    textAlign: "left",
    fontWeight: 600,
    fontSize: 16,
    cursor: "pointer",
    margin: "10px 0",
  },
  ".checkBox": {
    zoom: 1.5,
    margin: "0 10px 0 6px",
    background: "#002E6D",
  },
  ".firstOption": {
    border: "none",
    textAlign: "left",
    fontWeight: 600,
    fontSize: 16,
    padding: "0 10px 0 10px",
  },
  ".backBtn": {
    border: "none",
    textAlign: "left",
    fontWeight: 600,
    fontSize: 16,
    cursor: "pointer",
    marginTop: 24,
    padding: 10,
  },
  ".smallFont": {
    fontSize: 12,
    fontWeight: 500,
  },
  ".selectOption": {
    margin: 0,
  },
  ".btnContainer": {
    display: "flex",
    justifyContent: "flex-end",
  },
  ".confirmBtn": {
    alignSelf: "flex-end",
    height: 40,
    marginLeft: 25,
    [mq(bp.small)]: {
      maxWidth: "100px",
    },
  },
  ".continueContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ".greenTick": {
    transform: "scale(2)",
    marginRight: 30,
  },
  ".message": {
    margin: "auto",
  },
  ".continueBtn": {
    height: 40,
    margin: "30px auto 10px auto",
  },
};
