/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';
import {
  leftBox,
  button,
  formInput,
  twoColumn,
  goBackButton,
  buttonContainer,
  innerDesc,
  completedIcon,
  completeText,
} from 'theme/styleBlocks';
import { useForm, Controller } from 'react-hook-form';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { Ring } from 'react-spinners-css';
import { bp, mq } from 'constants/mediaQueries';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';

const IdentityVerification = ({
  phoneNumber,
  phoneCode,
  phoneCodeSent,
  onPhoneChange,
  onChange,
  twilioCreate,
  twilioCheck,
  phoneVerified,
  verificationStep,
  sinNumber,
  sinCheck,
  nextStep,
  message,
  sending,
  setVerificationStep,
  step,
  setState,
  setStep,
}) => {
  const [countdown, setCountdown] = useState(false);
  const [time, setTime] = useState(60);
  const [channel, setChannel] = useState('');
  const [textLoading, setTextLoading] = useState(false);
  const [callLoading, setCallLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [formattedPhoneNum, setFormattedPhoneNum] = useState('');
  const {
    getValues,
    register,
    setError,
    errors,
    clearErrors,
    unregister,
    control,
  } = useForm();
  useEffect(() => {
    // if (authUser) {
    //   return history.push('/dashboard');
    // }
    if (step !== 2) {
      history.push('/signup/agreement');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (verificationStep === 2) {
      const phoneNum = parsePhoneNumberFromString(`+${phoneNumber}`);
      const nationalNum = phoneNum.formatNational();
      console.log(nationalNum);
      setFormattedPhoneNum(nationalNum);
      setCountdown(true);
    }
  }, [verificationStep]);
  // useEffect(() => {
  //   register({ name: 'phoneNumber' });
  //   return () => unregister('phoneNumber');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [register]);
  useEffect(() => {
    let count = 60;
    const interval = setInterval(() => {
      if (!countdown) {
        clearInterval(interval);
      }
      setTime(count--);
      if (count < 0) {
        clearInterval(interval);
        setCountdown(false);
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);
  const handleResend = () => {
    setTextLoading(true);
    setCallLoading(true);
    setLoading(true);
    const phoneNum = getValues('phoneNumber');
    twilioCreate(channel, phoneNum).then((result) => {
      if (result.success) {
        setTime(60);
        setCountdown(true);
      }
      if (!result.success) {
        setError('phoneCode', '', result.message);
      }
      setTextLoading(false);
      setCallLoading(false);
      setLoading(false);
    });
  };
  const handleTwilioCreate = (channelType) => {
    if (channelType === 'call') {
      setCallLoading(true);
    } else {
      setTextLoading(true);
    }
    setLoading(true);
    const phoneNum = getValues('phoneNumber');
    setState((prev) => ({ ...prev, phoneNumber: phoneNum }));
    setChannel(channelType);
    twilioCreate(channelType, phoneNum).then((result) => {
      if (!result.success) {
        setError('phoneNumber', '', result.message);
      }
      setCallLoading(false);
      setTextLoading(false);
      setLoading(false);
    });
  };
  const goBack = () => {
    setState((prev) => ({ ...prev, message: null }));
    history.goBack();
  };
  const goBackStep = (num) => {
    setState((prev) => ({ ...prev, message: null }));
    setVerificationStep(num);
  };
  const onTwilioCheck = () => {
    const code = getValues('phoneCode');
    setCallLoading(true);
    setTextLoading(true);
    setLoading(true);
    if (code.length !== 6) {
      setError(
        'phoneCode',
        '',
        'Please enter a valid 6 digit verification code'
      );
      return [setCallLoading(false), setTextLoading(false), setLoading(false)];
    }
    twilioCheck(code).then((result) => {
      if (!result.success) {
        setError('phoneCode', '', result.message);
      }
      setCallLoading(false);
      setTextLoading(false);
      setLoading(false);
    });
  };
  const verificationSteps = () => {
    switch (verificationStep) {
      case 1:
        return (
          <div>
            <p css={innerDesc}>
              Please enter your phone number so that we can send you a one-time
              verification code in order to confirm it’s really you.
            </p>
            <div
              css={{
                marginBottom: 20,
                '.form-control': {
                  width: '100%',
                  backgroundColor: 'rgba(58, 177, 202, 0.1)',
                  padding: '10px 0 10px 50px',
                  borderColor: errors.phoneNumber && 'red !important',
                  display: 'block',
                  borderRadius: '4px',
                  fontSize: 14,
                  outline: 'none',
                  height: 'auto',
                  border: '1px solid transparent',
                  '&:focus': {
                    border: '1px solid #002E6D',
                  },
                  [mq(bp.small)]: {
                    fontSize: 16,
                  },
                },
                '.flag-dropdown': {
                  border: 'none',
                  backgroundColor: 'unset',
                  '&:hover .selected-flag': {
                    background: 'rgba(58, 177, 202, 0.1)',
                  },
                  '&.open .selected-flag': {
                    background: 'rgba(58, 177, 202, 0.1)',
                  },
                },
                '.selected-flag': {
                  background: 'none',
                  border: 'none',
                  padding: '0 0 0 11px',
                  width: '46px',
                  '&:focus': {
                    background: 'rgba(58, 177, 202, 0.1)',
                  },
                  '&:hover': {
                    background: 'rgba(58, 177, 202, 0.1)',
                  },
                },
              }}
            >
              <Controller
                as={PhoneInput}
                name='phoneNumber'
                placeholder='Enter phone number'
                defaultValue=''
                inputmode='numeric'
                onChange={(phone) => {
                  clearErrors('phoneNumber');
                  return phone;
                }}
                country='ca'
                disableDropdown
                control={control}
                inputClass='phoneInput'
                rules={{
                  required: true,
                }}
                onlyCountries={['ca', 'us', 'jp', 'co']}
              />
              <FormFieldErrorMessage error={errors.phoneNumber} />
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column-reverse',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                [mq(bp.large)]: {
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gridColumnGap: 15,
                },
                button: {
                  marginBottom: 10,
                },
              }}
            >
              <button
                type='button'
                onClick={goBack}
                css={[
                  goBackButton,
                  {
                    justifyContent: 'flex-end',
                    [mq(bp.large)]: {
                      marginRight: 15,
                    },
                  },
                ]}
              >
                Back
              </button>
              <div
                css={{
                  position: 'relative',
                  width: '100%',
                  [mq(bp.large)]: {
                    width: 'auto',
                  },
                }}
              >
                <button
                  type='button'
                  onClick={() => handleTwilioCreate('call')}
                  disabled={!!sending}
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                >
                  {callLoading ? (
                    <Ring
                      color='#002E6D'
                      size='22'
                      css={{
                        width: 22,
                        height: 22,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  ) : (
                    'Call Me'
                  )}
                </button>
              </div>
              <div
                css={{
                  position: 'relative',
                  width: '100%',
                  [mq(bp.large)]: {
                    width: 'auto',
                  },
                }}
              >
                <button
                  type='button'
                  onClick={() => handleTwilioCreate('sms')}
                  disabled={!!sending}
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                >
                  {textLoading ? (
                    <Ring
                      color='#002E6D'
                      size='22'
                      css={{
                        width: 22,
                        height: 22,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  ) : (
                    'Text Me'
                  )}
                </button>
              </div>
            </div>
            <p
              css={{
                color: '#5E5E5E',
                fontStyle: 'italic',
                fontSize: 14,
              }}
            >
              *Message and data rates may apply
            </p>
          </div>
        );
      case 2: {
        return (
          <div>
            <p css={innerDesc}>
              We just sent a text message containing the verification code to{' '}
              {formattedPhoneNum}. Please enter this below.
            </p>
            <div css={formInput}>
              <input
                type='text'
                name='phoneCode'
                inputMode='numeric'
                onChange={() => clearErrors('phoneCode')}
                placeholder='Enter your one-time verification code'
                css={{ borderColor: errors.phoneCode && 'red !important ' }}
                ref={register}
              />
              <FormFieldErrorMessage error={errors.phoneCode} />
            </div>
            <div css={buttonContainer}>
              <button
                type='button'
                onClick={() => goBackStep(1)}
                css={goBackButton}
              >
                Back
              </button>
              <div
                css={{
                  position: 'relative',
                  width: '100%',
                  [mq(bp.large)]: {
                    width: 'auto',
                  },
                }}
              >
                <button
                  type='button'
                  onClick={onTwilioCheck}
                  disabled={loading}
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                >
                  {loading ? (
                    <Ring
                      color='#002E6D'
                      size='22'
                      css={{
                        width: 22,
                        height: 22,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  ) : (
                    'Verify'
                  )}
                </button>
              </div>
            </div>
            {countdown ? (
              <p
                css={{
                  color: '#747474',
                  fontSize: 14,
                }}
              >
                <span css={{ textDecoration: 'underline' }}>
                  Resend Verification Code
                </span>{' '}
                ({time}s remaining)
              </p>
            ) : (
              <button
                type='button'
                onClick={handleResend}
                css={{
                  color: '#747474',
                  fontSize: 14,
                  border: 'none',
                }}
              >
                Resend Verification Code
              </button>
            )}
            <p
              css={{
                color: '#5E5E5E',
                fontStyle: 'italic',
                fontSize: 14,
              }}
            >
              *If you have not received your verification code within a few
              minutes, you may try sending it again after 60 seconds.
            </p>
          </div>
        );
      }
      case 3:
        return (
          <div css={{ textAlign: 'center' }}>
            <GreenCheck css={completedIcon} />
            <p css={completeText}>
              Awesome! We have verified your phone number.
            </p>
            <button
              type='button'
              onClick={() => nextStep(3, 'business-details')}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              Continue
            </button>
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div>
      <h2>Phone Verification</h2>
      <div css={twoColumn}>
        <div>
          <div css={leftBox}>
            {verificationSteps()}
            {message ? <p>{message}</p> : null}
          </div>
        </div>
        <div>
          {verificationStep === 1 ||
          verificationStep === 2 ||
          verificationStep === 3 ? (
            <div>
              <h3 css={{ marginTop: 0 }}>Why do we need your phone number?</h3>
              <p>
                We need to verify that you are a real person and that you have a
                mobile device. We also need your contact number in case we need
                to reach you due to an urgent matter.
              </p>
              <p>Don’t worry, we keep your contact information secure.</p>
            </div>
          ) : (
            <div>
              <h3 css={{ marginTop: 0 }}>Why do we need your SIN number? </h3>
              <p>
                We just need to verify that you possess a valid SIN and can
                legally work in Canada. WorkBriefly does not store/record your
                SIN.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IdentityVerification;
