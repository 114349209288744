/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef, useEffect, useState } from 'react';
// import useScript from "hooks/useScript";
import {
  formInput,
  button,
  gigBox,
  buttonContainer,
  goBackButton,
  customCheck,
  customRadio,
  stepTitle,
} from 'theme/styleBlocks';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { bp, mq } from 'constants/mediaQueries';
import { useHistory } from 'react-router-dom';

const Step4 = (props) => {
  const [location, setLocation] = useState({});
  const locationRef = useRef(null);
  const {
    errors,
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
  } = useForm();
  const history = useHistory();
  useEffect(() => {
    setLocation(props.location);
    setValue('address1', props.location.formattedAddress);
    setValue('address2', props.location.address2);
    setValue('parking', props.parking);
    setValue('transit', props.transit);
  }, []);
  const parkingWatch = watch('parking');
  useEffect(() => {
    if (props.step !== 4) history.push('/create');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onAutoComplete = (locationData) => {
    setLocation(locationData);
    setValue('address1', locationData.formattedAddress);
    setValue('address2', locationData.address2);
  };
  useEffect(() => {
    let listener;
    if (props.scriptLoaded) {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 49.231312, lng: -122.911052 },
        zoom: 10,
        disableDefaultUI: true,
      });

      const autoComplete = new window.google.maps.places.Autocomplete(
        locationRef.current,
        { types: ['geocode'] }
      );

      autoComplete.bindTo('bounds', map);

      autoComplete.setFields([
        'formatted_address',
        'geometry',
        'name',
        'place_id',
        'address_components',
      ]);

      const marker = new window.google.maps.Marker({
        map,
        anchorPoint: new window.google.maps.Point(0, -29),
      });

      if (props.location.locationID) {
        const latlng = new window.google.maps.LatLng(
          props.location.lat,
          props.location.lng
        );
        map.setCenter(latlng);
        map.setZoom(14);
        marker.setPosition(latlng);
        marker.setVisible(true);
      }

      listener = window.google.maps.event.addListener(
        autoComplete,
        'place_changed',
        () => {
          marker.setVisible(false);
          const {
            formatted_address,
            name,
            geometry,
            place_id,
            address_components,
          } = autoComplete.getPlace();

          if (!geometry) return;
          if (geometry.viewport) {
            map.setCenter(geometry.location);
            map.setZoom(14);
          }
          marker.setPosition(geometry.location);
          marker.setVisible(true);

          const lat = geometry.location.lat();
          const lng = geometry.location.lng();
          const locality = address_components.filter(
            (comps) => comps.types[0] === 'locality'
          );
          const address2 = address_components.filter(
            (comps) => comps.types[0] === 'premise'
          );
          const addressParts = {};

          address_components.map((part) => {
            return (addressParts[part.types[0]] = part.long_name);
          });

          let streetAddress;
          if (addressParts.route) {
            streetAddress = addressParts.route;
          }
          if (addressParts.street_number) {
            streetAddress = addressParts.street_number;
          }
          if (addressParts.street_number && addressParts.route) {
            streetAddress = `${addressParts.street_number} ${addressParts.route}`;
          }
          onAutoComplete({
            formattedAddress: formatted_address,
            address2: address2.length > 0 ? address2[0].long_name : '',
            address1: streetAddress,
            name,
            lat,
            lng,
            locationID: place_id,
            locality: locality[0].long_name || '',
          });
        }
      );
      return () => {
        listener.remove();
        autoComplete.unbindAll();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scriptLoaded]);
  const onSubmit = (data) => {
    props.setState((prev) => ({
      ...prev,
      parking: data.parking,
      transit: data.transit,
      location: {
        formattedAddress: location.formattedAddress,
        address1: data.address1,
        address2: data.address2,
        lat: location.lat,
        lng: location.lng,
        locationID: location.locationID,
        locality: location.locality,
      },
    }));
    props.nextStep(5);
  };
  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '60px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <p
        css={{
          fontWeight: 500,
          fontSize: 16,
          color: '#8A8A8A',
          marginBottom: 0,
          [mq(bp.large)]: {
            display: 'none',
          },
        }}
      >
        STEP 4 OF 5
      </p>
      <h2 css={stepTitle}>Location</h2>
      <div css={gigBox}>
        <p>
          Please provide location and transit details so that workers know where
          to report for the gig and are aware of the transit options available.
        </p>
        <div css={[formInput, { gridColumn: '1/3' }]}>
          <label htmlFor='address1'>Address 1</label>
          {/* <input
            type="text"
            name="address"
            id="location"
            ref={locationRef}
            value={props.location.address}
            onChange={(e) => props.onAddressChange(e)}
          /> */}
          <Controller
            as={
              <input
                ref={locationRef}
                css={{ borderColor: errors.address1 && 'red !important' }}
              />
            }
            control={control}
            type='text'
            name='address1'
            id='location'
            defaultValue=''
            rules={{ required: true }}
          />
        </div>
        <div css={[formInput, { gridColumn: '1/3' }]}>
          <label htmlFor='address2'>
            Address 2<span css={{ fontSize: 14 }}>(optional)</span>
          </label>
          <input
            type='text'
            name='address2'
            id='address2'
            placeholder='Unit/Suite No.'
            ref={register}
          />
        </div>
        <div>
          <div id='map' css={{ height: '400px', marginBottom: 15 }} />
        </div>
        <div css={{ marginBottom: 15 }}>
          <p
            css={{
              display: 'block',
              fontWeight: '600',
              color: '#272727',
              fontSize: 14,
              [mq(bp.small)]: {
                fontSize: 16,
              },
            }}
          >
            Travel Options
          </p>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridRowGap: 15,
              [mq(bp.small)]: {
                gridTemplateColumns: '1fr 1fr',
                gridColumnGap: 40,
              },
            }}
          >
            <div css={{ div: { marginBottom: 15 } }}>
              <div css={customCheck}>
                <label htmlFor='freeParking'>
                  <input
                    type='checkbox'
                    name='parking[0]'
                    id='freeParking'
                    ref={register}
                  />
                  <span
                    css={{
                      borderColor: errors.parking && 'red !important',
                    }}
                  />
                  Free Parking
                </label>
              </div>
              <div css={customCheck}>
                <label htmlFor='parkingNearby'>
                  <input
                    type='checkbox'
                    name='parking[1]'
                    id='parkingNearby'
                    ref={register}
                  />
                  <span
                    css={{
                      borderColor: errors.parking && 'red !important',
                    }}
                  />
                  Paid parking nearby
                </label>
              </div>
              <div css={customCheck}>
                <label htmlFor='noParking'>
                  <input
                    type='checkbox'
                    name='parking[2]'
                    id='noParking'
                    ref={register}
                  />
                  <span
                    css={{
                      borderColor: errors.parking && 'red !important',
                    }}
                  />
                  No parking available nearby
                </label>
              </div>
            </div>
            <div css={{ div: { marginBottom: 15 } }}>
              <div css={customRadio}>
                <label htmlFor='transitNearby'>
                  <input
                    type='radio'
                    name='transit'
                    id='transitNearby'
                    value={1}
                    ref={register({ required: true })}
                    defaultChecked
                  />
                  <span />
                  Transit options are nearby
                </label>
              </div>
              <div css={customRadio}>
                <label htmlFor='transitWalkAway'>
                  <input
                    type='radio'
                    name='transit'
                    id='transitWalkAway'
                    value={2}
                    ref={register({ required: true })}
                  />
                  <span />
                  Transit options are only a walk away
                </label>
              </div>
              <div css={customRadio}>
                <label htmlFor='noTransit'>
                  <input
                    type='radio'
                    name='transit'
                    id='noTransit'
                    value={3}
                    ref={register({ required: true })}
                  />
                  <span />
                  There are no transit options nearby
                </label>
              </div>
            </div>
          </div>
        </div>
        <div css={buttonContainer}>
          <button
            type='button'
            onClick={() => props.prevStep(3)}
            css={goBackButton}
          >
            Back
          </button>
          <button
            type='submit'
            onClick={handleSubmit(onSubmit)}
            css={(theme) =>
              button(
                theme.buttons.type_dark,
                theme.buttons.bg2,
                theme.buttons.hover2
              )
            }
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step4;
