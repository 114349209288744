/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { withFirebase } from "library/Firebase";
import { AuthUserContext } from "library/Session";
import GigItem from "components/GigItem";
import { bp, mq } from "constants/mediaQueries";
import moment from "moment";
import { gigContainer } from "theme/styleBlocks";
import qs from "query-string";
import { useLocation } from "react-router-dom";

const GigsCompleted = (props) => {
  const { authUser } = useContext(AuthUserContext);
  const [gigs, setGigs] = useState({});
  // const [gigsArray, setGigsArray] = useState([]);
  const [applicantData, setApplicantData] = useState({});
  // const [applicantDisplay, setApplicantDisplay] = useState(false);
  const location = useLocation();
  const [openedGig, setOpenedGig] = useState("");
  const parsed = qs.parse(location.search);
  const [scrollRef, setScrollRef] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(0);

  useEffect(() => {
    props.firebase
      .getTaxPercentage(authUser.province.value)
      .then((data) => setTaxPercentage(data));
  }, []);
  useEffect(() => {
    if (scrollRef) {
      window.scrollTo(0, scrollRef.offsetTop);
    }
  }, [scrollRef]);
  useEffect(() => {
    if (parsed.gig) {
      setOpenedGig(parsed.gig);
    }
  }, [parsed.gig]);
  // useEffect(() => {
  //   console.log(scrollRef.current);
  //   if (scrollTo && scrollRef.current) {
  //     setScrollTo(false);
  //   }
  // }, [scrollRef, scrollTo]);
  useEffect(() => {
    props.firebase
      .getGigs(authUser.completedGigs, "completed")
      .then((gigs) => {
        const categorized = gigs.reduce((acc, gig) => {
          const array = (acc[gig.formattedDate] = acc[gig.formattedDate] || []);

          array.push(gig);
          array.sort((a, b) => a.start - b.start);

          return acc;
        }, {});
        const sorted = {};
        Object.keys(categorized)
          .sort((a, b) => {
            return (
              moment(a, "MMMM DD, YYYY").toDate() -
              moment(b, "MMMM DD, YYYY").toDate()
            );
          })
          .forEach((key) => {
            sorted[key] = categorized[key];
          });
        return sorted;
      })
      .then((data) => setGigs(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const onApplicantClick = (applicantData, gigID) => {
  //   setApplicantDisplay(true);
  //   setApplicantData(applicantData);
  //   setOpenGigID(gigID);
  // };
  // const closeApplicantDisplay = () => {
  //   setApplicantDisplay(false);
  //   setApplicantData({});
  //   setOpenGigID('');
  // };
  const calcExp = () => {
    // array of objects [{
    //   industry: 'food and bev',
    //   years: 5
    // },{},{}]
    const exps = applicantData.exp.reduce((acc, curr) => {
      const currYears = curr.years / 1 + curr.months / 12;
      if (acc[curr.industry]) {
        acc[curr.industry] = currYears + acc[curr.industry];
      }
      if (!acc[curr.industry]) {
        acc[curr.industry] = currYears;
      }
      return acc;
    }, {});
    return (
      <div>
        {Object.keys(exps).map((industry, i) => {
          const years = Math.floor(exps[industry]);
          const months = Math.floor((exps[industry] - years) * 12);
          return (
            <div key={i}>
              <p>
                {industry} ({years} yrs - {months > 0 ? `${months} mo` : null} )
              </p>
            </div>
          );
        })}
      </div>
    );
  };
  // const bookForGig = (uid, gigID) => {
  //   props.firebase.bookForGig(uid, gigID);
  // };

  return (
    <div css={gigContainer}>
      <div css={{ [mq(bp.large)]: { marginBottom: 50, width: 750 } }}>
        <h1>Gigs Archived</h1>
        <p>
          These are the gigs which are no longer active/have been completed.
        </p>
      </div>
      <div>
        {Object.keys(gigs).map((gig, i) => {
          return (
            <div key={i}>
              <h2>{gig}</h2>
              {gigs[gig].map((gigData, i) => {
                return (
                  <div
                    key={gigData.gigID}
                    css={{ marginBottom: 20 }}
                    ref={(el) =>
                      gigData.gigID === openedGig ? setScrollRef(el) : null
                    }
                  >
                    <GigItem
                      gigData={gigData}
                      index={i}
                      opened={gigData.gigID === openedGig}
                      completed={
                        gigData.gigID === openedGig && !!parsed.completed
                      }
                      taxPercentage={taxPercentage}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {/* {applicantDisplay ? (
        <div
          css={{
            position: 'fixed',
            left: 250,
            top: 0,
            width: 'calc(100vw - 250px)',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.25)',
            display: 'flex',
          }}
        >
          <div
            css={{
              background: '#fff',
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              padding: 20,
            }}
          >
            <div>
              <img src={applicantData.profileImage} alt="" />
              <p>
                {applicantData.firstName} {applicantData.lastName}
              </p>
            </div>
          </div>
          <div
            css={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => bookForGig(applicantData.uid, openGigID)}
              css={(theme) =>
                button(theme.buttons.type_dark, theme.buttons.bg2, theme.buttons.hover2)
              }
            >
              Book for Gig
            </button>
          </div>
          <div
            css={{
              background: '#fff',
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              padding: 20,
            }}
          >
            <div css={{ textAlign: 'right' }}>
              <button
                onClick={closeApplicantDisplay}
                css={{ borderRadius: '50%', height: 24, width: 24, padding: 0 }}
              >
                X
              </button>
            </div>
            <div>
              <div>
                <h3>Experience</h3>
                {applicantData.exp ? calcExp() : <p>N/A</p>}
              </div>
              <hr />
              <div>
                <h3>Certificates</h3>
                {applicantData.certList ? (
                  <div>
                    {applicantData.certList.map((cert, i) => {
                      return (
                        <div key={i}>
                          <p>
                            {cert.name} ({cert.expiry ? cert.expiry : 'N/A'})
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default withFirebase(GigsCompleted);
