/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useContext } from 'react';
import { bp, mq } from 'constants/mediaQueries';
import { FirebaseContext } from 'library/Firebase';
import Profile from './Profile';
import NotAvailableModal from './NotAvailableModal';

const ApplicantCard = ({ gig, worker }) => {
  const firebase = useContext(FirebaseContext);
  const [profileOpen, setProfileOpen] = useState(false);
  const [notAvailableOpen, setNotAvailableOpen] = useState(false);
  const handleAccept = () => {
    firebase.acceptWorker(worker, gig.gigID).then(({ success }) => {
      setProfileOpen(false);
      if (!success) {
        return setNotAvailableOpen(true);
      }
    });
  };
  const handleDecline = () => {
    firebase.declineWorker(worker, gig).then(() => {
      setProfileOpen(false);
    });
  };
  const isVacant = gig.bookedWorkers.length < gig.vacancies;
  return (
    <div
      css={{
        marginBottom: 10,
        paddingBottom: 30,
        borderBottom: '1px solid #EFEFEF',

        [mq(bp.large)]: {
          marginBottom: 30,
          paddingBottom: 50,
        },
      }}
    >
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridRowGap: 15,
          gridColumnGap: 10,
          alignItems: 'center',
          background: worker.hasBeenDeclined && '#eaeaea',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '2fr 2fr 1fr 1fr',
            gridColumnGap: 20,
          },
        }}
      >
        <span
          css={{
            gridColumnStart: 1,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 600,
            color: '#393939',
            [mq(bp.large)]: {
              color: '#002E6D',
            },
          }}
        >
          <img
            src={worker.profileImage}
            alt=''
            css={{
              height: 30,
              width: 30,
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
          <div>
            <p css={{ color: worker.hasBeenDeclined && 'grey' }}>
              {worker.firstName} {worker.lastName}
            </p>
          </div>
        </span>
        <span>
          <button
            onClick={() => setProfileOpen(true)}
            css={{
              border: 0,
              color: '#002E6D',
              fontSize: 16,
              color: worker.hasBeenDeclined && 'grey',
            }}
            disabled={worker.hasBeenDeclined}
            type='button'
          >
            View Profile
          </button>
        </span>
        {isVacant ? (
          <React.Fragment>
            <span>
              <button
                onClick={handleAccept}
                css={{
                  border: 0,
                  background: worker.hasBeenDeclined ? 'darkgray' : '#FFCC41',
                  margin: 0,
                  padding: '8px 16px',
                  color: worker.hasBeenDeclined ? 'grey' : '#002E6D',
                  borderRadius: 5,
                  marginRight: 20,
                }}
                disabled={worker.hasBeenDeclined}
                type='button'
              >
                Accept
              </button>
            </span>
            <span>
              <button
                onClick={handleDecline}
                css={{
                  border: 0,
                  background: worker.hasBeenDeclined ? 'darkgray' : 'red',
                  margin: 0,
                  padding: '8px 16px',
                  color: worker.hasBeenDeclined ? 'grey' : '#002E6D',
                  borderRadius: 5,
                }}
                disabled={worker.hasBeenDeclined}
                type='button'
              >
                Decline
              </button>
            </span>
          </React.Fragment>
        ) : (
          <span>Vacancies Filled</span>
        )}
        {profileOpen && (
          <Profile
            workerID={worker.uid}
            setProfileOpen={setProfileOpen}
            handleAccept={handleAccept}
            handleDecline={handleDecline}
          />
        )}
        {notAvailableOpen && (
          <NotAvailableModal setNotAvaiableOpen={setNotAvailableOpen} />
        )}
      </div>
    </div>
  );
};

export default ApplicantCard;
