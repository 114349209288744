export const theme = {
  colors: {
    primary: '#002E6D',
    secondary: '#FFCC41',
    dark: '#272727',
    light: '#FFFFFF',
    neutral_dark1: '#393939',
    neutral_dark2: '#545454',
    neutral_light1: '#969696',
    neutral_light2: '#FAFAFA',
    util1: '#74B711',
    util2: '#FFC628',
    util3: '#D72F2F',
    util4: '#3AB1CA',
    util5: '#FC7753',
    overlay_light: 'rgba(255, 255, 255, 0.8)',
    overlay_dark: 'rgba(0, 0, 0, 0.35)',
  },
  layout: {
    max_width: 1280,
  },
  buttons: {
    type_light: '#FFFFFF',
    type_dark: '#002E6D',
    bg1: '#002E6D',
    bg2: '#FFCC41',
    bg3: '#969696',
    bg4: '#D2D1D1',
    hover1: '#1A4887',
    hover2: '#E0BE3A',
  },
  input: {
    bg1: 'rgba(58, 177, 202, 0.1)',
  },
};
