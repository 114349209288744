/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useContext } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  button,
  buttonContainer,
  goBackButton,
  customRadio,
  gigBox,
  formInput,
  addButton,
  popupContainer,
} from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';
import ParkingIndicator from 'components/ParkingIndicator';
import TransitIndicator from 'components/TransitIndicator';
import { ApplicantCard } from 'components/WorkerCards';
import { ReactComponent as Arrow } from 'assets/icons/topbarArrow.svg';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { ReactComponent as RedFlag } from 'assets/icons/redFlag.svg';
import { withFirebase } from 'library/Firebase';
import { AuthUserContext } from 'library/Session';
import { useHistory, useLocation } from 'react-router-dom';
import { Ring } from 'react-spinners-css';
import moment from 'moment';
import { ReactComponent as Visa } from 'assets/icons/visa.svg';
import { ReactComponent as MasterCard } from 'assets/icons/mastercard.svg';
import { ReactComponent as DinersClub } from 'assets/icons/dinersClub.svg';
import { ReactComponent as Discover } from 'assets/icons/discover.svg';
import { ReactComponent as Jcb } from 'assets/icons/jcb.svg';
import { ReactComponent as AmericaExpress } from 'assets/icons/americanExpress.svg';
import { ReactComponent as UnionPay } from 'assets/icons/unionPay.svg';
import { ReactComponent as CreditCard } from 'assets/icons/creditCardIcon.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';

import { useForm } from 'react-hook-form';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CheckoutSurvey from '../CheckoutSurvey';
import ReportIssue from '../ReportIssue';

const WorkerCard = ({
  worker,
  gig,
  firebase,
  setRefetchGigs,
  openModal,
  gigSummaryGroupData,
  taxPercentage,
}) => {
  const [pm, setPm] = useState(gig.paymentMethod);
  const [pay, setPay] = useState(gig.totalForThisDay);
  const [fee, setFee] = useState(0);
  const [tax, setTax] = useState(0);
  const [hours, setHours] = useState(0);
  const [cards, setCards] = useState([]);
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState(gig.totalForThisDay * 1.21);
  const [adjust, setAdjust] = useState(false);
  const [change, setChange] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [addCard, setAddCard] = useState(false);
  const [pmValid, setPmValid] = useState(null);
  const [minutes, setMinutes] = useState(0);
  const [checking, setChecking] = useState(false);
  const [loadingPm, setLoadingPm] = useState(false);
  const [preConfirm, setPreConfirm] = useState(false);
  const [preAnswers, setPreAnswers] = useState(false);
  const [timeWorked, setTimeWorked] = useState(gig.hours);
  const [checkingPm, setCheckingPm] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [removePending, setRemovePending] = useState(false);
  const [surveyResults, setSurveyResults] = useState({});
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [cardIndexToRemove, setCardIndexToRemove] = useState(null);
  const [summaryTotalHoursDecimal, setSummaryTotalHoursDecimal] = useState(0);
  const [summaryFees, setSummaryFees] = useState(0);
  const [summaryTax, setSummaryTax] = useState(0);
  const [summaryTotal, setSummaryTotal] = useState(0);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [pastGigData, setPastGigData] = useState([]);
  const [mdrPayment, setMdrPayment] = useState(false);

  const history = useHistory();
  const { authUser } = useContext(AuthUserContext);
  const { register, handleSubmit } = useForm();
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    const calcFees = Math.round(pay * 0.2 * 100) / 100;
    const calcTax =
      Math.round((pay + calcFees) * (taxPercentage / 100) * 100) / 100;
    setFee(calcFees);
    setTax(calcTax);
    setTotal(Math.round((pay + calcFees + calcTax) * 100) / 100);
  }, [pay]);
  useEffect(() => {
    setCheckingPm(true);
    if (!gig.invoicingOption) {
      firebase.checkPm(gig.paymentMethod).then((result) => {
        setPmValid(result.data);
        if (result.data) {
          setSelectedCardData(result.data);
          setChange(false);
        }
        if (!result.data) {
          setChange(true);
        }
        setCheckingPm(false);
      });
    }
    if (gig.invoicingOption) {
      setCheckingPm(false);
      setPmValid(true);
    }
  }, []);
  useEffect(() => {
    if (change) {
      setLoadingPm(true);
      firebase
        .getSavedCards(gig.stripeCustomerId)
        .then((data) => setCards(data));
    }
  }, [change]);
  useEffect(() => {
    const gigHours = moment.utc(
      moment.duration(gig.hours, 'hours').asMilliseconds()
    );
    setHours(gigHours.format('H'));
    setMinutes(gigHours.format('mm'));
  }, []);
  useEffect(() => {
    if (hours && minutes)
      setTimeWorked(moment.duration(`${hours}:${minutes}`).asHours());
  }, [hours, minutes]);
  useEffect(() => {
    if (gig.sameWorker && (gig.lastGigInMDR || gig.lastGigOfTheWeek)) {
      const gigData = [];
      const sumTotal = gigSummaryGroupData.reduce((acc, data) => {
        const matchingWorker = data.bookedWorkers.find(
          (data) => data.uid === worker.uid
        );
        if (matchingWorker) {
          gigData.push({ ...matchingWorker, day: data.day });
          return acc + matchingWorker.hoursLogged;
        }
        return acc;
      }, 0);
      setPastGigData(gigData);
      setSummaryTotalHoursDecimal(sumTotal + timeWorked);
    }
  }, [timeWorked, gig]);
  useEffect(() => {
    if (
      summaryTotalHoursDecimal &&
      gig.sameWorker &&
      (gig.lastGigInMDR || gig.lastGigOfTheWeek)
    ) {
      const fees =
        Math.round(gig.payPerHour * summaryTotalHoursDecimal * 0.2 * 100) / 100;

      const tax =
        Math.round(
          gig.payPerHour * summaryTotalHoursDecimal * 1.2 * 0.05 * 100
        ) / 100;
      setSummaryFees(fees);
      setSummaryTax(tax);
      setSummaryTotal(
        Math.round(
          (gig.payPerHour * summaryTotalHoursDecimal + fees + tax) * 100
        ) / 100
      );
    }
  }, [summaryTotalHoursDecimal]);

  const loadCards = () => {
    setLoading(true);
    firebase.getSavedCards(authUser.stripeCustomerId).then((cardsData) => {
      setCards(cardsData);
      setLoading(false);
    });
  };
  const cardIcon = (brand) => {
    switch (brand) {
      case 'visa':
        return <Visa />;
      case 'unionpay':
        return <UnionPay />;
      case 'mastercard':
        return <MasterCard />;
      case 'jcb':
        return <Jcb />;
      case 'diners club':
        return <DinersClub />;
      case 'discover':
        return <Discover />;
      case 'american express':
        return <AmericaExpress />;
      case 'unknown':
        return <CreditCard />;
      default:
        return <CreditCard />;
    }
  };
  const cardOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    disabled: saving,
  };
  const openRemoveCardModal = (index) => {
    setModal(true);
    setCardIndexToRemove(index);
  };
  const removeCard = (card) => {
    setRemovePending(true);
    firebase.removePaymentMethod(card).then(() => {
      loadCards();
      setModal(false);
      setCardIndexToRemove(null);
      setRemovePending(false);
    });
  };
  const selectCard = (cardData) => {
    setPm(cardData.id);
    setSelectedCardData(cardData);
    setPmValid(true);
  };
  const handleSaveCard = (data) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setSaving(true);
    setMessage('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    firebase
      .getClientSecret(authUser.stripeCustomerId)
      .then((client_secret) => {
        return stripe.confirmCardSetup(client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: data.nameOnCard,
            },
            metadata: {
              nickname: data.cardNickname,
            },
          },
        });
      })
      .then((result) => {
        if (result.error) {
          setSaving(false);
          setMessage(result.error.message);
        } else {
          firebase
            .saveCard(
              result.setupIntent.payment_method,
              authUser.stripeCustomerId
              // makeDefault
            )
            .then(() => {
              loadCards();
              setSaving(false);
              setAddCard(false);
            });
        }
      });
  };
  const onConfirmCheckin = (workerID) => {
    setChecking(true);
    firebase.confirmCheckin(workerID, gig.gigID).then(() => {
      setRefetchGigs(true);
      setChecking(false);
    });
  };
  const onConfirmCheckout = (workerID) => {
    setChecking(true);
    if (gig.sameWorker && (gig.lastGigOfTheWeek || gig.lastGigInMDR)) {
      return firebase
        .confirmCheckout(
          workerID,
          gig.gigID,
          pay,
          pm,
          gig.stripeCustomerId,
          surveyResults,
          timeWorked,
          parseFloat((gig.payPerHour * summaryTotalHoursDecimal).toFixed(2))
        )
        .then((result) => {
          console.log(result);
          if (!result.success) {
            setErrorMessage('Payment Unsuccessful');
          }
          if (result.success) {
            setPreConfirm(false);
            setPreAnswers(false);
          }
          if (result.redirect) {
            history.push(`/gigs/completed?gig=${result.gigID}&completed=true`);
          }
          setChecking(false);
        });
    }
    if (
      ((gig.invoicingOption || authUser.checkoutMethod === 'hours') &&
        !gig.sameWorker) ||
      (gig.sameWorker && (!gig.lastGigInMDR || !gig.lastGigOfTheWeek))
    ) {
      return firebase
        .confirmCheckoutInvoice(
          workerID,
          gig.gigID,
          pay,
          surveyResults,
          timeWorked
        )
        .then((result) => {
          if (result.success) {
            setPreConfirm(false);
            setPreAnswers(false);
          }
          if (result.redirect) {
            history.push(`/gigs/completed?gig=${result.gigID}&completed=true`);
          }
          setChecking(false);
        });
    }
    if (!gig.invoicingOption && !gig.sameWorker) {
      return firebase
        .confirmCheckout(
          workerID,
          gig.gigID,
          pay,
          pm,
          gig.stripeCustomerId,
          surveyResults,
          timeWorked
        )
        .then((result) => {
          if (!result.success) {
            setErrorMessage('Payment Unsuccessful');
          }
          if (result.success) {
            setPreConfirm(false);
            setPreAnswers(false);
          }
          if (result.redirect) {
            history.push(`/gigs/completed?gig=${result.gigID}&completed=true`);
          }
          setChecking(false);
          // if (result.complete && result.allCheckedout) {
          //   return history.push(`/gigs/completed?gig=${gig.gigID}`);
          // }
          // if (result.complete && !result.allCheckedout) {
          //   return;
          // }
          // if (!result.complete) {
          //   return setRefetchGigs(true);
          // }
        });
    }
  };

  const changeWage = (e) => {
    const event = e;
    if (!e.target.value) {
      setPay(0);
      event.target.value = '';
      return event;
    }
    if (e.target.value < 1) {
      setPay(1);
      event.target.value = '';
      return event;
    }
    if (e.target.value) {
      setPay(Math.trunc(e.target.value * 100) / 100);
      event.target.value = Math.trunc(e.target.value * 100) / 100;
      return event;
    }
  };

  const checkinText = () => {
    if (!worker.businessCheckin) {
      return 'Confirm Check In';
    }
    if (worker.businessCheckin) {
      return (
        <p
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0,
          }}
        >
          <GreenCheck
            css={{
              width: 17,
              height: 17,
              marginRight: 10,
              [mq(bp.large)]: { width: 23, height: 23 },
            }}
          />
          Checked In
        </p>
      );
    }
  };
  const checkoutText = () => {
    if (!worker.businessCheckout) {
      return 'Confirm Gig Completion';
    }
    if (worker.businessCheckout) {
      return (
        <p css={{ display: 'flex', alignItems: 'center', margin: 0 }}>
          <GreenCheck
            css={{
              width: 17,
              height: 17,
              marginRight: 10,
              [mq(bp.large)]: { width: 23, height: 23 },
            }}
          />
          Checked Out
        </p>
      );
    }
  };

  const DatePickerInput = ({ onClick }) => (
    <button type='button' onClick={onClick}>
      Change
    </button>
  );

  // const btnRef = useRef();

  const completeGigBtn = () => {
    // if (btnRef.current) {
    //   btnRef.current.setAttribute("disabled", "disabled");
    // }
    setPreAnswers(true);
    setPreConfirm(false);
  };

  const getTime = (decimalHours) => {
    const hour = Math.floor(Math.abs(decimalHours));
    const mins = Math.floor(Math.abs(decimalHours * 60) % 60);

    return `${hour}hr ${mins}min`;
  };

  const goToMDRPayment = () => {
    setMdrPayment(true);
    setPreConfirm(false);
  };

  return (
    <React.Fragment>
      <span
        css={{
          gridColumnStart: 1,
          display: 'flex',
          alignItems: 'center',
          fontWeight: 600,
          color: '#393939',
          [mq(bp.large)]: {
            color: '#002E6D',
          },
        }}
      >
        <img
          src={worker.profileImage}
          alt=''
          css={{
            height: 30,
            width: 30,
            borderRadius: '50%',
            marginRight: 8,
          }}
        />
        <div>
          {worker.firstName} {worker.lastName}
          <a
            href={`tel:${worker.phoneNumber}`}
            css={{
              display: 'inline-block',
              fontWeight: 500,
              [mq(bp.large)]: { display: 'none' },
            }}
          >
            {parsePhoneNumberFromString(`+${worker.phoneNumber}
                        `).formatNational()}
          </a>
        </div>
      </span>
      <span
        css={{
          display: 'none',
          [mq(bp.large)]: { display: 'inline' },
        }}
      >
        <a href={`tel:${worker.phoneNumber}`}>
          {parsePhoneNumberFromString(`+${worker.phoneNumber}
                        `).formatNational()}
        </a>
      </span>

      <button
        type='button'
        onClick={() =>
          openModal({
            firstName: worker.firstName,
            lastName: worker.lastName,
            id: worker.uid,
          })
        }
        css={{
          border: 'none',
          color: '#D72F2F',
          fontWeight: 600,
          textAlign: 'right',
          [mq(bp.large)]: { textAlign: 'left' },
        }}
      >
        Report Issue
      </button>

      <span>
        <button
          type='button'
          css={(theme) => [
            button(
              theme.buttons.type_light,
              theme.buttons.bg1,
              theme.buttons.hover1
            ),
            {
              padding: '14px 5px',
              minWidth: 115,
              fontSize: 12,
              [mq(bp.large)]: {
                padding: '14px 20px',
              },
            },
          ]}
          onClick={() => onConfirmCheckin(worker.uid)}
          disabled={checking || worker.businessCheckin}
        >
          {checking ? 'Confirming' : checkinText()}
        </button>
      </span>
      <span>
        <button
          type='button'
          css={(theme) => [
            button(
              theme.buttons.type_light,
              theme.buttons.bg1,
              theme.buttons.hover1
            ),
            {
              padding: '14px 5px',
              minWidth: 115,
              fontSize: 12,
              [mq(bp.large)]: {
                padding: '14px 20px',
              },
            },
          ]}
          onClick={() =>
            (!gig.sameWorker && authUser.checkoutRating) ||
            (gig.sameWorker && gig.lastGigInMDR)
              ? completeGigBtn()
              : setPreConfirm(true)
          }
          disabled={
            checking ||
            preAnswers ||
            preConfirm ||
            mdrPayment ||
            !worker.businessCheckin ||
            worker.businessCheckout
          }
        >
          {checking ? 'Confirming' : checkoutText()}
        </button>
      </span>

      {(!gig.sameWorker && preAnswers && authUser.checkoutRating) ||
      (preAnswers && gig.sameWorker && gig.lastGigInMDR) ? (
        <CheckoutSurvey
          gigData={gig}
          workerData={worker}
          setPreConfirm={setPreConfirm}
          preConfirm={preConfirm}
          setPreAnswers={setPreAnswers}
          preAnswers={preAnswers}
          setSurveyResults={setSurveyResults}
        />
      ) : null}
      {preConfirm ? (
        <div
          css={{
            display: 'grid',
            gridColumn: '1/3',
            maxWidth: '100%',
            // [mq(bp.medium)]: {
            //   gridColumn: "4/6",
            // },
          }}
        >
          {adjust ? (
            <div>
              <p css={{ fontWeigt: 'bold' }}>Pay</p>

              <div
                css={[
                  formInput,
                  {
                    input: { width: 'unset', padding: 8 },
                    'input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                    display: 'flex',
                    alignItems: 'center',
                  },
                ]}
              >
                <label
                  htmlFor=''
                  css={{
                    fontSize: '18px !important',
                    margin: '0 8px 0 0 !important',
                  }}
                >
                  $
                </label>
                <input
                  type='number'
                  name='adjustPay'
                  onChange={changeWage}
                  value={pay}
                  min={1}
                />
              </div>
            </div>
          ) : null}
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridColumnGap: 40,
              marginBottom: 40,
            }}
          >
            <p
              css={{
                gridColumn: '1/3',
                fontWeight: 'bold',
                fontSize: 20,
                margin: '0 0 16px 0',
              }}
            >
              {(!gig.sameWorker && !gig.invoicingOption) ||
              (!gig.sameWorker && authUser.checkoutMethod === 'wage')
                ? 'Payment Details'
                : null}

              {(!gig.sameWorker &&
                gig.invoicingOption &&
                authUser.checkoutMethod === 'hours') ||
              gig.sameWorker
                ? 'Confirm Worker Hours'
                : null}
            </p>
            {!gig.invoicingOption && !gig.sameWorker ? (
              <React.Fragment>
                <p
                  css={{
                    gridColumn: '1/3',
                    fontWeight: 'bold',
                    fontSize: 16,
                    margin: '0 0 16px 0',
                  }}
                >
                  Pay Using
                </p>
                {checkingPm ? (
                  <p>loading...</p>
                ) : (
                  <React.Fragment>
                    {change ? (
                      <div css={{ gridColumn: '1/3', marginBottom: 24 }}>
                        {cards.length ? (
                          <React.Fragment>
                            {cards.map((card, i) => (
                              <div
                                key={i}
                                css={[
                                  customRadio(),
                                  {
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    alignItems: 'center',
                                    gridColumnGap: 20,
                                    marginBottom: 15,
                                    p: {
                                      fontSize: 16,
                                    },
                                  },
                                ]}
                              >
                                <label
                                  css={{
                                    fontSize: 16,
                                    marginBottom: 4,
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    p: {
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  <input
                                    type='radio'
                                    name='card'
                                    onChange={() => selectCard(card)}
                                    checked={card.id === pm}
                                  />
                                  <span
                                    css={{
                                      top: 'unset !important',
                                    }}
                                  />
                                  <div css={{ width: '100%' }}>
                                    <div
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div
                                        css={{
                                          display: 'inline-block',
                                          marginRight: 5,
                                          svg: {
                                            width: 35,
                                            height: 20,
                                            verticalAlign: 'middle',
                                          },
                                        }}
                                      >
                                        {cardIcon(card.card.brand)}
                                      </div>
                                      <p
                                        css={{
                                          marginRight: '8px !important',
                                          marginTop: 0,
                                          marginBottom: 0,
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden !important',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {card.metadata.nickname}
                                      </p>
                                      <p css={{ margin: 0 }}>
                                        **** {card.card.last4}
                                      </p>
                                    </div>
                                    <p css={{ margin: '4px 0' }}>
                                      {card.billing_details.name}
                                    </p>
                                    <p css={{ margin: 0, color: '#8A8A8A' }}>
                                      Expires: {card.card.exp_month}/
                                      {card.card.exp_year}
                                    </p>
                                  </div>
                                </label>
                                <button
                                  type='button'
                                  onClick={() => openRemoveCardModal(i)}
                                  css={{
                                    textAlign: 'left',
                                    padding: 0,
                                    margin: 0,
                                    border: 'none',
                                    color: '#002E6D',
                                    fontSize: 16,
                                    fontWeight: 500,
                                  }}
                                >
                                  Remove Card
                                </button>
                              </div>
                            ))}
                            {/* Confirm modal */}
                            {cardIndexToRemove !== null && modal ? (
                              <div
                                css={{
                                  display: 'flex',
                                  position: 'fixed',
                                  width: '100vw',
                                  height: '100vh',
                                  background: 'rgba(0,0,0,.5)',
                                  top: 60,
                                  left: 0,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  zIndex: 99,
                                }}
                              >
                                <div
                                  css={(theme) => [
                                    popupContainer(),
                                    { height: 'auto' },
                                  ]}
                                >
                                  <div
                                    css={{
                                      background: '#fff',
                                      borderRadius: 4,
                                    }}
                                  >
                                    <div
                                      css={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: 30,
                                      }}
                                    >
                                      <h3
                                        css={{
                                          margin: 0,
                                          fontWeight: 700,
                                          fontSize: 20,
                                        }}
                                      >
                                        Remove Payment Method
                                      </h3>
                                      <button
                                        type='button'
                                        onClick={() => setModal(false)}
                                        disabled={removePending}
                                        css={{
                                          border: 'none',
                                          lineHeight: 1,
                                          fontWeight: 500,
                                        }}
                                      >
                                        <Close
                                          css={{
                                            width: 15,
                                            height: 15,
                                            path: { fill: '#393939' },
                                          }}
                                        />
                                      </button>
                                    </div>
                                    <div
                                      css={{
                                        fontSize: 16,
                                        marginBottom: 10,
                                        position: 'relative',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        p: {
                                          fontWeight: 600,
                                        },
                                      }}
                                    >
                                      <div
                                        css={{
                                          display: 'inline-block',
                                          marginRight: 5,
                                          svg: {
                                            width: 35,
                                            height: 20,
                                            verticalAlign: 'middle',
                                          },
                                        }}
                                      >
                                        {cardIcon(
                                          cards[cardIndexToRemove].card.brand
                                        )}
                                      </div>
                                      <p
                                        css={{
                                          marginRight: 5,
                                          marginTop: 0,
                                          marginBottom: 0,
                                          width: 90,
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden !important',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {
                                          cards[cardIndexToRemove].metadata
                                            .nickname
                                        }
                                      </p>
                                      <p
                                        css={{
                                          color: '#8A8A8A',
                                          margin: 0,
                                        }}
                                      >
                                        ending in{' '}
                                        {cards[cardIndexToRemove].card.last4}
                                      </p>
                                    </div>
                                    <p css={{ marginBottom: 30 }}>
                                      If you do not want this payment method to
                                      be displayed in your list of payment
                                      options, please select "Confirm Remove."{' '}
                                    </p>
                                    <div css={buttonContainer}>
                                      <button
                                        type='button'
                                        onClick={() => setModal(false)}
                                        css={goBackButton}
                                        disabled={removePending}
                                      >
                                        Back
                                      </button>
                                      <button
                                        type='submit'
                                        onClick={() =>
                                          removeCard(cards[cardIndexToRemove])
                                        }
                                        disabled={removePending}
                                        css={(theme) =>
                                          button(
                                            theme.buttons.type_dark,
                                            theme.buttons.bg2,
                                            theme.buttons.hover2
                                          )
                                        }
                                      >
                                        {removePending
                                          ? 'Removing'
                                          : 'Confirm Remove'}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </React.Fragment>
                        ) : null}
                        <div>
                          <button
                            type='button'
                            onClick={() => setAddCard(true)}
                            css={addButton}
                          >
                            + Add a new card
                          </button>
                        </div>
                        {addCard ? (
                          <div>
                            <div css={{ marginBottom: 20 }}>
                              <p>Enter your card information.</p>
                              <div
                                css={{
                                  display: 'grid',
                                  [mq(bp.large)]: {
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    gridColumnGap: 20,
                                    alignItems: 'end',
                                  },
                                }}
                              >
                                <div
                                  css={[
                                    formInput,
                                    {
                                      [mq(bp.large)]: { gridColumn: '1/3' },
                                    },
                                  ]}
                                >
                                  <label htmlFor='nameOnCard'>
                                    Name on card
                                  </label>
                                  <input
                                    type='text'
                                    name='nameOnCard'
                                    id='nameOnCard'
                                    ref={register}
                                  />
                                </div>
                                <div
                                  css={[
                                    formInput,
                                    {
                                      [mq(bp.large)]: { gridColumn: '3/4' },
                                    },
                                  ]}
                                >
                                  <label htmlFor='cardNickname'>
                                    Card Nickname
                                  </label>
                                  <input
                                    type='text'
                                    name='cardNickname'
                                    id='cardNickname'
                                    ref={register}
                                  />
                                </div>
                                <div
                                  css={[
                                    formInput,
                                    {
                                      [mq(bp.large)]: { gridColumn: '1/4' },
                                    },
                                  ]}
                                >
                                  <label>Card Number</label>
                                  <CardElement
                                    options={cardOptions}
                                    css={{
                                      padding: 16,
                                      backgroundColor: 'rgba(58,177,202,0.1)',
                                      borderRadius: 4,
                                    }}
                                  />
                                </div>
                                <div
                                  css={{
                                    [mq(bp.large)]: { gridColumn: '4/4' },
                                  }}
                                >
                                  <div css={{ position: 'relative' }}>
                                    <button
                                      type='button'
                                      onClick={handleSubmit(handleSaveCard)}
                                      css={(theme) => [
                                        button(
                                          theme.buttons.type_light,
                                          theme.buttons.bg1,
                                          theme.buttons.hover1
                                        ),
                                        {
                                          padding: '16px 10px',
                                          marginBottom: 15,
                                        },
                                      ]}
                                    >
                                      {saving ? (
                                        <Ring
                                          color='#fff'
                                          size='22'
                                          css={{
                                            width: 22,
                                            height: 22,
                                            display: 'flex',
                                            margin: 'auto',
                                          }}
                                        />
                                      ) : (
                                        'Add New Card'
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {message ? (
                                <p css={{ color: '#D72F2F' }}>{message}</p>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <React.Fragment>
                        <div css={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            css={{
                              marginRight: 5,
                              svg: {
                                width: 35,
                                height: 20,
                                verticalAlign: 'middle',
                              },
                            }}
                          >
                            {cardIcon(selectedCardData.card.brand)}{' '}
                          </div>
                          <p css={{ margin: 0 }}>
                            {selectedCardData.metadata.nickname} ****{' '}
                            {selectedCardData.card.last4}
                          </p>
                        </div>
                        <div>
                          <button
                            type='button'
                            onClick={() => setChange(!change)}
                            css={{
                              padding: 0,
                              margin: 0,
                              border: 'none',
                              color: '#002E6D',
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                          >
                            Change
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                <p
                  css={{
                    gridColumn: '1/3',
                    margin: '0 0 24px 0',
                    fontSize: 12,
                  }}
                >
                  If a Worker works more or less than originally agreed upon, or
                  if you have agreed to or want to pay them extra, you can
                  adjust the pay.
                  <span
                    css={{
                      fontStyle: 'italic',
                      fontWeight: 'normal',
                      display: 'block',
                    }}
                  >
                    Note: All adjustments must be agreed upon with the worker
                  </span>
                </p>
                <div
                  css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridColumnGap: 20,
                    marginBottom: 20,
                    alignItems: 'center',
                  }}
                >
                  <p css={{ margin: '0 0 10px 0' }}>Pay</p>

                  <div
                    css={{
                      'input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                      display: 'flex',
                      alignItems: 'center',
                      margin: '0 0 10px 0',
                    }}
                  >
                    <label
                      htmlFor=''
                      css={{
                        padding: '4px 0px 4px 2px',
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                        margin: '0 !important',
                        backgroundColor: 'rgba(58,177,202,0.1)',
                        border: '1px solid transparent',
                      }}
                    >
                      $
                    </label>
                    <input
                      type='number'
                      name='adjustPay'
                      onChange={changeWage}
                      value={pay}
                      min={1}
                      css={{
                        padding: '4px 4px 4px 0',
                        borderBottomRightRadius: 4,
                        borderTopRightRadius: 4,
                        width: 65,
                        backgroundColor: 'rgba(58,177,202,0.1)',
                        border: '1px solid transparent',
                        fontSize: 16,
                        outline: 'none',
                      }}
                    />
                  </div>

                  <p css={{ margin: '0 0 10px 0' }}>Fee</p>
                  <p css={{ margin: '0 0 10px 2px' }}>${fee.toFixed(2)}</p>
                  <p css={{ margin: '0 0 10px 0' }}>Tax (GST/HST)</p>
                  <p css={{ margin: '0 0 10px 2px' }}>${tax.toFixed(2)}</p>
                  <hr css={{ gridColumn: '1/3', width: '100%' }} />
                  <p css={{ margin: 0 }}>Total Cost</p>
                  <p css={{ margin: 0, fontSize: 20, fontWeight: 'bold' }}>
                    ${total.toFixed(2)}
                  </p>
                </div>
              </React.Fragment>
            ) : null}
            {gig.invoicingOption &&
            authUser.checkoutMethod === 'wage' &&
            !gig.sameWorker ? (
              <React.Fragment>
                <p
                  css={{
                    gridColumn: '1/3',
                    margin: '0 0 24px 0',
                    fontSize: 12,
                  }}
                >
                  If a Worker works more or less than originally agreed upon, or
                  if you have agreed to or want to pay them extra, you can
                  adjust the pay.
                  <span
                    css={{
                      fontStyle: 'italic',
                      fontWeight: 'normal',
                      display: 'block',
                    }}
                  >
                    Note: All adjustments must be agreed upon with the worker
                  </span>
                </p>
                <div
                  css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridColumnGap: 20,
                    marginBottom: 20,
                    alignItems: 'center',
                  }}
                >
                  <p css={{ margin: '0 0 10px 0' }}>Pay</p>

                  <div
                    css={{
                      'input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                      display: 'flex',
                      alignItems: 'center',
                      margin: '0 0 10px 0',
                    }}
                  >
                    <label
                      htmlFor=''
                      css={{
                        padding: '4px 0px 4px 2px',
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                        margin: '0 !important',
                        backgroundColor: 'rgba(58,177,202,0.1)',
                        border: '1px solid transparent',
                      }}
                    >
                      $
                    </label>
                    <input
                      type='number'
                      name='adjustPay'
                      onChange={changeWage}
                      value={pay}
                      min={1}
                      css={{
                        padding: '4px 4px 4px 0',
                        borderBottomRightRadius: 4,
                        borderTopRightRadius: 4,
                        width: 65,
                        backgroundColor: 'rgba(58,177,202,0.1)',
                        border: '1px solid transparent',
                        fontSize: 16,
                        outline: 'none',
                      }}
                    />
                  </div>

                  <p css={{ margin: '0 0 10px 0' }}>Fee</p>
                  <p css={{ margin: '0 0 10px 2px' }}>${fee.toFixed(2)}</p>
                  <p css={{ margin: '0 0 10px 0' }}>Tax (GST/HST)</p>
                  <p css={{ margin: '0 0 10px 2px' }}>${tax.toFixed(2)}</p>
                  <hr css={{ gridColumn: '1/3', width: '100%' }} />
                  <p css={{ margin: 0 }}>Total Cost</p>
                  <p css={{ margin: 0, fontSize: 20, fontWeight: 'bold' }}>
                    ${total.toFixed(2)}
                  </p>
                </div>
              </React.Fragment>
            ) : null}
            {(gig.invoicingOption && authUser.checkoutMethod === 'hours') ||
            gig.sameWorker ? (
              <div>
                <div>
                  <p css={{ margin: 0 }}>Gig Time</p>
                  <p css={{ margin: 0 }}>
                    {gig.start} - {gig.end}
                  </p>
                </div>
                <div>
                  <p css={{ marginBottom: 0 }}>Time Worked</p>
                  <div css={{ display: 'flex' }}>
                    <div
                      css={{
                        'input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 10px 10px 0',
                      }}
                    >
                      <input
                        type='number'
                        name='adjustHours'
                        onChange={(e) => setHours(e.target.value)}
                        value={hours}
                        min={1}
                        css={{
                          borderBottomRightRadius: 4,
                          borderTopRightRadius: 4,
                          width: 40,
                          backgroundColor: 'rgba(58,177,202,0.1)',
                          border: '1px solid transparent',
                          fontSize: 16,
                          outline: 'none',
                        }}
                      />
                      <label htmlFor=''>hr</label>
                    </div>
                    <div
                      css={{
                        'input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 0 10px 0',
                      }}
                    >
                      <input
                        type='number'
                        name='adjustMinutes'
                        onChange={(e) => setMinutes(e.target.value)}
                        value={minutes}
                        min={1}
                        css={{
                          borderBottomRightRadius: 4,
                          borderTopRightRadius: 4,
                          width: 40,
                          backgroundColor: 'rgba(58,177,202,0.1)',
                          border: '1px solid transparent',
                          fontSize: 16,
                          outline: 'none',
                        }}
                      />
                      <label htmlFor=''>min</label>
                    </div>
                  </div>
                </div>
                <p>
                  You may edit the "Time Worked" field if there has been a
                  change to the original hours.
                </p>
              </div>
            ) : null}
          </div>
          <div>
            <button
              type='button'
              disabled={
                checking ||
                (gig.invoicingOption &&
                  authUser.checkoutMethod === 'hours' &&
                  !hours) ||
                (gig.invoicingOption &&
                  authUser.checkoutMethod === 'hours' &&
                  !minutes) ||
                !pay
                  ? true
                  : !pmValid
              }
              onClick={() =>
                gig.sameWorker && (gig.lastGigInMDR || gig.lastGigOfTheWeek)
                  ? goToMDRPayment()
                  : onConfirmCheckout(worker.uid)
              }
              css={(theme) => [
                button(
                  theme.buttons.type_light,
                  theme.buttons.bg1,
                  theme.buttons.hover1
                ),
                {
                  width: 160,
                  [mq(bp.large)]: {
                    width: '100%',
                  },
                },
              ]}
            >
              {checking && (
                <Ring
                  color='#fff'
                  size='22'
                  css={{
                    width: 22,
                    height: 22,
                    display: 'flex',
                    margin: 'auto',
                  }}
                />
              )}
              {!gig.invoicingOption &&
                !checking &&
                !gig.sameWorker &&
                'Pay & Complete'}
              {(gig.invoicingOption && !checking) ||
              (gig.sameWorker &&
                !gig.lastGigOfTheWeek &&
                !gig.lastGigInMDR &&
                !checking)
                ? 'Confirm & Complete'
                : null}
              {gig.sameWorker && (gig.lastGigInMDR || gig.lastGigOfTheWeek)
                ? 'Confirm Hours'
                : null}
            </button>
            {errorMessage ? <p css={{ color: 'red' }}>{errorMessage}</p> : null}
          </div>
        </div>
      ) : null}
      {mdrPayment ? (
        <div css={{ gridColumn: '1/3', display: 'grid', maxWidth: '100%' }}>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridColumnGap: 40,
              marginBottom: 40,
            }}
          >
            <p css={{ fontWeight: 'bold', fontSize: 18 }}>Payment Details</p>
            <p
              css={{
                gridColumn: '1/3',
                fontSize: 16,
                margin: 0,
              }}
            >
              Pay Using
            </p>
            {checkingPm ? (
              <p>loading...</p>
            ) : (
              <React.Fragment>
                {change ? (
                  <div css={{ gridColumn: '1/3', marginBottom: 24 }}>
                    {cards.length ? (
                      <React.Fragment>
                        {cards.map((card, i) => (
                          <div
                            key={i}
                            css={[
                              customRadio(),
                              {
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                alignItems: 'center',
                                gridColumnGap: 20,
                                marginBottom: 15,
                                p: {
                                  fontSize: 16,
                                },
                              },
                            ]}
                          >
                            <label
                              css={{
                                fontSize: 16,
                                marginBottom: 4,
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                p: {
                                  fontWeight: 600,
                                },
                              }}
                            >
                              <input
                                type='radio'
                                name='card'
                                onChange={() => selectCard(card)}
                                checked={card.id === pm}
                              />
                              <span
                                css={{
                                  top: 'unset !important',
                                }}
                              />
                              <div css={{ width: '100%' }}>
                                <div
                                  css={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    css={{
                                      display: 'inline-block',
                                      marginRight: 5,
                                      svg: {
                                        width: 35,
                                        height: 20,
                                        verticalAlign: 'middle',
                                      },
                                    }}
                                  >
                                    {cardIcon(card.card.brand)}
                                  </div>
                                  <p
                                    css={{
                                      marginRight: '8px !important',
                                      marginTop: 0,
                                      marginBottom: 0,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden !important',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {card.metadata.nickname}
                                  </p>
                                  <p css={{ margin: 0 }}>
                                    **** {card.card.last4}
                                  </p>
                                </div>
                                <p css={{ margin: '4px 0' }}>
                                  {card.billing_details.name}
                                </p>
                                <p css={{ margin: 0, color: '#8A8A8A' }}>
                                  Expires: {card.card.exp_month}/
                                  {card.card.exp_year}
                                </p>
                              </div>
                            </label>
                            <button
                              type='button'
                              onClick={() => openRemoveCardModal(i)}
                              css={{
                                textAlign: 'left',
                                padding: 0,
                                margin: 0,
                                border: 'none',
                                color: '#002E6D',
                                fontSize: 16,
                                fontWeight: 500,
                              }}
                            >
                              Remove Card
                            </button>
                          </div>
                        ))}
                        {/* Confirm modal */}
                        {cardIndexToRemove !== null && modal ? (
                          <div
                            css={{
                              display: 'flex',
                              position: 'fixed',
                              width: '100vw',
                              height: '100vh',
                              background: 'rgba(0,0,0,.5)',
                              top: 60,
                              left: 0,
                              justifyContent: 'center',
                              alignItems: 'center',
                              zIndex: 99,
                            }}
                          >
                            <div
                              css={(theme) => [
                                popupContainer(),
                                { height: 'auto' },
                              ]}
                            >
                              <div
                                css={{
                                  background: '#fff',
                                  borderRadius: 4,
                                }}
                              >
                                <div
                                  css={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 30,
                                  }}
                                >
                                  <h3
                                    css={{
                                      margin: 0,
                                      fontWeight: 700,
                                      fontSize: 20,
                                    }}
                                  >
                                    Remove Payment Method
                                  </h3>
                                  <button
                                    type='button'
                                    onClick={() => setModal(false)}
                                    disabled={removePending}
                                    css={{
                                      border: 'none',
                                      lineHeight: 1,
                                      fontWeight: 500,
                                    }}
                                  >
                                    <Close
                                      css={{
                                        width: 15,
                                        height: 15,
                                        path: { fill: '#393939' },
                                      }}
                                    />
                                  </button>
                                </div>
                                <div
                                  css={{
                                    fontSize: 16,
                                    marginBottom: 10,
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    p: {
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  <div
                                    css={{
                                      display: 'inline-block',
                                      marginRight: 5,
                                      svg: {
                                        width: 35,
                                        height: 20,
                                        verticalAlign: 'middle',
                                      },
                                    }}
                                  >
                                    {cardIcon(
                                      cards[cardIndexToRemove].card.brand
                                    )}
                                  </div>
                                  <p
                                    css={{
                                      marginRight: 5,
                                      marginTop: 0,
                                      marginBottom: 0,
                                      width: 90,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden !important',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {cards[cardIndexToRemove].metadata.nickname}
                                  </p>
                                  <p
                                    css={{
                                      color: '#8A8A8A',
                                      margin: 0,
                                    }}
                                  >
                                    ending in{' '}
                                    {cards[cardIndexToRemove].card.last4}
                                  </p>
                                </div>
                                <p css={{ marginBottom: 30 }}>
                                  If you do not want this payment method to be
                                  displayed in your list of payment options,
                                  please select "Confirm Remove."{' '}
                                </p>
                                <div css={buttonContainer}>
                                  <button
                                    type='button'
                                    onClick={() => setModal(false)}
                                    css={goBackButton}
                                    disabled={removePending}
                                  >
                                    Back
                                  </button>
                                  <button
                                    type='submit'
                                    onClick={() =>
                                      removeCard(cards[cardIndexToRemove])
                                    }
                                    disabled={removePending}
                                    css={(theme) =>
                                      button(
                                        theme.buttons.type_dark,
                                        theme.buttons.bg2,
                                        theme.buttons.hover2
                                      )
                                    }
                                  >
                                    {removePending
                                      ? 'Removing'
                                      : 'Confirm Remove'}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                    <div>
                      <button
                        type='button'
                        onClick={() => setAddCard(true)}
                        css={addButton}
                      >
                        + Add a new card
                      </button>
                    </div>
                    {addCard ? (
                      <div>
                        <div css={{ marginBottom: 20 }}>
                          <p>Enter your card information.</p>
                          <div
                            css={{
                              display: 'grid',
                              [mq(bp.large)]: {
                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                gridColumnGap: 20,
                                alignItems: 'end',
                              },
                            }}
                          >
                            <div
                              css={[
                                formInput,
                                {
                                  [mq(bp.large)]: { gridColumn: '1/3' },
                                },
                              ]}
                            >
                              <label htmlFor='nameOnCard'>Name on card</label>
                              <input
                                type='text'
                                name='nameOnCard'
                                id='nameOnCard'
                                ref={register}
                              />
                            </div>
                            <div
                              css={[
                                formInput,
                                {
                                  [mq(bp.large)]: { gridColumn: '3/4' },
                                },
                              ]}
                            >
                              <label htmlFor='cardNickname'>
                                Card Nickname
                              </label>
                              <input
                                type='text'
                                name='cardNickname'
                                id='cardNickname'
                                ref={register}
                              />
                            </div>
                            <div
                              css={[
                                formInput,
                                {
                                  [mq(bp.large)]: { gridColumn: '1/4' },
                                },
                              ]}
                            >
                              <label>Card Number</label>
                              <CardElement
                                options={cardOptions}
                                css={{
                                  padding: 16,
                                  backgroundColor: 'rgba(58,177,202,0.1)',
                                  borderRadius: 4,
                                }}
                              />
                            </div>
                            <div
                              css={{
                                [mq(bp.large)]: { gridColumn: '4/4' },
                              }}
                            >
                              <div css={{ position: 'relative' }}>
                                <button
                                  type='button'
                                  onClick={handleSubmit(handleSaveCard)}
                                  css={(theme) => [
                                    button(
                                      theme.buttons.type_light,
                                      theme.buttons.bg1,
                                      theme.buttons.hover1
                                    ),
                                    {
                                      padding: '16px 10px',
                                      marginBottom: 15,
                                    },
                                  ]}
                                >
                                  {saving ? (
                                    <Ring
                                      color='#fff'
                                      size='22'
                                      css={{
                                        width: 22,
                                        height: 22,
                                        display: 'flex',
                                        margin: 'auto',
                                      }}
                                    />
                                  ) : (
                                    'Add New Card'
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          {message ? (
                            <p css={{ color: '#D72F2F' }}>{message}</p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 20,
                      }}
                    >
                      <div
                        css={{
                          marginRight: 5,
                          svg: {
                            width: 35,
                            height: 20,
                            verticalAlign: 'middle',
                          },
                        }}
                      >
                        {cardIcon(selectedCardData.card.brand)}{' '}
                      </div>
                      <p css={{ margin: 0 }}>
                        {selectedCardData.metadata.nickname} ****{' '}
                        {selectedCardData.card.last4}
                      </p>
                    </div>
                    <div css={{ marginBottom: 20 }}>
                      <button
                        type='button'
                        onClick={() => setChange(!change)}
                        css={{
                          padding: 0,
                          margin: 0,
                          border: 'none',
                          color: '#002E6D',
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        Change
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridColumnGap: 20,
                marginBottom: 20,
                alignItems: 'center',
                p: {
                  margin: '10px 0',
                },
              }}
            >
              <p>Hrs Worked</p>
              <p>{getTime(summaryTotalHoursDecimal)}</p>
              <p>Pay per hour</p>
              <p>${gig.payPerHour}</p>
              <p>Worker Pay</p>
              <p>${(gig.payPerHour * summaryTotalHoursDecimal).toFixed(2)}</p>
              <p>Fee</p>
              <p>${summaryFees.toFixed(2)}</p>
              <p>Tax (GST/HST)</p>
              <p>${summaryTax.toFixed(2)}</p>
              <hr css={{ gridColumn: '1/3', width: '100%' }} />
              <p>Total Cost</p>
              <p>${summaryTotal.toFixed(2)}</p>
            </div>
            {pastGigData.length > 0 ? (
              <div css={{ position: 'relative' }}>
                <button
                  type='button'
                  css={{
                    border: 'none',
                    padding: 0,
                    margin: '10px 0',
                    color: '#002E6D',
                    fontSize: 16,
                    fontWeight: 500,
                    textAlign: 'left',
                  }}
                  onClick={() => setDetailsOpen(true)}
                >
                  View Details
                </button>
                {detailsOpen ? (
                  <div
                    css={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'rgba(0,0,0,0.25)',
                      [mq(bp.small)]: {
                        position: 'initial',
                        height: 'unset',
                        width: 'unset',
                        display: 'block',
                      },
                    }}
                  >
                    <div
                      css={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        position: 'absolute',
                        width: 200,
                        border: '1px solid #979797',
                        background: '#fff',
                        padding: 20,
                        boxShadow: '0 4px 4px rgba(0,0,0,0.25)',
                      }}
                    >
                      <button
                        type='button'
                        onClick={() => setDetailsOpen(false)}
                        css={{
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          border: 'none',
                          padding: 0,
                        }}
                      >
                        <Close
                          css={{
                            width: 15,
                            height: 15,
                            path: { fill: '#393939' },
                          }}
                        />
                      </button>
                      <p css={{ fontWeight: 'bold' }}>Date Worked</p>
                      <p css={{ fontWeight: 'bold' }}>Hours Worked</p>
                      {pastGigData.map((data, i) => (
                        <React.Fragment key={i}>
                          <p>{data.day.split(',')[0]}</p>
                          <p>{getTime(data.hoursLogged)}</p>
                        </React.Fragment>
                      ))}
                      <p>{gig.day.split(',')[0]}</p>
                      <p>{getTime(timeWorked)}</p>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div>
            <button
              type='button'
              disabled={
                checking ||
                !parseFloat(
                  (gig.payPerHour * summaryTotalHoursDecimal).toFixed(2)
                )
                  ? true
                  : !pmValid
              }
              onClick={() => onConfirmCheckout(worker.uid)}
              css={(theme) => [
                button(
                  theme.buttons.type_light,
                  theme.buttons.bg1,
                  theme.buttons.hover1
                ),
                {
                  width: 160,
                  [mq(bp.large)]: {
                    width: '100%',
                  },
                },
              ]}
            >
              {checking && (
                <Ring
                  color='#fff'
                  size='22'
                  css={{
                    width: 22,
                    height: 22,
                    display: 'flex',
                    margin: 'auto',
                  }}
                />
              )}
              Pay & Complete
            </button>
            {errorMessage ? <p css={{ color: 'red' }}>{errorMessage}</p> : null}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const GigItem = ({
  gigData,
  worker,
  firebase,
  gigsActive,
  setRefetchGigs,
  handleComplete,
  opened,
  completed,
  taxPercentage,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [itemStep, setItemStep] = useState(1);
  const [color, setColor] = useState('');
  const [checking, setChecking] = useState(false);
  const [gig, setGig] = useState(null);
  const [adjust, setAdjust] = useState(true);
  const [adjustedStart, setAdjustedStart] = useState(null);
  const [adjustedEnd, setAdjustedEnd] = useState(null);
  const [adjustedHours, setAdjustedHours] = useState(null);
  const [reportedWorker, setReportedWorker] = useState({});
  const [selectedIssue, setSelectedIssue] = useState();
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [gigSummaryGroupData, setGigSummaryGroupData] = useState([]);
  const { authUser } = useContext(AuthUserContext);
  const history = useHistory();
  const { pathname } = useLocation();
  // const [applicants, setApplicants] = useState([]);
  useEffect(() => {
    if (gig) {
      const filled = gig.bookedWorkers.length;
      if (filled === 0) {
        return setColor('red');
      }
      if (gig.vacancies > filled) {
        return setColor('orange');
      }
      if (gig.vacancies === filled) {
        return setColor('green');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gig]);
  useEffect(() => {
    if (
      gigData.sameWorker &&
      (gigData.lastGigInMDR || gigData.lastGigOfTheWeek)
    ) {
      firebase
        .getGigSetInfo(gigData.gigSummaryGroup)
        .then((data) => setGigSummaryGroupData(data));
    }
  }, [gigData]);
  useEffect(() => {
    let unsub;
    setGig(gigData);
    if (gigsActive) {
      const gigSnapshot = firebase.gigSnapshot(gigData.gigID);
      unsub = gigSnapshot.onSnapshot((snapshot) => {
        setChecking(false);
        setGig(snapshot.data());
      });
    }
    return () => {
      if (gigsActive) {
        unsub();
      }
    };
  }, [gigData]);
  useEffect(() => {
    if (pathname === '/gigs/completed' && opened && !completed) {
      setItemStep(1);
      setExpanded(true);
    }
    if (pathname === '/gigs/completed' && opened && completed) {
      setItemStep(3);
      setExpanded(true);
    }
    if (pathname !== '/gigs/completed' && opened) {
      setItemStep(2);
      setExpanded(true);
    }
  }, [opened, completed]);
  useEffect(() => {
    let start = moment.unix(gigData.startTimeStamp.seconds);
    let end = moment.unix(gigData.endTimeStamp.seconds);
    if (adjustedStart) {
      start = moment(adjustedStart).milliseconds(0).seconds(0);
    }
    if (adjustedEnd) {
      end = moment(adjustedEnd).milliseconds(0).seconds(0);
    }
    if (adjustedEnd || adjustedStart) {
      setAdjustedHours(moment.duration(end.diff(start)).asHours());
    }
  }, [adjustedStart, adjustedEnd]);

  const onExpand = () => {
    setItemStep(1);
    setExpanded(!expanded);
  };

  const onViewWorkers = () => {
    setItemStep(2);
    setExpanded(!expanded);
  };

  // const onConfirmCheckin = (workerID) => {
  //   setChecking(true);
  //   firebase.confirmCheckin(workerID, gig.gigID).then(() => {
  //     setRefetchGigs(true);
  //     setChecking(false);
  //   });
  // };
  // const onConfirmCheckout = (workerID) => {
  //   setChecking(true);
  //   firebase.confirmCheckout(workerID, gig.gigID).then((result) => {
  //     if (result.complete && result.allCheckedout) {
  //       return history.push(`/gigs/completed?gig=${gig.gigID}`);
  //     }
  //     if (result.complete && !result.allCheckedout) {
  //       return;
  //     }
  //     if (!result.complete) {
  //       return setRefetchGigs(true);
  //     }
  //   });
  // };
  // const checkinText = (checkinStatus) => {
  //   if (checkinStatus < 2) {
  //     return "Confirm Check In";
  //   }
  //   if (checkinStatus >= 2) {
  //     return (
  //       <p
  //         css={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           margin: 0,
  //         }}
  //       >
  //         <GreenCheck
  //           css={{
  //             width: 17,
  //             height: 17,
  //             marginRight: 10,
  //             [mq(bp.large)]: { width: 23, height: 23 },
  //           }}
  //         />
  //         Checked In
  //       </p>
  //     );
  //   }
  // };
  // const checkoutText = (checkinStatus) => {
  //   if (checkinStatus < 4) {
  //     return "Confirm Gig Completion";
  //   }
  //   if (checkinStatus === 4) {
  //     return (
  //       <p css={{ display: "flex", alignItems: "center", margin: 0 }}>
  //         <GreenCheck
  //           css={{
  //             width: 17,
  //             height: 17,
  //             marginRight: 10,
  //             [mq(bp.large)]: { width: 23, height: 23 },
  //           }}
  //         />
  //         Checked Out
  //       </p>
  //     );
  //   }
  // };

  const displayEditorData = (content) => {
    return { __html: gig[content] };
  };
  const DatePickerInput = ({ onClick }) => (
    <button type='button' onClick={onClick}>
      Change
    </button>
  );
  const viewDetails = () => {
    history.push(`/gigs/completed?gig=${gigData.gigID}`);
  };
  const openModal = (workerData) => {
    setReportModalOpen(true);
    setReportedWorker(workerData);
  };

  const itemView = () => {
    switch (itemStep) {
      case 1:
        return (
          <div>
            <h2
              css={{
                marginBottom: 20,
                fontSize: 20,
                [mq(bp.large)]: {
                  fontSize: 24,
                },
              }}
            >
              Workers
            </h2>
            <div
              css={{
                display: 'flex',
                marginBottom: 30,
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                [mq(bp.large)]: {
                  justifyContent: 'flex-start',
                },
              }}
            >
              {gig.bookedWorkers.map((worker, i) => {
                return (
                  <React.Fragment key={i}>
                    <div
                      css={{
                        fontWeight: 600,
                        color: '#002E6D',
                        marginTop: 10,
                        [mq(bp.large)]: {
                          marginRight: 50,
                        },
                      }}
                    >
                      <div
                        css={{
                          height: 64,
                          width: 64,
                          margin: '0 auto 10px',
                          border: '1px solid lightgrey',
                          borderRadius: '50%',
                          [mq(bp.large)]: {
                            margin: '0 0 10px',
                          },
                        }}
                      >
                        <img
                          src={worker.profileImage}
                          alt=''
                          css={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                          }}
                        />
                      </div>
                      <div>
                        {worker.firstName} {worker.lastName}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                [mq(bp.large)]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              }}
            >
              <div
                css={{
                  [mq(bp.large)]: { width: '55%' },
                  h2: {
                    fontSize: 20,
                    [mq(bp.large)]: {
                      fontSize: 24,
                    },
                  },
                }}
              >
                <h2>Description</h2>
                <div
                  dangerouslySetInnerHTML={displayEditorData('description')}
                  css={{
                    width: '71vw',
                    margin: '0 auto',
                    overflowWrap: 'break-word',
                    [mq(bp.large)]: {
                      width: '100%',
                    },
                    a: {
                      color: 'cornflowerblue',
                    },
                    'ul,ol': {
                      paddingLeft: 24,
                      margin: 0,
                    },
                    p: { margin: 0 },
                  }}
                />
                <div
                  css={{
                    marginBottom: 15,
                    h3: {
                      fontSize: 16,
                      margin: '0 0 5px 0',
                    },
                  }}
                >
                  <h2>Instructions</h2>
                  <div
                    css={{
                      marginBottom: 30,
                    }}
                  >
                    <h3>Attire</h3>
                    <div
                      dangerouslySetInnerHTML={displayEditorData('attire')}
                      css={{
                        width: '71vw',
                        margin: '0 auto',
                        overflowWrap: 'break-word',
                        [mq(bp.large)]: {
                          width: '100%',
                        },
                        a: {
                          color: 'cornflowerblue',
                        },
                        'ul,ol': {
                          paddingLeft: 24,
                          margin: 0,
                        },
                        p: { margin: 0 },
                      }}
                    />
                  </div>
                  {gig.bring ? (
                    <div>
                      <h3>Things to Bring</h3>
                      <p>{gig.bring}</p>
                    </div>
                  ) : null}
                  {gig.additional ? (
                    <div>
                      <h3>Additional Information</h3>
                      <div
                        dangerouslySetInnerHTML={displayEditorData(
                          'additional'
                        )}
                        css={{
                          width: '71vw',
                          margin: '0 auto',
                          overflowWrap: 'break-word',
                          [mq(bp.large)]: {
                            width: '100%',
                          },
                          a: {
                            color: 'cornflowerblue',
                          },
                          'ul,ol': {
                            paddingLeft: 24,
                            margin: 0,
                          },
                          p: { margin: 0 },
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                {gig.needCerts ? (
                  <div
                    css={{
                      marginBottom: 20,
                      [mq(bp.large)]: {
                        marginBottom: 0,
                      },
                    }}
                  >
                    <h2>Certifications & Licenses</h2>
                    {gig.certs.map((cert, i) => (
                      <p key={i}>- {cert}</p>
                    ))}
                  </div>
                ) : null}
              </div>
              <div css={{ [mq(bp.large)]: { width: 350 } }}>
                <div
                  css={{
                    marginBottom: 20,
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    css={{
                      marginBottom: 8,
                    }}
                  >
                    <p css={{ marginBottom: 0 }}>
                      {gigData.location.address2
                        ? `${gigData.location.address2} - ${gigData.location.formattedAddress}`
                        : `${gigData.location.formattedAddress}`}
                    </p>
                  </div>
                </div>
                <div
                  css={{
                    marginBottom: 40,
                  }}
                >
                  {gigData.parking[0] && <ParkingIndicator num={1} />}
                  {gigData.parking[1] && <ParkingIndicator num={2} />}
                  {gigData.parking[2] && <ParkingIndicator num={3} />}
                  <TransitIndicator num={gig.transit} />
                </div>
                <a
                  href='https://www.workbriefly.com/contact-us/'
                  target='_blank'
                  css={{
                    marginTop: 30,
                    color: '#D72F2F',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  Report an Issue
                </a>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h2
              css={{
                fontSize: 20,
                [mq(bp.large)]: {
                  fontSize: 24,
                },
              }}
            >
              Booked Workers
            </h2>
            <p>
              Upon arrival, please click "Confirm Check-In" to confirm that the
              worker has arrived.
            </p>
            <div
              css={{
                alignItems: 'center',
                marginBottom: 10,
                paddingBottom: 30,
                borderBottom: '1px solid #EFEFEF',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridRowGap: 15,
                gridColumnGap: 10,
                [mq(bp.large)]: {
                  marginBottom: 30,
                  paddingBottom: 50,
                  display: 'grid',
                  gridTemplateColumns: '1.5fr 1.5fr 1.5fr 1.5fr 2fr',
                  gridColumnGap: 20,
                },
                '&>span:nth-of-type(-n+3)': {
                  fontWeight: 700,
                  fontSize: 18,
                  display: 'none',
                  [mq(bp.large)]: {
                    display: 'inline',
                  },
                },
              }}
            >
              <span>Worker</span>
              <span>Contact Number</span>
              <span>Actions</span>
              {gig.bookedWorkers.map((worker, i) => {
                return (
                  <WorkerCard
                    key={worker.uid}
                    gig={gig}
                    worker={worker}
                    firebase={firebase}
                    setRefetchGigs={setRefetchGigs}
                    openModal={openModal}
                    gigSummaryGroupData={gigSummaryGroupData}
                    taxPercentage={taxPercentage}
                  />
                );
              })}
            </div>
            {gigData.mode === 'apply' && gigData.status === 1 && (
              <React.Fragment>
                <h2
                  css={{
                    fontSize: 20,
                    [mq(bp.large)]: {
                      fontSize: 24,
                    },
                  }}
                >
                  Applicants
                </h2>
                {gig.appliedWorkers.map((worker) => {
                  if (worker.available) {
                    return (
                      <ApplicantCard
                        key={worker.uid}
                        gig={gig}
                        worker={worker}
                      />
                    );
                  }
                })}
              </React.Fragment>
            )}
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                [mq(bp.large)]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              }}
            >
              <div
                css={{
                  [mq(bp.large)]: { width: '55%' },
                  h2: {
                    fontSize: 20,
                    [mq(bp.large)]: {
                      fontSize: 24,
                    },
                  },
                }}
              >
                <h2>Description</h2>
                <div
                  dangerouslySetInnerHTML={displayEditorData('description')}
                  css={{
                    width: '71vw',
                    margin: '0 auto',
                    overflowWrap: 'break-word',
                    [mq(bp.large)]: {
                      width: '100%',
                    },
                    a: {
                      color: 'cornflowerblue',
                    },
                    'ul,ol': {
                      paddingLeft: 24,
                      margin: 0,
                    },
                    p: { margin: 0 },
                  }}
                />
                <div
                  css={{
                    marginBottom: 15,
                    h3: {
                      fontSize: 16,
                      margin: '0 0 5px 0',
                    },
                  }}
                >
                  <h2>Instructions</h2>
                  <div
                    css={{
                      marginBottom: 30,
                    }}
                  >
                    <h3>Attire</h3>
                    <div
                      dangerouslySetInnerHTML={displayEditorData('attire')}
                      css={{
                        width: '71vw',
                        margin: '0 auto',
                        overflowWrap: 'break-word',
                        [mq(bp.large)]: {
                          width: '100%',
                        },
                        a: {
                          color: 'cornflowerblue',
                        },
                        'ul,ol': {
                          paddingLeft: 24,
                          margin: 0,
                        },
                        p: { margin: 0 },
                      }}
                    />
                  </div>
                  {gig.bring ? (
                    <div>
                      <h3>Things to Bring</h3>
                      <p>{gig.bring}</p>
                    </div>
                  ) : null}
                  {gig.additional ? (
                    <div>
                      <h3>Additional Information</h3>
                      <div
                        dangerouslySetInnerHTML={displayEditorData(
                          'additional'
                        )}
                        css={{
                          width: '71vw',
                          margin: '0 auto',
                          overflowWrap: 'break-word',
                          [mq(bp.large)]: {
                            width: '100%',
                          },
                          a: {
                            color: 'cornflowerblue',
                          },
                          'ul,ol': {
                            paddingLeft: 24,
                            margin: 0,
                          },
                          p: { margin: 0 },
                        }}
                      />
                    </div>
                  ) : null}
                  {gigData.certs.length > 0 ? (
                    <div
                      css={{
                        marginBottom: 20,
                        [mq(bp.large)]: {
                          marginBottom: 0,
                        },
                      }}
                    >
                      <h2>Certifications & Licenses</h2>
                      {gigData.certs.map((cert, i) => (
                        <div key={i}>
                          <p>
                            {cert.label}
                            {cert.required ? (
                              <span css={{ color: 'red' }}> (Required)</span>
                            ) : (
                              <span css={{ color: 'red' }}> (Preferred)</span>
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                {/* {gig.needCerts ? (
                  <div
                    css={{
                      marginBottom: 20,
                      [mq(bp.large)]: {
                        marginBottom: 0,
                      },
                    }}
                  >
                    <h2>Certifications & Licenses</h2>
                    {gig.certs.map((cert, i) => (
                      <p key={i}>- {cert}</p>
                    ))}
                  </div>
                ) : null} */}
              </div>
              <div css={{ [mq(bp.large)]: { width: 350 } }}>
                <div
                  css={{
                    marginBottom: 20,
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    css={{
                      marginBottom: 8,
                    }}
                  >
                    <p css={{ marginBottom: 0 }}>
                      {gigData.location.address2
                        ? `${gigData.location.address2} - ${gigData.location.formattedAddress}`
                        : `${gigData.location.formattedAddress}`}
                    </p>
                  </div>

                  {/* <a
                    href={`https://www.google.com/maps/dir//${gigData.location.formattedAddress}/@${gigData.location.lat},${gigData.location.lng},15z`}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{ color: '#002E6D', fontWeight: 500 }}
                  >
                    Get Directions to here
                  </a> */}
                </div>
                <div
                  css={{
                    marginBottom: 40,
                  }}
                >
                  {gig.parking[0] && <ParkingIndicator num={1} />}
                  {gig.parking[1] && <ParkingIndicator num={2} />}
                  {gig.parking[2] && <ParkingIndicator num={3} />}
                  <TransitIndicator num={gig.transit} />
                </div>
                {/* {gig.status === 1 ? (
                <div>
                  <button
                    type="button"
                    css={(theme) =>
                      button(theme.buttons.type_dark, theme.buttons.bg2, theme.buttons.hover2)
                    }
                  >
                    Delete Gig
                  </button>
                </div>
              ) : null}
              {gig.status === 2 ? (
                <div>
                  <button
                    type="button"
                    css={(theme) =>
                      button(theme.buttons.type_dark, theme.buttons.bg2, theme.buttons.hover2)
                    }
                  >
                    Applicant Profile
                  </button>
                </div>
              ) : null} */}
                <a
                  href='https://www.workbriefly.com/contact-us/'
                  target='_blank'
                  css={{
                    marginTop: 30,
                    color: '#D72F2F',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <RedFlag css={{ marginRight: 10 }} />
                  Report an Issue
                </a>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div css={{ textAlign: 'center', margin: '20px 0' }}>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                flexDirection: 'column',
                [mq(bp.large)]: {
                  flexDirection: 'row',
                  width: '65%',
                },
              }}
            >
              <GreenCheck
                css={{
                  width: 60,
                  height: 60,
                  [mq(bp.large)]: { width: 50, height: 50 },
                }}
              />
              <h2
                css={(theme) => ({
                  fontSize: 24,
                  color: theme.colors.dark,
                  [mq(bp.large)]: {
                    margin: '0 0 0 10px',
                    fontSize: 30,
                  },
                })}
              >
                Awesome! This gig is complete
              </h2>
            </div>
            <p css={{ maxWidth: 475, margin: '0 auto 20px' }}>
              All workers have completed their shift and this gig is now
              complete! Sit back, relax, and enjoy.
            </p>
            <button
              type='button'
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
              ]}
              onClick={viewDetails}
            >
              View Details
            </button>
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div>
      {gig ? (
        <React.Fragment>
          <div>
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: '64px auto',
                boxShadow: '1px 2px 9px rgba(0, 0, 0, 0.5)',
                zIndex: '1',
                borderRadius: 3,
                overflow: 'hidden',
                [mq(bp.large)]: {
                  gridTemplateColumns: '160px auto',
                },
              }}
            >
              <div
                css={(theme) => ({
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  margin: '0 auto',
                  overflow: 'hidden',
                  position: 'relative',
                  background: '#fff',
                })}
              >
                <img
                  src={authUser.profileImage}
                  alt=''
                  css={{
                    position: 'absolute',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    borderRadius: 3,
                    height: 50,
                    [mq(bp.large)]: {
                      objectFit: 'contain',
                      height: 150,
                    },
                  }}
                />
              </div>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#fff',
                  padding: 5,
                  [mq(bp.large)]: {
                    padding: 16,
                  },
                  '&>div:not(:last-child)': {
                    [mq(bp.large)]: {
                      marginRight: 20,
                    },
                  },
                }}
                onClick={gigsActive === true ? onViewWorkers : onExpand}
              >
                <div css={{ flex: 1 }}>
                  <div css={{ display: 'flex' }}>
                    <span
                      css={{
                        height: 16,
                        width: 16,
                        borderRadius: '50%',
                        backgroundColor: color,
                        display: 'block',
                        marginRight: 8,
                      }}
                    />
                    <p
                      css={{ margin: 0 }}
                    >{`${gig.bookedWorkers.length}/${gig.vacancies}`}</p>
                  </div>
                  <h2
                    css={{
                      marginTop: 0,
                      marginBottom: 2,
                      fontSize: 20,
                      lineHeight: 1.1,
                      fontFamily: 'Lato',
                      [mq(bp.large)]: {
                        width: '100%',
                        marginBottom: '20px',
                        fontSize: 24,
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    {gig.position}
                  </h2>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      [mq(bp.large)]: {
                        flexDirection: 'row',
                      },
                      p: {
                        margin: 0,
                        fontSize: 14,
                        color: '#000',
                        display: 'none',
                        [mq(bp.large)]: {
                          display: 'block',
                        },
                      },
                      h5: {
                        fontFamily: 'Lato',
                        [mq(bp.large)]: {
                          fontWeight: 600,
                        },
                      },
                      div: {
                        [mq(bp.large)]: {
                          marginRight: 40,
                        },
                      },
                    }}
                  >
                    <div>
                      <p>Company</p>
                      <h5
                        css={{
                          marginTop: 0,
                          marginBottom: 0,
                          fontSize: 16,
                          width: '50vw',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden !important',
                          textOverflow: 'ellipsis',
                          [mq(bp.large)]: {
                            maxWidth: '200px',
                            width: 'auto',
                            fontSize: 18,
                          },
                        }}
                      >
                        {gig.businessName}
                      </h5>
                    </div>
                    <div>
                      <p>Time</p>
                      <h5
                        css={{
                          fontSize: 14,
                          margin: 0,
                          color: '#8A8A8A',
                          [mq(bp.large)]: {
                            color: '#272727',
                            fontSize: 18,
                          },
                        }}
                      >
                        {`${gig.start} - ${gig.end}${
                          gig.nextDay ? ' (Next Day)' : ''
                        }`}
                      </h5>
                      {gig.breakTime !== 0 && (
                        <span css={{ fontSize: 12 }}>
                          {gig.breakTime}min {gig.paidBreak ? 'paid' : 'unpaid'}{' '}
                          break
                        </span>
                      )}
                    </div>
                    <div
                      css={{
                        display: 'none',
                        [mq(bp.large)]: {
                          display: 'block',
                        },
                      }}
                    >
                      <p>Pay</p>
                      <h5
                        css={{
                          fontSize: 16,
                          margin: 0,
                          [mq(bp.large)]: {
                            fontSize: 18,
                          },
                        }}
                      >{`${'$'}${gig.totalForThisDay} (${'$'}${
                        gig.payPerHour
                      }/hr)`}</h5>
                    </div>
                  </div>
                  <h5
                    css={{
                      position: 'absolute',
                      right: 0,
                      top: 7,
                      margin: '0',
                      fontSize: 20,
                      textAlign: 'center',
                      lineHeight: 0.8,
                      [mq(bp.large)]: {
                        display: 'none',
                      },
                    }}
                  >
                    {`${'$'}${gig.totalForThisDay}`} <br />
                    <span css={{ color: '#8A8A8A', fontSize: 12 }}>{`(${'$'}${
                      gig.payPerHour
                    }/hr)`}</span>
                  </h5>
                </div>
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <div css={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <div css={{ marginRight: 20 }}>
                      <button
                        type='button'
                        css={(theme) => [
                          button(
                            theme.buttons.type_light,
                            theme.buttons.bg1,
                            theme.buttons.hover1
                          ),
                          {
                            width: '100%',
                            maxWidth: 200,
                            fontSize: 14,
                            display: 'none',
                            [mq(bp.large)]: {
                              display: 'block',
                            },
                          },
                        ]}
                        onClick={gigsActive ? onViewWorkers : onExpand}
                      >
                        {gigsActive
                          ? 'View Workers'
                          : expanded
                          ? 'Hide Details'
                          : 'View Details'}
                      </button>
                    </div>
                    <div
                      css={{
                        width: 24,
                        display: 'none',
                        [mq(bp.large)]: {
                          display: 'block',
                        },
                      }}
                    >
                      <Arrow
                        onClick={gigsActive ? onViewWorkers : onExpand}
                        css={{
                          transform: expanded ? 'scale(1, -1)' : null,
                          transition: '.25s ease',
                        }}
                      />
                    </div>
                  </div>
                  {gig.gigReferenceNumber && (
                    <div css={{ textAlign: 'right' }}>
                      <span
                        css={{
                          fontSize: 10,
                          color: 'grey',
                          fontWeight: 'bold',
                          '&:hover': { color: '#000' },
                        }}
                      >
                        Gig Ref #: {gig.gigReferenceNumber}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            css={{
              padding: '20px 20px 40px',
              boxShadow: '1px 2px 9px rgba(0, 0, 0, 0.5)',
              display: expanded ? 'block' : 'none',
              background: '#fff',
              [mq(bp.small)]: {
                padding: '20px 40px 40px',
              },
            }}
          >
            {itemView()}
          </div>
          <ReportIssue
            gigID={gig.gigID}
            gigData={gigData}
            workerData={reportedWorker}
            reportOpen={reportModalOpen}
            setReportModalOpen={setReportModalOpen}
          />
        </React.Fragment>
      ) : (
        <Ring color='#002E6D' size='22' />
      )}
    </div>
  );
};

export default withFirebase(GigItem);
