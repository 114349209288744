/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useState, useEffect } from 'react';
import { AuthUserContext } from 'library/Session';
import { Ring } from 'react-spinners-css';
import uuid from 'react-uuid';
import {
  button,
  customRadio,
  formInput,
  addButton,
  goBackButton,
  popupContainer,
  buttonContainer,
} from 'theme/styleBlocks';
import { ReactComponent as Visa } from 'assets/icons/visa.svg';
import { ReactComponent as MasterCard } from 'assets/icons/mastercard.svg';
import { ReactComponent as DinersClub } from 'assets/icons/dinersClub.svg';
import { ReactComponent as Discover } from 'assets/icons/discover.svg';
import { ReactComponent as Jcb } from 'assets/icons/jcb.svg';
import { ReactComponent as AmericaExpress } from 'assets/icons/americanExpress.svg';
import { ReactComponent as UnionPay } from 'assets/icons/unionPay.svg';
import { ReactComponent as CreditCard } from 'assets/icons/creditCardIcon.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { withFirebase } from 'library/Firebase';
import { useForm } from 'react-hook-form';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { bp, mq } from '../../constants/mediaQueries';

const SettingsBanking = (props) => {
  const { authUser } = useContext(AuthUserContext);
  const [addCard, setAddCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [cardIndexToRemove, setCardIndexToRemove] = useState(null);
  const [removePending, setRemovePending] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { register, handleSubmit } = useForm();

  const cardIcon = (brand) => {
    switch (brand) {
      case 'visa':
        return <Visa />;
      case 'unionpay':
        return <UnionPay />;
      case 'mastercard':
        return <MasterCard />;
      case 'jcb':
        return <Jcb />;
      case 'diners club':
        return <DinersClub />;
      case 'discover':
        return <Discover />;
      case 'american express':
        return <AmericaExpress />;
      case 'unknown':
        return <CreditCard />;
      default:
        return <CreditCard />;
    }
  };
  const loadCards = () => {
    setLoading(true);
    props.firebase
      .getSavedCards(authUser.stripeCustomerId)
      .then((cardsData) => {
        setCards(cardsData);
        setLoading(false);
      });
  };
  useEffect(() => {
    loadCards();
  }, []);

  const removeCard = (card) => {
    setRemovePending(true);
    props.firebase.removePaymentMethod(card).then(() => {
      loadCards();
      setModal(false);
      setCardIndexToRemove(null);
      setRemovePending(false);
    });
  };
  const cardOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    disabled: saving,
  };
  const handleSaveCard = (data) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setSaving(true);
    setMessage('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    props.firebase
      .getClientSecret(authUser.stripeCustomerId)
      .then((CLIENT_SECRET) => {
        return stripe.confirmCardSetup(CLIENT_SECRET, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: data.nameOnCard,
            },
            metadata: {
              nickname: data.cardNickname,
            },
          },
        });
      })
      .then((result) => {
        if (result.error) {
          setSaving(false);
          setMessage(result.error.message);
        } else {
          props.firebase
            .saveCard(
              result.setupIntent.payment_method,
              authUser.stripeCustomerId
              // makeDefault
            )
            .then(() => {
              loadCards();
              setSaving(false);
              setAddCard(false);
            });
        }
      });
  };

  const openRemoveCardModal = (index) => {
    setModal(true);
    setCardIndexToRemove(index);
  };

  return (
    <div css={{ flex: 1 }}>
      <div
        css={{
          border: '1px solid #969696',
          borderRadius: '4px',
          background: '#fff',
        }}
      >
        <div
          css={{
            padding: 30,
            alignItems: 'center',
          }}
        >
          <h2>Your Credit Cards</h2>
          {loading ? (
            <div
              css={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Ring
                color="#002E6D"
                size={30}
                css={{
                  width: 30,
                  height: 30,
                  marginRight: 10,
                }}
              />
            </div>
          ) : cards.length ? (
            <div css={{ marginBottom: 20 }}>
              <div
                css={{
                  display: 'none',
                  gridTemplateColumns: '2fr 1fr 0.6fr 0.8fr',
                  gridColumnGap: 10,
                  [mq(bp.large)]: {
                    display: 'grid',
                  },
                }}
              >
                <div
                  css={{
                    [mq(bp.large)]: {
                      gridColumn: '2/3',
                    },
                  }}
                >
                  <p css={{ margin: 0 }}>Name on Card</p>
                </div>
                <div css={{ gridColumn: '3/4' }}>
                  <p css={{ margin: 0 }}>Expiry Date</p>
                </div>
              </div>
              <hr />
              {cards.map((card, i) => (
                <div
                  key={uuid()}
                  css={[
                    customRadio(),
                    {
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      marginBottom: 15,
                      marginLeft: 20,
                      [mq(bp.large)]: {
                        gridTemplateColumns: '2fr 1fr 0.6fr 0.8fr',
                        gridColumnGap: 10,
                        marginLeft: 0,
                      },
                      p: {
                        fontSize: 16,
                      },
                    },
                  ]}
                >
                  <div
                    css={{
                      [mq(bp.large)]: {
                        gridColumn: '1/2',
                      },
                    }}
                  >
                    <div
                      css={{
                        fontSize: 16,
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        p: {
                          fontWeight: 600,
                        },
                      }}
                    >
                      <div
                        css={{
                          display: 'inline-block',
                          marginRight: 5,
                          svg: {
                            width: 35,
                            height: 20,
                            verticalAlign: 'middle',
                          },
                        }}
                      >
                        {cardIcon(card.card.brand)}
                      </div>
                      <p
                        css={{
                          marginRight: 5,
                          marginTop: 0,
                          marginBottom: 0,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden !important',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {card.metadata.nickname}
                      </p>
                      <p css={{ color: '#8A8A8A', margin: 0 }}>
                        ending in {card.card.last4}
                      </p>
                    </div>
                  </div>
                  <div
                    css={{
                      paddingLeft: 24,
                      [mq(bp.large)]: { paddingLeft: 0, gridColumn: '2/3' },
                    }}
                  >
                    <p
                      css={{
                        margin: 0,
                        width: 150,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {card.billing_details.name}
                    </p>
                  </div>
                  <div
                    css={{
                      paddingLeft: 24,
                      [mq(bp.large)]: { paddingLeft: 0, gridColumn: '3/4' },
                    }}
                  >
                    <p css={{ margin: 0 }}>
                      {card.card.exp_month}/{card.card.exp_year}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => openRemoveCardModal(i)}
                    css={{
                      border: 'none',
                      color: '#002E6D',
                      cursor: 'pointer',
                    }}
                  >
                    Remove Card
                  </button>
                </div>
              ))}
            </div>
          ) : null}
          {cardIndexToRemove !== null && modal ? (
            <div
              css={{
                display: 'flex',
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                background: 'rgba(0,0,0,.5)',
                top: 60,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 99,
              }}
            >
              <div css={() => [popupContainer(), { height: 'auto' }]}>
                <div
                  css={{
                    background: '#fff',
                    borderRadius: 4,
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: 30,
                    }}
                  >
                    <h3 css={{ margin: 0, fontWeight: 700, fontSize: 20 }}>
                      Remove Payment Method
                    </h3>
                    <button
                      type="button"
                      onClick={() => setModal(false)}
                      disabled={removePending}
                      css={{
                        border: 'none',
                        lineHeight: 1,
                        fontWeight: 500,
                      }}
                    >
                      <Close
                        css={{
                          width: 15,
                          height: 15,
                          path: { fill: '#393939' },
                        }}
                      />
                    </button>
                  </div>
                  <div
                    css={{
                      fontSize: 16,
                      marginBottom: 10,
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      p: {
                        fontWeight: 600,
                      },
                    }}
                  >
                    <div
                      css={{
                        display: 'inline-block',
                        marginRight: 5,
                        svg: {
                          width: 35,
                          height: 20,
                          verticalAlign: 'middle',
                        },
                      }}
                    >
                      {cardIcon(cards[cardIndexToRemove].card.brand)}
                    </div>
                    <p
                      css={{
                        marginRight: 5,
                        marginTop: 0,
                        marginBottom: 0,
                        width: 90,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {cards[cardIndexToRemove].metadata.nickname}
                    </p>
                    <p css={{ color: '#8A8A8A', margin: 0 }}>
                      ending in {cards[cardIndexToRemove].card.last4}
                    </p>
                  </div>
                  <p css={{ marginBottom: 30 }}>
                    If you do not want this payment method to be displayed in
                    your list of payment options, please select &quot;Confirm
                    Remove.&quot;{' '}
                  </p>
                  <div css={buttonContainer}>
                    <button
                      type="button"
                      onClick={() => setModal(false)}
                      css={goBackButton}
                      disabled={removePending}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      onClick={() => removeCard(cards[cardIndexToRemove])}
                      disabled={removePending}
                      css={(theme) =>
                        button(
                          theme.buttons.type_dark,
                          theme.buttons.bg2,
                          theme.buttons.hover2
                        )
                      }
                    >
                      {removePending ? 'Removing' : 'Confirm Remove'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div>
            <button
              type="button"
              onClick={() => setAddCard(true)}
              css={addButton}
            >
              + Add a new card
            </button>
          </div>
          {addCard ? (
            <div>
              <div css={{ marginBottom: 20 }}>
                <p>Enter your card information.</p>
                <div
                  css={{
                    display: 'grid',
                    [mq(bp.large)]: {
                      gridTemplateColumns: '1fr 1fr 1fr 1fr',
                      gridColumnGap: 20,
                      alignItems: 'end',
                    },
                  }}
                >
                  <div
                    css={[formInput, { [mq(bp.large)]: { gridColumn: '1/3' } }]}
                  >
                    <label htmlFor="nameOnCard">Name on card</label>
                    <input
                      type="text"
                      name="nameOnCard"
                      id="nameOnCard"
                      ref={register}
                    />
                  </div>
                  <div
                    css={[formInput, { [mq(bp.large)]: { gridColumn: '3/4' } }]}
                  >
                    <label htmlFor="cardNickname">Card Nickname</label>
                    <input
                      type="text"
                      name="cardNickname"
                      id="cardNickname"
                      ref={register}
                    />
                  </div>
                  <div
                    css={[formInput, { [mq(bp.large)]: { gridColumn: '1/4' } }]}
                  >
                    <label>Card Number</label>
                    <CardElement
                      options={cardOptions}
                      css={{
                        padding: 16,
                        backgroundColor: 'rgba(58,177,202,0.1)',
                        borderRadius: 4,
                      }}
                    />
                  </div>
                  <div css={{ [mq(bp.large)]: { gridColumn: '4/4' } }}>
                    <div css={{ position: 'relative' }}>
                      {saving ? (
                        <Ring
                          color="#fff"
                          size={30}
                          css={{
                            position: 'absolute',
                            left: 12,
                            top: 12,
                            [mq(bp.small)]: {
                              top: 10,
                            },
                          }}
                        />
                      ) : (
                        ''
                      )}
                      <button
                        type="button"
                        onClick={handleSubmit(handleSaveCard)}
                        css={(theme) => [
                          button(
                            theme.buttons.type_light,
                            theme.buttons.bg1,
                            theme.buttons.hover1
                          ),
                          {
                            padding: '16px 10px',
                            marginBottom: 15,
                          },
                        ]}
                      >
                        {saving ? (
                          <Ring
                            color="#002E6D"
                            size={22}
                            css={{
                              width: 22,
                              height: 22,
                              display: 'flex',
                              margin: 'auto',
                            }}
                          />
                        ) : (
                          'Add New Card'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {message ? <p>{message}</p> : null}

                {/**
            <div>
              <label htmlFor="makeDefault">Make default card</label>
              <input
                type="checkbox"
                name="makeDefault"
                id="makeDefault"
                onChange={() => setMakeDefault(!makeDefault)}
                checked={makeDefault}
              />
            </div>
             */}
              </div>
            </div>
          ) : null}
          <p>
            Note: A hold will be placed on the selected card 24 hours prior to
            start of the gig and the card will only be charged upon gig
            completion.
          </p>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(SettingsBanking);
