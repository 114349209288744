import React from 'react';

const ServiceAgreementCopy = () => {
  return (
    <div>
      <h2>CUSTOMER AGREEMENT FOR WORKBRIEFLY SERVICE</h2>
      <p>
        WorkBriefly Inc. (“WorkBriefly”) provides a service (the “Service”) that
        allows its customers to connect with WorkBriefly’s network of
        geographically distributed contractors (“WorkBriefly Workers”) to obtain
        service providers for various short term engagements. Please read this
        Customer Agreement (“Agreement”) in its entirety if you wish to become a
        customer.
      </p>
      <p>
        <strong>ACKNOWLEDGMENT AND ACCEPTANCE OF CUSTOMER AGREEMENT</strong>
      </p>
      <p>
        This Agreement sets out the terms of the relationship between
        WorkBriefly and you. By signing this Agreement and/or by using the
        Application or the WorkBriefly Mobile Application in connection with the
        Service, you represent that (1) you have read, understand, and agree to
        be bound by this agreement, and (2) you have the authority to enter into
        this agreement personally or on behalf of the company you have named as
        the Customer. The terms “you” and “Customer” refer to you or the legal
        entity which you work for or represent. This agreement includes
        WorkBriefly’s Privacy Policy.
      </p>
      <p>
        You agree and understand that certain features of the Application
        (defined below) may be subject to additional terms and conditions or
        registration requirements. You agree to abide by these additional terms
        and you further agree that a violation of those terms shall constitute a
        breach of this Agreement. WorkBriefly reserves the right to change this
        Agreement, and any additional terms at any time, effective upon making
        the modified provisions available on the Application or by notifying you
        directly. You are responsible for regularly reviewing this Agreement for
        updates and modifications to its terms. Continued use of the Application
        and/or the WorkBriefly Mobile Application after any such changes are
        made to this Agreement shall constitute your consent to such changes.
        Other than making the changes available on the Application, WorkBriefly
        does not and will not assume any obligation to notify WorkBriefly
        Workers of any changes to this Agreement, or the creation or
        modification of any additional terms.
      </p>
      <h3>1. DEFINITIONS</h3>
      <p>
        Capitalized terms are defined as set forth below or elsewhere in the
        Agreement.
      </p>
      <p>
        <strong>1.1 “Deliverables” </strong>means the deliverables specified in
        a WorkBriefly Request for delivery by a WorkBriefly Worker to you.
      </p>
      <p>
        <strong>1.2 “WorkBriefly Request” </strong>means a notice provided by
        WorkBriefly to one or more WorkBriefly Workers, which includes a
        description of the services to be provided by the WorkBriefly Workers
        and the associated Deliverables, the date by which the WorkBriefly
        Request must be completed and the fee for the WorkBriefly Worker who
        performs the WorkBriefly Request (the “Payment”). The Payment will be
        subject to an additional charge to compensate WorkBriefly for creating,
        hosting, administering and providing the Application (the “Service
        Fee”).
      </p>
      <p>
        <strong>1.3 “WorkBriefly Mobile Application” </strong>or{' '}
        <strong>“Application”</strong>means the software application used by
        WorkBriefly in connection with the Services.
      </p>
      <p>
        <strong>1.4 “Service Request” </strong>means a request for Service or
        work by you which includes a description of the Deliverables for which
        you wish to engage WorkBriefly Workers. Once the Customer and
        WorkBriefly Worker have agreed upon the terms of a Service Request
        pursuant to Section 2, the Service Request will become a{' '}
        <strong>“Service Engagement”</strong>
      </p>
      <h3>2. SERVICE REQUEST</h3>
      <p>
        Customer may from time to time submit a Service Request to WorkBriefly
        via the Application. We reserve the right to reject any Service Request
        that is not appropriate, that violates the terms of service or use or
        that we believe in good faith to be inconsistent with the business
        interests of WorkBriefly or the integrity of the Application.
      </p>
      <h3>3. FEES AND PAYMENT</h3>
      <p>
        <strong>3.1 Fees. </strong>WorkBriefly charges fees and collects payment
        for each Service Engagement. You agree to provide WorkBriefly with
        complete and accurate billing and contact information, including but not
        limited to, Customer’s legal name, street address, e-mail address, and
        the name and telephone number of an authorized billing contact. You
        agree to update this information within thirty (30) days of any change
        to it. If the contact information you have provided is false or
        fraudulent, WorkBriefly reserves the right to terminate your access to
        the Service, and any outstanding Service Engagement, in addition to
        pursuing any available legal remedies. Generally, you will be billed
        each day for services provided that day. Special payment arrangements
        may be made when appropriate. The Service Fee for engaging a WorkBriefly
        Worker as an independent contractor totals 20% of the Payment amount and
        is generally for creating, hosting, administering, maintaining and
        providing the Application (collectively, the “Invoice Amount”). A
        Service Request shall be considered complete once the Deliverables have
        been accepted by Customer in accordance with Section 4. The charge for
        Service Engagements that involve WorkBriefly’s payroll application for
        hiring WorkBriefly Workers as employees will be determined based on the
        type of engagement and geographic location of the WorkBriefly Worker and
        that is mutually agreed upon by WorkBriefly and you.
      </p>
      <p>
        <strong>3.2 Taxes. </strong>WorkBriefly’s fees do not include any taxes,
        levies, duties or similar governmental assessments of any nature,
        including but not limited to value-added, sales, use or withholding
        taxes, assessable by any local, state, provincial, federal or foreign
        jurisdiction (collectively, “Taxes”). Customer is responsible for paying
        all Taxes associated with its purchases hereunder. If WorkBriefly has
        the legal obligation to pay or collect Taxes for which Customer is
        responsible under this section, the appropriate amount shall be invoiced
        to and paid by Customer.
      </p>
      <p>
        <strong>3.3 Late Cancellation Fee. </strong>In the event that Customer
        cancels a project or a Service Engagement less than twelve (12) hours
        before the performance of the service is to begin, the Customer will be
        required to pay half of the cost of the Service Engagement. The cost
        includes both the payment to the WorkBriefly Worker and the fee paid to
        WorkBriefly.
      </p>
      <p>
        <strong>3.4 Late Payment Charges. </strong>Any invoice which remains
        unpaid after the due date shall bear interest at the rate of 4.5% per
        month. If the amount of any late payment charge exceeds the maximum
        permitted by law, the charge will be reduced to that maximum amount.
      </p>
      <h3>4. IP OWNERSHIP</h3>
      <p>
        <strong>4.1 WorkBriefly IP. </strong>Customer acknowledges that all the
        intellectual property rights in the Application, the Service, the
        WorkBriefly Mobile Application, and any metadata or other information
        generated or submitted to WorkBriefly by a WorkBriefly Worker in the
        course of performing a WorkBriefly Request are owned by WorkBriefly or
        WorkBriefly’s licensors or suppliers (the “WorkBriefly IP”). Customer
        shall not obtain, by this Agreement, any right, title or interest in the
        trademarks of WorkBriefly or WorkBriefly’s licensors, affiliates or
        suppliers, nor shall this Agreement give Customer the right to use,
        refer to, or incorporate in marketing or other materials the name,
        logos, trademarks or copyrights of WorkBriefly or WorkBriefly’s
        licensors, affiliates or suppliers. Customer agrees not to (a)
        reproduce, modify, publish, transmit, distribute, publicly perform or
        display, sell, or create derivative works based on the WorkBriefly IP,
        or (b) rent, lease, loan, or sell access to the WorkBriefly IP.
      </p>
      <p>
        <strong>4.2 Suggestions. </strong>Customer hereby grants to WorkBriefly
        a royalty-free, worldwide, transferable, irrevocable, perpetual license
        to use, reproduce, modify, or incorporate into the WorkBriefly IP, and
        otherwise fully exploit, any suggestions, enhancement requests,
        recommendations or other feedback provided by Customer related to the
        WorkBriefly IP.
      </p>
      <h3>5. CONFIDENTIALITY</h3>
      <p>
        <strong>5.1 Definition of Confidential Information. </strong>As used
        herein, <strong>“Confidential Information”</strong> means all
        confidential information disclosed by a party (
        <strong>“Disclosing Party”</strong>) to the other party (
        <strong>“Receiving Party”</strong>), whether orally or in writing, that
        is designated as confidential or that reasonably should be understood to
        be confidential given the nature of the information and the
        circumstances of disclosure. However, Confidential Information shall not
        include any information that (a) is or becomes generally known to the
        public without breach of any obligation owed to the Disclosing Party,
        (b) was known to the Receiving Party prior to its disclosure by the
        Disclosing Party without breach of any obligation owed to the Disclosing
        Party, (c) is received from a third party without breach of any
        obligation owed to the Disclosing Party, or (d) was independently
        developed by the Receiving Party without access to, or use of, the
        Disclosing Party’s Confidential Information.
      </p>
      <p>
        <strong>5.2 Protection of Confidential Information. </strong>Except as
        otherwise permitted in writing by the Disclosing Party, the Receiving
        Party shall use the same degree of care that it uses to protect the
        confidentiality of its own confidential information of like kind (but in
        no event less than reasonable care) and shall not disclose or use any
        Confidential Information of the Disclosing Party for any purpose outside
        the scope of this Agreement, and the Receiving Party shall only disclose
        the Confidential Information of the Disclosing Party to those of the
        Receiving Party’s employees, contractors and agents who need such access
        to perform obligations or exercise rights under this Agreement and who
        have signed confidentiality agreements with the Receiving Party
        containing protections no less stringent than those herein.
      </p>
      <p>
        <strong>5.3 Compelled Disclosure. </strong>The Receiving Party may
        disclose Confidential Information of the Disclosing Party to the extent
        such disclosure is compelled by law, provided the Receiving Party gives
        the Disclosing Party prior notice of such compelled disclosure (to the
        extent legally permitted) and reasonable assistance, at the Disclosing
        Party’s cost, if the Disclosing Party wishes to contest the disclosure.
        If the Receiving Party is compelled by law to disclose the Disclosing
        Party’s Confidential Information as part of a civil proceeding to which
        the Disclosing Party is a party, the Disclosing Party will reimburse the
        Receiving Party for its reasonable cost of compiling and providing
        secure access to such Confidential Information.
      </p>
      <p>
        <strong>5.4 Information Included in Service Engagements. </strong>
        Notwithstanding the foregoing, Customer acknowledges and agrees that
        some of the information that it provides in Service Engagements will be
        sent to WorkBriefly Workers who will need this information to respond to
        requests to perform one or more WorkBriefly Requests. By submitting a
        Service Request, Customer is requesting, and expressly consents to have
        details of the Service Engagement sent to WorkBriefly Workers that
        provide like or similar services to those sought through the WorkBriefly
        Requests. Customer agrees that all information that Customer provides
        will be accurate, current and truthful to the best of its knowledge.
      </p>
      <h3>6. DISCLAIMERS</h3>
      <p>
        THE SERVICE AND THE DELIVERABLES ARE PROVIDED “AS-IS” AND
        “AS-AVAILABLE.” WorkBriefly EXPRESSLY DISCLAIMS ANY WARRANTIES AND
        CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE
        WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.
        WorkBriefly MAKES NO WARRANTY THAT (A) THE SERVICE OR DELIVERABLES WILL
        MEET CUSTOMER’S REQUIREMENTS; (B) THE SERVICE WILL BE AVAILABLE ON AN
        UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE BASIS; OR (C) THE RESULTS
        THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE OR DELIVERABLES WILL BE
        ACCURATE OR RELIABLE. WorkBriefly DOES NOT AND CANNOT GUARANTEE A MATCH
        BETWEEN EACH WorkBriefly REQUEST AND A WorkBriefly Worker OR THAT THERE
        ARE WorkBriefly WorkerS IN EACH GEOGRAPHIC AREA REQUESTED BY CUSTOMER
        WHO ARE WILLING TO FULFILL THE WorkBriefly REQUEST AT THE TIME AND PLACE
        REQUESTED. CUSTOMER ACKNOWLEDGES AND AGREES THAT WorkBriefly HAS NO
        LIABILITY FOR THE ACTION, OR INACTION, OF ANY WorkBriefly Worker AND
        THAT THERE IS NO EMPLOYMENT, JOINT VENTURE, OR AGENCY RELATIONSHIP
        BETWEEN WorkBriefly AND THE WorkBriefly WorkerS. WorkBriefly DOES NOT
        GUARANTEE OR WARRANT THE WorkBriefly WorkerS’ PERFORMANCE OF THE
        WorkBriefly REQUESTS OR THE OUTCOME OR QUALITY OF THE DELIVERABLES
        PROVIDED.
      </p>
      <h3>7. LIMITATION OF LIABILITY; INDEMNITY</h3>
      <p>
        IN NO EVENT SHALL WorkBriefly BE LIABLE TO CUSTOMER OR ANY THIRD PARTY
        FOR ANY LOST PROFITS OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
        INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM CUSTOMER’S USE OF
        THE WorkBriefly IP OR THE DELIVERABLES, EVEN IF WorkBriefly HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO
        THE CONTRARY CONTAINED HEREIN, WorkBriefly’S LIABILITY TO CUSTOMER FOR
        ANY DAMAGES ARISING FROM OR RELATED TO CUSTOMER’S USE OF THE WorkBriefly
        IP OR THE DELIVERABLES (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
        FORM OF ACTION) WILL AT ALL TIMES BE LIMITED TO THE FEES CUSTOMER PAID
        TO WorkBriefly IN THE TWELVE (12) MONTHS PRIOR TO THE OCCURRENCE OF THE
        FIRST CLAIM TO GIVE RISE TO LIABILITY UNDER THE AGREEMENT.
      </p>
      <p>
        Customer warrants and represents that it has properly classified all
        WorkBriefly Workers that are used to provide services through the
        WorkBriefly App. Customer further agrees to indemnify, defend, and hold
        harmless WorkBriefly and its parents, affiliates, employees, and agents
        from and against any and all liabilities, losses, damages, costs, and
        other expenses (including attorney fees and costs associated with
        litigation) arising from or relating to: (1) a breach by you of any
        representation, warranty, covenant, or obligation in this Agreement; (2)
        any negligent or intentional act or omission committed by you, in
        connection with the performance of this Agreement, which act or omission
        gives rise to any claim for damages against you, WorkBriefly and/or its
        parents, affiliates, employees or agents; or (3) your violation of
        applicable law. WorkBriefly specifically denies any obligation to defend
        and/or indemnify you from and against any third party claims made
        against you arising from any negligent or intentional act or omission
        committed by you in connection with the performance of any WorkBriefly
        engagement.
      </p>
      <h3>
        8. RELATIONSHIPS BETWEEN WorkBriefly, WorkBriefly Workers AND THE
        CUSTOMERS
      </h3>
      <p>
        <strong>8.1 </strong>WorkBriefly provides a platform through which
        Customers and WorkBriefly Workers can buy and sell Services online.
        Under this Agreement (and WorkBriefly’s service agreement with
        WorkBriefly Workers), WorkBriefly provides services to both Customers
        and WorkBriefly Workers, including the provision of a technology
        platform, aimed at facilitating the formation of contracts between
        Customers and WorkBriefly Workers and informally managing disputes
        related to those contracts. Through the Application, Customers can post
        Service Requests and invite WorkBriefly Workers to accept. If a Customer
        and WorkBriefly Worker agree on terms, including how a WorkBriefly
        Worker will be classified, a Contract is formed directly between such
        Customer and WorkBriefly Worker.
      </p>
      <p>
        <strong>8.2 </strong>The Parties expressly agree that no joint venture,
        partnership, employment, or agency agreement exists between them as a
        result of this Agreement or any use of the Application.
      </p>
      <p>
        <strong>8.3 Worker classification. </strong>Customer assumes all
        liability for proper classification of WorkBriefly Workers as
        independent contractors or employees based on applicable legal
        guidelines. This Agreement does not create a partnership or agency
        relationship between Customer and WorkBriefly Worker. WorkBriefly Worker
        does not have authority to enter into written or oral — whether implied
        or express — contracts on behalf of Customer. WorkBriefly Worker
        acknowledges that WorkBriefly does not, in any way, supervise, direct,
        or control WorkBriefly Worker’s work or Services performed in any
        manner. WorkBriefly does not set WorkBriefly Worker’s work hours and
        location of work, nor is WorkBriefly involved in determining the type or
        manner compensation to be paid for any Service Request. WorkBriefly will
        not provide WorkBriefly Worker with training or any equipment, labor or
        materials needed for a particular Request. WorkBriefly will not deduct
        any amount for withholding, unemployment, Social Security, or other
        taxes as it would in the case of an employee. Customer and WorkBriefly
        Worker will be solely responsible for all tax returns and payments
        required to be filed with or made to any federal, provincial, or local
        tax authority, in any nation, with respect to WorkBriefly Worker’s
        performance of Service Requests. For Contracts classified as independent
        contractor relationships, Customer may not require an exclusive
        relationship between Customer and WorkBriefly Worker. A WorkBriefly
        Worker classified as independent contractor is free at all times to
        perform Requests, be employed by or otherwise engage with persons or
        businesses other than Customer, including any competitor of Customer.
        For Contracts classified as employer-employee relationships, Customer
        will manage the WorkBriefly Request through WorkBriefly’s payrolling
        program, where the WorkBriefly Worker becomes an hourly employee of
        WorkBriefly’s staffing affiliate and WorkBriefly Worker and Customer
        enter into appropriate additional agreements. Customer and WorkBriefly
        Worker agree to indemnify, hold harmless and defend WorkBriefly from any
        and all claims arising out of or related to their Service Request,
        including but not limited to claims that WorkBriefly Worker was
        misclassified as an independent contractor, any liabilities arising from
        a determination by a court, arbitrator, government agency or other body
        that WorkBriefly Worker was misclassified (including, but not limited
        to, taxes, penalties, interest and attorney’s fees), any claim that
        WorkBriefly was an employer or joint employer of WorkBriefly Worker, as
        well as claims under any employment-related laws, such as those relating
        to employment termination, employment discrimination, harassment or
        retaliation, as well as any claims for overtime pay, sick leave, holiday
        or vacation pay, retirement benefits, worker’s compensation benefits,
        unemployment benefits, or any other employee benefits. Customer warrants
        it will comply with all applicable wage and hour laws, including
        obligations under FLSA, or other federal, provincial, and local wage and
        hour laws.
      </p>
      <h3>9. TERMINATION</h3>
      <p>
        <strong>9.1 Termination Without Cause. </strong> Either party may
        terminate this Agreement without cause, effective immediately upon
        notice to the other party, however, cancellation of this Agreement shall
        not relieve any party of their obligations relative to any Service
        Engagements that has been entered into but not completed at the time of
        the cancellation.
      </p>
      <p>
        <strong>9.2 Surviving Provisions. </strong> Sections 3 (
        <strong>“Fees and Payment”</strong>), 5 (<strong>“IP Ownership”</strong>
        ), 6 (<strong>“Confidentiality”</strong>), 7 (
        <strong>“Disclaimers”</strong>), 8 (
        <strong>“Limitation of Liability”</strong>), 9.2 (
        <strong>“Surviving Provisions”</strong>) and 10 (
        <strong>“Miscellaneous”</strong>) shall survive any termination of this
        Agreement.
      </p>
      <h3>10. MISCELLANEOUS</h3>
      <p>
        <strong>10.1 Law. </strong>This Agreement or any claim, cause of action
        or dispute (“Claim”) arising out of or related to this Agreement shall
        be governed by the laws of the Province of British Columbia regardless
        of your country of origin or where you access WorkBriefly, and
        notwithstanding any conflicts of law principles.
      </p>
      <p>
        <strong>10.2 Arbitration. </strong>You agree that THE SOLE AND EXCLUSIVE
        FORUM AND REMEDY FOR ANY AND ALL DISPUTES AND CLAIMS RELATING IN ANY WAY
        TO OR ARISING OUT OF THESE TERMS OF USE, THE APPLICATION AND/OR THE
        SERVICES PROVIDED (INCLUDING YOUR VISIT TO OR USE OF THE WEBSITE AND/OR
        THE SERVICE) SHALL BE FINAL AND BINDING ARBITRATION, except that to the
        extent that either of us has in any manner infringed upon or violated or
        threatened to infringe upon or violate the other party’s patent,
        copyright, trademark or trade secret rights, or you have otherwise
        violated any of the user conduct rules set forth above then the parties
        acknowledge that arbitration is not an adequate remedy at law and that
        injunctive or other appropriate relief may be sought.
      </p>
      <p>
        To the fullest extent permitted by applicable law, NO ARBITRATION OR
        CLAIM UNDER THESE TERMS OF USE SHALL BE JOINED TO ANY OTHER ARBITRATION
        OR CLAIM, INCLUDING ANY ARBITRATION OR CLAIM INVOLVING ANY OTHER CURRENT
        OR FORMER USER OF THE SERVICE, AND NO CLASS ARBITRATION PROCEEDINGS
        SHALL BE PERMITTED. In no event shall any claim, action or proceeding by
        you related in any way to the Application and/or the Service (including
        your visit to or use of the Application and/or the Service) be
        instituted more than three (3) years after the cause of action arose.
      </p>
      <p>
        Before filing a Claim for arbitration or otherwise seeking relief in a
        court of law, you agree to first inform WorkBriefly
        (support@WorkBriefly.com) of your complaint and seek resolution. This
        notice of dispute must include: your name, relevant account information,
        a brief description of your dispute, and contact information, so that
        WorkBriefly may evaluate the dispute and attempt to informally resolve
        same. WorkBriefly will have 60 days from the date of your original
        complaint to informally resolve the dispute, which if successful will
        avoid the need for further action.
      </p>
      <p>
        <strong>10.3 Arbitration Procedures. </strong>In the unlikely event that
        you and WorkBriefly end up in a legal dispute and have not been able to
        resolve it within 60 days of your original informal claim, you and
        WorkBriefly agree to the following:
      </p>
      <p>
        All Claims (excluding claims for injunctive or other equitable relief)
        must be resolved through binding arbitration before an AAA arbitrator
        located in British Columbia under the commercial dispute resolution
        rules then in effect for AAA, except as provided herein. Any disputes as
        to the applicable rules and procedures shall be resolved by the AAA
        arbitrator.
      </p>
      <p>
        The party wishing to initiate arbitration must submit a written demand
        for arbitration. If you initiate a Claim, you will submit the demand by
        certified mail to WorkBriefly at 20856 76 Ave, Langley Twp, BC V2Y 0S7,
        ATTN: WorkBriefly Management. If WorkBriefly initiates a claim,
        WorkBriefly will serve a demand for arbitration upon you by email to the
        email address on file with WorkBriefly, and may send a copy by certified
        mail to your last known address (or to another address specified by you
        in your notice of dispute of your informal claim). You agree to service
        of process in that manner. Any demand for arbitration by either party
        shall identify the parties to the dispute, describe the legal and
        factual basis of the dispute, and specifically state the remedy being
        sought.
      </p>
      <p>
        <strong>10.4 Severability. </strong>If any provision of this Agreement
        is, for any reason, held to be invalid or unenforceable, the other
        provisions of this Agreement will be unimpaired, and the invalid or
        unenforceable provision will be deemed modified so that it is valid and
        enforceable to the maximum extent permitted by law.
      </p>
      <p>
        <strong>10.5 No Assignment. </strong>This Agreement and your rights and
        obligations under this Agreement may not be assigned, delegated, or
        otherwise transferred, in whole or in part, by operation of law or
        otherwise, by you without WorkBriefly’s express prior written consent.
        Any attempted assignment, delegation or transfer in violation of the
        foregoing will be null and void. WorkBriefly may assign this Agreement
        or any of its rights under this Agreement to any third party with or
        without your written consent.
      </p>
      <p>
        <strong>10.6 Notices. </strong>WorkBriefly may give any notice required
        by this Agreement by means of a general notice on the Application,
        electronic mail to your email address on record with WorkBriefly, or by
        written communication sent by first class mail or pre-paid post to your
        address on record with WorkBriefly. Such notice shall be deemed to have
        been given upon the expiration of forty-eight (48) hours after mailing
        or posting (if sent by first class mail or pre-paid post) or upon
        sending (if sent by email). Except as set forth in Section 10.3 above,
        you may give notice to WorkBriefly, addressed to the attention of
        WorkBriefly Management. Such notice shall be deemed given when received
        by WorkBriefly by letter delivered by nationally recognized overnight
        delivery service or first class postage prepaid mail at the following
        address: 20856 76 Ave, Langley Twp, BC V2Y 0S7 or email to
        support@WorkBriefly.com.
      </p>
      <p>
        <strong>10.7 Waiver. </strong>All waivers must be in writing and signed
        by the party to be charged. Any waiver or failure to enforce any
        provision of this Agreement on one occasion will not be deemed a waiver
        of any other provision or of such provision on any other occasion.
      </p>
      <p>
        <strong>10.8 Entire Agreement. </strong>This Agreement is the final,
        complete and exclusive agreement of the parties with respect to the
        subject matter hereof and supersedes and merges all prior or
        contemporaneous communications and understandings between the parties.
        Except as permitted herein, no modification or amendment to this
        Agreement will be effective unless in writing and signed by the party to
        be charged. However, to the extent of any conflict or inconsistency
        between the provisions in the body of this Agreement and any Service
        Engagement, the terms of this Agreement shall prevail. Notwithstanding
        any language to the contrary therein, no terms or conditions stated in
        any purchase order or other order documentation (excluding Service
        Engagements) shall be incorporated into or form any part of this
        Agreement, and all such terms and conditions shall be null and void.
      </p>
      <p>
        <strong>10.9 Modifications to Application.</strong>WorkBriefly reserves
        the right at any time to modify or discontinue, temporarily or
        permanently, the Application or the Service (or any part thereof) with
        or without notice. You agree that WorkBriefly shall not be liable to you
        or to any third party for any modification, suspension or discontinuance
        of the Application or the Service.
      </p>
      <h3>11. CONTACTING WorkBriefly</h3>
      <p>
        If you wish to report a violation of the Customer Agreement, have any
        questions or need assistance, please contact Customer Support at
        support@WorkBriefly.com.
      </p>
    </div>
  );
};

export default ServiceAgreementCopy;
