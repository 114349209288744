/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext } from 'react';
import { AuthUserContext } from 'library/Session';
import { Link } from 'react-router-dom';
import AuthNavigation from 'components/AuthNavigation';
import Topbar from 'components/Topbar';
import Bottombar from 'components/Bottombar';
import { button, sideNav, topBarContainer } from 'theme/styleBlocks';
import { ReactComponent as Warning } from 'assets/icons/warningIcon.svg';
import { bp, mq } from '../../constants/mediaQueries';
// import Location from './Location';
// import Industry from './Industry';

const Profile = () => {
  const { authUser } = useContext(AuthUserContext);

  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.light,
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
      })}
    >
      <React.Fragment>
        {authUser ? (
          <div css={sideNav}>
            <div css={{ padding: '20px 0' }}>
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo-white.svg?alt=media&token=8d014c04-599a-40e7-95b1-23090cab3430"
                  alt=""
                  css={{ height: 50 }}
                />
              </Link>
            </div>
            <AuthNavigation />
            <a
              href="/create"
              css={(theme) => [
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                ),
                { textAlign: 'center' },
              ]}
              // onClick={(e) => e.preventDefault()}
            >
              Create a Gig
            </a>
          </div>
        ) : null}
        <div>
          {authUser ? (
            <div css={topBarContainer}>
              <Topbar bgColor="#F3F3F3" pageTitle="Profile" />
              <Bottombar />
            </div>
          ) : null}
          <div
            css={() => ({
              flex: 1,
              display: 'flex',
              background: '#FAFAFA',
              height: '100%',
            })}
          >
            <div
              css={() => ({
                width: '100%',
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                marginBottom: 65,
                [mq(bp.large)]: {
                  flexDirection: 'row',
                  margin: '0',
                },
              })}
            >
              <div
                css={(theme) => ({
                  background: theme.colors.light,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '20px',
                  [mq(bp.large)]: {
                    padding: '20px 40px',
                    justifyContent: 'flex-start',
                    width: '30%',
                    borderBottomRightRadius: 47,
                    borderTopRightRadius: 47,
                  },
                })}
              >
                <div>
                  <h1
                    css={{
                      textAlign: 'center',
                      display: 'none',
                      [mq(bp.large)]: { display: 'block' },
                    }}
                  >
                    Your Profile
                  </h1>
                  <div
                    css={{
                      textAlign: 'center',
                      [mq(bp.large)]: {
                        marginTop: 75,
                      },
                    }}
                  >
                    <div
                      css={{
                        position: 'relative',
                        display: 'inline-block',
                      }}
                    >
                      <div
                        css={{
                          width: 150,
                          height: 150,
                          overflow: 'hidden',
                          border: '1px solid lightgrey',
                          background: '#ECF0F4',
                        }}
                      >
                        <img
                          src={authUser.profileImage || ''}
                          alt=""
                          css={{
                            objectFit: 'contain',
                            height: '100%',
                            width: '100%',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <h2
                    css={(theme) => ({
                      textAlign: 'center',
                      color: theme.colors.primary,
                      fontWeight: 'bold',
                      fontSize: 24,
                    })}
                  >
                    {authUser.firstName} {authUser.lastName}
                  </h2>
                  <div
                    css={{
                      textAlign: 'center',
                      backgroundColor: '#FFF0C6',
                      padding: '8px 20px',
                      marginBottom: 20,
                      p: { margin: '0 0 8px 0' },
                    }}
                  >
                    <h4>business metrics here?</h4>
                    <div css={{ marginBottom: 24 }}>
                      <p>98% On Time</p>
                      <p>100% Dressed Appropriately</p>
                      <p>95% Would Hire Again</p>
                    </div>
                    <p css={{ color: '#002E6D', fontWeight: 500 }}>
                      (Based on 42 reviews)
                    </p>
                  </div>
                  {/* <Location
                  location={authUser.location}
                  preferences={authUser.preferences}
                /> */}
                  {/* <Industry
                  industryPreference={authUser.preferences.industries}
                /> */}
                </div>
              </div>
              <div
                css={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  padding: 20,
                  [mq(bp.large)]: {
                    padding: 40,
                  },
                }}
              >
                <div>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '2px solid #002E6D',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '4px',
                      padding: 16,
                      marginBottom: 15,
                    }}
                  >
                    <div css={{ marginRight: 16 }}>
                      <Warning css={{ width: 30, height: 'auto' }} />
                    </div>
                    <p css={{ margin: 0 }}>
                      Please{' '}
                      <a css={{ textDecoration: 'underline' }} href="/setup">
                        complete filling out your profile
                      </a>{' '}
                      in order to start claiming gigs and earning money on
                      WorkBriefly.
                    </p>
                  </div>
                  <div>
                    <h3>Gigs Completed</h3>
                    <div>
                      <p>No Gigs Completed</p>
                    </div>
                  </div>
                  <div
                    css={{
                      border: '2px solid #002E6D',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '4px',
                      padding: 16,
                      marginTop: 80,
                      color: '#000',
                    }}
                  >
                    <h3>
                      Hello {authUser.firstName} and welcome to your WorkBriefly
                      Profile!
                    </h3>
                    <p>
                      At the moment the platform is limited to your profile and
                      settings as we continue to work hard behind the scenes to
                      make more features available. We’ll keep you posted here
                      and by email as features are enabled and gigs become
                      available. Thank you for your support and understanding!
                    </p>
                  </div>
                </div>
              </div>
              <div
                css={(theme) => ({
                  background: theme.colors.light,
                  height: '100%',
                  padding: '20px 40px',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  [mq(bp.large)]: {
                    height: 'auto',
                    width: '30%',
                    borderBottomLeftRadius: 47,
                    borderTopLeftRadius: 47,
                  },
                })}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Profile;
