/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { ReactComponent as TransitIcon } from 'assets/icons/transitIcon.svg';

const TransitIndicator = ({ num }) => {
  const [text, setText] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    switch (num) {
      case '1':
        setText('Transit options are nearby');
        setColor('#74B711');
        break;
      case '2':
        setText('Transit options are only a walk away');
        setColor('#FFC628');
        break;
      case '3':
        setText('There are no transit options nearby');
        setColor('#D72F2F');
        break;
      default:
        break;
    }
  }, [num]);
  return (
    <div css={{ display: 'flex', margin: '10px 0' }}>
      <TransitIcon css={{ marginRight: 10, path: { fill: color } }} />
      <p css={{ margin: 0 }}>{text}</p>
    </div>
  );
};

export default TransitIndicator;
