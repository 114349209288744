/** @jsx jsx */
import { jsx } from '@emotion/core';
import { bp, mq } from 'constants/mediaQueries';

const StartGuide = () => {
  return (
    <div>
      <h2>Business Start Guide</h2>
      <p>
        1. Create a gig by clicking on the “Create a gig” Button and following
        the steps.
      </p>
      <div
        css={{
          width: '100%',
          [mq(bp.large)]: { maxWidth: 750, margin: '30px auto 50px' },
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fbiz-getting-started-1.jpg?alt=media&token=cee42170-5c15-43f2-9e60-be85814b2a3c"
          alt="worker start 1"
          css={{ width: '100%' }}
        />
      </div>
      <p>
        2. Invite recommended workers to claim your gig by sending them
        requests.
      </p>
      <div
        css={{
          width: '100%',
          [mq(bp.large)]: { maxWidth: 750, margin: '30px auto 50px' },
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fbiz-getting-started-2.jpg?alt=media&token=e932d865-2001-4458-a851-611f3c3a525f"
          alt="worker start 1"
          css={{ width: '100%' }}
        />
      </div>
      <p>
        3. Prepare a brief introduction and an explanation of what needs to be
        done so that you can brief the worker(s) upon their arrival. (We
        recommend creating a short script/document that can be reused).
      </p>
      <p>
        4. Prepare any attire/equipment that you might require the worker to
        wear/use for the job (eg. apron, mask, gloves, goggles, etc).
      </p>
      <p>
        5. When the worker(s) arrive for a gig they will be prompted to
        “Check-in”. Please confirm their check-in by clicking “Confirm Check-in”
        (do this by clicking on the gig from your list of “active gigs” in the
        side menu).
      </p>
      <p>
        6. After the “check-in” process please brief the worker and get them
        started on the gig.
      </p>
      <p>
        7. Once the gig is done/time is up, the worker will report to you to
        confirm the gig has been completed. Please then click “Confirm Gig
        Completion” (similar to the way you had confirmed check-in) before
        releasing the worker.
      </p>
      <p>
        8. After this check-out process is completed please review the worker by
        answering the three simple yes or no questions provided.
      </p>
      <p css={{ marginTop: 70 }}>
        Please note that there are two ways workers can be booked for a gig:
      </p>
      <ul>
        <li>
          By requesting recommended workers shown to you after creating a gig.
        </li>
        <li>
          Workers can also be booked by ‘claiming the gig’ when it appears on
          their list of available gigs.
        </li>
        <li>
          Please note that gigs are claimed and therefore booked by workers on a
          first come, first served basis till all vacancies you have made for a
          particular gig are filled.
        </li>
      </ul>
    </div>
  );
};

export default StartGuide;
