/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, useRef } from 'react';
import { withFirebase } from 'library/Firebase';
import { useHistory } from 'react-router-dom';
import {
  leftBox,
  button,
  formInput,
  savedContainer,
  twoColumn,
  buttonContainer,
  goBackButton,
  completeText,
  completedIcon,
  addButton,
  blueBox,
} from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';
import Select from 'react-select';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import useScript from 'hooks/useScript';
import { useForm, Controller } from 'react-hook-form';
import composeRefs from '@seznam/compose-react-refs';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { goolgeApi } from 'constants/config';

const provinceList = [
  { label: 'Alberta', value: 'Alberta' },
  { label: 'British Columbia', value: 'British Columbia' },
  { label: 'Manitoba', value: 'Manitoba' },
  { label: 'New Brunswick', value: 'New Brunswick' },
  { label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
  { label: 'Nova Scotia', value: 'Nova Scotia' },
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Prince Edward Island', value: 'Prince Edward Island' },
  { label: 'Quebec', value: 'Quebec' },
  { label: 'Saskatchewan', value: 'Saskatchewan' },
];

const Locations = (props) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const defaultValues = {
    nickname: '',
    country: 'Canada',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    province: '',
  };
  // const [location, setLocation] = useState(initialState);
  const [saving, setSaving] = useState(false);
  const [changed, setChanged] = useState(false);
  const [saved, setSaved] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${goolgeApi()}&libraries=places`
  );
  const locationRef = useRef(null);
  const history = useHistory();
  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isSubmitted },
  } = useForm();

  useEffect(() => {
    if (isSubmitted && !Object.keys(errors).length) reset();
  }, [isSubmitted, reset, errors]);
  useEffect(() => {
    setLocationList(props.locations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setScriptLoaded(loaded);
  }, [loaded]);
  const onAddressChange = (addressParts) => {
    let streetAddress;
    if (addressParts.route) {
      streetAddress = addressParts.route;
    }
    if (addressParts.street_number) {
      streetAddress = addressParts.street_number;
    }
    if (addressParts.street_number && addressParts.route) {
      streetAddress = `${addressParts.street_number} ${addressParts.route}`;
    }
    setValue('country', addressParts.country);
    setValue('address1', streetAddress || '');
    setValue('address2', addressParts.premise);
    setValue('city', addressParts.locality || '');
    setValue('postalCode', addressParts.postal_code);
    setValue('province', {
      label: addressParts.administrative_area_level_1 || '',
      value: addressParts.administrative_area_level_1 || '',
    });
    // setLocation((prev) => ({
    //   ...prev,
    //   country: addressParts.country || '',
    //   address1: streetAddress || '',
    //   city: addressParts.locality || '',
    //   postalCode: addressParts.postal_code || '',
    //   province: {
    //     label: addressParts.administrative_area_level_1 || '',
    //     value: addressParts.administrative_area_level_1 || '',
    //   },
    // }));
  };

  useEffect(() => {
    let listener;
    if (scriptLoaded) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        locationRef.current,
        { types: ['geocode', 'establishment'] }
      );
      autoComplete.setComponentRestrictions({ country: ['ca'] });
      autoComplete.setFields(['address_components', 'geometry']);

      listener = window.google.maps.event.addListener(
        autoComplete,
        'place_changed',
        () => {
          // eslint-disable-next-line camelcase
          const { address_components, geometry } = autoComplete.getPlace();
          if (!geometry) return;
          const addressParts = {};

          address_components.map((part) => {
            addressParts[part.types[0]] = part.long_name;
            return addressParts;
          });
          onAddressChange(addressParts);
        }
      );
      return () => {
        listener.remove();
        autoComplete.unbindAll();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);

  useEffect(() => {
    if (locationList.length !== props.locations.length) {
      return setChanged(true);
    }
    if (JSON.stringify(locationList) !== JSON.stringify(props.locations)) {
      return setChanged(true);
    }
    setChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationList.length]);

  // const onChange = (e) => {
  //   e.persist();
  //   setLocation((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  // };
  // const onProvinceChange = (value) => {
  //   setLocation((prev) => ({ ...prev, province: value }));
  // };
  const addLocation = (data) => {
    const list = [...locationList];
    const location = { ...data };
    list.push(location);
    // if (!locationList.length) {
    //   setLocationList([initialState]);
    // }
    // if (locationList.length) {
    //   setLocationList((prev) => [...prev, initialState]);
    // }
    setLocationList(list);
    // setLocation(initialState);
    reset(defaultValues);
  };
  const remove = (i) => {
    const list = [...locationList];
    list.splice(i, 1);
    setLocationList(list);
  };
  const save = () => {
    setSaving(true);
    props.firebase.saveLocations(locationList).then(() => {
      setSaving(false);
      setSaved(true);
    });
  };

  return (
    <div>
      <h2>Additional Business Locations (optional)</h2>
      <div css={twoColumn}>
        <div css={leftBox}>
          {saved ? (
            <div css={savedContainer}>
              <GreenCheck css={completedIcon} />
              <p css={completeText}>Awesome! Your Locations has been added.</p>
              <a
                href="/setup"
                css={(theme) =>
                  button(
                    theme.buttons.type_dark,
                    theme.buttons.bg2,
                    theme.buttons.hover2
                  )
                }
              >
                Continue
              </a>
            </div>
          ) : (
            <div>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr ',
                  gridColumnGap: 20,
                }}
              >
                <div css={[formInput, { gridColumn: '1/3' }]}>
                  <label htmlFor="nickname">Nickname</label>
                  <input
                    type="text"
                    name="nickname"
                    id="nickname"
                    ref={register({
                      required: true,
                    })}
                    placeholder="e.g. Robson Branch"
                    // onChange={(e) => onChange(e)}
                    css={{ borderColor: errors.nickname && 'red !important' }}
                  />
                  <FormFieldErrorMessage error={errors.nickname} />
                </div>
                <div css={[formInput, { gridColumn: '1/3' }]}>
                  <label htmlFor="address1">Business Address 1</label>
                  <input
                    type="text"
                    name="address1"
                    id="address1"
                    placeholder="Enter your business street address"
                    ref={composeRefs(locationRef, register({ required: true }))}
                    // onChange={(e) => onChange(e)}
                    css={{ borderColor: errors.address1 && 'red !important' }}
                  />
                  <FormFieldErrorMessage error={errors.address1} />
                </div>
                <div css={[formInput, { gridColumn: '1/3' }]}>
                  <label htmlFor="address2">Business Address 2</label>
                  <input
                    type="text"
                    name="address2"
                    id="address2"
                    placeholder="Enter your apartment, suite, unit number etc. (optional)"
                    ref={register}
                    // onChange={(e) => onChange(e)}
                  />
                </div>
                <div
                  css={[
                    formInput,
                    {
                      gridColumn: '1/3',
                      [mq(bp.large)]: {
                        gridColumn: 'unset',
                      },
                    },
                  ]}
                >
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    placeholder="Enter your city"
                    // onChange={(e) => onChange(e)}
                    ref={register({
                      required: true,
                    })}
                    css={{ borderColor: errors.city && 'red !important' }}
                  />
                  <FormFieldErrorMessage error={errors.city} />
                </div>
                <div
                  css={[
                    formInput,
                    {
                      gridColumn: '1/3',
                      [mq(bp.large)]: {
                        gridColumn: 'unset',
                      },
                    },
                  ]}
                >
                  <label htmlFor="province">Province</label>
                  <Controller
                    as={Select}
                    control={control}
                    name="province"
                    id="province"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        background: 'rgba(58, 177, 202, 0.1)',
                        border: '1px solid transparent',
                        borderColor: errors.province && 'red !important',
                        cursor: 'pointer',
                        fontSize: 14,
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: 14,
                        cursor: 'pointer',
                      }),
                      indicatorSeparator: () => ({ display: 'none' }),
                      valueContainer: (provided) => ({
                        ...provided,
                        fontSize: 16,
                        padding: '0 8px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: 16,
                      }),
                    }}
                    options={provinceList}
                    placeholder="Select your province"
                    defaultValue=""
                    // onChange={(option) => onProvinceChange(option)}
                    isDisabled={false}
                    // value={location.province}
                    isSearchable={false}
                    rules={{ required: true }}
                  />
                  <FormFieldErrorMessage error={errors.province} />
                </div>
                <div
                  css={[
                    formInput,
                    {
                      gridColumn: '1/3',
                      [mq(bp.large)]: {
                        gridColumn: 'unset',
                      },
                    },
                  ]}
                >
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    name="country"
                    // value={location.country}
                    defaultValue="Canada"
                    // onChange={(e) => onChange(e)}
                    placeholder="Select your country"
                    id="country"
                    disabled
                    ref={register({ required: true })}
                  />
                </div>
                <div
                  css={[
                    formInput,
                    {
                      gridColumn: '1/3',
                      [mq(bp.large)]: {
                        gridColumn: 'unset',
                      },
                    },
                  ]}
                >
                  <label htmlFor="postalCode">Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/,
                        message: 'Invalid Postal Code',
                      },
                    })}
                    // onChange={(e) => onChange(e)}
                    placeholder="Enter your postal code"
                    id="postalCode"
                    css={{ borderColor: errors.postalCode && 'red !important' }}
                  />
                  <FormFieldErrorMessage error={errors.postalCode} />
                </div>
              </div>
              <div css={{ marginBottom: 40, marginTop: 8 }}>
                <button
                  type="button"
                  onClick={handleSubmit(addLocation)}
                  disabled={saving}
                  css={addButton}
                >
                  + Add this Location
                </button>
              </div>
              {locationList ? (
                <div>
                  {locationList.map((item, i) => (
                    <div key={i} css={blueBox}>
                      <p
                        css={{
                          margin: 0,
                          color: '#FFF',
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          fontSize: 16,
                          [mq(bp.small)]: {
                            fontSize: 18,
                          },
                        }}
                      >
                        {item.nickname ? `${item.nickname} at ` : null}
                        {item.address2 ? `${item.address2} - ` : null}
                        {item.address1}
                      </p>
                      <button
                        type="button"
                        onClick={() => remove(i)}
                        css={{
                          border: 'none',
                          background: 'none',
                          color: '#FFFFFF',
                          fontWeight: 'bold',
                          fontSize: 18,
                          padding: 0,
                          display: 'flex',
                        }}
                      >
                        <Close css={{ height: 16 }} />
                      </button>
                    </div>
                  ))}
                </div>
              ) : null}
              <div css={buttonContainer}>
                <button
                  onClick={() => history.push('/setup')}
                  type="button"
                  css={goBackButton}
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={save}
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                  disabled={!changed}
                >
                  {saving ? 'Saving' : 'Save'}
                </button>
              </div>
            </div>
          )}
        </div>
        <div>
          <div>
            <h3 css={{ marginTop: 0 }}>
              Additional business locations to help you streamline your hiring
            </h3>
            <p>
              You may have multiple business locations that you might want to
              send workers to. By listing your different locations/branches
              here, you’ll be able to select from a drop down list when creating
              a job post.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(Locations);
